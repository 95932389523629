// Box Sizing
//---------------------------------------------

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

// HTML
//---------------------------------------------

html {
  height: 100%;
}

// Body
//---------------------------------------------

body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-family: 'proxima-nova', sans-serif;
  font-style: normal;
  line-height: 1.325;
  color: #4a5162;
  background: #f9f9f9 !important;
}

// Page
//---------------------------------------------

// .page {
//   display: flex;
//   flex-direction: column;
//   position: relative;
//   height: 100%;

//   &__content {
//     position: relative;
//     display: flex;
//     flex: 1;
//     width: 100%;
//   }
// }

.page {
  // padding-top: 3.5rem;
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100%;

  &__content,
  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
  }

  &__content {
    transition: all 0.3s ease-in-out;
    &.sidebar-expanded {
      padding-left: 13.75rem;
    }
    &.sidebar-collapsed {
      padding-left: 3.5rem;
    }
  }
}

// Content
//---------------------------------------------

.content {
  min-height: 100%;

  &.with-browse-list {
    min-height: calc(100vh - 3.5rem);

    .page__body {
      &.with-list-preview {
        min-height: calc(100vh - 3.5rem);
        // min-height: 100vh;
        flex: 1;
      }
    }
  }

  &--100h {
    height: 100%;
  }

  &--bg {
    position: relative;
    background: #223b61 url('/itarp-business-application/assets/images/AuthBG.jpg') center top / cover no-repeat
      fixed;
    padding-bottom: 3rem;
  }
}

// Designer
//---------------------------------------------

.designer {
  height: 100%;
  position: relative;
  padding: 3.5rem 0 0 13.75rem;
  transition: .5s;

  &.sidebar-collapsed {
    padding: 3.5rem 0 0 3.5rem;
  }

  &.designer--workflow {
    padding: 3.5rem 0 0 0;
  }
}

// Page Rosters
//---------------------------------------------

.page-rosters {
  display: flex;

  &__cards {
    flex: 1;

    .row + .row {
      .roster {
        padding-top: 2rem;
        border-top: 2px solid #d1dbe3;
      }
    }
  }

  &__controls {
    flex-shrink: 0;
    width: 20rem;
  }
}
