img {
  position: relative;

  &.cover {
    object-fit: cover;
    -o-object-fit: contain;
    width: 100%;
  }

  &.stretch {
    height: 100%;
    width: 100%;
  }

  &.fit {
    height: 100%;
  }
}
