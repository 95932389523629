// Panels
//---------------------------------------------

.panel {
  border-radius: $panel-border-radius;
  background-color: $panel-background;

  &__header {
    position: relative;
    padding: $panel-header-padding;
    border-radius: $panel-header-border-radius;

    &__title {
      margin: 0;
    }

    &__note {
      font-size: $panel-header-note-font-size;
      opacity: .8;
    }

    $header-variants: 'dark'$panel-header-background-dark,
      'primary'$panel-header-background-primary,
      'secondary'$panel-header-background-secondary;

    @each $variant,
    $color in $header-variants {
      &.panel__header--#{$variant} {
        background-color: $color;
        color: $panel-header-variant-text-color;
      }
    }
  }

  &__body {
    position: relative;
    border-radius: $panel-body-border-radius;

    &.with-sidebar {
      display: flex;

      .panel__body__form {
        flex: 1;
      }
    }

    &__form {
      padding: $panel-body-form-padding;
    }
  }

  @media only screen and (max-width: 768px) {
    &__header {
      padding: 1.125rem 1.25rem;

      &__title {
        font-size: 1.375rem;
      }

      &__note {
        font-size: 1.125rem;
      }
    }

    &__body {
      &.with-sidebar {
        flex-direction: column;
      }

      &__form {
        padding: .25rem .25rem;
      }
    }
  }
}

// .panel {
//   @media only screen and (max-width: 768px) {
//     &__header {
//       padding: 1.125rem 1.25rem;

//       &__title {
//         font-size: 1.375rem;
//       }

//       &__note {
//         font-size: 1.125rem;
//       }
//     }

//     &__body {
//       &.with-sidebar {
//         flex-direction: column;
//       }

//       &__form {
//         padding: .25rem .25rem;
//       }
//     }
//   }
// }

// Panel Auth
//---------------------------------------------

.panel-auth {
  margin: $panel-auth-margin;
  border-radius: $panel-auth-border-radius;
  background-color: $panel-auth-background;

  &__header {
    border-radius: $panel-auth-header-border-radius;
    background-color: $panel-auth-header-background;
    padding: $panel-auth-header-padding;

    &__title {
      color: $panel-auth-header-title-color;
      margin: 0;
    }

    &__subscript {
      color: $panel-auth-header-subscript-color;
      font-size: $panel-auth-header-subscript-font-size;
    }
  }

  @media only screen and (max-width: 768px) {
    &__header {
      padding: 1.125rem 1.25rem;

      &__title {
        font-size: 1.375rem;
      }

      &__note {
        font-size: 1.125rem;
      }
    }

    &__body {
      &.with-sidebar {
        flex-direction: column;
      }

      &__form {
        padding: .25rem .25rem;
      }
    }
  } 
}

// Template Preview
//---------------------------------------------

.template-preview {
  border: $template-preview-border;
  border-radius: $template-preview-border-radius;

  &__header {
    background-color: $template-preview-header-background;
    padding: $template-preview-header-padding;
    border-bottom: $template-preview-border;
    border-top-left-radius: $template-preview-border-radius;
    border-top-right-radius: $template-preview-border-radius;
  }

  &__body {
    overflow-y: auto;
    background-color: $template-preview-body-background;
    height: $template-preview-preview-body-height;
    border-radius: $template-preview-body-border-radius;
  }

  &__info {
    list-style-type: none;
    margin: 0;
    padding: 0;

    &__item {
      font-size: $template-preview-info-item-font-size;
      font-family: 'proxima-nova', sans-serif !important;

      span {
        font-weight: $template-preview-info-item-font-weight;
      }
    }

    &__item + &__item {
      margin-top: $template-preview-info-item-distance;
    }
  }

  &__content {
    padding: $template-preview-content-padding;

    p, li {
      font-size: $template-preview-content-text-font-size;

      .mceNonEditable {
        font-size: 0;
      }

      .mceNonEditable .mceNonEditable::before {
        padding: 0;
        font-size: 0;
      }
      
      .mceNonEditable:before {
        content: attr(data-display-name);
        font-size: 16px;
        display: inline-block;
        padding: 2px 12px;
        border-radius: 14px;
        margin: 4px 2px;
      }

      $mceNonEditable-colors: (
        'primary' #4586bc rgba(69, 134, 188, 0.15),
        'secondary' #3c956a rgba(60, 149, 106, 0.15),
        'warning' #88437d rgba(136, 67, 125, 0.15),
        'info' #e3795b rgba(227, 121, 91, 0.15)
      );
      
      @each $color, $value, $background in $mceNonEditable-colors {
        .mceNonEditable.tag-#{$color}:before {
          color: $value;
          background-color: $background;
        }
      }
    }

    ol,
    ul {
      padding: $template-preview-content-list-padding;
      margin: 0;
    }

    > * {
      margin: $template-preview-content-text-element-margin !important;

      &:last-child {
        margin: 0 !important;
      }
    }
  }
}

// Panel Widgets
//---------------------------------------------

.panel-widgets {
  background-color: $panel-widgets-background;
  border-radius: $panel-widgets-border-radius;
  box-shadow: $panel-widgets-box-shadow;
  overflow: hidden;

  &__inner {
    width: 100%;
    overflow-x: auto;
  }

  &__list__wrapper {
    margin: 0 auto;
    width: 100%;
    // max-width: $panel-widgets-list-wrapper-width;
  }

  &__list {
    list-style-type: none;
    margin: 0 auto;
    padding: 0;
    display: flex;

    &__item {
      flex: 1;
      display: flex;
      flex-direction: column;
      min-width: $panel-widgets-list-item-width;
      padding: $panel-widgets-list-item-padding;

      &__title {
        display: block;
        font-size: $panel-widgets-list-item-title-font-size;
        font-weight: $panel-widgets-list-item-title-font-weight;
        text-align: center;
        text-transform: uppercase;
        color: $panel-widgets-list-item-title-color;
      }

      &__icon {
        display: block;
        height: $panel-widgets-list-item-icon-height;
        margin: $panel-widgets-list-item-icon-margin;

        $widget-icons: 'add-candidate' 'add_candidate',
                       'approve-opportunity' 'approve_opportunity',
                       'create-talentcloud' 'create_talentcloud',
                       'interviews' 'interviews',
                       'opportunity-applications' 'opportunity_applications',
                       'post-opportunity' 'post_opportunity',
                       'reports' 'reports',
                       'roster-and-favorite' 'roster_and_favorite',
                       'talentcloud-applications' 'talentcloud_applications',
                       'talent-community-application' 'talent_community_application',
                       'tasks' 'tasks';

        @each $type, $icon in $widget-icons {
          &.for-#{$type} {
            background: url('/itarp-shared-assets-addon/images/widgets/#{$icon}.svg') center top no-repeat;
          }
        }

      }
    }
  }
}

// Roster
//---------------------------------------------

.roster {
  &-header {
    margin-bottom: $roster-header-margin-bottom;

    &__title {
      font-size: $roster-header-title-font-size;
      font-weight: $roster-header-title-font-weight;
      margin-bottom: 0;
    }

    &__text {
      font-size: $roster-header-text-font-size;

      &__label {
        display: block;
        font-weight: $roster-header-text-label-font-weight;
      }
    }

    &__info {
      list-style-type: none;
      padding: 0;
      margin: 0;

      li {
        font-size: $roster-header-info-font-size;
      }

      > li + li {
        margin-top: $roster-header-info-distance;
      }
    }

    > * + * {
      margin-top: $roster-header-elements-distance;
    }
  }
}

// Roster Group
//---------------------------------------------

.roster-group {
  border: $roster-group-border;
  border-radius: $roster-group-border-radius;
  box-shadow: $roster-group-box-shadow;

  &__header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: $roster-group-border;
    background-color: $roster-group-header-background;
    padding: $roster-group-header-padding;
    border-radius: $roster-group-header-border-radius;

    &__title {
      margin: 0;
      font-size: $roster-group-header-title-font-size;
      font-weight: $roster-group-header-title-font-weight;
    }

    &__controls {
      list-style-type: none;
      margin: $roster-group-header-controls-margin;
      padding: 0;
      display: inline-flex;

      &__item,
      &__item .ember-basic-dropdown-trigger {
        display: inline-flex;
        align-items: center;
      }

      > li + li {
        margin-left: $roster-group-header-controls-distance;
      }
    }
  }

  &__body {
    background-color: $roster-group-body-background;

    &__header {
      display: block;
      position: relative;
      padding: $roster-group-body-header-padding;
      color: $roster-group-body-header-color;
      text-decoration: none;
      cursor: pointer;
      outline: 0;
      user-select: none;

      &__text {
        font-size: $roster-group-body-header-font-size;
        font-weight: $roster-group-body-header-font-weight;
      }

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: $roster-group-body-header-caret-right;
        width: $roster-group-body-header-caret-width;
        height: $roster-group-body-header-caret-height;
        margin-top: $roster-group-body-header-caret-margin-top;
        background: url('/itarp-shared-assets-addon/images/arrow_down_dark.svg') center center no-repeat;
      }

      &:hover,
      &:focus {
        background-color: $roster-group-body-header-background-active;
        color: $roster-group-body-header-color;
      }

      &.is-expanded {
        &::after {
          transform: rotate(180deg);
        }
      }
    }

    &__content {
      border-radius: $roster-group-body-content-border-radius;
      overflow: hidden;

      &__pagination {
        display: flex;
        justify-content: center;
        padding: $roster-group-body-content-pagination-padding;
        border-top: $roster-group-border;
      }

      &.is-collapsed {
        transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
        max-height: 0;
      }

      &.is-expanded {
        transition: max-height 1s ease-in-out;
        max-height: 100rem;
      }
    }
  }
}

// Task Dashboard
//---------------------------------------------
.panel-widgets__list__item a {
  text-decoration: none;
}
