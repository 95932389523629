// Overview
//---------------------------------------------

.opportunity-overview {
  background-color: $opportunity-overview-background;
  background-image: url('/itarp-shared-assets-addon/images/opportunity_background.png');
  background-repeat: no-repeat;
  background-position: right bottom;
  border:1px solid $opportunity-border-color;
  border-radius:$opportunity-border-radius;

  &__inner {
    padding: $opportunity-overview-padding;
    display: flex;
    width: 100%;
  }

  &__item {
    padding: $opportunity-item-padding;
    text-align: center;
    min-width: $opportunity-item-min-width;
    // max-width: $opportunity-item-max-width;

    &__number {
      color: $opportunity-item-number-color;
      font-size: $opportunity-item-number-font-size;
      font-weight: $opportunity-item-number-font-weight;
      font-family: 'proxima-nova', sans-serif !important;
    }

    &__text {
      font-size: $opportunity-item-text-font-size;
      font-weight: $opportunity-item-text-font-weight;
      color: $opportunity-item-text-color;
      font-family: 'proxima-nova', sans-serif !important;
    }
  }

  &__item + &__item{
    border-left:1px solid $opportunity-divider-color;
  }

  @media only screen and (max-width: 1400px) {
    overflow-x: hidden;

    &__inner {
      overflow-x: auto;

      &::-webkit-scrollbar {
        height: $table-wrapper-scrollbar-width;
      }
    
      &::-webkit-scrollbar-track {
        background: $table-wrapper-scrollbar-background;
      }
    
      &::-webkit-scrollbar-thumb {
        background: $table-wrapper-scrollbar-thumb-background;
    
        &:hover {
          background: $table-wrapper-scrollbar-thumb-background-hover;
        }
      }
    }
  }  
}

// Onboarding Process
//---------------------------------------------

.opportunity-onboarding-process {
  background-color: $opportunity-onboarding-process-background-color;
  padding: $opportunity-onboarding-process-padding;
  border:1px solid $opportunity-border-color;
  border-radius:$opportunity-border-radius;
  overflow: hidden;

  &__inner {
    align-items: center;
    display: flex;
    width: 100%;
    overflow-x: auto;

    &::-webkit-scrollbar {
      height: $table-wrapper-scrollbar-width;
    }
  
    &::-webkit-scrollbar-track {
      background: $table-wrapper-scrollbar-background;
    }
  
    &::-webkit-scrollbar-thumb {
      background: $table-wrapper-scrollbar-thumb-background;
  
      &:hover {
        background: $table-wrapper-scrollbar-thumb-background-hover;
      }
    }
  }

  &__item {
    padding: $opportunity-onboarding-process-item-padding;
    text-align: center;
    position: relative;
    display: inline;

    &__number {
      color: $opportunity-onboarding-process-item-number-color;
      font-size: $opportunity-onboarding-process-item-number-font-size;
      font-weight: $opportunity-onboarding-process-item-number-font-weight;
      font-family: 'proxima-nova', sans-serif !important;
    }

    &__text {
      font-size: $opportunity-onboarding-process-item-text-font-size;
      font-weight: 400;
      padding: $opportunity-onboarding-process-item-text-padding;
      font-family: 'proxima-nova', sans-serif !important;

      & > a {
        text-decoration: none;
        font-size: inherit;
        color: #4a5162;
        user-select: none;
        white-space: nowrap;
        cursor: pointer;

        &:hover {
          color: #4ba4da;
        }

        &.is-active {
          font-weight: 600;
          color: #4ba4da;
        }
      }
    }

    &:not(:last-child)::after {
      content: '';
      position: absolute;
      width: $opportunity-onboarding-process-item-icon-arrow-width;
      height: $opportunity-onboarding-process-item-icon-arrow-height;
      right: 0;
      top: 50%;
      margin: $opportunity-onboarding-process-item-icon-arrow-margin;
      background: url('/itarp-shared-assets-addon/images/icon_arrow_right_blue.svg') center center / contain no-repeat;
    }
  }
}

// Candidate Status
//---------------------------------------------

.opportunity-candidate-status {
  background-color: $opportunity-candidate-status-background-color;
  padding: $opportunity-candidate-status-padding;
  border:1px solid $opportunity-border-color;
  border-radius:$opportunity-border-radius;
  overflow: hidden;

  &__inner {
    // align-items: center;
    display: flex;
    width: 100%;
    overflow-x: auto;

    &::-webkit-scrollbar {
      height: $table-wrapper-scrollbar-width;
    }
  
    &::-webkit-scrollbar-track {
      background: $table-wrapper-scrollbar-background;
    }
  
    &::-webkit-scrollbar-thumb {
      background: $table-wrapper-scrollbar-thumb-background;
  
      &:hover {
        background: $table-wrapper-scrollbar-thumb-background-hover;
      }
    }
  }

  &__item {
    padding: $opportunity-candidate-status-item-padding;
    text-align: center;
    position: relative;
    display: inline-flex;

    &__number {
      color: $opportunity-candidate-status-item-number-color;
      font-size: $opportunity-candidate-status-item-number-font-size;
      font-weight: $opportunity-candidate-status-item-number-font-weight;
      line-height: $opportunity-candidate-status-item-line-height;
      font-family: 'proxima-nova', sans-serif !important;
    }

    &__text {
      font-size: $opportunity-candidate-status-item-text-font-size;
      padding: $opportunity-candidate-status-item-text-padding;
      font-family: 'proxima-nova', sans-serif !important;
    }

    @media only screen and (max-width: 1400px) {
      flex-direction: column;

      .opportunity-candidate-status__item__text {
        font-size: 1rem;
      }
    }  
  }
  
  &__item + &__item{
    border-left:1px solid $opportunity-divider-color;
  }
}
