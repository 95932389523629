// Tables
//---------------------------------------------

// Table 

.table {
  width: 100%;
  border-spacing: 0;
  border: 1px solid #D1DBE3;
  border-radius: .25rem;

  &__head {
    tr {
      th {
        background-color: #F1F3F5;
        font-size: .875rem;
        font-weight: 600;
        text-transform: uppercase;
        padding: 1rem 1rem;
        text-align: left;

        &:first-child {
          border-radius: .25rem 0 0 0;
        }

        &:last-child {
          border-radius: 0 .25rem 0 0;
        }
      }
    }
  }

  &__body {
    tr {
      td {
        vertical-align: middle;
        // padding: 1rem 1.5rem;
        border-bottom: 1px solid #D1DBE3;
        background-color: #FFF;

        &.no-stage-actions {
          padding: .5rem 1.5rem;
        }
      }

      &:last-child {
        td {
          border-bottom: none;
        }
      }
    }
  }

  &__options {
    width: 10rem;

    &__wrapper {
      height: 100%;  
      display: flex;
      align-items: center;
    }

    &__controls {
      @extend .link-group;
      list-style-type: none;
      margin: 0;
      padding: 0;
    }
  }  
}

// Table Attributes

.table-attributes {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  margin-bottom: 1.5rem;

  thead {
    tr {
      th {
        padding: 0 .5rem 1rem;
        font-size: 1rem;
        font-weight: 600;
        text-align: left;
        border-bottom: 2px solid #D1DBE3;

        &:first-child {
          padding-left: 0;
          width: 50%;
        }

        &:last-child {
          padding-right: 0;
          width: 25%;
          text-align: right;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 1rem .5rem;
        border-bottom: 1px solid #D1DBE3;

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
          text-align: right;
        }
      }
    }
  }
}
