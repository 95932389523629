// Buttons
//---------------------------------------------

.app-btn {
  display: inline-block;
  outline: 0;
  // font-family: $btn-font-family;
  font-size: $btn-font-size;
  font-weight: $btn-font-weight;
  text-align: center;
  background-color: $btn-background-color;
  border: $btn-border;
  border-radius: $btn-border-radius;
  padding: $btn-padding;
  color: $btn-color;
  position: relative;
  cursor: pointer;
  line-height: $btn-line-height;
  transform: perspective(1px) translateZ(0);
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-property: color, background-color;
  transition-property: color, background-color;
  font-family: 'proxima-nova', sans-serif !important;

  &:disabled,
  &.disabled,
  &.is-disabled {
    background-color: lighten($btn-background-color, 10%);
    border-color: lighten($btn-background-color, 10%);
    color: lighten($btn-color, 10%);
    cursor: default;
  }

  &:not(:disabled):not(.disabled):hover,
  &:not(:disabled):not(.disabled):focus,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).is-active {
    background-color: darken($btn-background-color, 10%);
    border-color: darken($btn-background-color, 10%);
    color: $btn-color;
  }
}

// Button Shapes
//---------------------------------------------

.btn--squared {
  border-radius: 0;
}

.btn--rounded {
  border-radius: $btn-rounded-border-radius;
}

// Button Sizes
//---------------------------------------------

$button-sizes: 'xl' $btn-xl-padding $btn-xl-font-size $btn-xl-line-height,
  'lg' $btn-lg-padding $btn-lg-font-size $btn-lg-line-height,
  'sm' $btn-sm-padding $btn-sm-font-size $btn-sm-line-height,
  'xs' $btn-xs-padding $btn-xs-font-size $btn-xs-line-height;

@each $size, $padding, $font, $line in $button-sizes {
  .app-btn--#{$size} {
    @include button-size($padding, $font, $line);
  }
}

// Button Types
//---------------------------------------------

$button-types: 'primary' #4ba4da #fff, 'secondary' #50ae81 #fff,
  'info' #88437d #fff, 'danger' #ce4b58 #fff, 'light' #ebebed #222e43,
  'transparent' transparent #4a5162, 'link' #fff  #4ba4da, 'action' #fff #4A5162;

@each $type, $background, $color in $button-types {
  .app-btn--#{$type} {
    @include button-type-fill($background, $color);
  }
}

// Button Add
//---------------------------------------------

.btn-add {
  display: block;
  width: 100%;
  cursor: pointer;
  user-select: none;
  outline: 0;
  position: relative;
  transform: perspective(1px) translateZ(0);
  font-size: $btn-add-font-size;
  font-weight: $btn-add-font-weight;
  text-transform: uppercase;
  color: $btn-add-color;
  background-color: $btn-add-background;
  border: $btn-add-border;
  border-radius: $btn-add-border-radius;
  padding: $btn-add-padding;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-property: color, border-color;
  transition-property: color, border-color;

  &__label {
    &::before {
      content: '+';
      display: inline-block;
      margin: $btn-add-plus-margin;
    }
  }

  &:disabled,
  &.disabled,
  &.is-disabled {
    opacity: 0.5;
    cursor: default;
  }

  &:not(:disabled):not(.disabled):hover,
  &:not(:disabled):not(.disabled):focus,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).is-active {
    border-color: $btn-add-border-color-active;
    color: $btn-add-color-active;
  }

  &.btn-add--sm {
    font-size: $btn-add-font-size-sm;
    font-weight: $btn-add-font-weight-sm;
    border: $btn-add-border-sm;
    padding: $btn-add-padding-sm;
  }

  &.with-icon {
    .btn-add__label {
      position: relative;
      padding-left: $btn-add-icon-padding-left;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: $btn-add-icon-margin-top;
        width: $btn-add-icon-width;
        height: $btn-add-icon-height;
        background: url('/itarp-shared-assets-addon/images/buttons/add_circle.svg')
          center center no-repeat;
      }
    }
  }
}

// Button Inline
//---------------------------------------------

.btn-inline {
  position: relative;
  display: inline-flex;
  outline: 0;
  cursor: pointer;
  padding: 0;
  text-decoration: none;
  text-transform: uppercase;
  border: $button-inline-border;
  border-radius: $button-inline-border-radius;
  background-color: $button-inline-background;
  height: $button-inline-height;
  min-width: $button-inline-min-width;

  &__label {
    display: inline-flex;
    align-items: center;
    font-size: $button-inline-label-font-size;
    font-weight: $button-inline-label-font-weight;
    line-height: $button-inline-label-line-height;
    border-left: $button-inline-border;
    margin-left: $button-inline-label-margin-left;
    padding: $button-inline-label-padding;
    color: $button-inline-label-color;
    height: 100%;
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: $button-inline-icon-wrapper-width;
    border-radius: $button-inline-icon-wrapper-border-radius;
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: $button-inline-icon-left;
    height: $button-inline-icon-height;
    width: $button-inline-icon-width;
    margin: $button-inline-icon-margin;
  }

  $link-type: 'trash' 'trash', 'pencil' 'edit';

  @each $name, $icon in $link-type {
    &.icon-#{$name} {
      &::after {
        background: url('/itarp-shared-assets-addon/images/icon_#{$icon}.svg')
          center
          center /
          contain
          no-repeat;
      }

      &:hover,
      &:focus,
      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled).active {
        &::after {
          background: url('/itarp-shared-assets-addon/images/icon_#{$icon}_active.svg')
            center
            center /
            contain
            no-repeat;
        }
      }
    }
  }

  &:not(:disabled):not(.disabled):hover,
  &:not(:disabled):not(.disabled):focus,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).is-active {
    background-color: $button-inline-background-active;
    border-color: $button-inline-background-active;

    .btn-inline__label {
      color: $button-inline-label-color-active;
    }
  }
}

// Button Icon
//---------------------------------------------

.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &--add {
    &::before {
      content: '';
      display: inline-block;
      width: $btn-icon-add-icon-width;
      height: $btn-icon-add-icon-height;
      margin: $btn-icon-add-icon-margin;
      background: url('/itarp-shared-assets-addon/images/buttons/add_circle.svg')
        center center no-repeat;
    }
  }

  &--remove {
    &::before {
      content: '';
      display: inline-block;
      width: $btn-icon-remove-icon-width;
      height: $btn-icon-remove-icon-height;
      margin: $btn-icon-remove-icon-margin;
      background: url('/itarp-shared-assets-addon/images/buttons/icon_remove_primary.svg')
        center center no-repeat;
    }
  }

  &--publish {
    &::before {
      content: '';
      display: inline-block;
      width: $btn-icon-publish-icon-width;
      height: $btn-icon-publish-icon-height;
      margin: $btn-icon-publish-icon-margin;
      background: url('/itarp-shared-assets-addon/images/buttons/publish.svg')
        center center no-repeat;
    }
  }

  &--new {
    &::before {
      content: '';
      display: inline-block;
      width: $btn-icon-add-icon-width;
      height: $btn-icon-add-icon-height;
      margin: $btn-icon-add-icon-margin;
      background: url('/itarp-shared-assets-addon/images/buttons/add.svg')
        center center no-repeat;
    }

    &.btn-outline {
      &::before {
        background: url('/itarp-shared-assets-addon/images/buttons/add_default.svg')
          center center no-repeat;
      }
    }

    &.btn-outline--primary {
      &::before {
        background: url('/itarp-shared-assets-addon/images/buttons/add_primary.svg')
          center center no-repeat;
      }
    }
  }

  &--new {
    &::before {
      content: '';
      display: inline-block;
      width: $btn-icon-add-icon-width;
      height: $btn-icon-add-icon-height;
      margin: $btn-icon-add-icon-margin;
      background: url('/itarp-shared-assets-addon/images/buttons/add.svg')
        center center no-repeat;
    }

    &.btn-outline {
      &::before {
        background: url('/itarp-shared-assets-addon/images/buttons/add_default.svg')
          center center no-repeat;
      }
    }

    &.btn-outline--primary {
      &::before {
        background: url('/itarp-shared-assets-addon/images/buttons/add_primary.svg')
          center center no-repeat;
      }
    }
  }

  &--user-remove,
  &--user-export {
    &::before {
      content: '';
      top: 50%;
      position: absolute;
      width: $btn-icon-user-action-icon-width;
      height: $btn-icon-user-action-icon-height;
      margin: $btn-icon-user-action-icon-margin;
      background: url('/itarp-shared-assets-addon/images/buttons/user_remove.svg')
        center center no-repeat;
    }

    &.app-btn--sm {
      padding-left: $btn-icon-user-action-padding-left;

      &::before {
        left: $btn-icon-user-action-icon-left;
      }
    }
  }

  &--user-remove {
    &::before {
      background: url('/itarp-shared-assets-addon/images/buttons/user_remove.svg')
        center center no-repeat;
    }
  }

  &--user-export {
    &::before {
      background: url('/itarp-shared-assets-addon/images/buttons/user_export.svg')
        center center no-repeat;
    }
  }

  &--prev,
  &--prev-dark {
    &::before {
      content: '';
      width: $btn-icon-arrow-width;
      height: $btn-icon-arrow-height;
      margin-right: $btn-icon-arrow-margin;
    }
  }

  &--prev {
    &::before {
      background: url('/itarp-shared-assets-addon/images/arrow_left_white.svg')
        center center no-repeat;
    }
  }

  &--prev-dark {
    &::before {
      background: url('/itarp-shared-assets-addon/images/arrow_left_dark.svg')
        center center no-repeat;
    }
  }

  &--next,
  &--next-dark {
    &::after {
      content: '';
      width: $btn-icon-arrow-width;
      height: $btn-icon-arrow-height;
      margin-left: $btn-icon-arrow-margin;
    }
  }

  &--next {
    &::after {
      background: url('/itarp-shared-assets-addon/images/arrow_right_white.svg')
        center center no-repeat;
    }
  }

  &--next-dark {
    &::after {
      background: url('/itarp-shared-assets-addon/images/arrow_right_dark.svg')
        center center no-repeat;
    }
  }

  &--check {
    &::before {
      content: '';
      width: 1rem;
      height: .75rem;
      margin-right: .5rem;
      background: url('/itarp-shared-assets-addon/images/check_white.svg') center center no-repeat;
    }
  }

  $button-icons: 'like' 'like' 1.25rem 1.25rem,
                 'dislike' 'dislike' 1.25rem 1.25rem,
                 'shortlisted' 'shortlisted' 1.75rem 1.25rem,
                 'schedule' 'schedule' 1.375rem 1.375rem,
                 'chat' 'chat' 1.5rem 1.5rem,
                 'text-message' 'text_message' 1.25rem 1.5rem,
                 'share' 'share' 1.25rem 1.375rem,
                 'decline' 'decline' .875rem .875rem,
                 'feedback' 'feedback' 1.375rem 1.375rem,
                 'guideline' 'guideline' 1.25rem 1.25rem,
                 'questionarie' 'questionarie' 1.75rem 1.25rem,
                 'roster' 'roster' 1.25rem 1.25rem,
                 'attach_interview_guide' 'guideline' 1.25rem 1.25rem,
                 'attach_scorecard' 'guideline' 1.25rem 1.25rem,
                 'schedule_interview' 'schedule' 1.375rem 1.375rem,
                 'send_assessment' 'guideline' 1.25rem 1.25rem,
                 'send_questionnaire' 'questionarie' 1.975rem 1.25rem,
                 'shortlist_for_interview' 'shortlisted' 1.25rem 1.25rem,
                 'send_text_message' 'text_message' 1.5rem 1.5rem,
                 'send_message' 'send_message' 1.25rem 1.25rem,
                 'fit_buttons' 'fit_buttons' 1.975rem 1.975rem,
                 'request_interview_feedback' 'feedback' 1.375rem 1.375rem,
                 'hire' 'hire' 1.375rem 1.375rem,
                 'view_offer' 'view_offer' 1.375rem 1.375rem;

  @each $type, $icon, $width, $height in $button-icons {
    $margin-top: math.div($height, 2);

    &--#{$type} {
      position: relative;
      padding-left: calc(1.5rem + #{$width});

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        width: $width;
        height: $height;
        margin-top: -$margin-top;
        background: url('/itarp-shared-assets-addon/images/buttons/#{$icon}.svg')
          center
          center / contain
          no-repeat;
      }

      &.app-btn--sm {
        &::before {
          left: $btn-icon-user-action-icon-left;
        }
      }

      @if $type == 'like' {
        &.is-active {
          color: #fff !important;
          background-color: #50ae81 !important;

          &::before {
            background: url('/itarp-shared-assets-addon/images/buttons/like_active.svg')
                        center
                        center
                        no-repeat;
          }
        }
      }

      @if $type == 'dislike' {
        &.is-active {
          color: #fff !important;
          background-color: #de5e6a !important;

          &::before {
            background: url('/itarp-shared-assets-addon/images/buttons/dislike_active.svg')
                        center
                        center
                        no-repeat;
          }
        }
      }
    }
  }
}

// Button Dropdown
//---------------------------------------------

.btn-dropdown {
  position: relative;
  display: inline-flex;
  align-items: center;
  outline: 0;
  cursor: pointer;
  user-select: none;
  color: $btn-dropdown-color !important;
  padding: $btn-dropdown-padding;
  font-size: $btn-dropdown-font-size;
  font-weight: $btn-dropdown-font-weight;
  border: $btn-dropdown-border;
  border-radius: $btn-dropdown-border-radius;
  line-height: $btn-dropdown-line-height;
  font-family: 'proxima-nova', sans-serif !important;
  white-space: nowrap;

  &::after {
    content: '';
    position: absolute;
    right: $btn-dropdown-caret-right;
    top: 50%;
    margin-top: $btn-dropdown-caret-margin-top;
    width: $btn-dropdown-caret-width;
    height: $btn-dropdown-caret-height;
    background: url('/itarp-shared-assets-addon/images/arrow_down_blue.svg')
      center center no-repeat;
  }

  $dropdown-types: 'filters' 1.375rem 1.25rem '', 'email' 1.25rem 1.25rem '',
    'columns' 1.375rem 1.25rem 'contain', 'trigger' 1rem 1.375rem '',
    'more' 0.25rem 1rem 'contain';

  @each $type, $width, $height, $size in $dropdown-types {
    $margin-top: math.div($height, 2);

    &.btn-dropdown--#{$type} {
      padding-left: calc(#{$width} + 1.5rem);

      &::before {
        content: '';
        position: absolute;
        left: 1rem;
        top: 50%;
        margin-top: -$margin-top;
        width: $width;
        height: $height;

        @if $size == 'contain' {
          background: url('/itarp-shared-assets-addon/images/buttons/#{$type}.svg')
            center
            center /
            contain
            no-repeat;
        } @else {
          background: url('/itarp-shared-assets-addon/images/buttons/#{$type}.svg')
            center
            center
            no-repeat;
        }
      }
    }
  }
}

// Button Question
//---------------------------------------------

.btn-questions {
  display: flex;
  flex-wrap: wrap;

  > * {
    margin: $btn-questions-distance;
  }
}

.btn-question {
  position: relative;
  display: inline-flex;
  align-items: center;
  user-select: none;
  outline: none;
  cursor: pointer;
  font-size: $btn-question-font-size;
  font-weight: $btn-question-font-weight;
  line-height: $btn-question-line-height;
  text-transform: uppercase;
  color: $btn-question-color;
  background-color: $btn-question-background;
  border: $btn-question-border;
  border-radius: $btn-question-border-radius;
  padding-top: $btn-question-padding-top;
  padding-bottom: $btn-question-padding-bottom;
  padding-right: $btn-question-padding-right;
  font-family: 'proxima-nova', sans-serif !important;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: $btn-question-icon-left;
  }

  $question-types: 'experience' 'experience' 1.25rem 1.25rem,
    'authorization' 'authorization' 1.125rem 1.25rem,
    'certification' 'certification' 1.25rem 1.25rem,
    'custom' 'custom' 1.5rem 1.5rem, 'travel' 'travel' 1.25rem 1.75rem,
    'visa' 'visa' 1.125rem 1.25rem;

  @each $type, $icon, $width, $height in $question-types {
    $half-height: math.div($height, 2);

    &.btn-question--#{$type} {
      padding-left: calc(2rem + #{$width});

      &::before {
        width: $width;
        height: $height;
        margin-top: -$half-height;
        background: url('/itarp-shared-assets-addon/images/buttons/question_#{$icon}.svg')
          center
          center /
          contain
          no-repeat;
      }
    }
  }

  &.btn-question--add {
    &::after {
      content: '';
      display: inline-block;
      width: $btn-question-add-icon-width;
      height: $btn-question-add-icon-height;
      margin-left: $btn-question-add-icon-margin-left;
      background: url('/itarp-shared-assets-addon/images/buttons/add_default.svg')
        center center / contain no-repeat;
    }
  }
}

// Button Square
//---------------------------------------------

.btn-square {
  display: inline-block;
  cursor: pointer;
  position: relative;
  border: $btn-square-border;
  border-radius: $btn-square-button-radius;
  background-color: $btn-square-background;
  width: $btn-square-width;
  height: $btn-square-height;
  outline: 0;
  transform: perspective(1px) translateZ(0);
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-property: background-color;
  transition-property: background-color;

  &:disabled,
  &.disabled,
  &.is-disabled {
    background-color: lighten($btn-square-background, 10%);
    border-color: lighten($btn-square-background, 10%);
    cursor: default;
  }

  &:not(:disabled):not(.disabled):hover,
  &:not(:disabled):not(.disabled):focus,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).is-active {
    background-color: darken($btn-square-background, 10%);
    border-color: darken($btn-square-background, 10%);
  }

  &::before {
    $icon-margin: math.div($btn-square-icon-size, 2);

    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: $btn-square-icon-size;
    height: $btn-square-icon-size;
    margin-left: -$icon-margin;
    margin-top: -$icon-margin;
  }
}

// Button Square Sizes
//---------------------------------------------

$button-square-sizes: 'xl' $btn-square-xl-size $btn-square-xl-icon-size,
  'lg' $btn-square-lg-size $btn-square-lg-icon-size,
  'md' $btn-square-md-size $btn-square-md-icon-size,
  'sm' $btn-square-sm-size $btn-square-sm-icon-size,
  'xs' $btn-square-xs-size $btn-square-xs-icon-size;

@each $size, $dimension, $icon-dimension in $button-square-sizes {
  .btn-square--#{$size} {
    @include button-square-size($dimension, $icon-dimension);
  }
}

// Button Square Variants
//---------------------------------------------

$button-square-variants: 'light' $btn-square-light-color,
  'dark' $btn-square-dark-color, 'primary' $button-primary-background,
  'secondary' $button-secondary-background, 'default' $btn-square-default-color;

@each $variant, $color in $button-square-variants {
  .btn-square--#{$variant} {
    @include button-square-variant($color);
  }
}

// Button Square Types
//---------------------------------------------

$button-square-types: 'assessment', 'more', 'schedule', 'score', 'share',
  'invitation', 'favorite';

@each $type in $button-square-types {
  .btn-square--#{$type} {
    @include button-square-type($type);
  }
}

// Button Outline
//---------------------------------------------

.app-btn-outline {
  @extend .app-btn;
  color: $btn-outline-color;
  padding: $btn-outline-padding;
  border: $btn-outline-border;
  background-color: transparent;

  &:disabled,
  &.disabled,
  &.is-disabled {
    background-color: transparent;
    border-color: lighten($btn-outline-color, 10%);
    color: lighten($btn-outline-color, 10%);
    cursor: default;
  }

  &:not(:disabled):not(.disabled):hover,
  &:not(:disabled):not(.disabled):focus,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).is-active {
    background-color: rgba($btn-outline-color, 0.15);
    border-color: $btn-outline-color;
    color: $btn-outline-color;
  }
}

// Button Outline Types
//---------------------------------------------

$button-outline-types: 'primary' $button-primary-background,
  'secondary' $button-secondary-background, 'alert' $button-alert-background;

@each $type, $color in $button-outline-types {
  .app-btn-outline--#{$type} {
    @include button-type-outline($color);
  }
}

.app-btn-outline--banner {
  color: $btn-outline-banner-color;
  border: $btn-outline-banner-border;
  background-color: transparent;

  &:disabled,
  &.disabled,
  &.is-disabled {
    background-color: transparent;
    border-color: lighten($btn-outline-banner-color, 10%);
    color: lighten($btn-outline-banner-color, 10%);
    cursor: default;
  }

  &:not(:disabled):not(.disabled):hover,
  &:not(:disabled):not(.disabled):focus,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).is-active {
    background-color: $btn-outline-banner-background-active;
    border-color: $btn-outline-banner-color-active;
    color: $btn-outline-banner-color;
  }

  &.on-right {
    display: inline-flex;
    justify-content: center;
    align-items: center;

    &::after {
      content: '';
      display: inline-block;
      width: $btn-outline-banner-arrow-width;
      height: $btn-outline-banner-arrow-height;
      margin-left: $btn-outline-banner-arrow-margin-left;
      border-left: $btn-outline-banner-arrow-border-left;
      background: url('/itarp-shared-assets-addon/images/arrow_right_white.svg')
        right 0 center no-repeat;
    }
  }
}

// Button Outline Sizes
//---------------------------------------------

$button-outline-sizes: 'xl' $btn-outline-xl-padding $btn-outline-xl-font-size
    $btn-outline-xl-line-height,
  'lg' $btn-outline-lg-padding $btn-outline-lg-font-size
    $btn-outline-lg-line-height,
  'md' $btn-outline-md-padding $btn-outline-md-font-size
    $btn-outline-md-line-height,
  'sm' $btn-outline-sm-padding $btn-outline-sm-font-size
    $btn-outline-sm-line-height,
  'xs' $btn-outline-xs-padding $btn-outline-xs-font-size
    $btn-outline-xs-line-height;

@each $size, $padding, $font, $line in $button-outline-sizes {
  .app-btn-outline--#{$size} {
    @include button-size($padding, $font, $line);
  }
}

// Button Search
//---------------------------------------------

.btn--search {
  position: relative;
  height: $button-search-min-height;

  &::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: $button-search-icon-width;
    height: $button-search-icon-height;
    margin: $button-search-icon-margin;
    background: url('/itarp-shared-assets-addon/images/buttons/search.svg') center center / 1.75rem 1.75rem no-repeat;
  }
}

// Button Switch
//---------------------------------------------

.btn--switch {
  display: inline-flex;
  align-items: center;
  padding-top: .5rem;
  padding-bottom: .5rem;
  min-height: 3rem;

  .form-switch {
    margin-right: .5rem;
  }
}

// Button Uppercase
//---------------------------------------------

.btn--upc {
  text-transform: uppercase;
}

// Button Cursor
//---------------------------------------------

.btn--not-clickable {
  cursor: default
}

// Button Scorecard
//---------------------------------------------

.btn-scorecard {
  display: inline-block;

  & .dropdown {
    & > button {
      display: inline-block;
      width: 3rem;
      height: 3rem;
      border-radius: .25rem;
      background-color: #243e63;
      border-color: #243e63;
      cursor: pointer;
      position: relative;
      min-width: 3rem !important;

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 1.75rem;
        height: 1.75rem;
        margin-left: -0.875rem;
        margin-top: -0.875rem;
        background-image: url('/itarp-shared-assets-addon/images/buttons/btn_score_dark.svg');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
      }

      &:hover,
      &:focus {
        background-color: #16273e;
        border-color: #16273e;
      }
    }

    & .dropdown-menu {
      background-color: #fff;
      z-index: 100;

      & > a {
        &:first-child {
          display: block;
          padding: .75rem 1rem;
          font-size: 1rem !important;
          font-weight: 600 !important;
          font-family: 'proxima-nova', sans-serif !important;
          border-bottom: 1px solid $border-color;
          text-decoration: none;
        }
      }

      & > hr {
        display: none;
      }
    }
  }
}
// Button Min Width
//---------------------------------------------

.btn--mw {
  min-width: 7.5rem;
}
