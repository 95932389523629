// Navs
//---------------------------------------------

.nav {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  margin: 1rem 0;

  &__link {
    display: inline-block;
    padding: 1rem 0;
    position: relative;
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;
    text-decoration: none;
    color: #4BA4DA;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      height: .375rem;
      background-color: #4BA4DA;
      display: none;
    }

    &:hover,
    &:focus {
      &::after {
        display: block;
      }
    }

    &.is-active {
      color: #4a5162;

      &::after {
        display: block;
        background-color: #4a5162;
      }
    }
  }
}