// Nav Source
//---------------------------------------------

.nav-source {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: $nav-source-height;
  border-radius: $nav-source-border-radius;
  background-color: $nav-source-background;
  padding: $nav-source-padding;

  &__title {
    font-size: $nav-source-title-font-size;
    font-weight: $nav-source-title-font-weight;
    margin: 0;
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: $nav-source-icon-right;
    width: $nav-source-icon-width;
    height: $nav-source-icon-height;
    margin-top: $nav-source-icon-margin-top;
    border-radius: 50%;
    transform: rotate(180deg);
    background: $nav-source-icon-background url('/itarp-shared-assets-addon/images/arrow_left_white.svg') $nav-source-icon-left center no-repeat;
  }
}
