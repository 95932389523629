// Links
//---------------------------------------------

// Link Group

.link-group {
  display: inline-flex;
  
  > * {
    border-radius: 0;
    height: 2rem;
    width: 2.375rem;
    position: relative;
    background-color: #FFF;
    border: 1px solid #D1DBE3;
    padding: 0;
    margin: 0;

    &:first-child {
      border-top-left-radius: .25rem;
      border-bottom-left-radius: .25rem;
    }

    &:last-child {
      border-top-right-radius: .25rem;
      border-bottom-right-radius: .25rem;
    }

    &:hover,
    &:focus,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      background-color: #4BA4DA;
    }

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      height: 1.25rem;
      width: 1.25rem;
      margin: -.625rem 0 0 -.625rem;
    }

    $link-icons: 'remove' 'trash' 'auto',
                 'add' 'add' 'auto',
                 'edit' 'edit' 'auto', 
                 'move' 'move' 'contain',
                 'launch' 'play' '.875rem 1.125rem';

    @each $name, $icon, $size in $link-icons {
      &.for-#{$name} {
        &::after {
          background: url('/itarp-shared-assets-addon/images/icon_#{$icon}.svg') center center / #{$size} no-repeat;
        }  

        &:hover,
        &:focus,
        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled).active {
          &::after {
            border-color: #4BA4DA;
            background: #4BA4DA url('/itarp-shared-assets-addon/images/icon_#{$icon}_active.svg') center center / #{$size} no-repeat;
          }
        }
      }
    }             
  }

  > * + * {
    border-left-width: 0;
  }
}


