// Typography
//---------------------------------------------

// Headings
//---------------------------------------------

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: $headings-font-weight !important;
  font-family: 'proxima-nova', sans-serif !important;
}

h1 {
  font-size: $headings-h1-font-size;
}

h2 {
  font-size: $headings-h2-font-size;
}

h3 {
  font-size: $headings-h3-font-size;
  line-height: $headings-h3-line-height;
}

h4 {
  font-size: $headings-h4-font-size;
}

h5 {
  font-size: $headings-h5-font-size;
}

// Paragraph
//---------------------------------------------

p {
  font-family: 'proxima-nova', sans-serif;
}

// Link
//---------------------------------------------

a,
.link {
  color: $link-color;
  outline: 0;
  cursor: pointer;
  font-family: 'proxima-nova', sans-serif !important;

  &:hover,
  &:focus {
    color: $link-color-active;
  }

  &.link--add {
    display: inline-block;
    position: relative;
    font-weight: $link-add-font-weight;
    padding: $link-add-padding;
    margin: $link-add-margin;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: $link-add-icon-margin-top;
      width: $link-add-icon-weight;
      height: $link-add-icon-height;
      background: url('/itarp-shared-assets-addon/images/buttons/add_primary.svg')
        center center no-repeat;
    }
  }


  &.link--back {
    display: inline-block;
    position: relative;
    font-weight: $link-add-font-weight;
    padding: $link-add-padding;
    margin: $link-add-margin;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: $link-add-icon-margin-top;
      width: $link-add-icon-weight;
      height: $link-add-icon-height;
      background: url('/itarp-shared-assets-addon/images/arrow_left_blue.svg')
        center center no-repeat;
    }
  }

  &.link--caret {
    display: inline-block;
    position: relative;
    font-weight: $link-caret-font-weight;
    padding: $link-caret-padding;

    &::after {
      content: '';
      position: absolute;
      right: 0;
      top: 50%;
      margin-top: $link-caret-icon-margin-top;
      width: $link-caret-icon-width;
      height: $link-caret-icon-height;
      background: url('/itarp-shared-assets-addon/images/arrow_down_blue.svg')
        center center no-repeat;
    }

    &.is-open {
      &::after {
        transform: rotate(180deg);
      }
    }
  }

  &.link--preview {
    display: inline-block;
    position: relative;
    font-size: $link-preview-font-size;
    font-weight: $link-preview-font-weight;
    padding: $link-preview-padding;
    color: $link-preview-color;
    text-decoration: none;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: $link-preview-icon-margin-top;
      width: $link-preview-icon-width;
      height: $link-preview-icon-height;
      background: url('/itarp-shared-assets-addon/images/buttons/preview.svg')
        center center no-repeat;
    }
  }

  &.link--settings {
    display: inline-block;
    width: $link-settings-weight;
    height: $link-settings-height;
    background: url('/itarp-shared-assets-addon/images/icon_cog.svg') center
      center no-repeat;
  }

  &.no-underline {
    text-decoration: none;
  }
}

// Decoration
//---------------------------------------------

.text--break {
  display: block;
}

.text--highlighted-primary {
  color: $text-highlighted-primary-color;
}

.text--highlighted-secondary {
  color: $text-highlighted-secondary-color;
}

.text--faded {
  color: $text-faded-color;
}

.text--align-vertical {
  display: flex;
  align-items: center;

  > * {
    margin: $text-align-verticaly-element-margin;
  }
}

.text--center {
  text-align: center;
}

.text--uppercase {
  text-transform: uppercase;
}

.text--italic {
  font-style: italic;
}

.text--underline {
  text-decoration: underline;
}

.text--bold {
  font-weight: 600;
}

.text--flex {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  span {
    margin: $text-flex-span-margin;
  }
}

.text--with-icon {
  position: relative;
  padding-left: $text-with-icon-padding-left;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: $text-with-icon-width;
    height: $text-with-icon-height;
    margin-top: $text-with-icon-margin-top;
  }

  $text-icons: 'experience' 'experience', 'authorization' 'authorization',
    'certification' 'certification', 'custom' 'custom', 'travel' 'travel',
    'visa' 'visa';

  @each $type, $icon in $text-icons {
    &.for-#{$type} {
      &::before {
        background: url('/itarp-shared-assets-addon/images/buttons/question_#{$icon}.svg')
          center
          center /
          contain
          no-repeat;
      }
    }
  }
}

.text--with-underline {
  padding-bottom: $text-underline-padding-bottom;
  border-bottom: $text-underline-border;
}

// Text with Icon Link
//---------------------------------------------

.text-with-icon-link {
  display: flex;
  align-items: center;

  .text-icon-link {
    display: inline-flex;
    align-items: center;
    margin-left: $text-icon-link-margin;

    .text-icon-link__trigger {
      display: inline-block;
      width: $text-icon-link-width;
      height: $text-icon-link-height;
      cursor: pointer;
      outline: 0;
    }

    &.for-settings {
      .text-icon-link__trigger {
        background: url('/itarp-shared-assets-addon/images/icon_cog.svg') center
          center / contain no-repeat;
      }
    }
  }
}

// Text with Controls
//---------------------------------------------

.text-with-controls {
  display: flex;
  align-items: center;

  .text-controls {
    margin-left: $text-controls-margin;
  }
}

.text-controls {
  @include link-group('large');
}

// Icons
//---------------------------------------------

.text-icon {
  position: relative;
  padding-left: $text-icon-padding-left;

  &::before {
    content: '';
    position: absolute;
    top: $text-icon-top;
    left: 0;
    width: $text-icon-width;
    height: $text-icon-height;
  }

  $text-icons: 'employment', 'education', 'certification';

  @each $icon in $text-icons {
    &.text-icon--#{$icon} {
      &::before {
        background-image: url('/itarp-shared-assets-addon/images/cards/icon_#{$icon}.svg');
        background-repeat: no-repeat;
        background-position: center center;
      }
    }
  }
}

// Cursor
//---------------------------------------------

.cursor--pointer {
  cursor: pointer;
}

// Cursor
//---------------------------------------------

.d-flex {
  display: flex;
}
