// Workflow
//---------------------------------------------

// Editor

.workflow-editor {
  display: flex;
  justify-content: center;
  position: relative;
}

// Diagram

.workflow {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__start {
    position: relative;
    padding: 0;

    &__label {
      display: flex;
      align-items: center;
      position: relative;
      background-color: #213558;
      color: #FFF;
      font-size: 1rem;
      line-height: 1;
      padding: .625rem 2.5rem;
      border-radius: 1.25rem;
      min-height: 2.5rem;
    }
  }

  &__add {
    position: relative;
    padding: 2rem 0 3.5rem;
    display: flex;

    &__button {
      position: relative;
      display: inline-block;
      width: 1.375rem;
      height: 1.375rem;
      border-radius: 50%;
      outline: 0;
      z-index: 1;
      background-color: #4BA4DA;
      cursor: pointer;
      background: #4BA4DA url('/itarp-shared-assets-addon/images/icon_add_active.svg') center center / .625rem .625rem no-repeat;

      &:hover,
      &:focus {
        background-color: darken(#4BA4DA, 10%);
      }
    }

    &__label {
      position: absolute;
      top: 2.125rem;
      left: calc(50% + 1rem);
      width: 5rem;
      font-size: .875rem;
    }

    &::before {
      content: '';
      position: absolute;
      width: 2px;
      height: 5.375rem;
      background-color: #D1DBE3;
      top: 0;
      left: 50%;
      margin-left: -1px;
    }

    &::after {
      content: '';
      position: absolute;
      width: 1.5rem;
      height: 1.5rem;
      bottom: 0;
      left: 50%;
      margin-left: -.75rem;
      border: 2px dashed #D1DBE3;
    }

    &.action-added {
      padding: 0 0 2rem;

      &::before {
        height: auto;
        bottom: 0;
      }

      &::after {
        display: none;
      }
    }
  }

  &__save {
    margin: 2rem 0 0;
    min-width: 10rem;
  }

  &__action {
    position: relative;
    padding: 2rem 0 0;

    &::before {
      content: '';
      position: absolute;
      width: 2px;
      height: 2rem;
      background-color: #D1DBE3;
      top: 0;
      left: 50%;
      margin-left: -1px;
    }
  }
}

.workflow-action-card {
  background-color: #FFF;
  border-radius: .25rem;
  border: 1px solid #D1DBE3;
  width: 20rem;
  box-shadow: 0 1px 3px rgba(#000, .15);

  &__header {
    position: relative;
    padding: .75rem 2.5rem .75rem 3.5rem; 

    &__title {
      margin: 0;
    }

    &__edit {
      position: absolute;
      right: 1rem;
      top: 50%;
      margin: -.625rem 0 0;
      width: 1.25rem;
      height: 1.25rem;
      outline: 0;
      cursor: pointer;
      background: url('/itarp-shared-assets-addon/images/icon_edit.svg') center center / contain no-repeat;
    }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 2.5rem;
    }
  }

  &__body {
    padding: 1rem 2rem 1rem;
    font-size: 1rem;
    line-height: 1.25; 
    min-height: 5.75rem;
  }

  $workflow-actions: 'workflow' #4BA4DA,
                     'internal-communication' #A0A8B7,
                     'external-communication' #A0A8B7,
                     'task' #54A5B4,
                     'interview' #78C493,
                     'assessment' #78C493,
                     'candidate' #925C8B,
                     'owner' #E37983,
                     'other-actions' #E68870;

    @each $action, $color in $workflow-actions {
      &.for-#{$action} {
        border-color: #{$color};

        .workflow-action-card__header {
          background-color: rgba($color, .2);

          &::before {
            background-color: #{$color};
          }
        }
      }
    }
}