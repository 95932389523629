// Farm Alert
//---------------------------------------------

.form-alert {
  position: relative;
  background-color: $form-alert-background;
  border: $form-alert-border;
  border-radius: $form-alert-border-radius;
  padding: $form-alert-padding;

  &__icon {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: $form-alert-icon-width;
    border-radius: $form-alert-icon-border-radius;
    background: $form-alert-icon-background
                url('/itarp-shared-assets-addon/images/alerts/alert_danger.svg')
                center center no-repeat;
  }

  &__content {
    padding: $form-alert-content-padding;
    font-size: $form-alert-content-font-size;
    color: $form-alert-content-color;

    &.is-centered {

      p {
        margin: 0 auto;
      }
    }

    ul {
      margin: $form-alert-content-ul-margin;
      padding: $form-alert-content-ul-padding;
    }

    &.no-left-padding {
      padding-left: 0;
    }
  }
}


// Alert Dialog
//---------------------------------------------

.alert-dialog-container {
  position: fixed;
  top: $alert-dialog-top;
  left: 50%;
  display: grid;
  justify-items: end;
  gap: 1.5rem;
  z-index: 1100;
  margin-left: $alert-dialog-margin-left;

  .alert-dialog {
    width: $alert-dialog-width;
    border-radius: $alert-dialog-border-radius;
    border: $alert-dialog-border;
    box-shadow: $alert-dialog-box-shadow;
    background-color: $alert-dialog-background;
    transition: transform 500ms ease-in-out, opacity 400ms ease-in-out 100ms;
    opacity: 0;

    &.is-collapsed {
      opacity: 0;
      transform: translateY(calc($alert-dialog-top * -1));
    }

    &.is-expanded {
      opacity: 1;
      transform: translateY(0);
    }

    &__inner {
      position: relative;
      padding: $alert-dialog-inner-padding;
    }

    &__heading {
      position: relative;
      padding: 0.75rem 1.5rem;
      font-size: $alert-dialog-heading-font-size;
      font-weight: $alert-dialog-heading-font-weight;
      border-bottom: $alert-dialog-heading-border;
    }

    &__text {
      display: inline-block;
      font-size: $alert-dialog-text-font-size;
      font-weight: $alert-dialog-text-font-weight;
    }

    &__close {
      position: absolute;
      right: $alert-dialog-close-right;
      top: 50%;
      margin-top: $alert-dialog-close-margin-top;
      width: $alert-dialog-close-width;
      height: $alert-dialog-close-height;
      cursor: pointer;
      outline: 0;
      background: url('/itarp-shared-assets-addon/images/forms/close.svg')
                      center center / contain
                      no-repeat;
    }
  }

  &--lg {
    margin-left: $alert-dialog-lg-margin-left;

    .alert-dialog {
      width: $alert-dialog-lg-width;

      &__inner {
        padding: $alert-dialog-lg-inner-padding;
      }

      &__text {
        font-size: $alert-dialog-lg-text-font-size;
      }
    }
  }
}


// Confirmation Dialog
//---------------------------------------------

.dialog-wrapper {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  overflow: hidden;
  outline: 0;
  z-index: 1010;
  padding: 2rem 2rem;
  background-color: rgba(#000, .5);
}

.dialog {
  background-color: #FFF;
  border-radius: .25rem;
  width: 100%;
  max-width: 30rem;
  margin: 5rem auto;

  &__body {
    padding: 1.5rem 1.5rem 1rem;
  }

  &__footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 1.5rem 1.5rem;

    .app-btn {
      min-width: 10rem;
    }

    .app-btn + .app-btn {
      margin-left: 1rem;
    }
  }

  &__text {
    font-size: 1.125rem;
    font-weight: 600;
    text-align: center;
    margin: 0;
  }
}

.hidden {
  display: none;;
}

