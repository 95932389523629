// Proxima Nova Regular
//---------------------------------------------

@font-face {
  font-family: "proxima-nova";
  src: url("/itarp-shared-assets-addon/fonts/proxima-nova/proximanova-bold-webfont.eot");
  src: url("/itarp-shared-assets-addon/fonts/proxima-nova/proximanova-bold-webfont.eot?#iefix") format("embedded-opentype"),
       url("/itarp-shared-assets-addon/fonts/proxima-nova/proximanova-bold-webfont.woff") format("woff"),
       url("/itarp-shared-assets-addon/fonts/proxima-nova/proximanova-bold-webfont.ttf") format("truetype"),
       url("/itarp-shared-assets-addon/fonts/proxima-nova/proximanova-bold-webfont.svg#proxima_nova_rgbold") format("svg");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "proxima-nova";
  src: url("/itarp-shared-assets-addon/fonts/proxima-nova/proximanova-semibold-webfont.eot");
  src: url("/itarp-shared-assets-addon/fonts/proximanova-semibold-webfont.eot?#iefix") format("embedded-opentype"),
       url("/itarp-shared-assets-addon/fonts/proxima-nova/proximanova-semibold-webfont.svg#proxima_novasemibold") format("svg"),
       url("/itarp-shared-assets-addon/fonts/proxima-nova/proximanova-semibold-webfont.woff") format("woff"),
       url("/itarp-shared-assets-addon/fonts/proxima-nova/proximanova-semibold-webfont.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "proxima-nova";
  src: url("/itarp-shared-assets-addon/fonts/proxima-nova/proximanova-regular-webfont.eot");
  src: url("/itarp-shared-assets-addon/fonts/proxima-nova/proximanova-regular-webfont.eot?#iefix") format("embedded-opentype"),
       url("/itarp-shared-assets-addon/fonts/proxima-nova/proximanova-regular-webfont.svg#proxima_nova_rgregular") format("svg"),
       url("/itarp-shared-assets-addon/fonts/proxima-nova/proximanova-regular-webfont.woff") format("woff"),
       url("/itarp-shared-assets-addon/fonts/proxima-nova/proximanova-regular-webfont.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "proxima-nova";
  src: url("/itarp-shared-assets-addon/fonts/proxima-nova/proximanova-light-webfont.eot");
  src: url("/itarp-shared-assets-addon/fonts/proxima-nova/proximanova-light-webfont.eot?#iefix") format("embedded-opentype"),
       url("/itarp-shared-assets-addon/fonts/proxima-nova/proximanova-light-webfont.woff") format("woff"),
       url("/itarp-shared-assets-addon/fonts/proxima-nova/proximanova-light-webfont.ttf") format("truetype"),
       url("/itarp-shared-assets-addon/fonts/proxima-nova/proximanova-light-webfont.svg#proxima_novalight") format("svg");
  font-weight: 300;
  font-style: normal;
}

// Proxima Nova Italic
//---------------------------------------------

@font-face {
  font-family: "proxima-nova";
  src: url("/itarp-shared-assets-addon/fonts/proxima-nova/proximanova-boldit-webfont.eot");
  src: url("/itarp-shared-assets-addon/fonts/proxima-nova/proximanova-boldit-webfont.eot?#iefix") format("embedded-opentype"),
       url("/itarp-shared-assets-addon/fonts/proxima-nova/proximanova-boldit-webfont.woff") format("woff"),
       url("/itarp-shared-assets-addon/fonts/proxima-nova/proximanova-boldit-webfont.ttf") format("truetype"),
       url("/itarp-shared-assets-addon/fonts/proxima-nova/proximanova-boldit-webfont.svg#proxima_nova_rgbold_italic") format("svg");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "proxima-nova";
  src: url("/itarp-shared-assets-addon/fonts/proxima-nova/proximanova-semibolditalic-webfont.eot");
  src: url("/itarp-shared-assets-addon/fonts/proxima-nova/proximanova-semibolditalic-webfont.eot?#iefix") format("embedded-opentype"),
       url("/itarp-shared-assets-addon/fonts/proxima-nova/proximanova-semibolditalic-webfont.woff") format("woff"),
       url("/itarp-shared-assets-addon/fonts/proxima-nova/proximanova-semibolditalic-webfont.ttf") format("truetype"),
       url("/itarp-shared-assets-addon/fonts/proxima-nova/proximanova-semibolditalic-webfont.svg#proxima_novasemibold_italic") format("svg");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "proxima-nova";
  src: url("/itarp-shared-assets-addon/fonts/proxima-nova/proximanova-regitalic-webfont.eot");
  src: url("/itarp-shared-assets-addon/fonts/proxima-nova/proximanova-regitalic-webfont.eot?#iefix") format("embedded-opentype"),
       url("/itarp-shared-assets-addon/fonts/proxima-nova/proximanova-regitalic-webfont.woff") format("woff"),
       url("/itarp-shared-assets-addon/fonts/proxima-nova/proximanova-regitalic-webfont.ttf") format("truetype"),
       url("/itarp-shared-assets-addon/fonts/proxima-nova/proximanova-regitalic-webfont.svg#proxima_novaregular_italic") format("svg");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "proxima-nova";
  src: url("/itarp-shared-assets-addon/fonts/proxima-nova/proximanova-lightitalic-webfont.eot");
  src: url("/itarp-shared-assets-addon/fonts/proxima-nova/proximanova-lightitalic-webfont.eot?#iefix") format("embedded-opentype"),
       url("/itarp-shared-assets-addon/fonts/proxima-nova/proximanova-lightitalic-webfont.woff") format("woff"),
       url("/itarp-shared-assets-addon/fonts/proxima-nova/proximanova-lightitalic-webfont.ttf") format("truetype"),
       url("/itarp-shared-assets-addon/fonts/proxima-nova/proximanova-lightitalic-webfont.svg#proxima_novalight_italic") format("svg");
  font-weight: 300;
  font-style: italic;
}