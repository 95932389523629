// Base Units
//---------------------------------------------

// Spacing

$spacing-unit: 1rem;

// Height

$base-unit-height: 4rem;

// Components

$component-align-checkbox-top: 1.25rem;


// Designer Content

$designer-content-width: calc(100vw - 3.5rem);
$designer-content-offset: calc($designer-content-width * -1);