// Tabs
//---------------------------------------------

.tabs {
  &__list {
    $parent: &;

    > ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      display: flex;
      border-bottom: $tab-list-border;

      > li,
      > li a {
        color: $tab-link-color;
      }

      > li {
        display: inline-block;
        position: relative;
        outline: 0;
        cursor: pointer;
        font-weight: $tab-link-font-weight !important;
        font-size: $tab-link-font-size;
        text-transform: uppercase;
        padding: $tab-link-padding;
        letter-spacing: -0.2px;
        font-family: 'proxima-nova', sans-serif !important;

        &.active,
        &:active,
        &[aria-selected='true'] {
          &,
          & a,
          & #{$parent}__link {
            color: $tab-link-color-active !important;
            cursor: auto;
          }

          &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: $tab-link-mark-height;
            border-radius: $tab-link-mark-border-radius;
            background-color: $tab-link-mark-background;
          }
        }

        a {
          text-decoration: none;

          &:hover {
            color: $tab-link-color-active !important;
          }
        }

        &.tabs__list__add {
          position: relative;
          padding: $tab-list-add-padding;

          &::before {
            content: '+';
            display: inline-block;
            font-size: $tab-list-add-font-size;
            color: $tab-list-add-color;
          }
        }
      }

      > li + li {
        margin-left: $tab-link-distance;
      }

      #{$parent}__link {
        color: $tab-link-color;
        text-decoration: none;
      }
    }

    &.no-border {
      > ul {
        border-bottom: none;
      }
    }

    &--sm {
      > ul {
        > li {
          font-size: $tab-link-font-size-sm;
        }
      }
    }
  }

  &__panels {
    .ember-tabs__tab-panel.ember-tabs__tab-panel--selected {
      padding: $tab-panel-padding;
    }
  }

  &.is-inside {
    .tabs__list {
      > ul {
        padding: $tab-list-inside-padding;
      }
    }

    .tabs__panels {
      .ember-tabs__tab-panel.ember-tabs__tab-panel--selected {
        padding: $tab-panel-inside-padding;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    &__list {
      $parent: &;
  
      > ul {
        > li {
          font-size: 1rem;
          padding: $tab-link-padding;
          white-space: nowrap;
          min-height: 3rem;
        }
  
        > li + li {
          margin-left: 1.5rem;
        }
  
        #{$parent}__link {
          color: $tab-link-color;
          text-decoration: none;
        }
      }  
    }
  }  
}

// Card Tabs
//---------------------------------------------

.card-tabs {
  &__list {
    margin: 0;
    padding: 0;
    list-style-type: none;

    &__item {
      &:first-child {
        .card-tabs__list__link {
          border-radius: $card-tabs-list-link-border-radius-first;

          &:active,
          &.active,
          &.is-active {
            &::before {
              border-radius: $card-tabs-list-link-active-bar-border-radius-first;
            }
          }
        }
      }

      &:last-child {
        .card-tabs__list__link {
          border-radius: $card-tabs-list-link-border-radius-last;

          &:active,
          &.active,
          &.is-active {
            &::before {
              border-radius: $card-tabs-list-link-active-bar-border-radius-last;
            }
          }
        }
      }
    }

    &__link {
      display: block;
      position: relative;
      font-size: $card-tabs-list-link-font-size;
      font-weight: $card-tabs-list-link-font-weight;
      color: $card-tabs-list-link-color;
      padding: $card-tabs-list-link-padding;
      cursor: pointer;
      outline: 0;
      letter-spacing: -0.2px;

      &:hover,
      &:focus {
        color: $card-tabs-list-link-color-hover;
      }

      &:active,
      &.active,
      &.is-active {
        &::before {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          width: $card-tabs-list-link-active-bar-width;
          background-color: $card-tabs-list-link-active-bar-background;
        }
      }
    }

    &__item + &__item {
      border-top: $card-tabs-item-border;
    }
  }

  @media only screen and (max-width: 768px) {
    &__list {
      &__link {
        font-size: 1.125rem;
        padding: 1.5rem 1.75rem;
      }
    }
  }
}

// Sidetabs
//---------------------------------------------

.sidetabs {
  &__wrapper {
    display: flex;
  }

  &__list {
    width: $sidetabs-list-width;

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        font-size: $sidetabs-list-link-font-size;
        font-weight: $sidetabs-list-link-font-weight;
        text-transform: uppercase;
        padding: $sidetabs-list-link-padding;
        cursor: pointer;

        &[aria-selected='true'] {
          background-color: $sidetabs-list-link-background-active;
          color: $sidetabs-list-link-color-active;
        }
      }
    }
  }

  &__panels {
    flex: 1;
    min-height: $sidetabs-list-panel-min-height;

    > * {
      padding: $sidetabs-list-panel-padding;
    }
  }
}
