// Loader
//---------------------------------------------

.loading-animation {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0;

  &__title {
    font-size: 1.125rem;
    font-weight: 600;
    text-align: center;
    padding: 0 0 .5rem;
    margin: 0;
  }

  &__svg {
    zoom: .5;
  }
}