// Ember Power Select
//---------------------------------------------

.ember-power-select-trigger {
  font-size: $ember-power-select-font-size !important;
  padding: $ember-power-select-padding !important;
  border-top: $ember-power-select-border !important;
  border-bottom: $ember-power-select-border !important;
  border-right: $ember-power-select-border !important;
  border-left: $ember-power-select-border !important;
  border-radius: $ember-power-select-border-radius !important;
  line-height: $ember-power-select-line-height !important;
  min-height: $ember-power-select-min-height !important;
  font-family: 'proxima-nova', sans-serif !important;

  &.ember-power-select-multiple-trigger {
    padding: $ember-power-multiselect-padding !important;
  }
}

.ember-basic-dropdown-trigger--below.ember-power-select-trigger[aria-expanded='true'],
.ember-basic-dropdown-trigger--in-place.ember-power-select-trigger[aria-expanded='true'] {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.ember-basic-dropdown-trigger--below.ember-power-select-trigger[aria-expanded='true'],
.ember-basic-dropdown-trigger--in-place.ember-power-select-trigger[aria-expanded='true'] {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.ember-power-select-placeholder {
  color: $ember-power-select-placeholder-color !important;
}

.ember-power-select-status-icon {
  right: $ember-power-select-icon-right !important;
  border-width: $ember-power-select-icon-border-width !important;
  border-color: $ember-power-select-icon-border-color;
}

.ember-power-select-dropdown {
  border-left: $ember-power-select-border !important;
  border-right: $ember-power-select-border !important;
  border-radius: $ember-power-select-border-radius !important;

  &.ember-basic-dropdown-content--below,
  &.ember-basic-dropdown-content--in-place {
    border-bottom: $ember-power-select-border !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
}
.ember-power-select-group {
  .ember-power-select-group-name {
    display: block;
    padding: 0.75rem 0.5rem;
  }
}

.ember-power-select-options[role='listbox'] {
  max-height: $ember-power-select-options-max-height !important;
}

.ember-power-select-option {
  font-size: $ember-power-select-option-font-size !important;
  padding: $ember-power-select-option-cell-padding !important;
  font-family: 'proxima-nova', sans-serif !important;
  font-weight: 400 !important;

  &[aria-current='true'] {
    background-color: $ember-power-select-option-cell-background !important;
    color: $ember-power-select-option-cell-color !important;
  }

  &[aria-selected='true'] {
    background-color: $ember-power-select-option-cell-background-selected !important;
    color: $ember-power-select-option-cell-color-selected !important;
  }
}

.ember-power-select-multiple-option {
  position: relative !important;
  font-size: $ember-power-multiselect-option-font-size !important;
  border: $ember-power-multiselect-option-border !important;
  border-radius: $ember-power-multiselect-option-border-radius !important;
  color: inherit !important;
  background-color: $ember-power-multiselect-option-background !important;
  padding: $ember-power-multiselect-option-padding !important;
  line-height: $ember-power-multiselect-option-line-height !important;
  margin: $ember-power-multiselect-option-margin !important;
  font-family: 'proxima-nova', sans-serif !important;
}

.ember-power-select-multiple-remove-btn {
  position: absolute;
  right: $ember-power-multiselect-option-remove-right;
  top: 50%;
  margin: $ember-power-multiselect-option-remove-margin;
  width: $ember-power-multiselect-option-remove-width;
  height: $ember-power-multiselect-option-remove-height;
  background: url('/itarp-shared-assets-addon/images/forms/tag_remove.svg')
    center center no-repeat;
  color: transparent !important;
  cursor: pointer;
}

.ember-power-select-trigger-multiple-input {
  font-size: $ember-power-multiselect-input-font-size !important;
  line-height: $ember-power-multiselect-input-line-height !important;
  padding: $ember-power-multiselect-input-padding !important;
  text-indent: $ember-power-multiselect-input-text-indent !important;
  font-family: 'proxima-nova', sans-serif !important;
}

.ember-power-select-selected-item,
.ember-power-select-placeholder {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: 'proxima-nova', sans-serif !important;
  font-weight: 400 !important;
}

.ember-basic-dropdown-content {
  &.has-nested-data {
    .ember-power-select-option {
      p {
        display: inline-block;
        margin-bottom: 0 !important;
      }

      p + p {
        &::before {
          content: '— '
        }
      }
    }  
  }  
}

// Ember Phone Input
//---------------------------------------------

.iti {
  display: block !important;

  &__flag-container {
    &,
    & .iti__selected-flag {
      background-color: $ember-phone-input-flag-container-background !important;
    }

    &:hover,
    &:hover .iti__selected-flag {
      background-color: $ember-phone-input-flag-container-background !important;
    }
  }

  &__selected-flag {
    padding: $ember-phone-input-selected-flag-padding !important;
    width: $ember-phone-input-selected-flag-width !important;
    border-right: $ember-phone-input-selected-flag-border !important;
    border-radius: $ember-phone-input-selected-flag-border-radius !important;
  }

  &__country-list {
    z-index: $ember-phone-input-country-list-index !important;
  }

  &__country {
    padding: $ember-phone-input-country-padding !important;
    line-height: $ember-phone-input-country-line-height !important;
    font-size: $ember-phone-input-country-font-size !important;

    &.iti__highlight {
      background-color: $ember-phone-input-country-highlight-background !important;
    }

    &.iti__active {
      color: $ember-phone-input-country-active-color !important;
      background-color: $ember-phone-input-country-active-background !important;

      .iti__dial-code {
        color: $ember-phone-input-country-active-dial-code-color !important;
      }
    }
  }

  &--allow-dropdown input,
  &--allow-dropdown input[type='text'],
  &--allow-dropdown input[type='tel'],
  &--separate-dial-code input,
  &--separate-dial-code input[type='text'],
  &--separate-dial-code input[type='tel'] {
    padding-right: $ember-phone-input-input-padding-right;
    padding-left: $ember-phone-input-input-padding-left !important;
    margin-left: $ember-phone-input-input-margin-left;
  }

  &--separate-dial-code {
    .iti__selected-flag {
      background-color: $ember-phone-input-selected-flag-background;
    }

    .iti__selected-dial-code {
      margin-left: $ember-phone-input-selected-flag-dial-code-margin-left;
    }

    .iti__arrow {
      position: absolute;
      right: $ember-phone-input-arrow-right;
      margin-left: $ember-phone-input-arrow-margin-left;
      width: $ember-phone-input-arrow-width;
      height: $ember-phone-input-arrow-height;
      border-left: $ember-phone-input-arrow-border-left;
      border-right: $ember-phone-input-arrow-border-right;
      border-top: $ember-phone-input-arrow-border-top;
    }
  }

  .iti__arrow--up {
    border-top: none;
    border-bottom: $ember-phone-input-arrow-up-border-bottom;
  }
}

// Ember CLI TinyMCE
//---------------------------------------------

.tox-tinymce {
  border-radius: $ember-tinymce-border-radius !important;
  border: $ember-tinymce-border !important;
}

.tox {
  .tox-toolbar-overlord {
    background-color: $ember-tinymce-background !important;
  }

  .tox-toolbar__group + .tox-toolbar__group:last-child {
    margin-left: auto !important;
  }

  .tox-toolbar,
  .tox-toolbar__overflow,
  .tox-toolbar__primary {
    background: none !important;
    background-color: $ember-tinymce-background !important;
    border-bottom: $ember-tinymce-border;
  }

  .tox-tbtn {
    width: $ember-tinymce-button-width !important;
    height: $ember-tinymce-button-height !important;

    .tox-icon {
      width: $ember-tinymce-icon-width !important;
      height: $ember-tinymce-icon-height !important;

      svg {
        display: none;
      }
    }

    $editor-button-icons: 'Numbered list' 'ol', 'Bullet list' 'ul',
      'Align left' 'align_left', 'Align center' 'align_center',
      'Insert/edit link' 'link', 'Bold' 'bold', 'Italic' 'italic',
      'Underline' 'underline';

    $editor-custom-icons: 'Fullscreen' 'editor_fullscreen',
      'Insert token' 'editor_placeholder', 'Add attachment' 'editor_attachment', 'Source code' 'editor_code';

    @each $label, $icon in $editor-button-icons {
      &[aria-label='#{$label}'] {
        .tox-icon {
          background: url('/itarp-shared-assets-addon/images/forms/tinymce/editor_#{$icon}.svg')
            center
            center /
            contain
            no-repeat !important;
        }
      }
    }

    @each $label, $icon in $editor-custom-icons {
      &[aria-label='#{$label}'] {
        background-color: $ember-tinymce-custom-button-background;
        border: $ember-tinymce-custom-button-border;
        cursor: pointer;

        .tox-icon {
          background: url('/itarp-shared-assets-addon/images/icon_#{$icon}.svg')
            center
            center /
            contain
            no-repeat !important;
        }

        &:hover,
        &:focus {
          background-color: $ember-tinymce-custom-button-background-active;
          border-color: $ember-tinymce-custom-button-border-color-active;

          .tox-icon {
            background: url('/itarp-shared-assets-addon/images/icon_#{$icon}_active.svg')
              center
              center /
              contain
              no-repeat !important;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .tox-tbtn {
      width: 2rem !important;
      height: 2rem !important;
    }
  }
}

// Ember Power Datepicker
//---------------------------------------------

.ember-power-datepicker-content {
  width: $ember-power-datepicker-width !important;
  border-left: $ember-power-datepicker-border;
  border-right: $ember-power-datepicker-border;
  background-color: $ember-power-datepicker-background !important;
  padding: $ember-power-datepicker-padding;
  box-shadow: $ember-power-datepicker-box-shadow;

  &.ember-basic-dropdown-content--above {
    border-top: $ember-power-datepicker-border;
    border-radius: $ember-power-datepicker-border-radius-above;
  }

  &.ember-basic-dropdown-content--below {
    border-bottom: $ember-power-datepicker-border;
    border-radius: $ember-power-datepicker-border-radius-below;
  }
}

.ember-power-calendar-nav {
  position: relative;
  align-items: center;
  justify-content: flex-start;
  height: $ember-power-calendar-nav-height;
  padding: $ember-power-calendar-nav-padding;

  &-title {
    font-size: $ember-power-calendar-nav-title-font-size;
    font-weight: $ember-power-calendar-nav-title-font-weight;
    font-family: inherit;
    color: inherit;
    text-align: left !important;
    text-transform: uppercase;
  }

  &-control {
    width: $ember-power-calendar-nav-control-width;
    height: $ember-power-calendar-nav-control-height;
    border: $ember-power-calendar-nav-control-border !important;
    border-radius: $ember-power-calendar-nav-control-border-radius;
    box-shadow: $ember-power-calendar-nav-control-box-shadow;
    color: transparent;
    cursor: pointer;
    position: absolute;
    top: 0;

    &.ember-power-calendar-nav-control--previous {
      right: $ember-power-calendar-nav-control-position-right-prev;
      background: $ember-power-calendar-nav-control-background
        url('/itarp-shared-assets-addon/images/forms/calendar/calendar_prev.svg')
        45% center no-repeat;
    }

    &.ember-power-calendar-nav-control--next {
      right: 0;
      background: $ember-power-calendar-nav-control-background
        url('/itarp-shared-assets-addon/images/forms/calendar/calendar_next.svg')
        55% center no-repeat;
    }

    &:focus {
      transform: none;
      border-color: $ember-power-calendar-nav-control-border-active;

      &.ember-power-calendar-nav-control--previous {
        background: $ember-power-calendar-nav-control-background-active
          url('/itarp-shared-assets-addon/images/forms/calendar/calendar_prev_active.svg')
          45% center no-repeat;
      }

      &.ember-power-calendar-nav-control--next {
        background: $ember-power-calendar-nav-control-background-active
          url('/itarp-shared-assets-addon/images/forms/calendar/calendar_next_active.svg')
          55% center no-repeat;
      }
    }
  }
}

.ember-power-calendar-weekdays {
  background-color: $ember-power-calendar-weekdays-background;
  height: $ember-power-calendar-weekdays-height;
  border-left: $ember-power-calendar-weekdays-border;
  border-right: $ember-power-calendar-weekdays-border;
  border-top: $ember-power-calendar-weekdays-border;
}

.ember-power-calendar-weekday {
  font-size: $ember-power-calendar-weekday-font-size;
  font-weight: $ember-power-calendar-weekday-font-weight;
  color: $ember-power-calendar-weekday-color;
  text-transform: uppercase;
  font-family: inherit;
}

.ember-power-calendar-day-grid {
  border: $ember-power-calendar-day-grid-border;
  background-color: $ember-power-calendar-day-grid-background;
}

.ember-power-calendar-week {
  height: $ember-power-calendar-week-height;
  border-bottom: $ember-power-calendar-day-grid-border;

  &:last-child {
    border-bottom: none;
  }

  .ember-power-calendar-day + .ember-power-calendar-day {
    border-left: $ember-power-calendar-day-grid-border;
  }

  @for $i from 1 to 7 {
    &:first-child {
      &[data-missing-days='#{$i}'] {
        justify-content: flex-end;

        .ember-power-calendar-day:first-child {
          border-left: $ember-power-calendar-day-grid-border;
        }
      }
    }

    &:last-child {
      &[data-missing-days='#{$i}'] {
        justify-content: flex-start;

        .ember-power-calendar-day:last-child {
          border-right: $ember-power-calendar-day-grid-border;
        }
      }
    }
  }
}

.ember-power-calendar-day {
  font-size: $ember-power-calendar-day-font-size;
  font-weight: $ember-power-calendar-day-font-weight;
  font-family: inherit;
  color: inherit;
  cursor: pointer;
  max-width: $ember-power-calendar-day-max-width;

  &:hover,
  &:focus {
    background-color: $ember-power-calendar-day-background-hover;
  }

  &.ember-power-calendar-day--other-month {
    color: transparent;
  }

  &.ember-power-calendar-day--selected {
    color: $ember-power-calendar-day-color-active;
    background-color: $ember-power-calendar-day-background-active;
  }
}

// Ember Modal Dialog
//---------------------------------------------

.ember-modal-overlay {
  z-index: $ember-modal-dialog-overlay-index;

  &.translucent {
    background-color: $ember-modal-dialog-overlay-background;
  }
}

.ember-modal-dialog {
  border-radius: $ember-modal-dialog-border-radius;
  background-color: $ember-modal-dialog-background;
  box-shadow: none;
  padding: 0;
  width: 100%;
}

// Ember Basic Dropdown
//---------------------------------------------

// Ember Slider
//---------------------------------------------

.ember-slider {
  .slider-path {
    width: calc(100% - 1.5rem);
    left: math.div($ember-slider-handle-cover-width, 2);
  }

  .slider-pseudo-path {
    background-color: $ember-slider-path-color;
  }

  .slider-color-filler {
    background-color: $ember-slider-filler-color;
  }

  .slider-handle {
    line-height: $ember-slider-handle-line-height;
    height: $ember-slider-handle-height;
    width: $ember-slider-handle-width;
    border-radius: $ember-slider-handle-border-radius;
    transform: translateX($ember-slider-handle-translate);

    .handle-cover {
      box-shadow: none;
      background-color: $ember-slider-filler-color;
      width: $ember-slider-handle-cover-width;
      height: $ember-slider-handle-cover-height;
      border-radius: $ember-slider-handle-cover-border-radius;
    }
  }
}
