// Buttons
//---------------------------------------------

// Defaults

button,
input[type='submit'] {
  display: inline-block;
  outline: 0;
  border: none;
  cursor: pointer;
}  

// Button

.app-btn {
  display: inline-block;
  outline: 0;
  font-family: inherit;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.25;
  text-align: center;
  text-decoration: none;
  background-color: #BCBEC4;
  border-width: 2px;
  border-style: solid;
  border-color: #BCBEC4;
  border-radius: .25rem;
  padding: .9375rem 1rem;
  color: #FFF;
  position: relative;
  cursor: pointer;
  transform: perspective(1px) translateZ(0);
  -webkit-transition-duration: 0.5s;
          transition-duration: 0.5s;
  -webkit-transition-property: color, background-color;
          transition-property: color, background-color;

  &:disabled,
  &.disabled,
  &.is-disabled {
    background-color: lighten(#BCBEC4, 10%);
    border-color: lighten(#BCBEC4, 10%);
    color: lighten(#FFF, 10%);
    cursor: default;
  }

  &:not(:disabled):not(.disabled):hover,
  &:not(:disabled):not(.disabled):focus,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).is-active {
    background-color: darken(#BCBEC4, 10%);
    border-color: darken(#BCBEC4, 10%);
    color: #FFF;
  }
}

// Button Block

.btn--block {
  width: 100%;
}

// Button Add

.btn--add {
  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    content: '';
    display: inline-block;
    width: .875rem;
    height: .875rem;
    margin-right: .5rem;
    background: url('/itarp-shared-assets-addon/images/icon_add_active.svg') center center / contain no-repeat;
  }
}
