// Modals
//---------------------------------------------

.app-modal {
  width: 100%;

  &.is-centered {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.app-modal-wrapper--dialog {
  padding: 1rem 1rem !important;
}

.app-modal-content {
  width: 100%;
  border-radius: $modal-border-radius;
  background-color: $modal-background;

  &__header {
    position: relative;
    border-radius: $modal-content-header-border-radius;
    padding: $modal-content-header-padding;
    border-bottom: $modal-content-header-border;

    &__title {
      margin: 0;
      font-size: $modal-content-header-title-font-size;

      &.is-success {
        position: relative;
        padding-right: $modal-content-header-title-sucecss-padding-right;

        &::after {
          content: '';
          position: absolute;
          right: 0;
          width: $modal-content-header-title-sucecss-icon-width;
          height: $modal-content-header-title-sucecss-icon-height;
          background: url('/itarp-shared-assets-addon/images/workflows/triggers/trigger_icon_added.svg')
            center center no-repeat;
        }
      }
    }

    &__close {
      position: absolute;
      top: $modal-content-header-close-position-top;
      right: $modal-content-header-close-position-right;
      margin: $modal-content-header-close-margin;
      width: $modal-content-header-close-width;
      height: $modal-content-header-close-height;
      cursor: pointer;
      opacity: 0.5;
      transition: opacity 0.25s ease-in-out;
      background: url('/itarp-shared-assets-addon/images/forms/close.svg')
        center center / contain no-repeat;

      &:hover,
      &:focus {
        opacity: 1;
      }
    }

    &.is-borderless {
      border-bottom: none;
    }

    &.app-modal-content__header--lg {
      padding: $modal-content-header-padding-lg;

      .app-modal-content__header__title {
        font-size: $modal-content-header-title-font-size-lg;
      }
    }
  }

  &__body {
    padding: $modal-content-body-padding;
  }

  &__footer {
    display: flex;
    border-top: $modal-content-footer-border;
    padding: $modal-content-footer-padding;

    &.is-aligned-right {
      justify-content: flex-end;
    }

    &.is-borderless {
      border-top: none;
    }

    .app-btn {
      min-width: $modal-content-footer-button-min-width;
    }

    & > * + * {
      margin-left: .75rem;
    }
  }

  @media only screen and (max-width: 768px) {
    &__header {
      padding: 1rem 2.75rem 1rem 1rem;

      &__title {
        font-size: 1.125rem;
      }

      &__close {
        right: 1rem;
      }
    }

    &__body {
      padding: 0;
    }

    &__footer {
      padding: 1rem 1rem;;

      .app-btn {
        width: 100%;
      }

      > * + * {
        margin-top: .75rem;
      }
    }
  }
}

// Modal Sizes
//---------------------------------------------

$modal-sizes: 'sm' $modal-width-sm, 'md' $modal-width-md, 'lg' $modal-width-lg,
  'xl' $modal-width-xl, 'full' 100%;

@each $size, $width in $modal-sizes {
  .app-modal--#{$size} {
    max-width: #{$width};
  }
}

// Modal Layout
//---------------------------------------------

.app-modal-open {
  overflow: hidden;
}

.app-modal-overlay {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: auto;
  height: auto;
  overflow-y: auto;
}

.app-modal-wrapper {
  position: relative;
  top: 0 !important;
  left: 0 !important;
  transform: none !important;
  padding: $modal-wrapper-padding;
  display: flex;
  justify-content: center;
  overflow-y: auto;
  height: 100%;
  // padding-bottom: 1rem;
}

// Modal Recommendation
//---------------------------------------------

.modal-recommendations {
  .app-modal-content__body {
    padding: 0;
    height: $modal-recommendations-body-height;
    overflow: hidden;
    position: relative;
  }

  &__wrapper {
    display: flex;
    height: $modal-recommendations-body-height;
    overflow: hidden;
    position: relative;
  }

  &__nav {
    display: block;
    border-bottom: none;
    width: $modal-recommendations-nav-width;
    padding: 0;
    overflow-y: auto;
    height: $modal-recommendations-body-height;
    border-right: 1px solid $border-color;

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;

      li {
        display: block;
        font-size: $modal-recommendations-nav-item-font-size;
        font-weight: $modal-recommendations-nav-item-font-weight;
        border-radius: 0;
        line-height: $modal-recommendations-nav-item-line-height;
        border-bottom: $modal-recommendations-nav-item-border;
        padding: $modal-recommendations-nav-item-padding;
        position: relative;
        cursor: pointer;
        user-select: none;
        outline: none;

        &::after {
          content: '';
          position: absolute;
          top: 50%;
          right: $modal-recommendations-nav-item-arrow-right;
          width: $modal-recommendations-nav-item-arrow-width;
          height: $modal-recommendations-nav-item-arrow-height;
          margin: $modal-recommendations-nav-item-arrow-margin;
          background: url('/itarp-shared-assets-addon/images/arrow_next.svg')
            center center no-repeat;
        }

        &:last-child {
          border-bottom: none;
        }

        &:hover {
          background-color: $modal-recommendations-nav-item-background-hover;
        }

        &:focus,
        &[aria-selected='true'] {
          color: $modal-recommendations-nav-item-color-active;
          border-left: none;
          border-right: none;
          background-color: $modal-recommendations-nav-item-background-active;
          border-color: $modal-recommendations-nav-item-border-color-active;

          &::after {
            background: url('/itarp-shared-assets-addon/images/arrow_right_white.svg')
              center center no-repeat;
          }
        }
      }
    }
  }

  &__panels {
    flex: 1;
    padding: 0;
    overflow-y: auto;
    height: $modal-recommendations-body-height;
  }

  &__panel {
    position: relative;
    padding: $modal-recommendations-panel-padding;

    &__search {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: $modal-recommendations-panel-search-height;
      border-bottom: $modal-recommendations-panel-search-border;

      &__input {
        border: none;
        height: 100%;
        width: 100%;
        outline: none;
        font-size: $modal-recommendations-panel-search-font-size;
        padding: $modal-recommendations-panel-search-input-padding;
        background: $modal-recommendations-panel-search-input-background
          url('/itarp-shared-assets-addon/images/forms/search.svg')
          $modal-recommendations-panel-search-input-position no-repeat;
      }
    }

    &__options {
      padding: $modal-recommendations-panel-options-padding;
    }

    &__controls {
      & > * + * {
        margin-left: .75rem;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .app-modal-content__body {
      height: auto;
    }

    &__wrapper {
      flex-direction: column;
      height: auto;
    }

    &__nav {
      width: 100%;
      padding: 0;
      height: 12.5rem;
    }

    &__panels {
      border-top: $modal-content-header-border;
    }
  }
}

// Checkboxes

.recommendation-row {
  padding: $recommenddation-row-padding;

  @media only screen and (max-width: 768px) {
    padding: .25rem 1rem;
  }
}

.depth--0,
.depth--1 {
  list-style-type: none;
}

.depth--0 {
  margin: $recommenddation-row-margin;
  padding: $recommenddation-row-padding-depth-zero;

  &.depth--2 {
    column-count: 2;
    column-gap: $recommenddation-row-gap;
  }

  @media only screen and (max-width: 768px) {
    &.depth--2 {
      column-gap: 0;
    }
  }
}

.depth--1 {
  margin: 0;
  padding: $recommenddation-row-padding-depth-one;

  li {
    column-count: 2;
    column-gap: $recommenddation-row-gap;
  }

  @media only screen and (max-width: 768px) {
    li {
      column-gap: 0;
    }
  }
}
