// Messages
//---------------------------------------------

.messages {
  height: calc(100vh - 3.5rem);
  // height: 100vh;
  position: relative;
  padding-left: 22.5rem;

  &__sidebar {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 22.5rem;
    border-right: 1px solid #d1dbe3;
    background-color: #fff;

    &__search {
      display: flex;
      align-items: center;
      height: 5rem;
      border-bottom: 1px solid #d1dbe3;
      padding: .5rem .75rem;

      & > * {
        width: 100%;
      }
    }

    &__inbox {
      // height: calc(100% - 5rem);
      height: 100%;
      overflow: hidden;
    }

    &__list {
      list-style-type: none;
      padding: 0;
      margin: 0;
      overflow-y: auto;
      height: 100%;

      &__item {
        display: block;
        padding: 1rem 1.25rem;
        cursor: pointer;
        text-decoration: none;

        &:hover,
        &:focus {
          background-color: #f1f3f5;
        }

        &:last-child {
          border-bottom: 1px solid #d1dbe3;
        }
      }

      &__item + &__item {
        border-top: 1px solid #d1dbe3;
      }

      &::-webkit-scrollbar {
        width: .375rem;
      }

      &::-webkit-scrollbar-track {
        background: #f1f3f5;
      }

      &::-webkit-scrollbar-thumb {
        background: #a7afb9;

        &:hover {
          background: #8a95a3;
        }
      }
    }
  }

  &__body {
    position: relative;
    display: flex;
    height: 100%;
  }

  &__thread {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: #fff;
    padding: 5rem 0 0;

    &__header {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 5rem;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #d1dbe3;
      padding: 1rem 1.25rem;
    }

    &__title {
      font-size: 1.375rem;
      margin-bottom: 0;
      white-space: nowrap;
    }

    &__project {
      position: relative;
      font-size: 1rem;
      font-weight: 600;
      color: #90959f;
      // white-space: nowrap;
      // overflow: hidden;
      // text-overflow: ellipsis;
      padding-left: 1.75rem;
      margin-left: 1.5rem;
      text-decoration: none;

      $project-types: 'job' 'message_op', 'tc' 'message_tc';

      @each $type, $icon in $project-types {
        &.is-#{$type} {
          &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            margin-top: -.625rem;
            width: 1.25rem;
            height: 1.25rem;
            background: url('/itarp-shared-assets-addon/images/#{$icon}.svg') center center / contain no-repeat;
          }
        }
      } 
    }

    &__body {
      flex: 1;
      overflow: hidden;

      &__inner {
        min-height: 0;
        height: 100%;
        padding: 1.25rem 1.25rem;
        overflow-y: auto;

        &::-webkit-scrollbar {
          width: .375rem;
        }
  
        &::-webkit-scrollbar-track {
          background: #f1f3f5;
        }
  
        &::-webkit-scrollbar-thumb {
          background: #a7afb9;
  
          &:hover {
            background: #8a95a3;
          }
        }
      }
    }

    &__form {
      padding: 1.25rem 1.25rem;
    }
  }

  &__contact {
    height: 100%;
    background-color: #fff;
    width: 17rem;
    flex-shrink: 0;
    border-left: 1px solid #d1dbe3;
  }

  &--empty {
    padding: 5rem 5rem;
    display: flex;
    justify-content: center;

    h3 {
      color: #a1a5ad;
    }
  }
}

// Thread Group
//---------------------------------------------

.thread-group {
  max-width: 60rem;
  margin: 0 auto;

  &__date {
    display: flex;
    justify-content: center;
    font-size: .875rem;
    color: #90959f;
    margin: 0 0 1rem;
  }

  &__post {
    position: relative;
    display: flex;
    padding: .25rem 0;

    &__inner {
      display: inline-flex;
      flex-direction: column;
      max-width: 40rem;
    }

    &__body {
      background-color: #f1f3f5;
      padding: .75rem 1rem;
      border-radius: .25rem;
      margin-bottom: .375rem;
      word-break: break-all;
    }

    &__info {
      display: inline-flex;
      align-items: center;
      font-size: .875rem;
      color: #90959f;

      span {
        margin-right: .375rem;

        img {
          width: 1.5rem;
          height: 1.5rem;
          border-radius: 50%;
        }
      }
    }

    &.is-right {
      justify-content: flex-end;

      .thread-group__post__inner {
        align-items: flex-end;
      }

      .thread-group__post__info {
        flex-direction: row-reverse;
      }

      .thread-group__post__body {
        background-color:rgba(#4ba4da, 0.2);
      }
    }

    &.is-left {
      justify-content: flex-start;

      .thread-group__post__inner {
        align-items: flex-start;
      }
    }
  }
}
