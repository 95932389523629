// Container
//---------------------------------------------

.container {
  display: block;
  position: relative;
  width: 100%;
  margin: 0 auto;

  &.container--1264 {
    max-width: 79rem;
  }

  &.container--1400 {
    max-width: 87.5rem;
  }
}

// Row
//---------------------------------------------

.row {
  display: flex !important;
  flex-wrap: nowrap !important;
  position: relative;
  align-items: stretch;
  margin: 0 !important;

  .row--wrap {
    flex-wrap: wrap;
    margin: 0 -.75rem;
  }

  & > * {
    padding: 0 !important;
  }

  @media only screen and (max-width: 768px) {
    flex-wrap: wrap !important;
  }
}

// Columns
//---------------------------------------------

$col-system-bases: 10, 12;

@each $base in $col-system-bases {
  @for $i from 1 through $base {
    .col--#{$base}-#{$i} {
      flex: 0 0 percentage(math.div($i, $base));
      max-width: percentage(math.div($i, $base));
      position: relative;
    }

    @media only screen and (max-width: 768px) {
      @if $i >= $i {
        .col--#{$base}-#{$i} {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
    } 
  }
}

div[class^="col--"] {
  &.is-pushing {
    display: flex;
    flex-direction: column;

    >.row {
      width: 100%;

      &:last-child {
        flex: 1;
      }
    }
  }
}

// Columns Alternative
//---------------------------------------------

.col {
  padding: .5rem .75rem;
  position: relative;
}

@for $i from 1 through 12 {
  .col--#{$i} {
    flex: 0 0 percentage(math.div($i, 12));
    max-width: percentage(math.div($i, 12));
  }
}

// Container

.designer-container {
  position: relative;
  padding: 0 0.75rem;
  width: 100%;
  height: 100%;
}

// Rpw

.designer-row {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -0.75rem;

  &.designer-row--fh {
    height: 100%;
  }
}

// Col

.designer-col {
  padding: 0 0.75rem;
  position: relative;
}

@for $i from 1 through 12 {
  .designer-col--#{$i} {
    flex: 0 0 percentage(math.div($i, 12));
    max-width: percentage(math.div($i, 12));
  }
}
