// Image Groups
//---------------------------------------------

.image-group {
  width: 100%;
  display: flex;
  
  &__item {
    max-width: 100%;    
  }

  $image-alignments: 'left' 'flex-start',
                     'right' 'flex-end',
                     'center' 'center';

  @each $aligment, $rule in $image-alignments {
    &.align--#{$aligment} {
      justify-content: #{$rule};
    }
  }                   

  $image-shapes: 'round' 50%,
                 'square' 0;

  @each $shape, $rule in $image-shapes {
    &.shape--#{$shape} {
      .image-group__item { 
        border-radius: #{$rule};
      }  
    }
  }              
}