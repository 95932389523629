// Lists
//---------------------------------------------

// List Models

.list-models {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -.75rem;

  &__item {
    flex: 0 0 25%;
    min-width: 0;
    padding: .75rem .75rem;
  }
}

// List Model Attributes

.list-model-atributtes {
  list-style-type: none;
  padding: 0;
  margin: 0;
  
  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__controls {
      @extend .link-group;
    }
  }

  &__item + &__item {
    margin-top: .25rem;
  }
}

// List Legend 

.list-legend {
  list-style-type: none;
  padding: 0;
  margin: 0 0 .75rem;
  display: flex;

  &__item {
    position: relative;
    padding: 0 0 0 1.5rem;
    font-size: 1rem;
    font-weight: 600;

    span {
      font-weight: 400;
    }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      margin: -.625rem 0 0;
      width: 1.125rem;
      height: 1.125rem;
      border-radius: .25rem;
    }

    $legend-status: 'system' lighten(#8CCBA2, 25%) #50AE81,
                    'active' #DBE8F2 #4A9DDD,
                    'inactive' #ECEDEF #A1A6B8;

    @each $status, $color, $border in $legend-status {
      &.is-#{$status} {
        &::before {
          border: 1px solid #{$border};
          background-color: #{$color};
        }
      }
    }   
  }

  &__item + &__item {
    margin-left: 2rem;
  }
}