// Paddings
//---------------------------------------------

@for $i from 0 to 20 {
  .pad-t--#{$i} {
    padding-top: $i * $spacing-unit  !important;
  }

  .pad-t--#{$i}-5 {
    padding-top: ($i * $spacing-unit) + math.div($spacing-unit, 2) !important;
  }

  .pad-l--#{$i} {
    padding-left: $i * $spacing-unit  !important;
  }

  .pad-l--#{$i}-5 {
    padding-left: ($i * $spacing-unit) + math.div($spacing-unit, 2) !important;
  }

  .pad-r--#{$i} {
    padding-right: $i * $spacing-unit  !important;
  }

  .pad-r--#{$i}-5 {
    padding-right: ($i * $spacing-unit) + math.div($spacing-unit, 2) !important;
  }

  .pad-b--#{$i} {
    padding-bottom: $i * $spacing-unit  !important;
  }

  .pad-b--#{$i}-5 {
    padding-bottom: ($i * $spacing-unit) + math.div($spacing-unit, 2) !important;
  }
}

// Margins
//---------------------------------------------

@for $i from 0 to 20 {
  .mar-t--#{$i} {
    margin-top: $i * $spacing-unit !important;
  }

  .mar-t--#{$i}-5 {
    margin-top: ($i * $spacing-unit) + math.div($spacing-unit, 2) !important;
  }

  .mar-l--#{$i} {
    margin-left: $i * $spacing-unit;
  }

  .mar-l--#{$i}-5 {
    margin-left: ($i * $spacing-unit) + math.div($spacing-unit, 2) !important;
  }

  .mar-r--#{$i} {
    margin-right: $i * $spacing-unit;
  }

  .mar-b--#{$i} {
    margin-bottom: $i * $spacing-unit !important;
  }

  .mar-b--#{$i}-5 {
    margin-bottom: ($i * $spacing-unit) + math.div($spacing-unit, 2) !important;
  }
}

// Positions
//---------------------------------------------

@for $i from 0 to 10 {
  .pos-t--#{$i} {
    top: $i * $spacing-unit;
  }

  .pos-l--#{$i} {
    left: $i * $spacing-unit;
  }

  .pos-r--#{$i} {
    right: $i * $spacing-unit;
  }

  .pos-b--#{$i} {
    bottom: $i * $spacing-unit;
  }
}

// Componenets Heights
//---------------------------------------------

@for $i from 1 to 100 {
  .com-h--#{$i} {
    height: $i * $spacing-unit !important;
  }

  .com-mh--#{$i} {
    min-height: $i * $spacing-unit !important;
  }

  .com-h--#{$i}-5 {
    height: ($i * $spacing-unit) + math.div($spacing-unit, 2) !important;
  }
}

.com-h--fill {
  height: 100%;
}

// Componenets Width
//---------------------------------------------

@for $i from 1 to 100 {
  .com-w--#{$i} {
    width: $i * $spacing-unit !important;
  }
}
