// Sidebar
//---------------------------------------------

.sidebar {
  background-color: $sidebar-background;

  &__links {
    position: relative;
    list-style-type: none;
    padding: 0;
    margin: 0;

    &__link {
      display: block;
      position: relative;
      padding: $sidebar-links-link-padding;
      font-size: $sidebar-links-link-font-size;
      font-weight: $sidebar-links-link-font-weight;
      color: $sidebar-links-link-color;
      cursor: pointer;
      outline: 0;
    }
  }

  &__sublinks {
    position: relative;
    list-style-type: none;
    padding: $sidebar-sublinks-padding;
    margin: 0;

    &__link {
      display: block;
      position: relative;
      padding: $sidebar-sublinks-link-padding;
      font-size: $sidebar-sublinks-link-font-size;
      font-weight: $sidebar-sublinks-link-font-weight;
      color: $sidebar-links-sublink-color;
      cursor: pointer;
      outline: 0;
    }  
  }
}

// Panel Sidebar
//---------------------------------------------

.panel-sidebar {
  position: relative;
  background: $panel-sidebar-background;
  padding: $panel-sidebar-padding;
  border-radius: 0 0 0 0.375rem;

  &__links {
    position: relative;
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  &__link {
    display: flex;
    flex-wrap: nowrap;
    position: relative;
    padding: $sidebar-links-link-padding;
    font-size: $sidebar-links-link-font-size;
    font-weight: $sidebar-links-link-font-weight;
    color: $panel-sidebar-link-color;
    cursor: pointer;
    outline: 0;
    text-decoration: none;

    &__icon {
      height: $panel-sidebar-link-icon-height;
      width: $panel-sidebar-link-icon-width;
      background: $blue-button-primary;
      border: 0;
      border-radius: 50%;

      $panel-sidebar-icon-types: 'signup',
      'autofill',
      'basic-info',
      'expertise',
      'work-arrangement',
      'preview';

      @each $type in $panel-sidebar-icon-types {
        &.#{$type} {
          background: $blue-button-primary url('/itarp-business-application/assets/images/sidebar/#{$type}.svg') center center no-repeat;
        }
      }
    }

    &__text {
      flex: 1;
      padding: $panel-sidebar-links-link-padding;
    }

    &.is-active {
      .panel-sidebar__link__icon {
        background-color: $green-primary;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    width: 100% !important;
    padding: .75rem .75rem;

    &__links {
      display: flex;
      justify-content: space-evenly;
    }

    &__link {
      padding: 0.75rem 0.75rem;

      &__text {
        display: none;
      }
    }
  }
}
