// Offscreen
//---------------------------------------------

.offscreen {
  position: fixed;
  z-index: 10;
  top: 0;
  bottom: 0;
  background-color: $offscreen-background;
  box-shadow: $offscreen-box-shadow;
  width: $offscreen-width;
  transition: 0.5s;

  &-content {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
  }

  &__header {
    position: relative;
    width: 100%;
    padding: $offscreen-header-padding;

    &__title {
      font-size: $offscreen-header-title-font-size;
      font-weight: $offscreen-header-title-font-weight !important;
      margin: 0;
      line-height: 1;
      font-family: 'proxima-nova', sans-serif !important;
    }

    .for-actions {
      font-weight: $offscreen-header-actions-title-font-weight;
    }

    &__icon {
      display: inline-block;
      margin: 0 auto;
      width: 100%;
      height: $offscreen-header-icon-height;

      &.is-success {
        background: url('/itarp-shared-assets-addon/images/offscreen_success.svg') center center no-repeat;
      }
    }

    &__close {
      position: absolute;
      top: $offscreen-header-close-position-top;
      right: $offscreen-header-close-position-right;
      width: $offscreen-header-close-width;
      height: $offscreen-header-close-height;
      cursor: pointer;
      opacity: 0.5;
      transition: opacity 0.25s ease-in-out;
      background: url('/itarp-shared-assets-addon/images/forms/close.svg') center center / contain no-repeat;

      &:hover,
      &:focus {
        opacity: 1;
      }
    }

    &.is-dark {
      padding: $offscreen-inbody-header-padding;
      background-color: $offscreen-inbody-header-background;

      .offscreen__header__title {
        color: $offscreen-inbody-header-title-color;
      }

      .offscreen__header__close {
        background: url('/itarp-shared-assets-addon/images/forms/close_light.svg') center center / contain no-repeat;
      }

      &.with-icon {
        padding: $offscreen-inbody-header-icon-padding;

        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: $offscreen-inbody-header-icon-left;
          width: $offscreen-inbody-header-icon-width;
          height: $offscreen-inbody-header-icon-height;
          margin-top: $offscreen-inbody-header-icon-margin-top;
          border-right: $offscreen-inbody-header-icon-border;
          background: url('/itarp-shared-assets-addon/images/back_icon.svg') left center no-repeat;
        }
      }
    }

    &.is-inline {
      padding: $offscreen-header-inline-padding;
    }

    &.is-bordered {
      border-bottom: $offscreen-header-border;
    }
  }

  &__body {
    position: relative;
    flex: 1;
    width: 100%;
    padding: $offscreen-body-padding;

    &__signature {
      position: relative;
      display: flex;
      justify-content: center;
      padding: $offscreen-body-signature-padding;

      &__text {
        position: relative;
        font-size: $offscreen-body-signature-text-font-size;
        font-weight: $offscreen-body-signature-text-font-weight;
        color: $offscreen-body-signature-text-color;
        padding: $offscreen-body-signature-text-padding;
        background-color: $offscreen-body-signature-text-background;
        z-index: 1;
      }

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: $offscreen-body-signature-line-left;
        right: $offscreen-body-signature-line-right;
        height: $offscreen-body-signature-line-height;
        background-color: $offscreen-body-signature-line-background;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    width: 100%;

    &__header {
      padding: 3rem 1.5rem 1rem;

      &__title {
        font-size: $offscreen-header-title-font-size;
      }

      &__close {
        top: 1rem;
        right: 1rem
      }
    }

    &__body {
      padding: 0 .5rem 1.5rem;
    }
  }
}

// Position
//---------------------------------------------

.offscreen--left {
  &.is-open {
    left: 0;
  }

  &.is-closed {
    left: -$offscreen-width;
  }

  @media only screen and (max-width: 768px) {
    left: -100%;
  }
}

.offscreen--right {
  &.is-open {
    right: 0;
  }

  &.is-closed {
    right: -$offscreen-width;
  }

  @media only screen and (max-width: 768px) {
    right: -100%;
  }
}

// Sizes
//---------------------------------------------

$offscreen-sizes: 'full' 100%, 'lg'$offscreen-width-lg,
  'md'$offscreen-width-md, 'sm'$offscreen-width-sm;

@each $size,
$width in $offscreen-sizes {
  .offscreen--#{$size} {
    width: $width  !important;

    &.offscreen--left.is-closed {
      left: -$width  !important;
    }

    &.offscreen.offscreen--right.is-closed {
      right: -$width  !important;
    }

    @media only screen and (max-width: 768px) {
      width: 100% !important;

      &.offscreen--left.is-closed {
        left: -100% !important;
      }

      &.offscreen.offscreen--right.is-closed {
        right: -100% !important;
      }
    }
  }
}

.offscreen {
  &.offscreen--header-sm {
    &.offscreen--in-body {
      .offscreen-content {
        padding-top: $offscreen-header-small-padding-top;
      }
    }
  }

  &__header {
    &.offscreen__header--sm {
      display: flex;
      align-items: center;
      min-height: $offscreen-header-small-min-height;

      .offscreen__header__title {
        font-size: $offscreen-header-small-title-font-size;
      }

      .offscreen__header__close {
        top: $offscreen-header-small-close-position-top;
      }
    }
  }
}

// In Body
//---------------------------------------------

.offscreen--in-body {
  z-index: 9;
  top: $offscreen-inbody-top;
  height: $offscreen-inbody-height;

  .offscreen-content {
    padding-top: $offscreen-inbody-content-padding-top;
    overflow-y: auto;
    overflow-x: hidden;

    &.no-header {
      padding-top: 0;
    }
  }

  .offscreen__header {
    position: absolute;
    top: 0;
  }

  .offscreen__body {
    overflow-y: auto;
  }

  &.is-fixed {
    position: fixed;
    z-index: 11;
  }

  &.offscreen--fixed-nav {
    top: 10.5rem;
    height: calc(100vh - 10.5rem);
  }

  &.is-closed {
    display: none;
  }
}

// Profile
//---------------------------------------------

.offscreen-profile {
  padding: $offscreen-profile-padding;

  &__header {
    display: flex;
    align-items: center;
    padding-bottom: $offscreen-profile-header-padding-bottom;

    &__image {
      width: $offscreen-profile-image-width;
      height: $offscreen-profile-image-height;
      border-radius: 50%;
      margin-right: $offscreen-profile-image-margin-left;
      background-color: $offscreen-profile-image-background;

      img {
        display: inline-block;
        max-width: 100%;
        max-height: 100%;
        border-radius: 50%;
      }

      &.no-image {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: $offscreen-profile-image-font-size;
        font-weight: $offscreen-profile-image-font-weight;
        color: $offscreen-profile-image-color;
      }
    }

    &__name {
      margin: 0;
      font-size: $offscreen-profile-name-font-size;
      font-weight: $offscreen-profile-name-font-weight;
    }
  }

  &__actions {
    display: flex;
    justify-content: space-between;

    &__link {
      font-size: $offscreen-profile-actions-font-size;
      font-weight: $offscreen-profile-actions-font-weight;
    }
  }
}

// Tabs
//---------------------------------------------

.offscreen-tabs {
  margin: $offscreen-tabs-margin;
}

// Offscreen Preview
//---------------------------------------------

.offscreen-preview {
  position: fixed;
  z-index: 10;
  top: $offscreen-preview-top;
  bottom: 0;
  right: 0;
  background-color: $offscreen-preview-background;
  width: $offscreen-preview-width;
  opacity: 1;
  transition: transform 500ms ease-in-out, opacity 400ms ease-in-out 100ms;

  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;

    >* {
      width: 100%;
    }
  }

  &__header {
    position: relative;
    background-color: $offscreen-preview-header-background;
    border-bottom: $offscreen-preview-header-border;
    min-height: $offscreen-preview-header-min-height;
    padding-left: $offscreen-preview-header-padding-left;

    &__close {
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: $offscreen-preview-header-close-width;
      background-color: $offscreen-preview-header-close-background;
      padding: $offscreen-preview-header-close-padding;
      text-decoration: none;
      outline: 0;
      cursor: pointer;
      user-select: none;

      &__icon {
        display: inline-block;
        width: $offscreen-preview-header-close-icon-width;
        height: $offscreen-preview-header-close-icon-height;
        margin: $offscreen-preview-header-close-icon-margin;
        background: url('/itarp-shared-assets-addon/images/back_icon.svg') center center no-repeat;
      }

      &__label {
        display: inline-block;
        font-size: $offscreen-preview-header-close-font-size;
        color: $offscreen-preview-header-close-color;
        text-transform: uppercase;
        font-family: 'proxima-nova', sans-serif !important;
      }
    }

    &__content {
      display: flex;
      align-items: center;
      height: 100%;
      padding: $offscreen-preview-header-content-padding;

      &__split {
        width: 100%;
        height: 100%;
        display: flex;

        >* {
          display: flex;
          flex-direction: column;
          justify-content: center;

          &:first-child {
            padding-right: 1.5rem;
            flex: 1;
          }

          &:last-child {
            width: 32rem;

            &.for-control {
              width: 27.25rem;
            }
          }
        }

        >*+* {
          border-left: $offscreen-preview-header-border;
          padding-left: 1.5rem;
        }
      }
    }

    &__title {
      font-size: $offscreen-preview-header-title-font-size;
      font-weight: $offscreen-preview-header-title-font-weight;
      margin: 0;
      font-family: 'proxima-nova', sans-serif !important;

      span {
        font-weight: 400;
      }
    }

    &__info {
      list-style-type: none;
      padding: 0;
      margin: $offscreen-preview-header-info-margin;
      display: inline-flex;
      flex-wrap: wrap;
      font-family: 'proxima-nova', sans-serif !important;

      &__item {
        font-size: $offscreen-preview-header-info-item-font-size;
        margin: 0 1.5rem 0 0;

        span {
          display: inline-block;
          color: $offscreen-preview-header-info-item-span-color;
          margin-right: $offscreen-preview-header-info-item-span-margin-right;
        }
      }

      &.is-standalone {
        >li {
          margin-bottom: .25rem;
        }
      }
    }
  }

  &__body {
    position: relative;
    flex: 1;
    overflow-y: auto;

    &__nav {
      padding: $offscreen-preview-body-nav-padding;
      border-bottom: $offscreen-preview-body-nav-border;
    }
  }

  &.is-collapsed {
    opacity: 0;
    transform: translateX($offscreen-preview-width);
  }

  &.is-expanded {
    opacity: 100;
    transform: translateX(0);
  }

  &.is-full {
    left: 0;
    width: 100%;

    &.is-collapsed {
      right: -100%;
    }
  }
}

.has-border {
  border: 3px solid #4ba3da29;
  border-radius: 6px;

  &__solid {
    border: 3px solid #4ba3da8e;
    border-radius: 6px;
  }
}

.vl {
  border-left: 3px solid #4ba3da29;
  height: 3rem;
  position: absolute;
  left: 50%;
  margin-left: -3px;
  top: 0;
}

.martop {
  margin-top: 3rem;
}
