// Progress
//---------------------------------------------

.progress {
  position: relative;
  overflow: hidden;
  width: $progress-width;
  height: $progress-height;
  background-color: $progress-background;
  border-radius: $progress-border-radius;

  & > *,
  &__bar {
    position: absolute;
    overflow: hidden;
    left: 0;
    top: 0;
    bottom: 0;
    width: 75%;
    border-radius: $progress-bar-border-radius;
    background-color: $progress-bar-background;
  }
}

// Form Progress
//---------------------------------------------

.form-progress {
  position: relative;
  display: block;
  width: 100%;
  height: $form-progress-height;
  background-color: $form-progress-background;

  &__bar {
    position: absolute;
    top: 0;
    left: 0;
    height: $form-progress-height;
    border-radius: $form-progress-bar-border-radius;
    background: rgba(86,149,181,1);
    background: -moz-linear-gradient(left, rgba(86,149,181,1) 0%, rgba(112,196,132,1) 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(86,149,181,1)), color-stop(100%, rgba(112,196,132,1)));
    background: -webkit-linear-gradient(left, rgba(86,149,181,1) 0%, rgba(112,196,132,1) 100%);
    background: -o-linear-gradient(left, rgba(86,149,181,1) 0%, rgba(112,196,132,1) 100%);
    background: -ms-linear-gradient(left, rgba(86,149,181,1) 0%, rgba(112,196,132,1) 100%);
    background: linear-gradient(to right, rgba(86,149,181,1) 0%, rgba(112,196,132,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5695b5', endColorstr='#70c484', GradientType=1 );
    transition: width .5s ease-in-out;
  }  
}
