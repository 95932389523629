// Accordion
//---------------------------------------------

.accordion {
  $accordian-lists: 'education' 'education', 'experience' 'employment',
    'certification' 'certification';

  @each $type, $icon in $accordian-lists {
    &.for-#{$type} {
      .accordion-header__title {
        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          width: $accordion-icon-width;
          height: $accordion-icon-height;
          margin-top: $accordion-icon-margin;
          background: url('/itarp-shared-assets-addon/images/cards/icon_#{$icon}.svg')
            center
            center
            no-repeat;
        }
      }
    }
  }
}

// Accordion Header
//---------------------------------------------

.accordion-header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: $accordion-header-margin;

  &__title {
    position: relative;
    margin: 0;
    font-size: $accordion-header-title-font-size;
    font-weight: $accordion-header-title-font-weight;
    text-transform: uppercase;
    padding: $accordion-header-title-padding;
  }

  &__controls {
    display: flex;
    align-items: center;

    > * + * {
      margin-left: $accordion-header-controls-distance;
    }
  }
  
  @media only screen and (max-width: 768px) {
    &__controls {
      flex-direction: column;
      align-items: flex-end;

      > * + * {
        margin-left: 0;
        margin-bottom: .75rem;
      }

      > * {
        &:first-child {
          order: 2 
        }

        &:last-child {
          order: 1
        }
      }
    }  
  }  
}

// Accordion List
//---------------------------------------------

.accordion-list {
  color: $accordion-list-color;
  border-bottom: $accordion-list-item-border;

  &__item {
    color: inherit;

    &__header {
      position: relative;
      color: inherit;
      border-top: $accordion-list-item-border;

      h5 {
        margin: 0;
      }

      button {
        display: flex;
        align-items: center;
        background-color: transparent;
        border: 0;
        outline: 0;
        color: inherit;
        width: 100%;
        text-align: left;
        padding: $accordion-list-item-header-padding;
        min-height: $accordion-list-item-header-min-height;

        &:hover,
        &:focus {
          background-color: transparent !important;
          color: inherit !important;
        }
      }

      &.expanded {
        .accordion-list__item__title::before {
          transform: rotate(360deg);
        }
      }
    }

    &__title {
      display: flex;
      flex: 1;
      position: relative;
      font-size: $accordion-list-item-title-font-size;
      font-weight: $accordion-list-item-title-font-weight;
      padding: $accordion-list-item-title-padding;

      &::before {
        content: '';
        position: absolute;
        display: inline-block;
        width: 0;
        height: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        border-style: solid;
        left: 0;
        border-width: $accordion-list-item-title-caret-border-width;
        border-color: $accordion-list-item-title-caret-border-color;
        transform: rotate(270deg);
      }
    }

    &__controls {
      @include link-group('large');
    }

    &__body {
      color: inherit;
      padding: $accordion-list-item-body-padding;
    }

    &.card {
      all: unset;
    }
  }
}

// Accordion Form
//---------------------------------------------

.accordion-form {
  margin: $accordion-form-margin;
}

// Collapse effect
//---------------------------------------------

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: $collapse-transition;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
