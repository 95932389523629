// Profile
//---------------------------------------------

.profile {
  &__header {
    background-color: $profile-header-background;
    padding: $profile-header-padding;

    > .row {
      > .col--12-6 {
        padding: $profile-header-parent-column-padding;
      }

      > .col--12-6 + .col--12-6 {
        border-left: $profile-header-parent-column-border;
      }
    }

    &.in-popup {
      padding-top: 10rem;
    }
  }

  &__nav {
    position: relative;
    background-color: $profile-nav-background;
    padding: $profile-nav-padding;
    box-shadow: $profile-nav-box-shadow;
  }

  &__body {
    padding: $profile-body-padding;
  }

  @media only screen and (max-width: 768px) {
    &__header {
      padding: 1.5rem 0 0;

      > .row {
        > .col--12-6 {
          padding: 0 .5rem 1rem;
        }
  
        > .col--12-6 + .col--12-6 {
          border-left: none;
          border-top: $profile-header-parent-column-border;
        }
      }
    }

    &__nav {
      padding: 1rem .5rem 0;
      overflow: hidden;
      width: 100%;

      & > .row {
        overflow-x: auto;
      }
    }

    &__body {
      padding: 1rem .5rem 1rem;
    }
  }  
}
