// Navbar
//---------------------------------------------

.app-navbar {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  height: 3.5rem !important;
  background-color: #1B2C4A !important;
  z-index: 100 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;

  &__content {
    display: flex;
    align-items: center;
    position: relative;
    height: 100%;
    width: 100% !important;

    &.for-designer {
      justify-content: center;
      padding: 0 12.5rem;
    }
  }

  &__brand {
    width: 13.75rem;
    height: 100%;

    &__trigger,
    &__inner {
      padding: .75rem 2.5rem .5rem 1.5rem;
    }

    &__trigger {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 1.25rem;
        margin-top: -.375rem;
        width: .75rem;
        height: .75rem;
        transform: rotate(270deg);
        background: url('/itarp-shared-assets-addon/images/arrow_left_white.svg') center center no-repeat;
      }

      &[aria-expanded="true"] {
        &::after {
          transform: rotate(90deg);
        }
      }
    }

    img {
      display: block;
      height: 100%;
      width: auto;
      max-height: 2rem;
      max-width: 100%;
    }
  }

  &__tenant {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    height: 6.5rem;
    background-color: #4ba4da;
    padding: 1rem .75rem 1rem 1rem;
    line-height: 1.125;
    cursor: pointer;
    outline: 0;
    user-select: none;

    &__dropdown {
      width: 13.75rem;
      border-right: 1px solid #d1dbe3;
      background: #f1f3f5;
      margin-top: 0.125rem;
    }

    &__title {
      position: relative;
      font-size: 1.125rem;
      font-weight: 700;
      color: #fff;
      margin: .125rem;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__id {
      display: inline-block;
      font-size: .875rem;
      color: #ebebed;
    }

    &__list {
      list-style-type: 0;
      margin: 0;
      padding: 0;
      line-height: 1.125;

      &__header {
        position: relative;
        font-size: 1rem;
        font-weight: 600;
        color: #4ba4da;
        padding: 1rem 1rem 1rem 2.5rem;

        &::before {
          content: '';
          position: absolute;
          left: .75rem;
          top: 50%;
          margin-top: -1rem;
          width: 1.25rem;
          height: 2rem;
          background: url('/itarp-shared-assets-addon/images/sidebar/switch.svg') center center no-repeat;
        }
      }

      &__link {
        display: inline-block;
        padding: .75rem 1rem .75rem 2.5rem;
        color: #4a5162;
        text-decoration: none;
        cursor: pointer;
        user-select: none;
        outline: 0;

        &__title {
          font-size: 1rem;
          font-weight: 600;
        }

        &__id {
          font-size: .8125rem;
          display: block;
        }
      }

      >li+li {
        border-top: 1px solid #d1dbe3;
      }
    }
  }

  &__inner {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 0 1.5rem 0 0;

    >.app-navbar__component {
      height: 100%;
    }
  }

  &__links {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    position: relative;
    height: 100%;

    &__link {
      display: flex;
      align-items: center;
      position: relative;
      font-size: .875rem;
      text-transform: uppercase;
      text-decoration: none;
      font-weight: 600;
      color: #F7F7F7;
      cursor: pointer;
      outline: 0;
      padding: 0 1.25rem 0 0;
      height: 100%;
      line-height: 1.325 !important;
      font-family: 'proxima-nova', sans-serif !important;
    }

    &__menu {
      min-width: 12.5rem;
      box-shadow: 0 1px 3px rgba(#BCBEC4, 0.5);
    }

    &__item {
      &.dropdown {
        .app-navbar__links__link::after {
          content: '';
          position: absolute;
          right: 0;
          top: 50%;
          margin: -.25rem 0 0;
          width: .75rem;
          height: .5rem;
          background: url('/itarp-shared-assets-addon/images/arrow_down_blue.svg') center center no-repeat;
        }
      }

      &:not(.dropdown) {
        .app-navbar__links__link {
          padding-right: 0;

          &:hover,
          &:focus {
            color: #4ba4da !important;
          }
        }  
      }

      &+& {
        margin-left: 1.5rem;
      }
    }
  }

  &__title {
    position: relative;
    font-size: 1.5rem;
    font-weight: 600;
    color: #F7F7F7;
    padding: 0 5rem;
    max-width: 29rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: 'proxima-nova', sans-serif !important;

    &__edit {
      position: absolute;
      width: 1.25rem;
      height: 1.25rem;
      right: 2.25rem;
      top: 50%;
      margin: -.625rem 0 0;
      cursor: pointer;
      background: url('/itarp-shared-assets-addon/images/icon_edit_active.svg') center center / contain no-repeat;
    }
  }

  &__form {
    position: relative;
    padding: 0 5rem;

    &__field {
      border: none;
      padding: .25rem .5rem;
      font-size: 1.375rem;
      font-weight: 600;
      color: #F7F7F7;
      background-color: lighten(#1B2C4A, 10%);
      outline: none;
      width: 25rem;
      text-align: center;
      border-radius: .25rem;
    }

    &__toggle {
      position: absolute;
      width: 1.25rem;
      height: 1.25rem;
      right: 2.25rem;
      top: 50%;
      margin: -.625rem 0 0;
      cursor: pointer;
      background: url('/itarp-shared-assets-addon/images/btn_check.svg') center center / contain no-repeat;
    }

    ::-webkit-input-placeholder {
      /* Edge */
      color: #a1a5ad !important;
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #a1a5ad !important;
    }

    ::placeholder {
      color: #a1a5ad !important;
    }
  }

  &__title,
  &__form {

    .app-navbar__title__play,
    .app-navbar__form__play {
      position: absolute;
      width: 1.25rem;
      height: 1.25rem;
      right: .25rem;
      top: 50%;
      margin: -.625rem 0 0;
      cursor: pointer;
      background: url('/itarp-shared-assets-addon/images/icon_play_active.svg') center center / contain no-repeat;
    }
  }

  &__return {
    position: absolute;
    left: 2rem;

    &__link {
      font-size: 1rem;
      font-weight: 600;
      color: #86C2E6;
      text-decoration: none;
      font-family: 'proxima-nova', sans-serif !important;

      &:focus,
      &:hover {
        color: darken(#86C2E6, 20%);
      }
    }
  }

  &__user {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: inline-flex;
    height: 100%;
    align-items: center;

    &__notifications {
      display: inline-flex;
      align-items: center;
      padding: 0 1rem;
      height: 2.5rem;

      &__link {
        position: relative;
        display: inline-block;
        width: 1.875rem;
        height: 1.25rem;
        background: url('/itarp-shared-assets-addon/images/navbar_bell.svg') center center / contain no-repeat;

        &.no-notifications {
          opacity: .5;
          background: url('/itarp-shared-assets-addon/images/navbar_bell_inactive.svg') center center / contain no-repeat;
        }
      }

      &__counter {
        position: absolute;
        right: -.25rem;
        top: -.25rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        min-width: 1rem;
        height: 1rem;
        border-radius: .5rem;
        background-color: #4ba4da;
        color: #fff;
        font-size: .625rem;
        font-weight: 600;
        padding: .125rem .125rem;
        font-family: 'proxima-nova', sans-serif !important;
      }

      &__menu {
        margin-top: 1.125rem;
        width: 15rem;
        height: 20rem;
        box-shadow: 0 1px 3px rgba(#bcbec4, 0.5);

        &__inner {
          width: 100%;
          height: 100%;
          overflow-y: auto;

          &::-webkit-scrollbar {
            width: .375rem;
          }

          &::-webkit-scrollbar-track {
            background: #F1F3F5;
          }

          &::-webkit-scrollbar-thumb {
            background: #A7AFB9;

            &:hover {
              background: #8A95A3;
            }
          }

          .app-navbar__user__notifications__item + .app-navbar__user__notifications__item {
            border-top: 1px solid #d1dbe3;
          }
        }

        &__empty {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          padding: 2rem 1.5rem;
          font-size: 1.125rem;
          font-weight: 600;
          color: #a1a5ad;
          text-align: center;
          font-family: 'proxima-nova', sans-serif !important;
        }
      }

      &__item {
        display: block;
        padding: .75rem 1rem;
        font-size: .875rem;
        color: #4a5162;
        text-decoration: none;

        &__content,
        &__time {
          display: block;
        }

        &__time {
          font-size: .75rem;
          font-weight: 600;
          margin-top: .125rem;
          font-family: 'proxima-nova', sans-serif !important;
        }

        &:hover,
        &:focus {
          color: #4a5162;
          background-color: #f7f7f7;
        }
      }
    }

    &__inbox {
      display: inline-flex;
      align-items: center;
      padding: 0 1rem;
      height: 2.5rem;

      &__link {
        position: relative;
        display: inline-block;
        width: 1.875rem;
        height: 1.25rem;
        font-family: 'proxima-nova', sans-serif !important;
        background: url('/itarp-shared-assets-addon/images/navbar_inbox.svg') center center / contain no-repeat;
        font-family: 'proxima-nova', sans-serif !important;
        
        &.no-messages {
          opacity: .5;
        }
      }

      &__counter {
        position: absolute;
        right: -.25rem;
        top: -.25rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        min-width: 1rem;
        height: 1rem;
        border-radius: .5rem;
        background-color: #4ba4da;
        color: #fff;
        font-size: .625rem;
        font-weight: 600;
        padding: .125rem .125rem;
        font-family: 'proxima-nova', sans-serif !important;
      }

      &__menu {
        margin-top: 1.125rem;
        width: 15rem;
        height: 20rem;
        box-shadow: 0 1px 3px rgba(#bcbec4, 0.5);

        &__inner {
          width: 100%;
          height: 100%;
          overflow-y: auto;

          &::-webkit-scrollbar {
            width: .375rem;
          }

          &::-webkit-scrollbar-track {
            background: #F1F3F5;
          }

          &::-webkit-scrollbar-thumb {
            background: #A7AFB9;

            &:hover {
              background: #8A95A3;
            }
          }

          .app-navbar__user__notifications__item + .app-navbar__user__notifications__item {
            border-top: 1px solid #d1dbe3;
          }
        }
      }

      &__item {
        display: block;
        padding: .75rem 1rem;
        font-size: .875rem;
        color: #4a5162;
        text-decoration: none;

        &__content,
        &__time {
          display: block;
        }

        &__time {
          font-size: .75rem;
          font-weight: 600;
          margin-top: .125rem;
          font-family: 'proxima-nova', sans-serif !important;
        }

        &:hover,
        &:focus {
          color: #4a5162;
          background-color: #f7f7f7;
        }
      }
    }

    &__dropdown {
      height: 100%;

      &__trigger {
        display: flex;
        align-items: center;
        outline: 0;
        cursor: pointer;
        height: 100%;

        &[aria-expanded='true'] {
          .app-navbar__user__name {
            &::after {
              transform: rotate(180deg);
            }
          }
        }
      }

      &__menu {
        margin: 0;
        width: 16rem;
        padding: 0;
        box-shadow: 0 1px 3px rgba(#bcbec4, 0.5);
        right: 0 !important;
      }
    }

    &__name {
      position: relative;
      font-size: .875rem;
      color: #F7F7F7;
      padding: 0 1.25rem 0 0;
      margin-right: .5rem;
      font-family: 'proxima-nova', sans-serif !important;

      &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        margin: -.25rem 0 0;
        width: .75rem;
        height: .5rem;
        background: url('/itarp-shared-assets-addon/images/arrow_down_blue.svg') center center no-repeat;
      }

      &__inner {
        display: inline-block;
      }
    }

    &__avatar {
      height: 2.5rem;
      width: 2.5rem;
      border-radius: 50%;
      background-color: #90959f;

      img {
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
  }

  &__dropdown__user {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: .75rem 1rem .75rem 5.25rem;
    border-bottom: 1px solid #d1dbe3;
    min-height: 5rem;

    &__avatar {
      position: absolute;
      left: 1rem;
      top: .75rem;
      width: 3.5rem;
      height: 3.5rem;
      background-color: #90959f;
      border-radius: 50%;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        display: inline-block;
      }
    }

    &__name {
      display: block;
      font-size: .9375rem;
      font-weight: 600;
      font-family: 'proxima-nova', sans-serif !important;
    }

    &__company {
      font-size: .875rem;
      display: block;
      font-family: 'proxima-nova', sans-serif !important;
    }
  }

  &__dropdown__list {
    list-style-type: none;
    padding: .75rem 0;
    margin: 0;

    &__link {
      display: block;
      color: #4a5162;
      padding: .375rem 1.5rem .375rem 1rem;
      font-size: .875rem;
      font-weight: 600;
      text-decoration: none;
      white-space: nowrap;
      cursor: pointer;
      user-select: none;
      font-family: 'proxima-nova', sans-serif !important;

      &:hover {
        background-color: #f1f3f5;
        color: #4a5162;
      }

      &:focus,
      &:active,
      &.active {
        background-color: #4ba4da;
        color: #fff;
      }
    }
  }

  @media only screen and (max-width: 1400px) {
    .app-navbar__user {
      &__name {
        &__inner {
          display: none;
        }
      }  
    }
  }  
}

// Navbar Buttons
//---------------------------------------------

.app-navbar-btn {
  @extend .app-btn;
  width: 2.125rem;
  height: 2.125rem;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 1rem;
    height: 1rem;
    margin: -.5rem 0 0 -.5rem;
  }

  &--save {
    @extend .app-btn--secondary;

    &::after {
      background: url('/itarp-shared-assets-addon/images/btn_check.svg') center center / contain no-repeat;
    }
  }

  &--cancel {
    @extend .app-btn--danger;

    &::after {
      background: url('/itarp-shared-assets-addon/images/btn_cancel.svg') center center / contain no-repeat;
    }
  }
}
