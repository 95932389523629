// Button Sizes
//---------------------------------------------

@mixin button-size($button-padding, $button-font-size, $button-line-height) {
  padding: $button-padding;
  font-size: $button-font-size;
  line-height: $button-line-height;
}

// Button Types
//---------------------------------------------

@mixin button-type-fill(
  $button-background,
  $button-color: $button-color-default
) {
  background-color: $button-background;
  border-color: $button-background;
  color: $button-color;

  @if $button-background == $white {
    border-color: $border-color;
  }

  @if $button-background == transparent {
    border-color: $border-color;
    color: $button-color;
  }

  &:not(:disabled):not(.disabled):hover,
  &:not(:disabled):not(.disabled):focus,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).is-active {
    @if $button-background == transparent {
      color: darken($button-color, 10%);
      background-color: transparent;
      border-color: $border-color;
    } @else {
      background-color: darken($button-background, 10%);
      border-color: darken($button-background, 10%);
      color: $button-color;
    }
  }

  &:disabled,
  &.disabled,
  &.is-disabled {
    @if $button-background == transparent {
      color: lighten($button-color, 30%);
      background-color: transparent;
      border-color: $border-color;
    } @else {
      background-color: lighten($button-background, 10%);
      border-color: lighten($button-background, 10%);
      color: lighten($button-color, 10%);
    }
  }
}

// Button Outline Types
//---------------------------------------------

@mixin button-type-outline($button-color) {
  border-color: $button-color;
  color: $button-color;

  &:not(:disabled):not(.disabled):hover,
  &:not(:disabled):not(.disabled):focus,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).is-active {
    background-color: rgba($button-color, 0.15);
    border-color: $button-color;
    color: $button-color;
  }

  &:disabled,
  &.disabled,
  &.is-disabled {
    border-color: lighten($button-color, 10%);
    color: lighten($button-color, 10%);
  }
}

// Button Social
//---------------------------------------------

@mixin button-social(
  $button-background,
  $button-icon,
  $button-color: $button-social-icon-border-default,
  $button-border: $button-social-icon-color-default
) {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: $button-background;
  border-color: $button-border;
  color: $button-color;

  &::before {
    content: '';
    display: inline-block;
    width: $button-social-icon-width;
    height: $button-social-icon-height;
    margin: $button-social-icon-margin;
    background: url('/itarp-shared-assets-addon/images/buttons/#{$button-icon}')
      center center no-repeat;
  }

  &:not(:disabled):not(.disabled):hover,
  &:not(:disabled):not(.disabled):focus,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).is-active {
    background-color: darken($button-background, 10%);
    border-color: $button-border;
    color: $button-color;
  }

  &:disabled,
  &.disabled,
  &.is-disabled {
    background-color: lighten($button-background, 10%);
    border-color: lighten($button-border, 10%);
    color: lighten($button-color, 10%);
  }
}

// Form Button
//---------------------------------------------

@mixin form-button(
  $type,
  $font-weight: $form-button-font-weight,
  $font-size: $form-button-form-size
) {
  position: relative;

  label {
    font-size: $font-size !important;
    font-weight: $font-weight !important;
    display: inline-flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    margin: 0;
    color: $text-color-dark !important;
    font-family: 'proxima-nova', sans-serif !important;

    &::before {
      content: '';
      display: inline-block;
      position: relative;
      flex-shrink: 0;
      margin: $form-button-margin;
      width: $form-button-width;
      height: $form-button-height;
      border-style: solid;
      border-color: $form-button-border-color;
      background: $form-button-background;
    }

    &.is-strong {
      font-weight: $form-button-font-weight-strong;
    }

    & > span,
    & > a {
      margin: 0 .25rem;
    }
  }

  input[type='#{$type}'] {
    display: none;

    &:checked + .radio-group__label,
    &:checked + label {
      &::before {
        border-color: $form-button-border-color-active;
        background: url('/itarp-shared-assets-addon/images/forms/dot.svg')
          center center no-repeat;
      }
    }
  }

  @if $type == 'checkbox' {
    label {
      &::before {
        border-width: $form-button-border-width-checkbox;
        border-radius: $form-button-border-radius-checkbox;
      }
    }

    input[type='checkbox'] {
      &:checked + label {
        &::before {
          box-shadow: $form-button-box-shadow-active;
          background: url('/itarp-shared-assets-addon/images/forms/check.svg')
            center
            center /
            $form-button-background-size-checkbox
            no-repeat;
        }
      }
    }
  } @else if $type == 'radio' {
    label {
      &::before {
        border-width: $form-button-border-width-radio;
        border-radius: $form-button-border-radius-radio;
      }
    }

    input[type='checkbox'] {
      &:checked + label {
        &::before {
          background: url('/itarp-shared-assets-addon/images/forms/dot.svg')
            center
            center
            no-repeat;
        }
      }
    }
  } @else {
    @error "Unknown type #{$type}.";
  }
}

// Button Square Variants
//---------------------------------------

@mixin button-square-variant($color) {
  background-color: $color;
  border-color: $color;

  @if $color == $white {
    border-color: $btn-square-border-default;
  }

  @if $color == $white {
    &:not(:disabled):not(.disabled):hover,
    &:not(:disabled):not(.disabled):focus,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).is-active {
      background-color: $green-secondary;
      border-color: $green-secondary;
    }

    &:disabled,
    &.disabled,
    &.is-disabled {
      background-color: lighten($color, 10%);
      border-color: lighten($color, 10%);
    }
  } @else {
    &:not(:disabled):not(.disabled):hover,
    &:not(:disabled):not(.disabled):focus,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).is-active {
      background-color: darken($color, 10%);
      border-color: darken($color, 10%);
    }

    &:disabled,
    &.disabled,
    &.is-disabled {
      background-color: lighten($color, 10%);
      border-color: lighten($color, 10%);
    }
  }
}

// Button Square Sizes
//---------------------------------------

@mixin button-square-size($size, $icon-size) {
  width: $size;
  height: $size;

  $icon-size-half: math.div($icon-size, 2);

  &::before {
    height: $icon-size;
    width: $icon-size;
    margin-top: -$icon-size-half;
    margin-left: -$icon-size-half;
  }
}

// Button Square Type
//---------------------------------------

@mixin button-square-type($type) {
  & {
    &::before {
      background-image: url('/itarp-shared-assets-addon/images/buttons/btn_#{$type}.svg');
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
    }

    &.btn-square--dark {
      &::before {
        background-image: url('/itarp-shared-assets-addon/images/buttons/btn_#{$type}_dark.svg');
      }
    }

    &.btn-square--primary,
    &.btn-square--secondary {
      &::before {
        background-image: url('/itarp-shared-assets-addon/images/buttons/btn_#{$type}_white.svg');
      }
    }

    &.is-alternating {
      &:not(:disabled):not(.disabled):hover,
      &:not(:disabled):not(.disabled):focus,
      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled).is-active {
        &::before {
          background-image: url('/itarp-shared-assets-addon/images/buttons/btn_#{$type}_white.svg');
        }
      }
    }

    @if $type == 'favorite' {
      &.is-selected {
        &::before {
          background-image: url('/itarp-shared-assets-addon/images/buttons/btn_favorite_true.svg');
        }

        &.btn-square--dark {
          &::before {
            background-image: url('/itarp-shared-assets-addon/images/buttons/btn_favorite_true_dark.svg');
          }
        }
      }
    }
  }
}

// Card
//---------------------------------------------

@mixin card() {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  border-radius: $card-border-radius;
  box-shadow: $card-box-shadow;

  &__body {
    flex: 1;
  }
}

// Brakpoints
//---------------------------------------

$breakpoints: (
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1248px,
) !default;

@mixin breakpoint($breakpoint, $direction) {
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    @if $direction == max {
      @media (max-width: ($breakpoint-value - 1)) {
        @content;
      }
    } @else if $direction == min {
      @media (min-width: $breakpoint-value) {
        @content;
      }
    }
  } @else {
    @if $direction == max {
      @media (max-width: $breakpoint) {
        @content;
      }
    } @else if $direction == min {
      @media (min-width: $breakpoint) {
        @content;
      }
    }
  }
}

// Links group
//---------------------------------------

@mixin link-group($size: 'regural') {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: inline-flex;

  > li {
    > .ember-basic-dropdown-trigger a,
    > a {
      position: relative;
      display: block;
      width: $link-group-link-width;
      height: $link-group-link-height;
      border-top: $link-group-link-border;
      border-bottom: $link-group-link-border;
      background-color: $link-group-link-background;
      user-select: none;

      &:hover,
      &:focus,
      &.is-active {
        border-color: $link-group-link-border-color-active;
        background-color: $link-group-link-background-active;
      }

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        height: $link-group-link-icon-height;
        width: $link-group-link-icon-width;
        margin: $link-group-link-icon-margin;
      }

      $link-type: 'remove' 'trash' 'auto',
        'remove-circle' 'remove_circle' 'cover', 'edit' 'edit' 'auto',
        'more' 'more' 'auto', 'copy' 'clone' 'cover', 'add' 'add' 'auto',
        'add-circle' 'add_circle' '1.375rem 1.375rem', 'merge' 'merge' 'auto',
        'archive' 'archive' 'auto', 'settings' 'cog' 'contain', 'hide' 'hide' 'auto',
        'like' 'like' 'auto', 'dislike' 'like' 'auto',
        'shortlisted' 'shortlisted' 'contain',
        'placeholder' 'editor_placeholder' 'contain',
        'lock' 'editor_lock' 'contain',
        'attachment' 'editor_attachment' 'contain', 'clone' 'clone' 'contain',
        'refresh' 'refresh' 'contain', 'fullscreen' 'fullscreen' '1rem 1rem';

      @each $name, $icon, $size in $link-type {
        &.for-#{$name} {
          &::after {
            background: url('/itarp-shared-assets-addon/images/icon_#{$icon}.svg')
              center
              center /
              #{$size}
              no-repeat;
          }

          &:hover,
          &:focus,
          &.is-active,
          &:not(:disabled):not(.disabled):active,
          &:not(:disabled):not(.disabled).active {
            &::after {
              border-color: $link-group-link-border-color-active;
              background: $link-group-link-background-active
                url('/itarp-shared-assets-addon/images/icon_#{$icon}_active.svg')
                center
                center /
                #{$size}
                no-repeat;
            }
          }

          @if $name == 'dislike' {
            &::after {
              transform: rotate(180deg);
            }
          }
        }
      }

      &.with-label {
        width: auto;

        span {
          display: inline-block;
          font-size: $link-group-link-label-font-size;
          color: $link-group-link-label-color;
          text-transform: uppercase;
        }

        &:hover,
        &:focus,
        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled).active {
          span {
            color: $link-group-link-label-color-active;
          }
        }
      }
    }

    &:first-child {
      .ember-basic-dropdown-trigger a,
      a {
        border-left: $link-group-link-border;
        border-top-left-radius: $link-group-link-border-radius;
        border-bottom-left-radius: $link-group-link-border-radius;
      }
    }

    &:last-child {
      .ember-basic-dropdown-trigger a,
      a {
        border-right: $link-group-link-border;
        border-top-right-radius: $link-group-link-border-radius;
        border-bottom-right-radius: $link-group-link-border-radius;
      }
    }
  }

  > li + li {
    > .ember-basic-dropdown-trigger a,
    > a {
      border-left: $link-group-link-border;
    }
  }

  @if $size == 'large' {
    > li {
      > .ember-basic-dropdown-trigger a,
      > a {
        width: $link-group-link-width-large;
        height: $link-group-link-height-large;

        &::after {
          height: $link-group-link-icon-height-large;
          width: $link-group-link-icon-width-large;
          margin: $link-group-link-icon-margin-large;
        }

        &.with-label {
          padding: $link-group-link-label-padding-large;

          &::after {
            left: $link-group-link-label-icon-left-large;
            margin-left: 0 !important;
          }

          span {
            line-height: $link-group-link-height-large;
          }
        }
      }
    }
  } @else if $size == 'small' {
    > li {
      > .ember-basic-dropdown-trigger a,
      > a {
        width: $link-group-link-width-small;
        height: $link-group-link-height-small;

        &::after {
          height: $link-group-link-icon-height-small;
          width: $link-group-link-icon-width-small;
          margin: $link-group-link-icon-margin-small;
        }

        &.with-label {
          padding: $link-group-link-label-padding-small;

          &::after {
            left: $link-group-link-label-icon-left-small;
            margin-left: 0 !important;
          }

          span {
            line-height: $link-group-link-height-small;
          }
        }
      }
    }
  } @else {
    > li {
      > .ember-basic-dropdown-trigger a,
      > a {
        width: $link-group-link-width;
        height: $link-group-link-height;

        &::after {
          height: $link-group-link-icon-height;
          width: $link-group-link-icon-width;
          margin: $link-group-link-icon-margin;
        }

        &.with-label {
          padding: $link-group-link-label-padding;

          &::after {
            left: $link-group-link-label-icon-left;
            margin-left: 0 !important;
          }

          span {
            line-height: $link-group-link-height;
          }
        }
      }
    }
  }
}

// Label notes
//---------------------------------------

@mixin label-note() {
  .label-note {
    display: inline-block;

    &.label-note--required {
      margin-right: $fieldset-label-margin-required;

      &::before {
        content: '*';
        display: inline-block;
      }
    }

    &.label-note--optional,
    &.label-note--text {
      font-weight: $fieldset-lable-font-weight-optional;
      margin-left: $fieldset-label-margin-optional;
    }

    &.label-note--optional {
      &::before {
        content: '(Optional)';
        display: inline-block;
      }
    }
  }
}

// Match Score
//---------------------------------------

@mixin match-score($size: $match-score-dot-size) {
  display: inline-flex;
  align-items: center;

  > span {
    display: inline-flex;
    width: $size;
    height: $size;
    border-radius: 50%;
    background-color: $match-score-dot-background;

    &.is-filled {
      background-color: $match-score-dot-background-filled;
    }
  }

  > span + span {
    margin-left: $match-score-dot-distance;
  }
}

// Bullet List
//---------------------------------------

@mixin bullet-list($font-size: $bullet-list-font-size) {
  margin: 0;
  padding: 0;
  list-style-type: none;
  font-size: $font-size;

  > li {
    position: relative;
    padding: $bullet-list-item-padding;

    &::before {
      content: '•';
      position: absolute;
      top: $bullet-list-item-bullet-top;
      left: $bullet-list-item-bullet-left;
      font-size: $bullet-list-item-bullet-font-size;
      color: $bullet-list-item-bullet-color;
      line-height: 1;
    }
  }

  > li + li {
    margin-top: $bullet-list-item-distance;
  }
}

// Profile Badges
//---------------------------------------

@mixin profile-badges($type, $icon, $width, $height, $version: 'regular') {
  width: $width;
  height: $height;

  @if $version == 'regular' {
    background: url('/itarp-shared-assets-addon/images/cards/#{$icon}.svg')
      center
      center
      no-repeat;
  } @else if $version == 'light' {
    background: url('/itarp-shared-assets-addon/images/cards/#{$icon}_light.svg')
      center
      center
      no-repeat;
  }

  &:hover,
  &:focus {
    background: url('/itarp-shared-assets-addon/images/cards/#{$icon}_active.svg')
      center center no-repeat;
  }
}

// Tags
//---------------------------------------

@mixin tags {
  $parent: &;

  &__title {
    font-size: $tags-title-font-size;
    font-weight: $tags-title-font-weight;
    margin: $tags-title-margin;

    &.in-capitals {
      font-size: $tags-title-capitals-font-size;
      text-transform: uppercase;
    }

    &.is-light {
      color: $tags-title-color-light;
    }
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;

    &__item {
      position: relative;
      font-size: $tags-list-item-font-size;
      border: $tags-list-item-border;
      border-radius: $tags-list-item-border-radius;
      padding: $tags-list-item-padding;
      background-color: $tags-list-item-background;
      margin: $tags-list-item-margin;
      line-height: $tags-list-line-height;

      &__title,
      &__time,
      &__remove {
        display: inline-block;
      }

      &__title + &__time,
      &__title + &__remove {
        margin-left: $tags-list-item-elements-distance;
      }

      &__remove {
        &::after {
          content: '';
          display: inline-block;
          width: $tags-list-item-remove-width;
          height: $tags-list-item-remove-height;
          background: url('/itarp-shared-assets-addon/images/forms/close.svg')
            center center / contain no-repeat;
          cursor: pointer;
        }
      }

      &.is-checked {
        padding-right: $tags-list-item-checked-padding-right !important;

        &::after {
          content: '';
          position: absolute;
          top: 50%;
          right: $tags-list-item-checked-icon-position-right;
          margin-top: $tags-list-item-checked-icon-margin-top;
          width: $tags-list-item-checked-icon-width;
          height: $tags-list-item-checked-icon-height;
          background: url('/itarp-shared-assets-addon/images/check_yes.png')
            center center no-repeat;
        }
      }
    }

    &__add {
      margin: $tags-list-item-margin;

      &__link {
        position: relative;
        display: inline-block;
        cursor: pointer;
        outline: 0;
        user-select: none;
        font-size: $tags-list-add-link-font-size;
        font-weight: $tags-list-add-link-font-weight;
        padding: $tags-list-add-link-padding;
        border: $tags-list-add-link-border;
        border-radius: $tags-list-add-link-border-radius;
        background-color: $tags-list-add-link-background;
        color: $tags-list-add-link-color;
        text-decoration: none;
        box-shadow: $tags-list-add-link-box-shadow;

        &::before {
          content: '';
          position: absolute;
          left: $tags-list-add-link-icon-left;
          top: 50%;
          margin-top: $tags-list-add-link-icon-margin-top;
          width: $tags-list-add-link-icon-width;
          height: $tags-list-add-link-icon-height;
          background: url('/itarp-shared-assets-addon/images/cards/tags.svg')
            center center no-repeat;
        }

        &:hover,
        &:focus {
          background-color: $tags-list-add-link-background-active;
          color: $tags-list-add-link-color-active;
          border-color: $tags-list-add-link-border-color-active;

          &::before {
            background: url('/itarp-shared-assets-addon/images/cards/tags_active.svg')
              center center no-repeat;
          }
        }
      }
    }

    &__more {
      display: inline-flex;
      align-items: center;

      &__link {
        font-size: $tags-list-more-font-size;
        font-weight: $tags-list-more-font-weight;
        text-transform: uppercase;
      }
    }
  }

  &.with-duration {
    .card-block--tags__list__item__title {
      font-weight: $tags-list-item-duration-title-font-weight;
    }
  }

  &.tags--lg {
    #{$parent}__title {
      font-size: $tags-title-font-size-large;
      margin: $tags-title-margin-large;
    }

    #{$parent}__list {
      &__item {
        font-size: $tags-list-item-font-size-large;
        padding: $tags-list-item-padding-large;
        margin: $tags-list-item-margin-large;
      }
    }
  }

  &.tags--primary {
    #{$parent}__list {
      &__item {
        color: $tags-list-item-color-primary;
        background-color: $tags-list-item-background-primary;
        border-color: $tags-list-item-border-color-primary;
      }
    }
  }

  &.tags--outline-secondary {
    #{$parent}__list {
      &__item {
        background-color: $tags-list-item-background-outline-secondary;
        border-color: $tags-list-item-border-color-outline-secondary;
      }
    }
  }

  $colors-list: 'primary' $tag-params-primary-color,
    'secondary' $tag-params-secondary-color, 'warning' $tag-params-info-color,
    'info' $tag-params-warning-color;

  @each $type, $color in $colors-list {
    #{$parent}__list {
      &__item {
        &.is-active {
          &.tags--params-#{$type} {
            background-color: rgba($color, 0.05);
            color: $color;
            border-color: $color;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    &.tags--lg {
      #{$parent}__list {
        &__item {
          font-size: 1.0625rem;
          padding: 0.5rem .75rem;
          margin: 0.25rem 0.5rem 0.25rem 0;
        }
      }
    }
  }
}

// Workflow Line
//---------------------------------------------

@mixin workflow-line($direction) {
  content: '';
  position: absolute;
  background-color: $workflow-line-background;

  @if $direction == 'vertical' {
    width: $workflow-line-thinkness;
    margin-left: $workflow-line-margin;
    left: 50%;
  } @else if $direction == 'horizontal' {
    height: $workflow-line-thinkness;
  }
}

// Select Sizes
//---------------------------------------------

@mixin select-size(
  $select-size,
  $select-padding,
  $select-font-size: $select-default-font-size
) {
  .ember-power-select-trigger {
    font-size: $select-font-size !important;
    padding: $select-padding !important;
    min-height: auto !important;

    @if $select-size == 'xs' {
      min-width: $select-min-width-xs;
    }
  }
}

// Input Sizes
//---------------------------------------------

@mixin input-size(
  $input-padding,
  $input-line-height,
  $input-font-size: $input-default-font-size
) {
  padding: $input-padding;
  line-height: $input-line-height;
  font-size: $input-font-size;
}
