// Table
//---------------------------------------------

.table-wrapper {
  // width: 100%;
  // overflow-y: hidden;
  // overflow-x: auto;
  box-shadow: $table-wrapper-box-shadow;
  border: $table-wrapper-border;
  border-radius: $table-wrapper-border-radius;
  background-color: $table-wrapper-background;

  // &::-webkit-scrollbar {
  //   height: $table-wrapper-scrollbar-width;
  // }

  // &::-webkit-scrollbar-track {
  //   background: $table-wrapper-scrollbar-background;
  // }

  // &::-webkit-scrollbar-thumb {
  //   background: $table-wrapper-scrollbar-thumb-background;

  //   &:hover {
  //     background: $table-wrapper-scrollbar-thumb-background-hover;
  //   }
  // }

  &.in-card {
    box-shadow: none;
    border: none;
  }

  &.no-shadow {
    box-shadow: none;
  }
}

table,
.table {
  width: 100%;
}

.table {
  // min-width: $table-min-width;
  margin: 0;
  border: none;
  border-collapse: separate;
  border-spacing: 0;

  &__head {
    &__cell {
      background-color: $table-head-cell-background;
      text-align: left;
      padding: $table-head-cell-padding;
      z-index: 1;
      user-select: none;
      white-space: nowrap;

      &:first-child {
        border-radius: $table-head-cell-border-raious-first;
      }

      &:last-child {
        border-radius: $table-head-cell-border-raious-last;
      }

      &.is-selectable {
        padding-left: $table-light-head-cell-selectable-padding-left;
      }
    }

    &__title {
      position: relative;
      display: inline-block;
      color: $table-head-title-color;
      font-size: $table-head-title-font-size;
      font-weight: $table-head-title-font-weight;

      &.is-filter {
        padding: $table-head-title-padding-filter;
        cursor: pointer;

        &::after {
          content: '';
          position: absolute;
          width: $table-head-title-caret-width;
          height: $table-head-title-caret-height;
          right: 0;
          top: 50%;
          margin: $table-head-title-caret-margin;
          background: url('/itarp-shared-assets-addon/images/table_caret.svg')
            center center no-repeat;
        }
      }
    }
  }

  &__body {
    &__cell {
      text-align: left;
      vertical-align: top;
      background: $table-body-cell-background;
      border-bottom: $table-body-cell-border;
      padding: $table-body-cell-padding;

      &.is-shifted {
        padding-left: $table-body-cell-padding-left-shifted;
      }
    }

    &__row {
      &:hover {
        .table__body__cell {
          background-color: $table-body-cell-background-active;
        }
      }

      &.with-wider-cells {
        .table__body__cell {
          padding: $table-body-cell-wider-padding;
        }
      }

      &.with-menu {
        position: relative;

        td {
          position: relative;
        }

        .table-row-menu {
          position: absolute;
          top: $table-row-menu-top;
          right: $table-row-menu-right;
        }
      }

      &.for-info {
      }

      &.for-pdf {
        .table__body__collapse {
          &.is-expanded {
            height: $table-body-cell-pdf-height;

            iframe {
              width: 100%;
              height: 100%;
            }
          }

          .card-block--controls__list {
            min-width: 68rem;
          }
        }
      }
    }

    &__filters {
      &__cell {
        padding: $table-body-filters-cell-padding;
        border-bottom: $table-body-filters-cell-border !important;
      }
    }

    &__collapse {
      &.is-collapsed {
        display: none;
      }

      .card-block--controls__list {
        min-width: 68rem;
      }
    }

    &.table__row__group {
      .table__body__row {
        &.with-menu {
          .table-row-menu {
            display: none;
          }
        }
      }

      .table__body__cell {
        vertical-align: top;
      }

      &:hover {
        > tr {
          .table__body__cell {
            background-color: $table-body-cell-background-active;

            .card-block--chat__post {
              background-color: $border-color;
            }
          }
        }

        .table__body__row {
          &.with-menu {
            .table-row-menu {
              display: inline-flex;
              justify-content: flex-end;
            }
          }
        }
      }

      tr {
        .table__body__cell {
          border: none;
        }

        &:last-child {
          .table__body__cell {
            border-bottom: $table-body-cell-border;
          }
        }
      }
    }
  }

  tbody {
    &:last-child {
      > tr {
        &:last-child {
          .table__body__cell {
            border-bottom: none;

            &:first-child {
              border-radius: $table-body-cell-border-radius-first;
            }

            &:last-child {
              border-radius: $table-body-cell-border-radius-last;
            }
          }
        }
      }
    }
  }

  .component-wrapper {
    padding: 1rem 1rem;
  }

  &.table--dark {
    th {
      color: #fff !important;
      background-color: #8a95a3 !important;
    }

    &.table--no-actions {
      .table__body__filters {
        & > td {
          border-bottom: none !important;
        }
      }

      .table__row__group {
        & > tr {
          &:first-child {
            td {
              border-top: 1px solid $border-color !important;
            }
          }
        }
      }
    }
  }
}

.table-row-menu {
  width: $table-row-menu-width;

  &__controls {
    @include link-group('large');
    box-shadow: $table-row-menu-box-shadow;
  }
}

.table-row-filters {
  display: flex;
  justify-content: space-between;

  &__group {
    display: inline-flex;
    align-items: center;

    > * + * {
      position: relative;
      margin-left: $table-row-filters-distance;
    }

    .dropdown {
      height: $table-row-filters-dropdown-height;
    }

    .checkbox-group {
      margin-top: .25rem;
    }
  }
}

// Table Reviews
//---------------------------------------------

.table-reviews {
  border-collapse: collapse;
  border: none;

  &__head {
    &__cell {
      font-size: $table-review-head-cell-font-size;
      font-weight: $table-review-head-cell-font-weight;
      text-transform: uppercase;
      text-align: left;
      color: $table-review-head-cell-color;
      padding: $table-review-head-cell-padding;
      letter-spacing: -0.2px;
    }
  }

  &__body {
    &__header {
      &__cell {
        font-size: $table-review-header-cell-font-size;
        font-weight: $table-review-header-cell-font-weight;
        background-color: $table-review-header-cell-background;
        color: $table-review-header-cell-color;
        padding: $table-review-header-cell-padding;
        border: none !important;
      }
    }

    &__row {
      &.is-borderless {
        td {
          border: none !important;
        }
      }
    }

    &__cell {
      font-size: $table-review-body-cell-font-size;
      padding: $table-review-body-cell-padding;

      &__arrow {
        display: inline-block;
        width: $table-review-body-cell-arrow-width;
        height: $table-review-body-cell-arrow-height;
        margin: $table-review-body-cell-arrow-margin;
        background: url('/itarp-shared-assets-addon/images/arrow_next.svg')
          center center / contain no-repeat;
      }
    }

    > tr + tr {
      td {
        border-top: $table-review-body-row-border;
      }
    }
  }

  &__list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: inline-flex;
    align-items: center;
    min-height: $table-review-list-min-height;

    &__item {
      display: inline-block;
      height: $table-review-list-item-height;
      width: $table-review-list-item-width;
      border-radius: 50%;

      $review-types: '1' $review-color-0-20 'center center',
        '2' $review-color-21-40 'center .5rem',
        '3' $review-color-41-60 'center center',
        '4' $review-color-61-80 'center center',
        '5' $review-color-81-100 'center center';

      @each $type, $color, $position in $review-types {
        &.review-type--#{$type} {
          background: #{$color}
            url('/itarp-shared-assets-addon/images/cards/rating_#{$type}.svg')
            #{$position}
            no-repeat;
        }
      }
    }

    &__item + &__item {
      margin-left: $table-review-list-item-distance;
    }
  }

  &__percentages {
    width: 100%;
    height: $table-review-percentage-height;
    border-radius: $table-review-percentage-border-radius;
    padding: $table-review-percentage-padding;

    &__bar {
      height: 100%;
      border-radius: $table-review-percentage-border-radius;
      background-color: $table-review-percentage-bar-background;
    }
  }

  &__posters {
    border: $table-review-posters-border;
    border-radius: $table-review-posters-border-radius;
    padding: $table-review-posters-padding;

    &__list {
      list-style-type: none;
      margin: 0;
      padding: 0;

      &__item {
        position: relative;
        padding: $table-review-posters-list-item-padding;
        min-height: $table-review-posters-list-item-min-height;
      }

      &__avatar {
        position: absolute;
        left: 0;
        top: $table-review-posters-list-avatar-top;
        width: $table-review-posters-list-avatar-weight;
        height: $table-review-posters-list-avatar-height;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        font-size: $table-review-posters-list-avatar-font-size;
        font-weight: $table-review-posters-list-avatar-font-weight;
        color: $table-review-posters-list-avatar-color;
        background-color: $green-primary;
      }

      &__name {
        &,
        & a {
          margin: 0;
          font-size: $table-review-posters-list-name-font-size;
          font-weight: $table-review-posters-list-name-font-weight;
          color: $table-review-posters-list-name-color;

          &:hover,
          &:focus {
            color: $table-review-posters-list-name-color-active;
          }
        }
      }

      &__text {
        font-size: $table-review-posters-list-text-font-size;
        margin: $table-review-posters-list-text-margin;
      }

      li + li {
        border-top: $table-review-posters-border;
      }
    }
  }

  &__comments {
    display: inline-block;

    &__trigger {
      display: inline-block;
      width: $table-review-comments-trigger-width;
      height: $table-review-comments-trigger-height;
      cursor: pointer;
      outline: 0;
      background: url('/itarp-shared-assets-addon/images/cards/comments.svg')
        center center no-repeat;
    }

    &__menu {
      background-color: $table-review-comments-menu-background;
      box-shadow: $table-review-comments-menu-box-shadow;
      border: $table-review-comments-menu-border;
      border-radius: $table-review-comments-menu-border-radius;
      padding: $table-review-comments-menu-padding;
      margin: $table-review-comments-menu-margin;
      width: 100%;
      max-width: $table-review-comments-menu-max-width;
      max-height: $table-review-comments-menu-max-height;

      .table-reviews__posters__list {
        padding: $table-review-comments-menu-posters-padding;
        overflow-y: auto;
      }
    }
  }

  &--percentages {
    td {
      &.not-breaking {
        white-space: nowrap;
      }

      &.with-bar {
        width: 100%;
      }
    }

    tr {
      &:last-child {
        border-bottom: $table-review-body-row-border;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    &__head {
      &__cell {
        font-size: .75rem;
        padding: .75rem .625rem;
      }
    }

    &__body {
      &__header {
        &__cell {
          font-size: 1.125rem;
        }
      }

      &__cell {
        font-size: 1rem;
        padding: .75rem .625rem;
      }
    }

    &__posters {
      padding: .5rem .75rem;

      &__list {

        &__item {
          padding: .75rem 0 .75rem 3.5rem;
        }
      }
    }

    &__percentages {
      padding: 0 0 0 .625rem;
    }
  }
}

// Table Modal Reviews
//---------------------------------------------

.table-modal-reviews {
  border-collapse: collapse;
  border: none;

  &__head {
    &__cell {
      font-size: $table-modal-review-head-font-size;
      font-weight: $table-modal-review-head-font-weight;
      text-align: left;
      text-transform: uppercase;
      padding: $table-modal-review-head-padding;
      color: $table-modal-review-head-color;
      background-color: $table-modal-review-head-font-background;
      letter-spacing: -0.2px;
    }
  }

  &__body {
    &__header,
    &__row {
      td {
        font-size: $table-modal-review-body-cell-font-size;
        border-bottom: $table-modal-review-body-cell-border;
        padding: $table-modal-review-body-cell-padding;
      }
    }

    &__header {
      &__cell {
        font-size: $table-modal-review-body-header-font-size;
        font-weight: $table-modal-review-body-header-font-weight;
        color: $table-modal-review-body-header-color;
        padding: $table-modal-review-body-header-padding;
      }
    }

    &__cell {
      &.with-input {
        width: $table-modal-review-body-cell-input-width;
      }

      &.with-rating {
        width: $table-modal-review-body-cell-rating-width;
      }

      &.with-comment {
        text-align: center;
        width: $table-modal-review-body-cell-comment-width;
      }
    }

    &__row {
      &.is-parent {
        .table-modal-reviews__body__cell {
          background-color: $table-modal-review-body-parent-cell-background;

          &:first-child {
            font-size: $table-modal-review-body-parent-cell-font-size;
            font-weight: $table-modal-review-body-parent-cell-font-weight;
            padding-right: $table-modal-review-body-parent-cell-padding-right;
          }
        }
      }

      &.is-child {
        td {
          &:first-child {
            padding-left: $table-modal-review-body-child-cell-padding;
          }
        }
      }

      td + td {
        border-left: $table-modal-review-body-cell-border;
      }
    }
  }

  &__comments {
    display: inline-block;

    &__trigger {
      display: inline-block;
      width: $table-modal-review-comments-trigger-width;
      height: $table-modal-review-comments-trigger-height;
      cursor: pointer;
      outline: 0;
      background: url('/itarp-shared-assets-addon/images/cards/comments_not_available.svg')
        center center no-repeat;
    }

    &.has-comment {
      .table-modal-reviews__comments__trigger {
        background: url('/itarp-shared-assets-addon/images/cards/comments.svg')
          center center no-repeat;
      }
    }
  }
}

// Table Card
//---------------------------------------------

.table-card {
  border-collapse: collapse;
  border: none;

  &__head {
    &__cell {
      font-size: $table-card-head-cell-font-size;
      font-weight: $table-card-head-cell-font-weight;
      padding: $table-card-head-cell-padding;
      text-transform: uppercase;
      text-align: left;
      border-top: $table-card-cell-border;
      white-space: nowrap;

      &:first-child {
        padding-left: $table-card-body-cell-padding-extrems;
      }

      &:last-child {
        padding-right: $table-card-body-cell-padding-extrems;
      }

      // &.is-limited {
      //   width: $table-card-body-cell-limited-width;
      // }
    }
  }

  &__body {
    &__cell {
      border-top: $table-card-cell-border;
      padding: $table-card-body-cell-padding;

      &:first-child {
        padding-left: $table-card-body-cell-padding-extrems;
      }

      &:last-child {
        padding-right: $table-card-body-cell-padding-extrems;
      }

      .card-block--profile,
      .card-block--job-role {
        min-width: auto;
      }
    }

    &.table-card__body--align-top {
      .table-card__body__cell {
        vertical-align: top;
      }
    }
  }

  &__controls {
    @include link-group;
  }

  &__actions {
    display: inline-flex;
    align-items: center;

    > * + * {
      margin-left: $table-card-body-cell-actions-distance-sm !important;
    }
  }

  &.no-top-border {
    .table-card__head__cell {
      border-top: none;
    }
  }

  &.no-pad-start {
    .table-card__head {
      tr {
        th:first-child {
          padding-left: 0;
        }
      }
    }

    .table-card__body {
      tr {
        td:first-child {
          padding-left: 0;
        }
      }
    }
  }

  &.no-pad-end {
    .table-card__head {
      tr {
        th:last-child {
          padding-right: 0;
        }
      }
    }

    .table-card__body {
      tr {
        td:last-child {
          padding-right: 0;
        }
      }
    }
  }

  &.table-card--sm {
    .table-card__head {
      &__cell {
        font-size: $table-card-head-cell-font-size-sm;
      }
    }

    .table-card__body__cell--actions {
      width: $table-card-body-cell-actions-width-sm;
      text-align: right;

      &.not-strict {
        width: auto;
      }
    }

    .table-card__body__cell--controls {
      width: $table-card-body-cell-controls-width-sm;
    }

    .table-card__controls {
      @include link-group('small');
    }
  }

  &.table-card--light {
    .table-card__head {
      &__cell {
        font-weight: $table-card-head-cell-font-weight-light;
        background-color: $table-card-head-cell-background-light;
      }
    }
  }
}

// Table Types
//---------------------------------------------

.app-table-light,
.app-table-dark {
  border-collapse: separate;
  border-spacing: 0;

  &__head {
    &__cell {
      font-size: $table-light-head-cell-font-size;
      font-weight: $table-light-head-cell-fonr-weight;
      text-align: left;
      text-transform: uppercase;
      padding: $table-light-head-cell-padding;
      z-index: 1;
      user-select: none;

      &:first-child {
        border-radius: $table-light-head-cell-border-radius-first;
      }

      &:last-child {
        border-radius: $table-light-head-cell-border-radius-last;
      }

      &.is-selectable {
        padding-left: $table-light-head-cell-selectable-padding-left;
      }

      &.has-max-width {
        width: 20rem;
      }

      &.is-pushing {
        width: 99%;
      }
    }
  }

  &__body {
    &__cell,
    &__header {
      text-align: left;
      vertical-align: top;
      background: $table-light-body-cell-background;
      // padding: $table-light-body-cell-padding;
    }

    &__cell {
      position: relative;
      border-bottom: $table-light-body-cell-border;

      &.is-pushing {
        width: 99%;
      }

      .card-block--paragraph,
      .card-block--rates__list__item,
      .card-block--tracking__list li,
      .card-block--rates {
        color: #4a5162 !important;
      }
    }

    &__row {
      &:hover {
        .table__body__cell {
          background-color: $table-light-body-cell-background-active;
        }
      }

      &--clickable {
        cursor: pointer;

        &:hover {
          .app-table-light__body__cell {
            background-color: $table-light-body-cell-background-active;
          }
        }
      }
    }

    > tr {
      &:last-child {
        .app-table-light__body__cell {
          border-bottom: none;

          &:first-child {
            border-radius: $table-light-body-cell-border-radius-first;
          }

          &:last-child {
            border-radius: $table-light-body-cell-border-radius-last;
          }
        }
      }
    }
  }

  .input-group {
    width: 100%;
  }

  &__status {
    &__header {
      position: relative;
      display: flex;
      align-items: center;
      padding: $table-light-status-header-padding;
      width: 100%;
      flex: 1;
    }


    &__body {
      position: relative;
      font-size: $table-light-status-font-size;
      font-weight: $table-light-status-font-weight;
      padding: $table-light-status-padding;
      font-family: 'proxima-nova', sans-serif !important;

      &::before {
        content: '';
        position: absolute;
        display: inline-block;
        left: 0;
        top: 50%;
        width: $table-light-status-dot-width;
        height: $table-light-status-dot-height;
        margin-top: $table-light-status-dot-margin-top;
        border-radius: 50%;
      }

      $workflow-statuses: 'inactive' $grey-primary,
        'draft' $review-color-41-60,
        'rejected' $review-color-0-20,
        'active' $table-light-status-color-active,
        'pending_delete' $table-light-status-color-pending_delete;

      @each $status, $color in $workflow-statuses {
        &.is-#{$status} {
          color: $color;

          &::before {
            background-color: $color;
          }
        }
      }

      &.is-rejected {
        padding-right: 1.25rem;

        &::after {
          content: '';
          position: absolute;
          right: 0;
          width: 0.75rem;
          height: 0.75rem;
          top: 50%;
          margin-top: -0.375rem;
          background: url('/itarp-shared-assets-addon/images/error_caret.svg')
            center center no-repeat;
        }
      }
    }
  }

  &__controls {
    @include link-group;
  }

  .component-wrapper {
    padding: 1rem 1rem;
  }
}

.app-table-light {
  &__head {
    &__cell {
      color: $table-light-head-cell-color;
      background-color: $table-light-head-cell-background;
      border-bottom: 1px solid $border-color;
    }
  }
}

.app-table-dark {
  &__head {
    &__cell {
      color: $white;
      background-color: #8a95a3;
    }
  }
}

// Table Scroll
//---------------------------------------------

.table-scroll {
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;

  &.with-fixed-column {
    > table {
      white-space: nowrap;
      margin: 0;
      border: none;
      border-collapse: separate;
      border-spacing: 0;
      table-layout: auto;

      thead {
        th {
          position: sticky;

          &.is-fixed {
            z-index: 2;
            position: sticky;
            left: 0;
          }
        }
      }

      tbody {
        td {
          &.is-fixed {
            position: sticky;
            left: 0;
            z-index: 1;
          }
        }

        tr {
          &:hover {
            td {
              background-color: $table-body-cell-background-active;
            }
          }
        }
      }
    }
  }

  &::-webkit-scrollbar {
    height: $table-wrapper-scrollbar-width;
  }

  &::-webkit-scrollbar-track {
    background: $table-wrapper-scrollbar-background;
  }

  &::-webkit-scrollbar-thumb {
    background: $table-wrapper-scrollbar-thumb-background;

    &:hover {
      background: $table-wrapper-scrollbar-thumb-background-hover;
    }
  }
}

// Table Selectable
//---------------------------------------------

.table-selectable {
  &__row {
    width: 100%;

    &:hover {
      td {
        background: $table-body-cell-background !important;
      }
    }
  }

  &__controls {
    position: sticky;
    position: -webkit-sticky;
    left: 0;
    height: $table-selectable-controls-height;
    top: $table-selectable-controls-top;
    z-index: 3;

    &__select,
    &__actions {
      position: absolute;
      display: inline-flex;
      align-items: center;
      top: 0;
      height: $table-selectable-controls-height;
    }

    &__select {
      padding: $table-selectable-controls-select-padding;
      width: $table-selectable-controls-select-width;
    }

    &__actions {
      left: $table-selectable-controls-select-width;

      .app-btn + .app-btn {
        margin-left: $table-selectable-controls-actions-distance;
      }
    }

    &:nth-child(2) {
      left: $table-selectable-controls-select-width;
      z-index: 2;
    }
  }

  tbody {
    > tr {
      &:nth-child(2) {
        td {
          border-top: $table-body-cell-border;
        }
      }
    }
  }
}

// Table Empty
//---------------------------------------------

.table-empty {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: $table-empty-padding;

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__icon {
    display: inline-block;
    margin: $table-empty-icon-margin;

    &.no-users {
      margin-left: $table-empty-icon-users-margin-left;
      width: $table-empty-icon-users-width;
      height: $table-empty-icon-users-height;
      background: url('/itarp-shared-assets-addon/images/table_no_user.svg')
        center center no-repeat;
    }

    &.no-groups {
      width: $table-empty-icon-groups-width;
      height: $table-empty-icon-groups-height;
      background: url('/itarp-shared-assets-addon/images/table_no_group.svg')
        center center no-repeat;
    }
  }

  &__title {
    font-size: $table-empty-title-font-size;
    font-weight: $table-empty-title-font-weight;
    margin: $table-empty-title-margin;
  }

  &__text {
    margin: $table-empty-text-margin;
  }

  &.is-narrow {
    padding: $table-empty-padding-narrow;
  }

  &.is-dark {
    background-color: $table-empty-dark-background;
    border-radius: $table-empty-dark-border-radius;
  }
}

// Table Templates
//---------------------------------------------

.table-templates {
  &__head {
    &__cell {
      &__status {
        width: $table-templates-head-cell-status-width;
      }

      // &__sentby {
      //   width: $table-templates-head-cell-sentby-width;
      // }

      // &__to {
      //   width: $table-templates-head-cell-to-width;
      // }
    }
  }

  &__body {
    &__cell {
      padding: $table-templates-body-cell-padding;
    }
  }

  tbody {
    tr {
      td {
        border-bottom: $table-templates-body-cell-border;
      }

      &:last-child {
        td {
          border-bottom: none;
        }
      }
    }
  }
}

.template-group {
  .bg-default.card {
    all: unset !important;
  }

  &.is-out {
    opacity: 0.25;
  }

  .mb-0 {
    display: flex;
    margin-bottom: 0;
    flex: 1;
  }

  &__header {
    display: flex;
    justify-content: space-between;

  }

  &__title {
    font-size: $template-group-title-font-size !important;
    font-weight: $template-group-title-font-weight !important;
    font-family: 'proxima-nova', sans-serif !important;
    color: $template-group-title-color;
    margin: $template-group-title-margin;
    line-height: 1.325 !important;

    span {
      display: block;
      font-size: $template-group-title-span-font-size;
      font-weight: $template-group-title-span-font-weight;
      color: $template-group-title-span-color;
      margin: $template-group-title-span-margin;
    }
  }

  &__item {
    background-color: $template-group-item-background !important;
    border: $template-group-item-border !important;
    border-radius: $template-group-item-border-radius !important;

    &__wrapper {
      padding: 0.5rem 0;

      .card {
        box-shadow: none !important;
      }
    }

    &.is-out {
      border: 1px solid #de5e6a;
    }

    h5 {
      &.mb-0 {
        font-family: 'proxima-nova', sans-serif !important;
        line-height: 1.325 !important;

        > .btn {
          display: flex;
          align-items: center;
          background-color: transparent;
          border: 0;
          outline: 0;
          color: inherit;
          width: 100%;
          padding: 0;
          text-align: left;

          &:hover,
          &:focus {
            color: $text-color-dark;
            background-color: transparent;
          }
        }

        > button {
          > * {
            padding: $template-group-item-header-element-padding;
          }
        }
      }
    }

    &__header {
      position: relative;
      display: flex;
      align-items: center;
      padding: $template-group-item-header-padding !important;
      width: 100%;
      flex: 1;
      background-color: transparent !important;
      border-bottom: none !important;

      &::before {
        content: '';
        position: absolute;
        left: 0.75rem;
        top: 50%;
        width: 0.5rem;
        height: 1.5rem;
        margin-top: -.75rem;
        background: url('/itarp-shared-assets-addon/images/icon_handler.svg')
          center center / contain no-repeat;
      }

      &__info {
        display: block;
        position: relative;
        flex: 1;
        cursor: pointer;
        outline: 0;
        padding-left: $template-group-item-header-info-padding-left;
        text-decoration: none;

        &::before {
          content: '';
          position: absolute;
          left: $template-group-item-header-caret-left;
          top: 50%;
          width: $template-group-item-header-caret-width;
          height: $template-group-item-header-caret-height;
          margin-top: $template-group-item-header-caret-margin-top;
          background: url('/itarp-shared-assets-addon/images/table_caret.svg')
            center center no-repeat;
          transition: transform 0.25s ease-in-out;
          transform: rotate(-90deg);
        }
      }

      &__status {
        width: $template-group-item-header-status-width;
      }

      // &__sentby,
      // &__to {
      //   font-size: $template-group-item-header-sentby-font-size;
      // }

      // &__sentby {
      //   width: $template-group-item-header-sentby-width;
      // }

      // &__to {
      //   width: $template-group-item-header-to-width;
      // }

      &__controls {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: $template-group-item-header-controls-width;
      }

      &.expanded {
        .template-group__item__header__info {
          &::before {
            transform: rotate(0deg);
          }
        }
      }
    }

    &__body {
      overflow: hidden;

      &__inner {
        padding: $template-group-item-body-padding;
        border-top: $template-group-item-border;
      }

      &.is-collapsed {
        transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
        max-height: 0;
      }

      &.is-expanded {
        transition: max-height 1s ease-in-out;
        max-height: 100rem;
      }
    }

    &__title {
      font-size: $template-group-item-title-font-size !important;
      font-weight: $template-group-item-title-font-weight !important;
      margin: $template-group-item-title-margin !important;
      color: $template-group-item-title-color !important;
      line-height: 1.325 !important;
    }

    &__text {
      font-size: $template-group-item-text-font-size !important;
      color: $template-group-item-text-color !important;
      margin: 0 !important;
      line-height: 1.325 !important;
    }

    &__controls {
      @include link-group('large');
    }

    &__status {
      position: relative;
      font-size: $template-group-item-status-font-size;
      font-weight: $template-group-item-status-font-weight;
      padding: $template-group-item-status-padding;
      font-family: 'proxima-nova', sans-serif !important;

      &::before {
        content: '';
        position: absolute;
        display: inline-block;
        left: 0;
        top: 50%;
        width: $template-group-item-status-dot-width;
        height: $template-group-item-status-dot-height;
        margin-top: $template-group-item-status-dot-margin-top;
        border-radius: 50%;
      }

      $template-statuses: 'inactive' $template-group-item-status-color-inactive,
      'draft' $template-group-item-status-color-draft,
      'rejected' $template-group-item-status-color-rejected,
      'active' $template-group-item-status-color-active,
      'deleted' $template-group-item-status-color-deleted,
      'deletion_rejected' $template-group-item-status-color-rejected;

      @each $status, $color in $template-statuses {
        &.is-#{$status} {
          color: $color;

          &::before {
            background-color: $color;
          }
        }
      }
    }

    &__info {
      list-style-type: none;
      margin: 0;
      padding: 0;

      &__item {
        font-size: $template-group-item-info-item-font-size;

        span {
          font-weight: $template-group-item-info-item-font-weight;
        }
      }

      &__item + &__item {
        margin-top: $template-group-item-info-item-distance;
      }
    }

    &__content {
      border: $template-group-item-border;
      border-radius: $template-group-item-content-border-radius;
      background-color: $template-group-item-content-background;
      padding: $template-group-item-content-padding;
      overflow-y: auto;
      // max-height: $template-group-item-content-max-height;
      margin: $template-group-item-content-margin;

      p,
      li {
        font-size: $template-group-item-content-text-font-size;
      }

      ol,
      ul {
        padding: $template-group-item-content-list-padding;
        margin: 0;
      }

      > * {
        margin: $template-group-item-content-text-element-margin !important;

        &:last-child {
          margin: 0 !important;
        }
      }
    }
  }

  &__workflows {
    &__title {
      font-size: $template-group-workflows-title-font-size;
      font-weight: $template-group-workflows-title-font-weight;
      margin: $template-group-workflows-title-margin;
    }

    &__list {
      border: $template-group-workflows-list-border;
      border-radius: $template-group-workflows-list-border-radius;
      background-color: $template-group-workflows-list-background;
      list-style-type: none;
      margin: 0;
      padding: 0;

      &__item {
        display: flex;
        padding: $template-group-workflows-list-item-padding;
        align-items: center;

        &__title{
          flex: 1;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      &__controls {
        @include link-group;
      }

      &__item + &__item {
        border-top: $template-group-workflows-list-border;
      }
    }


  }

  > * {
    &:last-child {
      margin: 0;
    }
  }
}

// Aligments
//---------------------------------------------

.app-table,
.app-table-light {
  $table-cell-alignments: 'left', 'center', 'right';
  $table-cell-v-alignments: 'top', 'middle', 'bottom';

  @each $align in $table-cell-alignments {
    .table-cell-align--#{$align} {
      text-align: #{$align} !important;
    }
  }

  @each $align in $table-cell-v-alignments {
    .table-cell-v-align--#{$align} {
      vertical-align: #{$align} !important;
    }
  }
}
