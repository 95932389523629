// Fieldset
//---------------------------------------------

.fieldset {
  @include label-note;
  position: relative;
  font-family: inherit;

  label {
    position: relative;
    display: inline-block;
    margin-bottom: $fieldset-label-margin;
    font-size: $fieldset-label-font-size;
    font-weight: $fieldset-lable-font-weight;
    font-family: 'proxima-nova', sans-serif !important;

    .label-note {
      display: inline-block;

      &.label-note--required {
        margin-right: $fieldset-label-margin-required;

        &::before {
          content: '*';
          display: inline-block;
        }
      }

      &.label-note--optional,
      &.label-note--text {
        font-weight: $fieldset-lable-font-weight-optional;
        margin-left: $fieldset-label-margin-optional;
      }

      &.label-note--optional {
        &::before {
          content: '(Optional)';
          display: inline-block;
        }
      }
    }
  }

  &.has-tip {
    label {
      padding: $fieldset-has-tip-label-padding;
    }
  }

  &.has-recommendations {
    label {
      padding: $fieldset-has-recommendation-label-padding;
    }
  }

  &.has-tip.has-recommendations {
    label {
      padding: $fieldset-has-tip-and-recommendation-label-padding;
    }

    .form-recommendations {
      right: $fieldset-has-tip-and-recommendation-right;
    }

    @media only screen and (max-width: 768px) {
      label {
        padding: 0 10.25rem 0 0 !important;
      }

      .form-recommendations {
        right: 0 !important;
        top: 1.75rem;
      }
    }
  }

  &.for-email {
    display: flex !important;
    padding: .25rem 0;
    border-bottom: $input-group-field-border;

    label {
      display: inline-flex;
      align-items: center;
      height: 3.5rem;
      margin-bottom: 0;
      margin-right: 1rem;
    }

    & > input,
    & > textarea,
    .ember-basic-dropdown-trigger {
      flex: 1;
    }

    & > input,
    & > textarea,
    & > .input-group__fields > input {
      border: none !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    & > .input-group__fields > input {
      padding-right: 4rem !important;
    }

    .ember-basic-dropdown-trigger {
      border: none !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    &.has-border--top {
      border-top: $input-group-field-border;
    }
  }

  &-note {
    display: inline-block;
    font-size: $fieldset-note-font-size;
    color: $fieldset-note-color;
    margin: $fieldset-note-margin;

    &.fieldset-note--error {
      color: $accent-color-4;
    }
  }

  &-suggestions {
    margin: $fieldset-suggestions-margin;

    &__title {
      display: block;
      font-size: $fieldset-suggestions-title-font-size;
      font-weight: $fieldset-suggestions-title-font-weight;
      color: $fieldset-suggestions-title-color;
      margin: $fieldset-suggestions-title-margin;
    }

    &__list {
      margin: 0;
      padding: 0;
      list-style-type: none;
      display: flex;
      flex-wrap: wrap;

      &__item {
        margin: $fieldset-suggestions-list-item-margin;
      }

      &__link {
        display: inline-block;
        position: relative;
        font-size: $fieldset-suggestions-list-link-font-size;
        padding: $fieldset-suggestions-list-link-padding;
        cursor: pointer;
        user-select: none;
        outline: 0;

        &::before {
          content: '';
          position: absolute;
          left: $fieldset-suggestions-list-link-icon-left;
          top: 50%;
          margin: $fieldset-suggestions-list-link-icon-margin;
          width: $fieldset-suggestions-list-link-icon-width;
          height: $fieldset-suggestions-list-link-icon-height;
          background: url('/itarp-shared-assets-addon/images/buttons/add_primary.svg') center center / contain no-repeat;
        }

        &.is-added {
          color: $fieldset-suggestions-list-link-color-added;
          background-color: $fieldset-suggestions-list-link-background-added;
          border-radius: $fieldset-suggestions-list-link-border-radius;

          &::before {
            background: url('/itarp-shared-assets-addon/images/buttons/add.svg') center center / contain no-repeat;
          }
        }
      }
    }

    &.fieldset-suggestions--boxed {
      .fieldset-suggestions__list__link {
        padding: $fieldset-suggestions-list-link-padding-boxed;
        border: $fieldset-suggestions-list-link-border-boxed;
        border-radius: $fieldset-suggestions-list-link-border-radius-boxed;
        background-color: $fieldset-suggestions-list-link-background-boxed;
        margin: $fieldset-suggestions-list-item-margin-boxed;

        &::before {
          left: $fieldset-suggestions-list-link-icon-left-boxed;
        }
      }
    }

    &.fieldset-suggestions--secondary {

      .fieldset-suggestions__title,
      .fieldset-suggestions__list__link {
        color: $fieldset-suggestions-secondary-color;
      }

      .fieldset-suggestions__list__link {
        &::before {
          background: url('/itarp-shared-assets-addon/images/buttons/add_secondary.svg') center center / contain no-repeat;
        }

        &.is-added {
          background-color: $fieldset-suggestions-secondary-color;
        }
      }

      &.fieldset-suggestions--boxed {
        .fieldset-suggestions__list__link {
          border-color: $fieldset-suggestions-secondary-color;
        }
      }
    }
  }

  &-subgroup {
    position: relative;
    margin: $fieldset-subgroup-margin;
    padding: $fieldset-subgroup-padding;

    &__header {
      margin: $fieldset-subgroup-header-margin;
    }

    &__title {
      display: inline-block;
      font-size: $fieldset-subgroup-title-font-size;
      font-weight: $fieldset-subgroup-title-font-weight;
    }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: $fieldset-subgroup-caret-top;
      width: $fieldset-subgroup-caret-width;
      height: $fieldset-subgroup-caret-height;
      background: url('/itarp-shared-assets-addon/images/arrow_down_dark.svg') center center / contain no-repeat;
    }
  }
}

// Form tip
//---------------------------------------------

.form-tip {
  position: absolute;
  top: 0;
  right: 0;
  font-size: $form-tip-font-size;
  font-weight: $form-tip-font-weight;
  text-transform: uppercase;
  color: $form-tip-color;
  background-color: $form-tip-background;
  padding: $form-tip-padding;
  border-radius: $form-tip-border-radius;
  font-family: 'proxima-nova', sans-serif !important;

  &__tooltip {
    color: $form-tip-tooltip-color;
    border: $form-tip-tooltip-border;
    max-width: $form-tip-tooltip-max-width;

    &[x-placement^='left'] .ember-popover-arrow {
      border-left-color: $form-tip-tooltip-border-color;
    }
  }
}

// Form Recommendations
//---------------------------------------------

.form-recommendations {
  position: absolute;
  display: inline-flex;
  align-items: center;
  top: $form-recommendations-top;
  right: 0;
  cursor: pointer;

  &__info {
    display: inline-block;
    width: $form-recommendations-tooltip-width;
    height: $form-recommendations-tooltip-height;
    margin-right: $form-recommendations-tooltip-margin-right;
    background: url('/itarp-shared-assets-addon/images/forms/info.svg') left center no-repeat;
  }

  &,
  &__link {
    display: inline-block;
    font-size: $form-recommendations-font-size;
    font-weight: $form-recommendations-font-weight;
    line-height: $form-recommendations-line-height;
    text-transform: uppercase;
    outline: 0;
    user-select: none;
    color: #4ba4da !important;
    font-family: 'proxima-nova', sans-serif !important;
  }

  &__tooltip {
    color: $form-tip-tooltip-color;
    border: $form-tip-tooltip-border;
    width: $form-tip-tooltip-max-width;

    &[x-placement^='left'] .ember-popover-arrow {
      border-left-color: $form-tip-tooltip-border-color;
    }
  }
}

// Input Group
//---------------------------------------------

.input-group {
  @extend .fieldset;
  display: block !important;

  &__add-on {
    position: relative;
    display: flex;

    &__text {
      display: flex;
      align-items: center;
      font-size: $input-group-add-on-font-size;
      background-color: $input-grou-add-on-background;
      border: $input-grou-add-on-border;
      padding: $input-grou-add-on-padding;
    }

    >*+* {
      border-left-color: transparent !important;
    }

    *:first-child {
      border-radius: $input-group-add-on-border-radius-first;
    }

    *:last-child {
      border-radius: $input-group-add-on-border-radius-last;
    }
  }

  &__elements {
    display: flex;

    &.is-horizontal {
      align-items: center;

      >* {
        &:not(span) {
          flex: 1;
        }
      }
    }

    &.is-vertical {
      flex-direction: column;
    }

    &.for-range {
      .input-group__range__divider {
        display: inline-block;
        position: relative;
        padding: $input-group-range-divider-padding;

        &::before {
          content: 'to';
          font-size: $input-group-range-divider-font-size;
          font-weight: $input-group-range-divider-font-weight;
        }
      }

      &.no-divider {
        >*+* {
          margin-left: $input-group-range-distance;
        }
      }
    }

    &.has-from {
      position: relative;
      padding-left: $input-group-from-padding-left;

      &::before {
        content: 'From';
        position: absolute;
        display: inline-flex;
        align-items: center;
        left: 0;
        top: 0;
        bottom: 0;
        font-size: $input-group-from-font-size;
        font-weight: $input-group-from-font-weight;
      }
    }
  }

  &__range__divider {
    display: none;
  }

  &__fields {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}

// Input Field
//---------------------------------------------

.input-group__field {
  font-family: inherit;
  color: inherit;
  font-size: $input-group-field-font-size;
  line-height: $input-group-field-line-height;
  border: $input-group-field-border;
  border-radius: $input-group-field-border-radius;
  background-color: $input-group-field-background-color;
  padding: $input-group-field-padding;
  width: 100%;
  outline: 0;
  font-family: 'proxima-nova', sans-serif !important;

  &:focus {
    border-color: $input-group-field-focus-border;
    box-shadow: $input-group-field-focus-box-shadow;
  }

  &__button {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0.5rem;
    width: $ember-tinymce-button-width !important;
    height: $ember-tinymce-button-height !important;
    background-color: $ember-tinymce-custom-button-background;
    border: $ember-tinymce-custom-button-border;
    border-radius: 3px;

    &__icon {
      background: url('/itarp-shared-assets-addon/images/icon_editor_placeholder.svg') center center / contain no-repeat !important;
      width: $ember-tinymce-icon-width !important;
      height: $ember-tinymce-icon-height !important;
      border: none;
    }

    &:hover,
    &:focus {
      background-color: $ember-tinymce-custom-button-background-active;
      border-color: $ember-tinymce-custom-button-border-color-active;

      .input-group__field__button__icon {
        background: url('/itarp-shared-assets-addon/images/icon_editor_placeholder_active.svg') center center / contain no-repeat !important;
      }
    }
  }
}

// Input Field Sizes

$input-field-sizes: 'lg'$input-group-field-padding-lg $input-group-field-line-height-lg,
  'md'$input-group-field-padding-md $input-group-field-line-height-md,
  'sm'$input-group-field-padding-sm $input-group-field-line-height-sm,
  'xs'$input-group-field-padding-xs $input-group-field-line-height-xs;

@each $size,
$padding,
$line-height in $input-field-sizes {
  .input-group__field--#{$size} {
    @include input-size($padding, $line-height);
  }
}

// Checkbox Group
//---------------------------------------------

.checkbox-group {
  @include form-button('checkbox');
  @include label-note;
}

// Radio Group
//---------------------------------------------

.radio-group {
  @include form-button('radio');
  @include label-note;
}

// Select Group
//---------------------------------------------

.select-group {
  @extend .fieldset;

  &.for-collaborators {
    .collaborator__initials {
      position: absolute;
      top: 50%;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      font-weight: $multiselect-collaborators-initials-font-weight;
      color: $multiselect-collaborators-initials-color;
      background-color: $multiselect-collaborators-initials-background;
    }

    .ember-power-select-multiple-option {
      padding-left: $multiselect-collaborators-selected-padding-left;

      .collaborator__initials {
        font-size: $multiselect-collaborators-selected-initials-font-size;
        width: $multiselect-collaborators-selected-initials-width;
        height: $multiselect-collaborators-selected-initials-height;
        left: $multiselect-collaborators-selected-initials-left;
        margin-top: $multiselect-collaborators-selected-initials-margin-top;
      }

      .collaborator__name {
        display: none !important;
      }
    }

    .ember-power-select-option {
      position: relative;
      padding-left: $multiselect-collaborators-option-padding-left;

      .collaborator__name,
      .collaborator__email {
        display: block;
        line-height: $multiselect-collaborators-option-line-height;
      }

      .collaborator__name {
        display: block;
        font-weight: $multiselect-collaborators-option-name-font-weight;
      }

      .collaborator__initials {
        font-size: $multiselect-collaborators-option-initials-font-size;
        width: $multiselect-collaborators-option-initials-width;
        height: $multiselect-collaborators-option-initials-height;
        left: $multiselect-collaborators-option-initials-left;
        margin-top: $multiselect-collaborators-option-initials-margin-top;
      }

      &[aria-selected='true'] {
        .collaborator__initials {
          color: $multiselect-collaborators-option-initials-selected-color;
          background: $multiselect-collaborators-option-initials-selected-background;
        }
      }
    }
  }

  &.is-limited {
    flex: 1;
    max-width: 12.5rem;
  }

  .ember-power-select-trigger {
    .ember-power-select-clear-btn {
      right: 3rem;
      font-size: 1.2rem;
    }
  }
}

// Select Group Sizes
//---------------------------------------------

$select-group-sizes: 'md'$select-group-field-padding-md,
  'sm'$select-group-field-padding-sm, 'xs'$select-group-field-padding-xs;

@each $size,
$padding in $select-group-sizes {
  .select-group--#{$size} {
    @include select-size($size, $padding);
  }
}

// Textarea
//---------------------------------------------

.textarea-group {
  @extend .fieldset;

  &__input {
    @extend .input-group__field;
  }
}

// Datepicker
//---------------------------------------------

.datepicker {
  display: flex;

  &-group {
    flex: 1;
  }

  &-group + &-group {
    margin-left: $datepicker-group-distance;
  }

  &-select {
    position: relative;
    padding-right: .875rem;
    margin-right: .75rem;

    select {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
    }

    &::after {
      content: '';
      position: absolute;
      right: 0;
      top: 50%;
      margin-top: -.25rem;
      width: .75rem;
      height: .5rem;
      background: url('/itarp-shared-assets-addon/images/arrow_down_dark.svg') center center no-repeat;
    }
  }

  @media only screen and (max-width: 768px) {
    flex-wrap: wrap;

    &-group {
      flex: 0 0 100%;
      width: 100%
    }

    &-group + &-group {
      margin-left: 0;
      margin-top: 1rem;
    }
  }
}

.datepicker-group {
  @extend .fieldset;

  &__label {
    min-height: $datepicker-group-label-min-height;

    &.is-small {
      min-height: auto;
    }
  }

  &__field {
    @extend .input-group__field;
    position: relative;
    padding-right: $datepicker-group-field-padding-right;
    background-image: url('/itarp-shared-assets-addon/images/forms/caret_down.svg');
    background-position: $datepicker-group-field-background-position;
    background-repeat: no-repeat;

    &:focus {
      border-color: $datepicker-group-field-border-color;
      box-shadow: none;
    }
  }

  &__select {
    display: flex;

    > * {
      &,
      & .ember-power-select-trigger {
        border-radius: 0 !important;
      }

      &:not(:first-child),
      &:not(:first-child) .ember-power-select-trigger {
        border-left-color: transparent !important;
      }

      &:first-child,
      &:first-child .ember-power-select-trigger {
        border-radius: $datepicker-group-select-border-radius-first !important;
      }

      &:last-child,
      &:last-child .ember-power-select-trigger {
        border-radius: $datepicker-group-select-border-radius-last !important;
      }

      &:first-child {
        flex: 1;
      }

      &:not(:first-child) {
        flex: $datepicker-group-select-item-width;
      }
    }
  }

  &__checkbox {
    @include form-button('checkbox');
    margin: $datepicker-group-checkbox-margin;
  }

  .ember-basic-dropdown-trigger--below {
    &.ember-basic-dropdown-trigger--left {
      .datepicker-group__field {
        border-radius: $datepicker-group-field-border-radius-open-below;
      }
    }
  }

  .ember-basic-dropdown-trigger--above {
    &.ember-basic-dropdown-trigger--left {
      .datepicker-group__field {
        border-radius: $datepicker-group-field-border-radius-open-above;
      }
    }
  }

  .ember-basic-dropdown-trigger--above,
  .ember-basic-dropdown-trigger--below {
    .datepicker-group__field {
      background-image: url('/itarp-shared-assets-addon/images/forms/caret_up.svg');
    }
  }

  &.with-icon {
    .datepicker-group__field {
      padding-left: $datepicker-group-field-icon-padding-left;
    }

    .ember-basic-dropdown-trigger {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: $datepicker-group-field-icon-left;
        width: $datepicker-group-field-icon-width;
        height: $datepicker-group-field-icon-height;
        margin: $datepicker-group-field-icon-margin;
        background: url('/itarp-shared-assets-addon/images/forms/calendar.svg');
        z-index: 1;
      }
    }
  }
}

// Form Group
//---------------------------------------------

.form-group {
  @extend .fieldset;

  &__elements {
    display: flex;

    >* {

      &,
      & .ember-power-select-trigger {
        border-radius: 0 !important;
      }

      &:not(:first-child),
      &:not(:first-child) .ember-power-select-trigger {
        border-left-color: transparent !important;
      }

      &:first-child,
      &:first-child .ember-power-select-trigger {
        border-radius: $form-group-border-radius-first  !important;
      }

      &:last-child,
      &:last-child .ember-power-select-trigger {
        border-radius: $form-group-border-radius-last  !important;
      }

      &.no-caret {
        .ember-power-select-trigger {
          padding-right: 1rem !important;
          
          .ember-power-select-status-icon {
            display: none;
          }
        }
      }
    }
  }

  // Date Select

  &--date {
    .form-group__elements {
      >* {
        &:first-child {
          flex: 1;
        }

        &:not(:first-child) {
          flex: $form-group-date-select-width;
        }
      }
    }
  }

  // Select location : Country with flags
  &--location {
    .form-group__elements {
      >* {
        &:first-child {
          flex: 1;
        }

        &:not(:first-child) {
          flex: 2;
        }
      }
    }
  }

  // Dismissable

  &--dismissable {
    .form-group__elements {
      position: relative;

      .input-group__field {
        padding-right: $form-group-dismissable-field-padding-right;
      }
    }

    .form-group__dismiss {
      display: inline-block;
      position: absolute;
      right: $form-group-dismissable-button-position-right;
      top: 50%;
      margin: $form-group-dismissable-button-margin;
      width: $form-group-dismissable-button-width;
      height: $form-group-dismissable-button-height;
      opacity: $form-group-dismissable-button-opacity;
      cursor: pointer;
      outline: 0;
      background: url('/itarp-shared-assets-addon/images/forms/close.svg') center center / contain no-repeat;

      &:hover,
      &:focus {
        opacity: $form-group-dismissable-button-opacity-active;
      }
    }
  }

  // Separated

  &--separated {
    .form-group__elements {
      >*+* {
        margin-left: $form-group-separated-distance;
      }

      >* {

        &,
        & .ember-power-select-trigger {
          border-radius: $input-group-field-border-radius  !important;
        }

        & .ember-power-select-trigger {
          min-width: $form-group-separated-select-min-width;
        }

        &:not(:first-child),
        &:not(:first-child) .ember-power-select-trigger {
          border-left-color: $border-color  !important;
        }
      }

      &:focus-within {
        box-shadow: none;
      }
    }
  }

  // In Rows

  &--rows {
    .form-group__elements {
      flex-direction: column;

      >* {
        width: 100%;
      }

      >*+* {
        margin-left: 0;
        margin-top: $form-group-separated-distance;
      }
    }
  }

  // Search

  &--search {
    .form-group__elements {
      .input-group {
        flex: 1;

        .input-group__field {
          border-radius: .25rem 0 0 .25rem;
        }
      }

      .btn--search {
        min-width: 4.5rem !important;
      }
    }
  }

  // Responsive

  @media only screen and (max-width: 768px) {
    &__elements {
      flex-direction: column;

      > * {
        &,
        & .ember-power-select-trigger {
          border-radius: 0 !important;
        }

        &:not(:first-child),
        &:not(:first-child) .ember-power-select-trigger {
          border-left-color: $border-color !important;
        }

        &:first-child,
        &:first-child .ember-power-select-trigger {
          border-radius: .25rem .25rem 0 0 !important;
        }

        &:last-child,
        &:last-child .ember-power-select-trigger {
          border-radius: 0 0 .25rem .25rem !important;
        }
      }
    }
  }
}

// Form Add
//---------------------------------------------

.form-add {
  @extend .fieldset;

  &__elements {
    display: flex;

    > * {
      &:first-child {
        flex: 1;
      }
    }

    > * + * {
      margin-left: $form-add-elements-distance;
    }
  }
}

// Radios
//---------------------------------------------

.form-options {
  @extend .fieldset;

  .radio-group+.radio-group,
  .checkbox-group+.checkbox-group {
    margin-top: 0.375rem;
  }

  &.in-boxes {
    .form-options__elements {
      display: flex;

      >* {
        flex: 1;
      }

      .radio-group:first-child,
      .checkbox-group:first-child {
        label {
          border-left-color: #d3dbe2;
          border-radius: 0.25rem 0 0 0.25rem;
        }
      }

      .radio-group:last-child,
      .checkbox-group:last-child {
        label {
          border-right-color: #d3dbe2;
          border-radius: 0 0.25rem 0.25rem 0;
        }
      }

      .radio-group+.radio-group,
      .checkbox-group+.checkbox-group {
        margin-top: 0;

        label {
          border-left-color: #d3dbe2;
        }
      }
    }

    .radio-group,
    .checkbox-group {
      &__label {
        display: flex;
        justify-content: center;
        background-color: #fff;
        padding: .75rem .75rem;
        border-width: 1px;
        border-style: solid;
        border-color: #d3dbe2 transparent;
        height: 100%;
        min-height: 3.5rem;
      }

      &__field,
      input[type='radio'] {
        display: none;

        &:checked+.radio-group__label,
        &:checked+label {
          border-color: #4ba4da !important;
          background: rgb(241, 243, 245);
          background: linear-gradient(0deg,
              rgba(241, 243, 245, 1) 0%,
              rgba(255, 255, 255, 1) 100%);
        }
      }
    }
  }

  &.in-line {
    .form-options__elements {
      display: flex;

      .radio-group+.radio-group,
      .checkbox-group+.checkbox-group {
        margin-top: 0;
        margin-left: 2.5rem;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    &.in-boxes {
      .form-options__elements {
        flex-direction: column;

        .radio-group,
        .checkbox-group {
          label {
            border-bottom-color: transparent;
            border-right-color: #d3dbe2;
          }
        }

        .radio-group:first-child,
        .checkbox-group:first-child {
          label {
            border-top-left-radius: .25rem;
            border-top-right-radius: .25rem;
            border-bottom-left-radius: 0;
          }
        }

        .radio-group:last-child,
        .checkbox-group:last-child {
          label {
            border-bottom-color: #d3dbe2;
            border-top-right-radius: 0;
            border-bottom-right-radius: .25rem;
            border-bottom-left-radius: .25rem;
          }
        }

        .radio-group + .radio-group,
        .checkbox-group + .checkbox-group {
          margin-top: 0;

          label {
            border-left-color: #d3dbe2;
          }
        }
      }

      .radio-group,
      .checkbox-group {
        &__label {
          justify-content: flex-start;
        }
      }
    }

    &.in-line {
      .form-options__elements {
        flex-direction: column;

        .radio-group + .radio-group,
        .checkbox-group + .checkbox-group {
          margin-top: .5rem;
          margin-left: 0;
        }
      }
    }
  }
}

// Uploader
//---------------------------------------------

.file-group {
  @extend .fieldset;

  .uploaded-list {
    list-style: none;
    padding: 0;

    &__item {
      display: flex;
      flex-direction: row;
      align-items: space-between;
      border-top: $file-group-uploaded-list-item-border;
      padding: 0.5rem 0;
      font-size: $file-group-uploaded-list-item-font-size;
      font-weight: $file-group-uploaded-list-item-font-weight;

      &:last-child {
        border-bottom: $file-group-uploaded-list-item-border;
      }

      &__file {
        width: 100%;
        align-self: center;
      }

      &__controls {
        list-style: none;
        @include link-group('small');
      }
    }
  }

  &__dropzone {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: $file-group-dropzone-border;
    border-radius: $file-group-dropzone-border-radius;
    background-color: $file-group-dropzone-background;
    min-height: $file-group-dropzone-min-height;
    padding: $file-group-dropzone-padding;

    &__inner {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  &__file {
    margin: $file-group-file-margin;

    &.no-file {
      width: $file-group-file-empty-width;
      height: $file-group-file-empty-height;
      background: url('/itarp-shared-assets-addon/images/forms/upload.svg') center center no-repeat;
    }

    &.no-avatar {
      width: $file-group-file-avatar-empty-width;
      height: $file-group-file-avatar-empty-height;
      background: url('/itarp-shared-assets-addon/images/forms/profile-placeholder.svg') center center no-repeat;
    }

    &__title {
      position: relative;
      font-size: $file-group-file-title-font-size;
      font-weight: $file-group-file-title-font-weight;
      line-height: $file-group-file-title-line-height;
      padding: $file-group-file-title-padding;

      &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        margin: $file-group-file-title-icon-margin;
        width: $file-group-file-title-icon-width;
        height: $file-group-file-title-icon-height;
        background: url('/itarp-shared-assets-addon/images/forms/check_secondary.svg') center center no-repeat;
      }
    }
  }

  &__text {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $file-group-text-font-size;
    color: $file-group-text-color;
  }

  &__link {
    &__label {
      font-size: inherit;
      font-weight: $file-group-link-label-font-weight  !important;
      color: $file-group-link-label-color;
      margin: $file-group-link-label-margin  !important;
      cursor: pointer;
      outline: 0;
      text-decoration: none;

      &:hover,
      &:focus {
        color: $file-group-link-label-color-active;
      }
    }

    &__input {
      display: none;
    }

    label {
      margin-bottom: 0;
    }
  }

  &__uploading {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__label {
      font-size: $file-group-uploading-label-font-size;
      font-weight: $file-group-uploading-label-font-weight;
      margin: $file-group-uploading-label-margin;
      color: $file-group-uploading-label-color;
    }

    &__progress {
      @extend .progress;
    }
  }

  &.for-avatar {
    .file-group__dropzone {
      min-height: $file-group-dropzone-for-avatar-min-height;
    }

    .file-group__file {
      img {
        display: block;
        margin: 0 auto;
        width: $file-group-file-avatar-empty-width;
        height: $file-group-file-avatar-empty-height;
        border-radius: 50%;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    &__dropzone {
      padding: 1rem 1rem;
    }

    &__text {
      flex-wrap: wrap
    }
  }
}

// Form Tabs
//---------------------------------------------

.form-tabs {
  &__wrapper {
    position: relative;
  }

  &__list {
    >ul {
      list-style-type: none;
      padding: 0;
      margin: $form-tabs-list-margin;
      display: flex;

      li {
        border-top: $form-tabs-list-item-border;
        border-bottom: $form-tabs-list-item-border;
        background-color: $form-tabs-list-item-background;
        padding: $form-tabs-list-item-padding;
        font-size: $form-tabs-list-item-font-size;
        font-weight: $form-tabs-list-item-font-weight;
        line-height: $form-tabs-list-item-line-height;
        color: $form-tabs-list-item-color;
        cursor: pointer;

        &:first-child {
          border-left: $form-tabs-list-item-border;
        }

        &:last-child {
          border-right: $form-tabs-list-item-border;
        }

        &[aria-selected='true'] {
          border-bottom-color: transparent;
          background-color: $form-tabs-panel-background;
          color: $form-tabs-list-item-color-active;
        }

        &.form-tabs__list__add {
          position: relative;
          padding: $form-tabs-list-add-padding;

          &::before {
            content: '+';
            display: inline-block;
            font-size: $form-tabs-list-add-font-size;
            color: $form-tabs-list-add-color;
          }
        }
      }

      li+li {
        border-left: $form-tabs-list-item-border-between;
      }
    }
  }

  &__panels {
    border: $form-tabs-panel-border;
    border-radius: $form-tabs-panel-border-radius;
    padding: $form-tabs-panel-padding;
    background-color: $form-tabs-panel-background;
    min-height: $form-tabs-panel-min-height;
  }

  .form-tip {
    top: 2.5rem;
    right: 1rem;
  }

  @media only screen and (max-width: 768px) {
    &__list {
      > ul {
        li {
          font-size: 1rem;
          padding: 1rem 1rem;
        }
      }
    }

    &__panels {
      padding: 0;
    }

    .form-tip {
      top: 1.5rem;
    }
  }
}

// Form Fieldset
//---------------------------------------------

.form-fieldset {
  @extend .fieldset;

  &__panel {
    border: $form-fieldset-panel-border;
    border-radius: $form-fieldset-panel-border-radius;
    padding: $form-fieldset-panel-padding;
    background-color: $form-fieldset-panel-background;

    @media only screen and (max-width: 768px) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__user {
    display: flex;

    &__info {
      padding: 0 1.5rem;
      flex: 1;
    }

    &__avatar {
      img {
        display: block;
        width: 6rem;
        height: 6rem;
        border-radius: 50%;
      }
    }

    @media only screen and (max-width: 768px) {
      flex-direction: column;
      position: relative;

      &__avatar {
        display: flex;
        justify-content: center;
      }

      &__info {
        padding: 1rem 0 0;
      }

      &__controls {
        position: absolute;
        right: 0;
        top: -1rem;
      }
    }
  }
}

// Country Select
//---------------------------------------------

.country-select {
  .ember-power-select-selected-item {
    display: flex;
    align-items: center;

    svg {
      width: $country-select-flag-width;
      margin-right: $country-select-flag-margin;
    }
  }
}

.country-select__menu {
  .ember-power-select-option {
    display: flex;
    align-items: center;

    svg {
      width: $country-select-flag-width;
      margin-right: $country-select-flag-margin;
    }
  }
}

// Modal Group
//---------------------------------------------

.modal-group {
  background-color: $modal-group-background;
  padding: $modal-group-padding;
  border: $modal-group-border;
  border-radius: $modal-group-border-radius;
  box-shadow: $modal-group-box-shadow;

  &__panel {
    &__controls {

      button,
      a {
        display: inline-block;
        min-width: $modal-group-panel-controls-min-width;
      }

      button+button,
      button+a,
      a+button,
      a+a {
        margin-left: $modal-group-panel-controls-distance;
      }
    }
  }
}

// Switch
//---------------------------------------------

.form-switch {
  position: relative;
  display: inline-block;
  width: $form-switch-width;
  height: $form-switch-height;

  input {
    display: none;
  }

  label {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $form-switch-slider-background;
    border-radius: $form-switch-slider-border-radius;
    transition: $form-switch-slider-transition;
    margin: 0;

    &:before {
      position: absolute;
      content: '';
      height: $form-switch-toggle-height;
      width: $form-switch-toggle-width;
      left: $form-switch-toggle-left;
      bottom: $form-switch-toggle-left;
      background: $form-switch-toggle-background;
      border-radius: 50%;
      transition: $form-switch-toggle-transition;
    }
  }

  input:checked+label {
    background-color: $form-switch-slider-background-active;
  }

  input:checked+label:before {
    background: $form-switch-toggle-background url('/itarp-shared-assets-addon/images/forms/check_primary.svg') center center / $form-switch-toggle-background-size no-repeat;
    left: $form-switch-toggle-translate;
  }
}

.form-switch--sm {
  width: $form-switch-width-sm;
  height: $form-switch-height-sm;

  label {
    border-radius: $form-switch-slider-border-radius-sm;

    &:before {
      height: $form-switch-toggle-height-sm;
      width: $form-switch-toggle-width-sm;
      left: $form-switch-toggle-left-sm;
      bottom: $form-switch-toggle-left-sm;
    }
  }

  input:checked+label:before {
    background: $form-switch-toggle-background;
    left: $form-switch-toggle-translate-sm;
  }
}

// Switch Group
//---------------------------------------------

.form-switch-group {
  display: flex;
  align-items: center;

  &__label {
    font-size: $form-switch-group-label-font-size;
    font-weight: 400 !important;
    margin: $form-switch-group-label-margin;
    font-family: 'proxima-nova', sans-serif !important;
  }

  &.is-opposite {
    .form-switch-group__label {
      flex: 1;
      order: 1;
      margin: $form-switch-group-opposite-label-margin;
    }

    .form-switch {
      order: 2;
    }
  }

  &.as-row {
    display: flex;
    width: 100%;
    padding: $form-switch-group-as-row-padding;
    border-bottom: $form-switch-group-as-row-border;

    .form-switch-group__label {
      flex: 1;
      order: 1;
      margin: $form-switch-group-as-row-label-margin;
    }

    .form-switch {
      order: 2;
    }
  }
}

// Slider
//---------------------------------------------

.slider-group {
  @extend .fieldset;

  &__elements {
    display: flex;
    align-items: center;

    >*+* {
      margin-left: $slider-group-elements-distance;
    }
  }

  &__slider {
    flex: 1;

    input[type='range'] {
      -webkit-appearance: none;
      width: 100%;
    }

    input[type='range']:focus {
      outline: none;
    }

    input[type='range']::-webkit-slider-runnable-track {
      width: 100%;
      height: 0.375rem;
      cursor: pointer;
      background: $blue-button-primary;
      border-radius: 0.1875rem;
    }

    input[type='range']::-webkit-slider-thumb {
      height: 1.25rem;
      width: 1.25rem;
      border-radius: 0.625rem;
      background: $blue-button-primary;
      cursor: pointer;
      -webkit-appearance: none;
      margin-top: -0.4375rem;
    }

    input[type='range']:focus::-webkit-slider-runnable-track {
      background: $blue-button-primary;
    }

    input[type='range']::-moz-range-track {
      width: 100%;
      height: 0.375rem;
      cursor: pointer;
      background: $blue-button-primary;
      border-radius: 0.25rem;
    }

    input[type='range']::-moz-range-thumb {
      height: 1.25rem;
      width: 1.25rem;
      border-radius: 0.625rem;
      background: $blue-button-primary;
      cursor: pointer;
    }

    input[type='range']::-ms-track {
      width: 100%;
      height: 0.375rem;
      cursor: pointer;
      background: transparent;
      border-color: transparent;
      border-width: 0.5rem 0;
      color: transparent;
    }

    input[type='range']::-ms-fill-lower {
      background: $blue-button-primary;
      border-radius: 1.875rem;
    }

    input[type='range']::-ms-fill-upper {
      background: $border-color;
      border-radius: 0.1875rem;
    }

    input[type='range']::-ms-thumb {
      height: 1.25rem;
      width: 1.25rem;
      border-radius: 0.625rem;
      background: $blue-button-primary;
      cursor: pointer;
    }

    input[type='range']:focus::-ms-fill-lower {
      background: $blue-button-primary;
    }

    input[type='range']:focus::-ms-fill-upper {
      background: $border-color;
    }
  }

  &__input {
    position: relative;
    z-index: 2;
    width: $slider-group-input-width;
  }
}

// Form Item
//---------------------------------------------

.form-item {
  &__list {
    &__title {
      font-weight: $form-item-list-title-font-weight;
    }

    &__text {
      margin: 0;
    }

    &__title,
    &__text {
      font-size: $form-item-list-font-size;
    }

    &.is-inline {
      display: flex;

      .form-item__list__title {
        margin-right: $form-item-list-title-inline-margin-left;
      }
    }
  }
}

// Colorpicker
//---------------------------------------------

.colorpicker {
  @extend .fieldset;

  &__elements {
    border-radius: $colorpicker-elements-border-radius;
    display: flex;

    input[type='text'] {
      border-left: none;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;

      &:focus {
        border-color: $colorpicker-field-border-color;
        box-shadow: none;
      }
    }

    &:focus-within {
      box-shadow: $colorpicker-elements-focus-box-shadow;

      input[type='text'],
      input[type='color'] {
        border-top-color: $colorpicker-elements-focus-border-color;
        border-bottom-color: $colorpicker-elements-focus-border-color;
      }

      input[type='text'] {
        border-right-color: $colorpicker-elements-focus-border-color;
      }

      input[type='color'] {
        border-left-color: $colorpicker-elements-focus-border-color;
      }
    }
  }

  &__field {
    width: $colorpicker-field-width;
    height: $colorpicker-field-height;
    background-color: $colorpicker-field-background;
    border-width: $colorpicker-field-border-width;
    border-style: solid;
    border-color: $colorpicker-field-border-color;
    border-image: none;
    padding: $colorpicker-field-padding;
    border-right: none;
    border-radius: $colorpicker-field-border-radius;
  }
}

// Form Controls
//----------------------------------------------

.form-controls {
  display: flex;
  align-items: center;

  .app-btn {
    min-width: $form-controls-button-min-width;
  }

  >*+* {
    margin-left: $form-controls-distance;
  }

  @media only screen and (max-width: 768px) {
    flex-wrap: wrap;

    .app-btn {
      width: 100%;
    }

    a {
      text-decoration: none;

      &:last-child {
        margin-left: 0 !important;
      }

      &:first-child {
        margin-left: 0 !important;
      }
    }

    > * + * {
      margin-left: 0;
      margin-top: .75rem;
    }
  }
}

// Errors
//---------------------------------------------
.form-error {
  border: $form-element-error-border;
  box-shadow: $form-element-error-box-shadow;
}

.has-error {

  &.input-group .input-group__field,
  &.accordion,
  &.select-group .ember-basic-dropdown-trigger,
  .tox.tox-tinymce {
    @extend .form-error;
  }

  &.form-options {

    &,
    &.in-line {

      .checkbox-group,
      .radio-group {
        label {
          &::before {
            @extend .form-error;
          }
        }
      }
    }
  }

  &.select-group {
    .ember-basic-dropdown-trigger {
      @extend .form-error;
    }
  }

  &.checkbox-group {
    label {
      &::before {
        @extend .form-error;
      }
    }
  }

  &.datepicker-group {
    .ember-power-datepicker-trigger {
      border: none !important;
      box-shadow: none !important;

      .datepicker-group__field {
        @extend .form-error;
      }
    }

    .ember-basic-dropdown-trigger {
      @extend .form-error;
    }
  }

  &.form-group--location {
    .form-group__elements {
      border-radius: .25rem;
      box-shadow: $form-element-error-box-shadow;

      .ember-basic-dropdown-trigger {
        border-top: $form-element-error-border;
        border-bottom: $form-element-error-border;
        border-left: $form-element-error-border; 
      }

      .input-group__field {
        border-top: $form-element-error-border;
        border-bottom: $form-element-error-border;
        border-right: $form-element-error-border;

      }
    }
  }
}

// Form Collaborators
//---------------------------------------------

.form-collaborators {
  @extend .fieldset;

  &__list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;

    &__item {

      >*,
      >.ember-basic-dropdown-trigger>* {
        position: relative;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: $form-collaborators-list-item-width;
        height: $form-collaborators-list-item-height;
        border-radius: 50%;
      }

      &:nth-child(2n) {
        .form-collaborators__user {
          background-color: $form-collaborators-user-background-even;
        }
      }

      &:nth-child(2n + 1) {
        .form-collaborators__user {
          background-color: $form-collaborators-user-background-odd;
        }
      }
    }

    >li+li {
      margin-left: $form-collaborators-list-distance;
    }
  }

  &__new {
    background-image: url('/itarp-shared-assets-addon/images/buttons/add.svg');
    background-size: $form-collaborators-new-background-size;
    background-color: $form-collaborators-new-background;
    background-repeat: no-repeat;
    background-position: center center;
    outline: 0;
    cursor: pointer;
  }

  &__user {
    font-size: $form-collaborators-user-font-size;
    font-weight: $form-collaborators-user-font-weight;
    color: $form-collaborators-user-color;
    user-select: none;
    // outline: 0;
    // cursor: pointer;

    &__remove {
      position: absolute;
      top: 0;
      right: 0;
      display: inline-block;
      width: $form-collaborators-user-remove-width;
      height: $form-collaborators-user-remove-height;
      background: url('/itarp-shared-assets-addon/images/icon_remove_circle.svg') center center / contain no-repeat;
      outline: 0;
      cursor: pointer;
      z-index: 1;
    }
  }

  &__users {
    background-image: url('/itarp-shared-assets-addon/images/buttons/person.svg');
    background-color: $form-collaborators-users-background;
    background-size: $form-collaborators-users-background-size;
    background-position: $form-collaborators-users-background-position;
    background-repeat: no-repeat;
    text-decoration: none;
    outline: 0;
    cursor: pointer;

    &__count {
      position: absolute;
      right: $form-collaborators-users-count-right;
      font-size: $form-collaborators-users-count-font-size;
      font-weight: $form-collaborators-users-count-font-weight;
      color: $form-collaborators-users-count-color;
      user-select: none;
    }

    &__menu {
      width: $form-collaborators-users-menu-width;

      &__item {
        position: relative;
        padding: $form-collaborators-users-menu-item-padding;
        font-size: $form-collaborators-users-menu-item-font-size;
        font-weight: $form-collaborators-users-menu-item-font-weight;

        a {
          position: absolute;
          top: 50%;
          margin-top: $form-collaborators-user-remove-margin-top;
          right: $form-collaborators-user-remove-right;
          width: $form-collaborators-user-remove-width;
          height: $form-collaborators-user-remove-height;
          background: url('/itarp-shared-assets-addon/images/icon_remove_circle.svg') center center / contain no-repeat;
          outline: 0;
          cursor: pointer;
          z-index: 1;
        }
      }
    }
  }
}

// Filters
//---------------------------------------------

.filter-inline {
  width: 100%;
  display: flex;
  max-width: $filter-inline-max-width;
  box-shadow: $filter-inline-box-shadow;

  >* {

    &,
    & .ember-power-select-trigger {
      border-radius: 0 !important;
    }

    & .ember-power-select-trigger {
      min-width: $form-group-select-trigger-min-width;
    }

    &:not(:first-child),
    &:not(:first-child) .ember-power-select-trigger {
      border-left-color: transparent !important;
    }

    &:first-child,
    &:first-child .ember-power-select-trigger {
      flex: 1;
      border-radius: $form-group-border-radius-first !important;

      .ember-power-select-trigger-multiple-input {
        height: 3.125rem;
      }
    }

    &:last-child,
    &:last-child .ember-power-select-trigger {
      border-radius: $form-group-border-radius-last !important;
    }
  }

  .form-inline--location {
    min-width: 35rem;

    .form-group__elements {
      height: 100%;

      .input-group__field {
        border-radius: 0 !important
      }

      .ember-basic-dropdown-trigger {
        height: 100%;
        border-radius: 0 !important;
        padding-top: 1.125rem !important;
        padding-bottom: 1.125rem !important;
      }
    }
  }

  .app-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    height: 100%;
    min-width: $filter-inline-button-min-width;
    height: 3.75rem;
  }


  &.is-longer {
    max-width: $filter-inline-max-width;

    .app-btn {
      min-width: unset;
    }
  }

  .for-keywords {
    flex: 1;
  }

  .for-location {
    max-width: 20rem;
  }

  .for-range {
    flex: 0 0 10rem;

    .ember-power-select-trigger {
      min-width: unset;
      width: 100%;
    }
  }
}

.filter-options {
  display: flex;
  flex-wrap: wrap;

  >*+* {
    margin-left: $filter-options-distance;
  }

  .select-group {
    min-width: $filter-options-select-min-width;
  }

  .dropdown {
    min-width: $filter-options-dropdown-min-width;
  }
}

// Form Links
//---------------------------------------------

.form-links {
  position: relative;


  &__label {
    position: relative;
    display: block;
    margin-bottom: $form-links-label-margin;
    font-size: $form-links-label-font-size;
    font-weight: $form-links-label-font-weight;
    padding: $form-links-label-padding;
    min-height: $form-links-label-min-height;
  }

  &__control {
    position: absolute;
    top: 0;
    right: 0;
  }

  .form-group {
    margin-top: $form-link-items-distance;

    .ember-power-select-trigger {
      min-width: $form-group-select-trigger-min-width;
    }
  }
}

// Asset Uploader
//---------------------------------------------

.form-asset-uploader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px dashed #d1dbe3 !important;
  border-radius: 0.25rem;
  background-color: #fff;
  margin-bottom: 1rem;
  padding: 1.5rem 1.5rem;
  min-height: 8.125rem;

  .asset-icon {
    height: 7rem;
    width: 7rem;
    margin: 0 0 1rem;

    &.asset-icon--image {
      background: url('/itarp-business-application/assets/images/forms/profile-placeholder.svg') center center no-repeat;
    }

    &.asset-icon--cloud {
      background: url("/itarp-business-application/assets/images/asset_icon_cloud.svg") center center no-repeat;
    }

    &.asset-icon--video {
      height: 3.125rem;
      width: 3.125rem;
      background: url("/itarp-business-application/assets/images/asset_icon_video.svg") center center no-repeat;
    }
  }

  .asset-label {
    color: #a1a5ad;
    text-align: center;
    margin: 0;

    a {
      color: #61a7db;

      &:hover,
      &:focus {
        color: #5695b5;
      }
    }
  }

  &.uploader-16by9 {
    width: 100%;
    padding-bottom: 56.25%;
  }

  .asset-upload-progress {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .progress-label {
      margin: 0 0 0.5rem;
    }

    .progress {
      width: 15rem;
      height: 0.75rem;
      background: #d1dbe3;
      border-radius: 0.375rem 0.375rem;

      .progress-bar {
        border-radius: 0.375rem 0.375rem;

        &.bg-primary {
          background-color: #61a7db !important;
        }
      }
    }
  }

  .asset-upload-finished {
    .finished-label {
      margin: 0;
    }
  }

  .cropper-wrapper {
    max-width: 37.5rem !important;
    max-height: 30rem !important;
    margin-bottom: 1rem;

    .image-cropper,
    .cropper-container {
      max-width: 37.5rem !important;
      max-height: 30rem !important;
    }

    img {
      width: auto !important;
      height: auto !important;
      max-width: 37.5rem !important;
      max-height: 30rem !important;
    }
  }

  .asset-uploaded {
    width: 18.75rem;
    height: 18.75rem;
    margin-bottom: 1rem;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }
}

.preview-profile-image {
  width: 150px !important;
  height: 150px !important;
  overflow: hidden;
  border-radius: 50%;
  margin: 0 auto;
}

.profile-image {
  margin-bottom: 1rem;
}

.img-circle {
  border-radius: 50%;
}

.preview-box {
  width: 100%;
  border-top: 1px solid #d1dbe3;
  padding-top: 1rem;
  text-align: center;

  span {
    margin-bottom: 1rem;
    display: inline-block;
  }
}

.crop-btn {
  background-color: #61a7db;
  color: white;
  border: none;
  padding: 0.375rem 0.75rem;
  margin-top: 1rem;
}

// Input Placeholder
//---------------------------------------------

::-webkit-input-placeholder {
  color: $placeholder-color;
}

:-ms-input-placeholder {
  color: $placeholder-color;
}

::placeholder {
  color: $placeholder-color;
}

// Component Divider
//----------------------------------------------
.divider {
  border: $divider-border;
  margin: $divider-margin;
}

// Email Options
//----------------------------------------------

.email-options {
  display: flex;
  justify-content: flex-end;

  &__link {
    display: inline-block;
    text-decoration: none;
    font-size: .875rem;
  }

  & > * + * {
    margin-left: 1rem;
  }
}

// Form Editable
//---------------------------------------------

.form-editable {
  @extend .fieldset;

  &__value {
    display: flex;
    align-items: center;
    margin: .25rem 0;

    &__label {
      font-weight: 400;
    }

    &__controls {
      @include link-group('small');
      margin-left: 1rem;
    }

    &.align--top {
      align-items: flex-start;

      .form-editable__value__table {
        padding-top: .125rem;
      }
    }
  }

  &__form {
    padding-top: 1rem;
  }

  .fieldset-note {
    display: block;
  }
}

// Payment Options
//---------------------------------------------

.payment-options {
  display: flex;

  &__item {
    flex: 1;

    &__label {
      position: relative;
      border: 1px solid $border-color;
      border-radius: .25rem;
      padding: 2.5rem 1rem 1rem;
      height: 100%;
      width: 100%;

      &::before {
        content: '';
        display: inline-block;
        position: absolute;
        top: 1rem;
        right: 1rem;
        width: $form-button-width;
        height: $form-button-height;
        border-width: 2px;
        border-radius: 50%;
        border-style: solid;
        border-color: $form-button-border-color;
        background: $form-button-background;
      }
    }

    input[type='radio'] {
      display: none;

      &:checked + label {
        border-color: #4ba4da !important;
        background: rgb(241, 243, 245);
        background: linear-gradient(0deg,
            rgba(241, 243, 245, 1) 0%,
            rgba(255, 255, 255, 1) 100%);

        &::before {
          border-color: $form-button-border-color-active;
          background: url('/itarp-shared-assets-addon/images/forms/dot.svg')
            center center no-repeat;
        }
      }
    }

    &__title,
    &__note {
      display: block;
    }

    &__title {
      font-size: 1.125rem;
      font-weight: 600;
      margin: .5rem 0 .25rem;
    }

    &__note {
      font-size: .875rem;
      font-weight: 400;
      color: $fieldset-note-color;
    }
  }

  & > * + * {
    margin-left: 1rem;
  }
}