// Cards
//---------------------------------------------

// Card Model

.card-model {
  display: flex;
  flex-direction: column;
  border-radius: .25rem;
  background-color: #FFF;
  box-shadow: 0 1px 3px rgba(#000, .15);
  width: 100%;
  height: 100%;

  &__header {
    position: relative;
    padding: .75rem 6rem .75rem 1.25rem;
    border-bottom: 1px solid #D1DBE3;
    border-radius: .25rem .25rem 0 0;
    min-width: 0;

    &__title {
      margin: 0;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__controls {
      @extend .link-group;
      position: absolute;
      top: 50%;
      right: 1.25rem;
      margin: -1rem 0 0 0;
    }
  }

  &__body {
    padding: 1.25rem 1.25rem;
    flex: 1;
    border-radius: 0 0 .25rem .25rem;
  }

  $model-status: 'active' #DBE8F2 #4A9DDD,
                 'inactive' #ECEDEF #A1A6B8;

  @each $status, $color, $border in $model-status {
    &.is-#{$status} {
      border: 1px solid #{$border};

      .card-model__header {
        background-color: #{$color};
      }
    }
  }   
  
  &.is-system {
    border: 1px solid #50AE81;

    .card-model__header {
      background-color: lighten(#8CCBA2, 25%);
    }
  }
}
