.pa-banner {
  height: 30rem;
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -.5rem;

  &__inner {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 5rem 4rem;
  }

  &__content {
    max-width: 40rem;

    &__title {
      font-size: 2.5rem;
      font-weight: 700;
      letter-spacing: -0.0625rem;
      line-height: 1.15;
      margin-bottom: 1rem;
    }

    &__description {
      font-size: 1.3125rem;
      
    }
  }
}