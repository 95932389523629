// Card Wrapper
//---------------------------------------------

.card-wrapper {
  display: flex;
  padding: $card-wrapper-padding;
}

// Card Holder
//---------------------------------------------

.card-holder {
  display: flex;
  flex-direction: column;
  height: 100%;

  >* {
    width: 100%;
    height: 100%;
    flex: 1;
  }
}

// Card
//---------------------------------------------

.card {
  @include card;
  background-color: $card-background;

  &__header {
    position: relative;
    display: flex;
    align-items: center;
    padding: $card-header-padding;

    &__title {
      position: relative;
      margin: 0;
      font-size: $card-header-title-font-size;
      font-family: 'proxima-nova', sans-serif !important;

      &.card__header__title--sm {
        font-size: $card-header-title-font-size-sm;
      }

      &.with-icon {
        padding: $card-header-title-icon-padding;

        &::before {
          content: '';
          position: absolute;
          left: 0;
          width: $card-header-title-icon-width;
          height: $card-header-title-icon-height;
        }

        &.with-icon--lg {
          padding: $card-header-title-icon-padding-large;

          &::before {
            width: $card-header-title-icon-width-large;
            height: $card-header-title-icon-height-large;
          }
        }
      }

      $card-icons: 'employment''no''', 'equity''no''', 'mentorship''no''',
        'web''no''', 'work''yes''', 'expertise''yes''',
        'location''no''contain', 'salary''no''contain',
        'time''no''contain', 'join''no''contain';

      @each $icon,
      $option,
      $size in $card-icons {
        &.for-#{$icon} {
          &::before {
            background-image: url('/itarp-shared-assets-addon/images/cards/icon_#{$icon}.svg');
            background-repeat: no-repeat;
            background-position: center center;

            @if $option  !='no' {
              border-radius: 50%;
              background-color: $card-header-title-icon-background;
            }

            @if $size =='contain' {
              background-size: contain;
            }
          }
        }
      }
    }

    &__controls {
      @include link-group('small');
    }

    &.with-controls {
      padding: $card-header-controls-padding;
      justify-content: space-between;
    }
  }

  &__body {
    position: relative;
    padding: $card-body-padding;
  }

  &__table {
    padding: $card-table-padding;
  }

  &__footer {
    display: flex;
    padding: $card-footer-padding;

    &.is-bordered {
      border-top: $card-footer-border;
    }

    &.is-aligned-right {
      justify-content: flex-end;
    }

    &.is-split {
      justify-content: space-between;
      align-items: center;
    }

    &__controls {
      list-style-type: none;
      margin: 0;
      padding: 0;
      display: inline-flex;

      &__link {
        display: block;
        width: $card-footer-control-link-width;
        height: $card-footer-control-link-height;

        &.for-move-up,
        &.for-move-down {
          background: url('/itarp-shared-assets-addon/images/icon_arrow_left.svg') center center no-repeat;
        }

        &.for-move-up {
          transform: rotate(90deg);
        }

        &.for-move-down {
          transform: rotate(270deg);
        }

        &.for-remove {
          background: url('/itarp-shared-assets-addon/images/icon_trash.svg') center center no-repeat;
        }
      }

      &__item+&__item {
        margin-left: $card-footer-control-link-distance;
      }
    }
  }

  &__schedule {
    &__inner {
      display: flex;
      border-top: $card-border;
      padding: $card-schedule-padding;
    }

    &__calendar {
      width: $card-schedule-calendar-width;
    }

    &__content {
      flex: 1;
      padding-left: $card-schedule-content-padding-left;
    }

    &__list {
      list-style-type: none;
      padding: 0;
      margin: $card-schedule-list-margin;

      &__title,
      &__title a,
      &__text {
        font-size: $card-schedule-list-font-size;
      }

      &__title {
        margin: 0;

        &,
        & a {
          color: $card-schedule-list-title-color;
        }
      }

      &__text {
        &.is-bolded {
          font-weight: $card-schedule-list-text-font-weight;
           margin: 0;
        }

        span+span {
          &::before {
            content: '|';
            display: inline-block;
            margin: $card-schedule-list-text-divider-margin;
          }
        }
      }

      &__item+&__item {
        margin-top: $card-schedule-list-distance;
      }
    }
  }

  &.no-header {
    .card__body {
      padding: $card-body-headerless-padding;
    }
  }

  &.no-shadow {
    box-shadow: none;
  }

  &.is-bordered {
    border: $card-border;
  }
}

// Card Types
//---------------------------------------------

.card--light {
  border: $card-light-border;
  background-color: $card-light-background;
}

.card--note {
  border: $card-note-border;
  border-radius: $card-note-border-radius;
}

// Card TalentCloud
//---------------------------------------------

.card-tc {
  @include card;

  &__header {
    padding: $card-talencloud-header-padding;

    &__title,
    &__title a {
      font-size: $card-talencloud-header-title-font-size;
      font-weight: $card-talencloud-header-title-font-weight;
      letter-spacing: $card-talencloud-header-title-letter-spacing;
      color: $card-talencloud-header-title-color;
      margin: 0;
    }

    &__title {
      a {
        cursor: pointer;
        text-decoration: none;
        outline: 0;

        &:hover,
        &:focus {
          color: $card-talencloud-header-title-color-active;
        }
      }
    }
  }

  &__body {
    font-size: $card-talencloud-body-title-font-size;
    padding: $card-talencloud-body-padding;
  }

  &__footer {
    padding: $card-talencloud-footer-padding;
  }
}

// Card TalentCloud
//---------------------------------------------

.card-option {
  @include card;

  padding: $card-option-padding;
  background: $card-option-background;
  min-height: $card-option-min-height;
  border: $card-option-border;
  border-radius: $card-option-border-radius;
  text-decoration: none;
  position: relative;

  &__switch {
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 1;
  }

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__icon {
      width: 100%;
      height: $card-option-header-icon-height;
      margin: $card-option-header-icon-margin;

      $option-icons: 'experts',
      'jobs-free',
      'jobs-premium',
      'public-pages',
      'recruiters',
      'referals',
      'social';

      @each $icon in $option-icons {
        &.for-#{$icon} {
          background: url('/itarp-shared-assets-addon/images/cards/options/#{$icon}.svg') center center / contain no-repeat;
        }
      }
    }

    &__title {
      font-size: $card-option-header-title-font-size;
      font-weight: $card-option-header-title-font-weight;
      text-align: center;
      margin: 0;
      color: $card-option-header-title-color;
    }
  }

  &__body {
    padding: $card-option-body-padding;
  }

  &__stats {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;

    &__item {
      font-size: $card-option-stats-item-font-size;
      color: $card-option-stats-item-color;
    }
  }

  &__socials {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;

    &__link {
      display: inline-block;
      width: $card-option-socials-link-width;
      height: $card-option-socials-link-height;
      cursor: pointer;
      outline: 0;

      $solcial-links: 'facebook',
      'instagram',
      'twitter',
      'linkedin';

      @each $link in $solcial-links {
        &.for-#{$link} {
          background: url('/itarp-shared-assets-addon/images/cards/social/lg_#{$link}.svg') center center / contain no-repeat;
        }
      }
    }
  }
}

// Card Blocks
//---------------------------------------------

// Profile

.card-block--profile {
  $badges-items: 'linkedin''link''linkedin' 1.5rem 1.5rem,
  'email''link''email' 1.5rem 1.5rem,
  'resume''link''resume' 1.375rem 1.625rem,
  'experfy''info''experfy' 1.375rem 1.625rem,
  'jobs''info''jobs' 2rem 1.5rem,
  'deloitte''info''deloitte_jobs' 1.5rem 1.5rem;

  $badges-items-sm: 'linkedin''link''linkedin' 1.125rem 1.125rem,
  'email''link''email' 1.125rem 1.125rem,
  'resume''link''resume' 1.25rem 1.375rem,
  'experfy''info''experfy' 1.25rem 1.375rem,
  'jobs''info''jobs' 1.625rem 1.125rem,
  'deloitte''info''deloitte_jobs' 1.125rem 1.125rem;

  position: relative;
  padding: $card-block-profile-padding;
  min-height: $card-block-profile-min-height;
  min-width: $card-block-profile-min-width;

  &__avatar {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    width: $card-block-profile-avatar-width;
    height: $card-block-profile-avatar-height;
    background-color: $card-block-profile-avatar-background;

    img {
      display: inline-block;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      margin: 0;
    }

    &.not-present {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: $card-block-profile-avatar-font-size;
      font-weight: $card-block-profile-avatar-font-weight;
      color: $card-block-profile-avatar-color;
    }

    &.is-private {
      background: url('/itarp-shared-assets-addon/images/cards/private_profile.svg') center center / contain no-repeat;

      &::after {
        content: '';

      }
    }
  }

  &__name {
    &,
    & a,
    &__link {
      margin: 0;
      font-size: $card-block-profile-name-font-size;
      font-weight: $card-block-profile-name-font-weight !important;
      color: $card-block-profile-name-color;
      letter-spacing: -.2px;
      cursor: pointer;
      outline: 0;
      text-decoration: none;
      line-height: 1.235 !important;
      font-family: 'proxima-nova', sans-serif !important;

      &:hover,
      &:focus {
        color: $card-block-profile-name-color-active;
      }
    }

    &.has-date {
      position: relative;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: $card-block-profile-name-date-padding-right;

      .card-block--profile__name__date {
        position: absolute;
        right: 0;
        font-size: $card-block-profile-name-date-font-size;
        font-weight: $card-block-profile-name-date-font-weight;
        color: $card-block-profile-name-date-color;
      }
    }
  }

  &__title {
    font-size: $card-block-profile-title-font-size;
    font-weight: $card-block-profile-title-font-weight;
    margin: $card-block-profile-title-margin;
    font-family: 'proxima-nova', sans-serif !important;

    &__duration {
      color: $card-block-profile-title-duration-color;
    }

    span {
      display: inline-block;

      &::after {
        content: ',';
      }

      &:last-child {
        &::after {
          display: none;
        }
      }
    }
  }

  &__location {
    position: relative;
    font-size: $card-block-profile-location-font-size;
    margin: $card-block-profile-location-margin;
    padding: $card-block-profile-location-padding;
    color: $card-block-profile-location-color;
    font-family: 'proxima-nova', sans-serif !important;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: $card-block-profile-location-pin-position-top;
      width: $card-block-profile-location-pin-width;
      height: $card-block-profile-location-pin-height;
      background: url('/itarp-shared-assets-addon/images/cards/location_pin.svg') center center / contain no-repeat;
    }
  }

  &__source {
    font-size: $card-block-profile-source-font-size;
    margin: $card-block-profile-source-margin;
    color: $card-block-profile-source-color;
    font-family: 'proxima-nova', sans-serif !important;
  }

  &__project {
    position: relative;
    font-size: $card-block-profile-project-font-size;
    font-weight: $card-block-profile-project-font-weight;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #90959f;
    padding-left: $card-block-profile-project-padding-left;

    $project-types: 'job' 'message_op', 'tc' 'message_tc';

    @each $type, $icon in $project-types {
      &.is-#{$type} {
        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          margin-top: $card-block-profile-project-icon-margin;
          width: $card-block-profile-project-icon-width;
          height: $card-block-profile-project-icon-height;
          background: url('/itarp-shared-assets-addon/images/#{$icon}.svg') center center / contain no-repeat;
        }
      }
    }
  }

  &__message {
    position: relative;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0;
    min-height: 1.375rem;
    font-size: $card-block-profile-message-font-size;
    color: $card-block-profile-message-color;
    padding: $card-block-profile-message-padding;

    &__count {
      position: absolute;
      right: 0;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding: .125rem;
      min-width: $card-block-profile-message-count-width;
      height: $card-block-profile-message-count-height;
      border-radius: $card-block-profile-message-count-border-radius;
      font-size: $card-block-profile-message-count-font-size;
      font-weight: $card-block-profile-message-count-font-weight;
      color: $card-block-profile-message-count-color;
      background-color: $card-block-profile-message-count-background;
    }
  }

  &__badges {
    margin: $card-block-profile-badges-margin;
    padding: 0;
    list-style-type: none;
    display: inline-flex;

    &__item {
      position: relative;
    }

    &__link {
      display: block;
      cursor: pointer;
      outline: none;
    }

    &__info {
      display: block;
    }

    @each $name, $type, $icon, $width, $height in $badges-items {
      .card-block--profile__badge__#{$name} {
        @include profile-badges($type, $icon, $width, $height);
      }
    }

    &__tooltip {
      color: $card-block-profile-badges-tooltip-color;
      border: $card-block-profile-badges-tooltip-border;
      background-color: $card-block-profile-badges-tooltip-background;
      z-index: 11;

      &[x-placement^='bottom'] .ember-tooltip-arrow{
        border-bottom-color: $card-block-profile-badges-tooltip-border-color;
      }

      &__text {
        display: block;
        font-size: $card-block-profile-badges-tooltip-font-size;
        font-weight: $card-block-profile-badges-tooltip-font-weight;

        &--primary {
          color: $card-block-profile-badges-tooltip-text-color-primary;
        }
      }
    }

    &__item + &__item {
      margin-left: $card-block-profile-badges-item-distance;
    }
  }

  &__checkbox {
    @include form-button('checkbox');
    position: absolute;
  }

  &__more {
    position: absolute;
    justify-content: center;
    top: $card-block-profile-more-top;
    left: 0;
    display: none;
    width: $card-block-profile-more-width;

    &__link {
      position: relative;
      display: inline-block;
      cursor: pointer;
      user-select: none;
      font-size: $card-block-profile-more-link-font-size;
      font-weight: $card-block-profile-more-link-font-weight;
      text-transform: uppercase;
      text-align: center;
      color: $card-block-profile-more-link-color;
      background-color: $card-block-profile-more-link-background;
      border: $card-block-profile-more-link-border;
      border-radius: $card-block-profile-more-link-border-radius;
      padding: $card-block-profile-more-link-padding;

      &::after {
        content: '';
        position: absolute;
        right: $card-block-profile-more-link-icon-right;
        top: 50%;
        margin-top: $card-block-profile-more-link-icon-margin;
        width: $card-block-profile-more-link-icon-width;
        height: $card-block-profile-more-link-icon-height;
        background: url('/itarp-shared-assets-addon/images/buttons/add_primary.svg') center center / contain no-repeat;
      }

      &:hover,
      &:focus,
      &.is-active {
        color: $card-block-profile-more-link-color-active;
        background-color: $card-block-profile-more-link-background-active;

        &::after {
          background: url('/itarp-shared-assets-addon/images/buttons/add.svg') center center / contain no-repeat;
        }
      }

      &.is-clicked {
        color: $card-block-profile-more-link-color-active;
        background-color: $card-block-profile-more-link-background-active;

        &::after {
          background: url('/itarp-shared-assets-addon/images/buttons/icon_remove.svg') center center / contain no-repeat;
        }
      }
    }
  }

  &.is-selectable {
    padding: $card-block-profile-selectable-padding;

    .card-block--profile__avatar {
      left: $card-block-profile-selectable-avatar-position-left;
    }

    .card-block--profile__checkbox {
      left: 0;
      top: $card-block-profile-selectable-checkbox-position-top;
    }

    &.is-private {
      .card-block--profile__private__circle {
        left: $card-block-profile-selectable-private-circle-left;
      }
    }

    &.with-more {
      .card-block--profile__more {
        left: $card-block-profile-selectable-more-left;
      }
    }
  }

  &.is-private {
    .card-block--profile__private__circle {
      display: inline-block;
      position: absolute;
      top: $card-block-profile-private-circle-top;
      left: $card-block-profile-private-circle-left;
      width: $card-block-profile-private-circle-width;
      height: $card-block-profile-private-circle-width;
      border: $card-block-profile-private-circle-border;
      border-radius: 50%;
      border-left-color: transparent;
      background: transparent;
      z-index: 1;
    }

    .card-block--profile__name__private {
      display: inline-block;
      position: relative;
      padding: $card-block-profile-name-private-padding;
      color: $card-block-profile-name-private-color;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        height: $card-block-profile-name-private-icon-height;
        width: $card-block-profile-name-private-icon-width;
        background: url('/itarp-shared-assets-addon/images/cards/icon_private.svg') center center no-repeat;
      }
    }
  }

  &.with-more {
    min-height: $card-block-profile-more-min-height;

    .card-block--profile__more {
      display: flex;
    }
  }

  &.is-vertical {
    padding: $card-block-profile-vertical-padding;
    min-width: 0;

    .card-block--profile__controls {
      position: absolute;
      top: 0;
      right: 0;

      &__trigger {
        display: inline-block;
        width: $card-block-profile-vertical-control-width;
        height: $card-block-profile-vertical-control-height;
        cursor: pointer;
        outline: 0;
        background: url('/itarp-shared-assets-addon/images/buttons/more_light.svg') center center / contain no-repeat;
      }
    }

    .card-block--profile__avatar {
      left: 50%;
      margin-left: $card-block-profile-vertical-avatar-margin-left;
    }

    .card-block--profile__match-score {
      @include match-score($card-block-profile-vertical-match-score-size);
      display: flex;
      justify-content: center;
      margin: $card-block-profile-vertical-match-score-margin;
    }

    .card-block--profile__name,
    .card-block--profile__name__link {
      font-size: $card-block-profile-vertical-name-font-size;
    }

    .card-block--profile__title,
    .card-block--profile__location,
    .card-block--profile__source {
      font-size: $card-block-profile-vertical-title-font-size;
    }

    .card-block--profile__location,
    .card-block--profile__source {
      margin: $card-block-profile-vertical-location-margin;
    }

    .card-block--profile__badges {
      margin: $card-block-profile-vertical-badges-margin;

      @each $name, $type, $icon, $width, $height in $badges-items-sm {
        .card-block--profile__badge__#{$name} {
          @include profile-badges($type, $icon, $width, $height);
        }
      }

      .card-block--profile__badges__item {
        a,
        span {
          background-size: contain !important;
        }
      }
    }

    &.is-selectable {
      .card-block--profile__checkbox {
        top: 0;
      }
    }
  }

  &.card-block--profile--lg {
    padding: $card-block-profile-lg-padding;
    min-height: $card-block-profile-lg-min-height;

    .card-block--profile__avatar {
      width: $card-block-profile-avatar-lg-width;
      height: $card-block-profile-avatar-lg-height;

      &.not-present {
        font-size: $card-block-profile-avatar-lg-font-size;
      }
    }

    .card-block--profile__name {
      &,
      &__link {
        font-size: $card-block-profile-name-lg-font-size;
        font-weight: $card-block-profile-name-lg-font-weight;
      }
    }

    .card-block--profile__title {
      font-size: $card-block-profile-title-lg-font-size;
    }

    .card-block--profile__location {
      font-size: $card-block-profile-location-lg-font-size;
    }
  }

  &.card-block--profile--sm {
    padding: $card-block-profile-sm-padding;
    min-height: $card-block-profile-sm-min-height;

    .card-block--profile__avatar {
      width: $card-block-profile-avatar-sm-width;
      height: $card-block-profile-avatar-sm-height;

      &.not-present {
        font-size: $card-block-profile-avatar-sm-font-size;
      }
    }

    .card-block--profile__name {
      &,
      &__link {
        font-size: $card-block-profile-name-sm-font-size;
        font-weight: $card-block-profile-name-sm-font-weight;
      }
    }

    .card-block--profile__title {
      font-size: $card-block-profile-title-sm-font-size;
    }

    .card-block--profile__location {
      font-size: $card-block-profile-location-sm-font-size;
    }

    .card-block--profile__badges {
      margin: $card-block-profile-badges-sm-margin;
    }
  }

  &.card-block--profile--light {
    .card-block--profile__name,
    .card-block--profile__name__link,
    .card-block--profile__title,
    .card-block--profile__title__duration,
    .card-block--profile__location {
      color: $card-profile-light-color;
    }

    .card-block--profile__badges {
      @each $name, $type, $icon, $width, $height in $badges-items {
        .card-block--profile__badge__#{$name} {
          @include profile-badges($type, $icon, $width, $height, 'light');
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    &.card-block--profile--lg {
      padding: 11.5rem 0 0;

      .card-block--profile__avatar {
        left: 50%;
        margin-left: -5.125rem;
      }

      .card-block--profile__name {
        &,
        &__link {
          font-size: 1.5rem;
        }
      }

      .card-block--profile__title {
        font-size: 1.125rem;
      }
    }
  }
}

// Match Score

.card-block--match-score {
  @include match-score;
}

.card-block--info {
  &__list {
    margin: 0;
    padding: 0;
    list-style-type: none;

    &__item {
      font-size: $card-block-info-item-font-size;

      &.for-tracking,
      &.for-availability {
        &::after {
          position: relative;
          display: inline-block;
          font-size: $card-block-info-item-label-font-size;
          text-transform: uppercase;
          color: $card-block-info-item-label-color;
        }
      }

      &.for-tracking {
        $tracking-statuses: 'ontrack''On Track''yes.png'
        $card-block-info-tracking-padding-ontrack,
        'overdue''Overdue''warning.svg'
        $card-block-info-tracking-padding-overdue;

        @each $status,
        $label,
        $icon,
        $padding in $tracking-statuses {
          &.is-#{$status} {
            &::after {
              content: '#{$label}';
              padding: $padding;
              background: url('/itarp-shared-assets-addon/images/check_#{$icon}') left top no-repeat;
            }
          }
        }
      }

      &.for-availability {
        &::after {
          padding: $card-block-info-availability-padding;
        }

        $availibility-states: 'is''Available''yes', 'not''Unavailable''no';

        @each $state,
        $label,
        $icon in $availibility-states {
          &.#{$state}-available {
            &::after {
              content: '#{$label}';
              background: url('/itarp-shared-assets-addon/images/check_#{$icon}.png') left center no-repeat;
            }
          }
        }
      }
    }

    &__item+&__item {
      margin-top: $card-block-info-item-distance;
    }
  }

  &__bolded {
    font-weight: $card-block-info-bolded-font-weight;
  }

  &.card-block--info--lg {
    padding: $card-block-info-lg-padding;

    .card-block--info__list {
      &__item {
        font-size: $card-block-info-item-lg-font-size;

        &.for-tracking,
        &.for-availability {
          &::after {
            font-size: $card-block-info-item-label-lg-font-size;
          }
        }
      }
    }

    .card-block--info__bolded {
      font-weight: $card-block-info-bolded-lg-font-weight;
    }
  }

  &.card-block--info--sm {
    .card-block--info__list {
      &__item {
        font-size: $card-block-info-item-sm-font-size;

        &.for-tracking,
        &.for-availability {
          &::after {
            font-size: $card-block-info-item-label-sm-font-size;
          }
        }
      }
    }
  }

  &.card-block--info--light {
    .card-block--info__list {
      &__item {

        &,
        &.for-tracking::after,
        &.for-availability::after {
          color: $card-block-info-light-color;
        }
      }
    }
  }
}

// Rates

.card-block--rates {
  &__list {
    margin: 0;
    padding: 0;
    list-style-type: none;

    &__item {
      font-size: $card-block-rates-list-item-font-size;
      font-weight: $card-block-rates-list-item-font-weight;
    }

    &__item+&__item {
      margin-top: $card-block-rates-list-item-distance;
    }
  }

  &__currency,
  &__unit {
    font-weight: $card-block-rates-unit-font-weight;
  }
}

// Work

.card-block--work {
  &__list {
    margin: 0;
    padding: 0;
    list-style-type: none;

    &__hours {
      font-size: $card-block-work-hours-font-size;
      font-weight: $card-block-work-hours-font-weight;
      margin: $card-block-work-hours-margin;
      font-family: 'proxima-nova', sans-serif !important;

      &__unit {
        font-weight: $card-block-work-hours-unit-font-weight;
      }
    }

    &__availability {
      &::after {
        position: relative;
        display: inline-block;
        font-size: $card-block-work-availability-font-size;
        // font-weight: 600;
        text-transform: uppercase;
        color: $card-block-work-availability-color;
        padding: $card-block-work-availability-padding;
        font-family: 'proxima-nova', sans-serif !important;
      }

      $availibility-states: 'is''Available''yes', 'not''Unavailable''no';

      @each $state,
      $label,
      $icon in $availibility-states {
        &.#{$state}-available {
          &::after {
            content: '#{$label}';
            background: url('/itarp-shared-assets-addon/images/check_#{$icon}.png') left .0625rem no-repeat;
          }
        }
      }
    }
  }
}

// Paragraph

.card-block--paragraph {
  font-size: $card-block-paragraph-font-size;
  font-weight: 400;
  font-family: 'proxima-nova', sans-serif !important;

  &.card-block--paragraph--sm {
    font-size: $card-block-paragraph-sm-font-size;
  }
}

// Heading

.card-block--heading {
  >* {
    font-size: $card-block-heading-font-size;
    font-weight: $card-block-heading-font-weight;
    font-family: 'proxima-nova', sans-serif !important;
    margin: 0;
  }

  &.is-primary {
    >* {
      color: $card-block-heading-color-primary;
    }
  }
}

// Text

.card-block--text {
  font-size: $card-block-text-font-size;
  font-family: 'proxima-nova', sans-serif !important;

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-size: $card-block-text-header-font-size;
    font-weight: $card-block-text-header-font-weight;
    margin: $card-block-text-header-margin;
    font-family: 'proxima-nova', sans-serif !important;
  }

  ul {
    @include bullet-list($card-block-text-font-size);
  }

  ol {
    padding: $card-block-text-list-ordered-padding;
    margin: 0;
  }

  ul,
  ol {
    &.is-spaced {
      li+li {
        margin-top: $card-block-text-list-spaced-distance;
      }
    }
  }

  >* {
    margin: $card-block-text-element-margin  !important;

    &:last-child {
      margin: 0 !important;
    }
  }

  &.card-block--text--light {
    color: $card-block-text-light-color;
  }

  @media only screen and (max-width: 768px) {
    font-size: 1rem;

    h1,
    h2,
    h3,
    h4,
    h5 {
      font-size: 1.125rem;
    }

    ul {
      @include bullet-list(1rem);
    }
  }
}

// Stages

.card-block--stages {
  display: inline-flex;
  align-items: center;

  &__item {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: $card-block-stages-item-font-size;
    color: $card-block-stages-item-color;
    width: $card-block-stages-item-width;
    height: $card-block-stages-item-height;
    padding: $card-block-stages-item-padding;
    background: url('/itarp-shared-assets-addon/images/cards/stage.svg') center center no-repeat;

    &.is-done {
      background: url('/itarp-shared-assets-addon/images/cards/stage_active.svg') center center no-repeat;
    }
  }

  &__tooltip {
    span {
      display: block;
    }
  }

  &__item+&__item {
    margin-left: $card-block-stages-item-distance;
  }
}

// Tracking

.card-block--tracking {
  &__list {
    margin: 0;
    padding: 0;
    list-style-type: none;

    &__date {
      font-size: $card-block-tracking-date-font-size;
      margin: $card-block-tracking-date-margin;
    }

    &__status {
      &::after {
        position: relative;
        display: inline-block;
        font-size: $card-block-tracking-status-font-size;
        text-transform: uppercase;
        color: $card-block-tracking-status-color;
      }

      $tracking-statuses: 'ontrack''On Track''yes.png'
        $card-block-tracking-status-padding-ontrack,
        'overdue''Overdue''warning.svg'
        $card-block-tracking-status-padding-overdue;

      @each $status,
      $label,
      $icon,
      $padding in $tracking-statuses {
        &.is-#{$status} {
          &::after {
            content: '#{$label}';
            padding: $padding;
            background: url('/itarp-shared-assets-addon/images/check_#{$icon}') left top no-repeat;
          }
        }
      }
    }
  }
}

// Job Role

.card-block--job-role {
  position: relative;
  min-width: $card-block-job-role-min-width;

  &__title {
    &,
    &__link {
      margin: 0;
      font-size: $card-block-job-role-title-font-size;
      font-weight: $card-block-job-role-title-font-weight;
      color: $card-block-job-role-title-color;
      letter-spacing: -0.2px;
      cursor: pointer;
      outline: 0;
      text-decoration: none;

      &:hover,
      &:focus {
        color: $card-block-job-role-title-color-active;
      }
    }
  }

  &__owner {
    font-size: $card-block-job-role-owner-font-size;
    margin: $card-block-job-role-owner-margin;
  }

  &__checkbox {
    @include form-button('checkbox');
    position: absolute;
  }

  &.is-selectable {
    padding: $card-block-job-role-selectable-padding;

    .card-block--job-role__checkbox {
      left: 0;
      top: 0;
    }
  }

  &.card-block--job-role--sm {

    .card-block--job-role__title,
    .card-block--job-role__title a,
    .card-block--job-role__owner {
      font-size: $card-block-job-role-title-sm-font-size;
    }
  }
}

// Controls

.card-block--controls {
  &__list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;

    .select-group {
      min-width: $card-block-controls-select-min-width;
    }

    &__item {
      &.is-pushing {
        flex: 1;
      }
    }

    &__item+&__item {
      margin-left: $card-block-controls-item-distance;
    }
  }

  @media only screen and (max-width: 768px) {
    &__list {
      flex-wrap: wrap;

      &.for-job {
        .card-block--controls__list__item {
          &:nth-child(3) {
            margin-left: 0;
            margin-top: .5rem;
            width: 100%;

            & > * {
              width: 100%;

              .app-btn {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

// Video

.card-block--video {
  position: relative;
  padding-bottom: $card-block-video-padding;
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

// Links

.card-block--links {
  &__list {
    margin: 0;
    padding: 0;
    list-style-type: none;

    &__link {
      font-size: $card-block-links-link-font-size;
      font-weight: $card-block-links-link-font-weight;
      color: $card-block-links-link-color;
      cursor: pointer;
      outline: 0;

      &:hover,
      &:focus {
        color: $card-block-links-link-color-active;
      }
    }

    &__item+&__item {
      margin-top: $card-block-links-item-distance;
    }
  }
}

// Credentials

.card-block--credentials {
  &__title {
    position: relative;
    font-size: $card-block-credentials-title-font-size;
    font-weight: $card-block-credentials-title-font-weight;
    margin: $card-block-credentials-title-margin;

    &.with-icon {
      padding: $card-block-credentials-title-icon-padding;
    }

    $credential-types: 'education', 'employment', 'certification';

    @each $type in $credential-types {
      &.for-#{$type} {
        &::before {
          content: '';
          position: absolute;
          left: 0;
          width: $card-block-credentials-title-icon-width;
          height: $card-block-credentials-title-icon-height;
          background: url('/itarp-shared-assets-addon/images/cards/icon_#{$type}.svg') center center no-repeat;
        }
      }
    }
  }

  .card-block--credential+.card-block--credential {
    margin-top: $card-block-credentials-distance;
  }

  &.is-inline {
    .card-block--credentials__title {
      font-size: $card-block-credentials-title-font-size-inline;
    }

    .card-block--credential+.card-block--credential {
      margin-top: $card-block-credentials-distance-inline;
    }
  }
}

.card-block--credential {
  &__title {
    position: relative;
    font-size: $card-block-credential-title-font-size;
    font-weight: $card-block-credential-title-font-weight;
    margin: $card-block-credential-title-margin;
  }

  &__info {
    margin: $card-block-credential-info-margin;
    padding: 0;
    list-style-type: none;

    &__item {
      font-size: $card-block-credential-info-item-font-size;

      $separation-types: 'pipe''|'
      $card-block-credential-info-separation-margin-pipe,
      'comma'','$card-block-credential-info-separation-margin-comma,
      'hyphen''—'$card-block-credential-info-separation-margin-hyphen;

      @each $type,
      $symbol,
      $margin in $separation-types {
        &.separation-#{$type} {
          span+span {
            &::before {
              content: '#{$symbol}';
              display: inline-block;
              margin: $margin;
            }
          }
        }
      }
    }

    li+li {
      margin-top: $card-block-credential-info-item-distance;
    }
  }

  &__text {
    font-size: $card-block-credential-text-font-size;
  }

  &.is-inline {
    display: flex;
    align-items: baseline;

    .card-block--credential__title {
      margin: $card-block-credential-title-margin-inline;
      font-size: 1rem;
    }

    .card-block--credential__info {
      display: inline-flex;
      margin: 0;

      // li {
      //   color: $card-block-credential-info-item-color-inline;
      // }

      li+li {
        margin-top: 0;
        margin-left: $card-block-credential-info-item-distance-inline;
      }
    }

    >*+*,
    li+li {
      &::before {
        content: ',';
        display: inline-block;
        margin: $card-block-credential-elements-separator-margin-inline;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    &__info {
      &__item {
        font-size: 1rem;
      }
    }

    &__text {
      font-size: 1rem;
    }
  }
}

// Preferences

.card-block--preferences {
  &__title {
    font-size: $card-block-preferences-title-font-size;
    font-weight: $card-block-preferences-title-font-weight;
    margin: $card-block-preferences-title-margin;
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style-type: none;

    &__item {
      position: relative;
      font-size: $card-block-preferences-list-font-size;

      &.item-pin {
        padding: $card-block-preferences-list-item-pin-padding;

        &::before {
          content: '';
          position: absolute;
          left: 0;
          width: $card-block-preferences-list-item-pin-width;
          height: $card-block-preferences-list-item-pin-height;
          background: url('/itarp-shared-assets-addon/images/cards/location_pin.svg') center center no-repeat;
        }
      }

      &.item-check {
        padding: $card-block-preferences-list-item-check-padding;

        $check-types: 'true',
        'false';

        @each $type in $check-types {
          &.is-#{$type} {
            &::before {
              content: '';
              position: absolute;
              left: 0;
              width: $card-block-preferences-list-item-check-width;
              height: $card-block-preferences-list-item-check-height;
              background: url('/itarp-shared-assets-addon/images/cards/check_#{$type}.svg') center center no-repeat;
            }
          }
        }
      }

      span {
        font-weight: $card-block-preferences-list-bolded-font-weight;
      }
    }

    &__item+&__item {
      margin-top: $card-block-preferences-list-distance;
    }
  }

  @media only screen and (max-width: 768px) {
    &__list {
      &__item {
        position: relative;
        font-size: 1rem;
      }
    }
  }
}

// Tags

.card-block--tags {
  @include tags;

  &__title {
    &.with-icon {
      position: relative;
      padding: $card-blog-tags-title-icon-padding;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        width: $card-blog-tags-title-icon-width;
        height: $card-blog-tags-title-icon-height;
      }
    }

    $tags-icons: 'top-tags';

    @each $icon in $tags-icons {
      &.for-#{$icon} {
        &::before {
          background: url('/itarp-shared-assets-addon/images/cards/#{$icon}.svg') center center no-repeat;
        }
      }
    }
  }
}

// Scorecard

.card-block--scorecard {
  display: flex;
  padding: $card-block-scorecard-padding;

  &__score {
    &__circle {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: $card-block-scorecard-circle-width;
      height: $card-block-scorecard-circle-height;
      border-radius: 50%;
      background-color: $green-primary;

      &__icon {
        width: $card-block-scorecard-circle-icon-width;
        height: $card-block-scorecard-circle-icon-height;
        margin: $card-block-scorecard-circle-icon-margin;
      }

      &__label {
        font-size: $card-block-scorecard-circle-label-font-size;
        font-weight: $card-block-scorecard-circle-label-font-weight;
        color: $card-block-scorecard-circle-label-color;
        letter-spacing: -0.4px;
      }

      $score-types: '1'$review-color-0-20, '2'$review-color-21-40,
        '3'$review-color-41-60, '4'$review-color-61-80,
        '5'$review-color-81-100;

      @each $type,
      $color in $score-types {
        &.review-score--#{$type} {
          background: #{$color};

          .card-block--scorecard__score__circle__icon {
            background: url('/itarp-shared-assets-addon/images/cards/rating_#{$type}.svg') center center / contain no-repeat;
          }
        }
      }
    }
  }

  &__info {
    flex: 1;
    padding: $card-block-scorecard-info-padding;
  }

  &__title {
    font-size: $card-block-scorecard-title-font-size;
  }

  &__text {
    font-size: $card-block-scorecard-text-font-size;
    font-weight: $card-block-scorecard-text-font-weight;
    margin: $card-block-scorecard-text-margin;
  }

  &__list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;

    &__poster {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      width: $card-block-scorecard-list-poster-width;
      height: $card-block-scorecard-list-poster-height;
      font-size: $card-block-scorecard-list-poster-font-size;
      font-weight: $card-block-scorecard-list-poster-font-weight;
      color: $card-block-scorecard-list-poster-color;
      background-color: $green-primary;

      span {
        font-weight: $card-block-job-header-title-font-weight;
      }
    }

    li+li {
      margin-left: $card-block-scorecard-list-item-distance;
    }
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;

    &__score {
      display: flex;
      justify-content: center;

      &__circle {
        width: 10rem;
        height: 10rem;

        &__label {
          font-size: 2rem;
        }
      }
    }

    &__info {
      padding: 1rem 0 0;
    }

    &__title {
      font-size: 1.25rem;
    }

    &__text {
      font-size: 1rem;
      margin: 0 0 .25rem;
    }

    &__list {
      display: flex;
      flex-wrap: wrap;

      &__poster {
        width: 2.75rem;
        height: 2.75rem;
        font-size: 1.125rem;
        margin: .25rem .5rem .25rem 0;
      }

      li + li {
        margin-left: 0;
      }
    }
  }
}

// Job Header

.card-block--job-header {
  position: relative;

  &__title {
    &,
    & a {
      font-size: $card-block-job-header-title-font-size;
      font-weight: $card-block-job-header-title-font-weight;
      margin: $card-block-job-header-title-margin;
      color: $text-color-dark;
      font-family: 'proxima-nova', sans-serif !important;
    }

    a {
      color: $card-block-job-header-title-color;
      cursor: pointer;
      outline: 0;
      text-decoration: none;

      &:hover,
      &:focus {
        color: $card-block-job-header-title-color-active;
      }
    }

    &.card-block--job-header__title--sm {
      &,
      & a {
        font-size: 1.25rem;
      }
    }
  }

  &__poster {
    font-size: $card-block-job-header-poster-font-size;
    margin: $card-block-job-header-poster-margin;
    color: $text-color-dark;
    font-family: 'proxima-nova', sans-serif !important;
  }

  &__info {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: inline-flex;
    flex-wrap: wrap;
    color: $text-color-dark;

    &__item {
      position: relative;
      display: inline-flex;
      align-items: baseline;
      font-size: $card-block-job-header-info-font-size;
      font-family: 'proxima-nova', sans-serif !important;
      color: $icon-color-dark;

      &::before {
        content: '';
        position: absolute;
        left: 0;
      }

      $job-info-types: 'location''location_pin'$job-info-icon-location-padding $job-info-icon-location-width $job-info-icon-location-height,
        'contract''contract'$job-info-icon-contract-padding $job-info-icon-contract-width $job-info-icon-contract-height,
        'salary''salary'$job-info-icon-salary-padding $job-info-icon-salary-width $job-info-icon-salary-height,
        'positions''user'$job-info-icon-positions-padding $job-info-icon-positions-width $job-info-icon-positions-height,
        'remote''check_yes'$job-info-icon-remote-padding $job-info-icon-remote-width $job-info-icon-remote-height;

      @each $type,
      $icon,
      $padding,
      $width,
      $height in $job-info-types {
        &.for-#{$type} {
          padding: $padding;

          &::before {
            width: $width;
            height: $height;
            background: url('/itarp-shared-assets-addon/images/cards/#{$icon}.svg') center center no-repeat;
          }
        }
      }
    }

    &__item {
      margin-right: $card-block-job-header-info-distance;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__requirements {
    margin: $card-block-job-header-requirements-margin;
    color: $text-color-dark;

    ul {
      padding-left: 1rem;
    }
  }

  &.with-bookmark {
    padding-right: $card-block-job-header-bookmark-padding-right;

    .card-block--job-header__bookmark {
      position: absolute;
      right: $card-block-job-header-bookmark-right;
      top: 0;
      cursor: pointer;
      outline: 0;
      width: $card-block-job-header-bookmark-width;
      height: $card-block-job-header-bookmark-height;
      background: url('/itarp-shared-assets-addon/images/cards/bookmark.svg') center center no-repeat;

      &.is-active {
        background: url('/itarp-business-application/assets/images/cards/bookmark_active.svg') center center
          no-repeat;
      }
    }

    .card-block--job-header__fullscreen {
      position: absolute;
      right: 3rem;
      top: .25rem;
      cursor: pointer;
      outline: 0;
      width: 1.25rem;
      height: 1.25rem;
      background: url('/itarp-business-application/assets/images/resize_arrows.svg') center center / contain no-repeat;
    }
  }

  &.is-splited {
    padding-right: $card-block-job-header-padding-splited;
    min-height: $card-block-job-header-min-height-splited;

    .card-block--job-header__info {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: $card-block-job-header-info-width-splited;
      padding: $card-block-job-header-info-padding-splited;
      flex-direction: column;
      border-left: $card-block-job-header-info-border;

      li+li {
        margin-left: 0;
        margin-top: $card-block-job-header-info-distance-splited;
      }
    }
  }

  &.card-block--job-header--sm {
    .card-block--job-header__title {
      font-size: 1.0625rem;
    }

    .card-block--job-header__poster {
      font-size: .9375rem;
      margin: 0 0 .25rem;
    }

    .card-block--job-header__info {
      &__item {
        font-size: .875rem;
        color: #90959f;
      }
    }

    .card-block--job-header__requirements {
      &,
      & p,
      & li {
        font-size: .875rem;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    &__title {
      font-size: 1.5rem;
    }

    &__poster {
      font-size: 1rem;
    }

    &__info {
      flex-direction: column;

      &__item + &__item {
        margin-top: .125rem;
      }
    }

    &.with-bookmark {
      padding-right: 1.75rem;

      .card-block--job-header__bookmark {
        right: 0;
      }
    }
  }
}

// Job Status

.card-block--job-status {
  &__list {
    margin: 0;
    padding: 0;
    list-style-type: none;

    &__item {
      font-size: $card-block-job-status-font-size;
      font-family: 'proxima-nova', sans-serif !important;

      span {
        font-weight: $card-block-job-status-font-weight !important;
      }
    }

    &__item+&__item {
      margin-top: $card-block-job-status-item-distance;
    }
  }

  &__mark {
    display: inline-block;
    position: relative;
    padding: $card-block-job-status-mark-padding;

    &::before {
      content: '';
      position: absolute;
      left: $card-block-job-status-mark-left;
      top: 50%;
      margin: $card-block-job-status-mark-margin;
      width: $card-block-job-status-mark-width;
      height: $card-block-job-status-mark-height;
      border-radius: 50%;
    }

    $mark-colors: 'active'$card-block-job-status-mark-color-active,
      'draft'$card-block-job-status-mark-color-draft,
      'in-progress'$card-block-job-status-mark-color-in-progress,
      'closed'$card-block-job-status-mark-color-closed;

    @each $status,
    $color in $mark-colors {
      &.is-#{$status} {
        &::before {
          background-color: $color;
        }
      }
    }
  }
}

// Job Owner

.card-block--job-owner {
  position: relative;
  padding: $card-block-job-owner-padding;
  min-height: $card-block-job-owner-min-height;

  &__logo {
    position: absolute;
    left: 0;
    top: 0;
    width: $card-block-job-owner-logo-width;
    height: $card-block-job-owner-logo-height;
    border-radius: 50%;

    img {
      min-width: 100%;
      min-height: 100%;
      border-radius: 50%;
    }

    &.not-present {
      background: url('/itarp-shared-assets-addon/images/cards/placeholder_logo.svg') center center / cover no-repeat;
    }
  }

  &__title,
  &__text {
    font-size: $card-block-job-owner-text-font-size;
    margin: 0;
  }

  &__title,
  &__text span {
    font-weight: $card-block-job-owner-title-font-weight;
  }
}

// TC Header

.card-block--tc-header {
  position: relative;

  &__title {
    position: relative;
    padding-left: 1.875rem;

    &,
    & a {
      font-size: $card-block-job-header-title-font-size;
      font-weight: $card-block-job-header-title-font-weight;
      margin: $card-block-job-header-title-margin;
      font-family: 'proxima-nova', sans-serif !important;
    }

    a {
      color: $card-block-job-header-title-color;
      cursor: pointer;
      outline: 0;
      text-decoration: none;

      &:hover,
      &:focus {
        color: $card-block-job-header-title-color-active;
      }
    }

    &.card-block--tc-header__title--sm {
      &,
      & a {
        font-size: 1.25rem;
        font-family: 'proxima-nova', sans-serif !important;
      }
    }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: -.5rem;
      height: 1rem;
      width: 1.5rem;
      background: url('/itarp-shared-assets-addon/images/cards/cloud.svg') center center no-repeat;
    }
  }

  &__description {
    font-size: 1rem;

    h1,
    h2,
    h3,
    h4,
    h5 {
      font-size: $card-block-text-header-font-size;
      font-weight: $card-block-text-header-font-weight;
      margin: $card-block-text-header-margin;
    }

    ul {
      @include bullet-list(1rem);

      li + li {
        margin-top: .25rem;
      }
    }

    ol {
      padding: $card-block-text-list-ordered-padding;
      margin: 0;
    }

    ul,
    ol {
      &.is-spaced {
        li + li {
          margin-top: $card-block-text-list-spaced-distance;
        }
      }
    }

    > * {
      margin: $card-block-text-element-margin !important;

      &:last-child {
        margin: 0 !important;
      }
    }
  }
}

// Workflow Header

.card-block--workflow-header {
  &__title {

    &,
    & a {
      color: $text-color-dark;
      text-decoration: none;
      font-size: $card-block-workflow-header-title-font-size;
      font-weight: $card-block-workflow-header-title-font-weight;
      margin: $card-block-workflow-header-title-margin;
      font-family: 'proxima-nova', sans-serif !important;
    }
  }

  &__text {
    font-size: $card-block-workflow-header-text-font-size;
    color: $card-block-workflow-header-text-color;
  }
}

// User Name

.card-block--user-name {
  position: relative;
  min-width: $card-block-user-name-min-width;

  &__title {

    &,
    &__link {
      margin: 0;
      font-size: $card-block-user-name-title-font-size;
      font-weight: $card-block-user-name-title-font-weight;
      color: $card-block-user-name-title-color;
      letter-spacing: -0.2px;
      cursor: pointer;
      outline: 0;
      text-decoration: none;

      &:hover,
      &:focus {
        color: $card-block-user-name-title-color-active;
      }
    }
  }

  &__checkbox {
    @include form-button('checkbox');
    position: absolute;
  }

  &.is-selectable {
    padding: $card-block-user-name-selectable-padding;

    .card-block--user-name__checkbox {
      left: 0;
      top: 0;
    }
  }
}

// Data

.card-block--data {
  counter-reset: item;

  &__title {
    font-size: $card-block-data-title-font-size;
    font-weight: $card-block-data-title-font-weight;
    margin: $card-block-data-title-margin;
  }

  &__list {
    margin: 0;
    font-size: $card-block-data-list-font-size;
    line-height: $card-block-data-list-line-height;

    &__title {
      font-weight: $card-block-data-list-title-font-weight;
      margin: $card-block-data-list-title-margin;
    }

    &__text {
      margin: 0;
    }
  }

  &__group {
    &.is-numbered {
      .card-block--data__list {
        position: relative;
        padding-left: $card-block-data-list-numbered-padding;

        &::before {
          content: counter(item) ' ';
          counter-increment: item;
          position: absolute;
          left: 0;
          font-weight: $card-block-data-list-title-font-weight;
        }
      }
    }

    >*+* {
      margin-top: $card-block-data-list-distance;
    }
  }
}

// Licence Plan

.card-block--licence-plan {
  &__title {
    font-size: $card-block-licence-plan-title-font-size;
    font-weight: $card-block-licence-plan-title-font-weight;
    margin: $card-block-licence-plan-title-margin;

    span {
      font-weight: $card-block-licence-plan-title-font-weight-span;
    }
  }

  &__list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    font-size: $card-block-licence-plan-list-font-size;
    line-height: $card-block-licence-plan-list-line-height;
  }
}

// Tenant

.card-block--tenant {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  min-height: $card-block-tenant-min-height;
  padding: $card-block-tenant-padding;

  &__initials {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: $card-block-tenant-initials-width;
    height: $card-block-tenant-initials-height;
    border: $card-block-tenant-initials-border;
    border-radius: $card-block-tenant-initials-border-radius;
    font-size: $card-block-tenant-initials-font-size;
    font-weight: $card-block-tenant-initials-font-weight;
    color: $card-block-tenant-initials-color;
    background-color: $card-block-tenant-initials-background;
    text-transform: uppercase;
  }

  &__title {
    font-size: $card-block-tenant-title-font-size;
    font-weight: $card-block-tenant-title-font-weight;
    margin: $card-block-tenant-title-margin;
  }

  &__id {
    font-size: $card-block-tenant-id-font-size;
    border-radius: $card-block-tenant-id-border-radius;
    background-color: $card-block-tenant-id-background;
    padding: $card-block-tenant-id-padding;
  }
}

// Presentation

.card-block--presentation {
  &__list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;

    &__item {
      display: inline-flex;
      align-items: center;
      position: relative;
      font-size: $card-block-presentation-list-item-font-size;
      font-weight: $card-block-presentation-list-item-font-weight;
      color: $card-block-presentation-list-item-color;

      $presentation-items: 'email' 1.5rem 1.25rem,
      'phone' 1.375rem 1.375rem,
      'github' 1.25rem 1.25rem,
      'stackoverflow' 1.125rem 1.25rem,
      'kaggle' 1rem 1.5rem;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
      }

      @each $item,
      $width,
      $height in $presentation-items {
        $half-height: math.div($height, 2);

        &.for-#{$item} {
          padding-left: calc(#{$width} + 0.5rem);

          &::before {
            width: $width;
            height: $height;
            margin-top: -$half-height;
            background: url('/itarp-shared-assets-addon/images/cards/#{$item}.svg') center center no-repeat;
          }
        }
      }

      &.for-links {
        display: inline-flex;
        justify-content: flex-end;
        flex: 1;
      }
    }

    &__item+&__item {
      margin-left: $card-block-presentation-list-item-distance;
    }
  }

  &__rating {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: inline-flex;
    align-items: center;

    &__item {
      width: $card-block-presentation-rating-item-width;
      height: $card-block-presentation-rating-item-height;
      background: url('/itarp-shared-assets-addon/images/cards/star.svg') center center no-repeat;

      &.is-filled {
        background: url('/itarp-shared-assets-addon/images/cards/star_fill.svg') center center no-repeat;
      }
    }
  }

  &__sublist {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: inline-flex;
    align-items: center;

    &__link {
      display: inline-block;
      user-select: none;
      outline: 0;
      cursor: pointer;
      width: $card-block-presentation-sublist-link-width;
      height: $card-block-presentation-sublist-link-height;

      $presentation-links: 'bing',
      'facebook',
      'google',
      'google-scholar',
      'info',
      'linkedin',
      'twitter',
      'web',
      'win';

      @each $link in $presentation-links {
        &.for-#{$link} {
          background: url('/itarp-shared-assets-addon/images/cards/social/#{$link}.svg') center center no-repeat;
        }
      }
    }

    &__item+&__item {
      margin-left: $card-block-presentation-sublist-item-distance;
    }
  }
}

// Status

.card-block--status {
  position: relative;
  font-size: $template-group-item-status-font-size;
  font-weight: $template-group-item-status-font-weight;
  padding: $template-group-item-status-padding;

  &::before {
    content: '';
    position: absolute;
    display: inline-block;
    left: 0;
    top: 50%;
    width: $template-group-item-status-dot-width;
    height: $template-group-item-status-dot-height;
    margin-top: $template-group-item-status-dot-margin-top;
    border-radius: 50%;
  }

  $template-statuses: 'inactive' $template-group-item-status-color-inactive,
  'draft' $template-group-item-status-color-draft,
  'rejected' $template-group-item-status-color-rejected,
  'active' $template-group-item-status-color-active,
  'deleted' $template-group-item-status-color-deleted,
  'deletion_rejected' $template-group-item-status-color-rejected;

  @each $status, $color in $template-statuses {
    &.is-#{$status} {
      color: $color;

      &::before {
        background-color: $color;
      }
    }
  }
}

// Chat

.card-block--chat {
  &__thread {
    margin: $card-block-chat-margin;

    & > * + * {
      margin-top: $card-block-chat-post-distance;
    }
  }

  &__post {
    position: relative;
    padding: $card-block-chat-post-padding;
    border-radius: $card-block-chat-post-border-radius;
    background-color: $card-block-chat-post-background-color;
    min-height: $card-block-chat-post-min-height;

    &__avatar {
      position: absolute;
      top: $card-block-chat-post-avatar-top;
      left: $card-block-chat-post-avatar-left;
      width: $card-block-chat-post-avatar-width;
      height: $card-block-chat-post-avatar-height;
      border-radius: 50%;
    }

    &__title {
      font-size: $card-block-chat-post-title-font-size;
      margin: $card-block-chat-post-title-margin;
    }

    &__date {
      position: absolute;
      top: $card-block-chat-post-date-top;
      right: $card-block-chat-post-date-right;
      font-size: $card-block-chat-post-date-font-size;
    }

    &__controls {
      @include link-group;
      position: absolute;
      right: $card-block-chat-post-controls-right;
      top: $card-block-chat-post-controls-top;
    }
  }
}

// Table Review

.card-block--table-reviews {
  @media only screen and (max-width: 768px) {
    width: 100%;
    overflow-x: hidden;
  }
}

// Select

.card-block--select {
  .ember-basic-dropdown-trigger {
    padding: 0 !important;
    min-height: 0 !important;
    border: none !important;
    font-size: $card-block-paragraph-font-size !important;
  }

  &.card-block--select--sm {
    .ember-basic-dropdown-trigger {
      font-size: $card-block-paragraph-sm-font-size !important;
    }
  }

  &.dropdown {
    display: inline-block;
  }

  &__label {
    position: relative;
    display: inline-block;
    padding-right: $card-block-select-label-padding-right;

    &::after {
      content: '';
      position: absolute;
      right: 0;
      top: 50%;
      margin-top: $card-block-select-label-caret-margin-top;
      width: $card-block-select-label-caret-width;
      height: $card-block-select-label-caret-height;
      background: url('/itarp-shared-assets-addon/images/table_caret.svg') center center no-repeat;
    }
  }
}

// Card Roster
//---------------------------------------------

.card-roster {
  display: flex;
  border-top: $card-roster-border;
  padding: $card-roster-padding;

  >* {

    &:first-child,
    &:nth-child(2) {
      flex: 1;
    }

    &:nth-child(2) {
      max-width: $card-roster-second-element-width;
    }
  }

  >*+* {
    margin-left: $card-roster-distance;
  }
}


// Card Thread
//---------------------------------------------

.card-thread {
  height: auto;
  min-height: 10rem;
  max-height: 30rem;
  overflow-y: auto;

  &__inner {
    min-height: 0;
    height: 100%;
    padding: 1.25rem 1.25rem;
    // overflow-y: auto;
  }

  &__post {
    display: flex;

    &.is-sent {
      justify-content: flex-end;
    }
  }

  &__message {
    padding: .5rem 0 .5rem 3rem;
    position: relative;
    max-width: 30rem;
    word-break: break-all;

    &__avatar {
      position: absolute;
      left: 0;
      width: 2.5rem;
      height: 2.5rem;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      font-size: .875rem;
      text-transform: uppercase;
      margin-right: .5rem;
      color: #fff;

      &.is-sender {
        background-color: #4ba4da;
      }

      &.is-receiver {
        background-color: #db8181;
      }

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: .875rem;
      margin: 0 0 .125rem;

      span {
        &:first-child {
          font-weight: 600;
        }
      }
    }

    &__body {
      border: 1px solid #d1dbe3;
      border-radius: .25rem;
      background-color: #fff;
      padding: .5rem .625rem;
      line-height: 1.375;
    }
  }

  &.card-thread--bordered {
    border: 1px solid $border-color;
    border-radius: .25rem .25rem;
    margin-bottom: 1rem;
  }

  &.card-thread--fw {
    .card-thread__post {
      &.is-sent {
        justify-content: flex-start;
      }
    }

    .card-thread__message {
      width: 100%;
      max-width: 100%;
    }
  }

  &.is-long {
    max-height: 45rem;
  }

  &::-webkit-scrollbar {
    width: .375rem;
  }

  &::-webkit-scrollbar-track {
    background: #f1f3f5;
  }

  &::-webkit-scrollbar-thumb {
    background: #a7afb9;

    &:hover {
      background: #8a95a3;
    }
  }
}

// Card List

.card-list {
  display: flex;
  flex-wrap: wrap;
  margin: -.75rem;

  & > * {
    padding: .75rem; 
    flex: 0 0 25%;
    height: auto;
  }
}

// Card TalentCloud

.card--tc {
  height: 100%;
  width: 100%;
  min-height: 15rem;
  box-shadow: 0 3px 6px rgba(0 0 0 / 16%);

  .card__header {
    &__title {
      margin: 0;
      font-weight: 600;
      font-size: 1.125rem;
      line-height: 1.334;
      display: block;
      text-decoration: none;
    }

    &.has-action {
      position: relative;
      padding-right: 3rem;
    }

    &__bookmark {
      position: absolute;
      right: 1rem;
      top: 1rem;
      cursor: pointer;
      outline: 0;
      width: 1.25rem;
      height: 1.75rem;
      background: url('/itarp-shared-assets-addon/images/cards/bookmark.svg') center center no-repeat;

      &.is-active {
        background: url('/itarp-business-application/assets/images/cards/bookmark_active.svg') center center
          no-repeat;
      }
    }

    .card__dropdown {
      position: absolute;
      top: .75rem;
      right: .5rem;

      &__trigger {
        outline: 0;
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;
        background: url('/itarp-shared-assets-addon/images/icon_more.svg') center center / 1rem 1rem no-repeat;

        &:hover,
        &:focus {
          background-color: #f1f3f5;
        }
      }
    }
  }

  .card__body {
    flex: 1;

    &__summary {
      margin: 0;
      line-height: 1.5;
      max-height: 9rem;
      overflow: hidden;
    }
  }

  .card__footer {
    padding: 0.75rem 1rem !important;
    border-top: 1px solid #d1dbe3;
    min-height: 3.75rem;
    justify-content: space-between;
    align-items: center;

    &__item {
      display: inline-flex;

      &.for-members {
        font-size: 0.9375rem;
        align-items: baseline;

        .card-footer__number {
          font-size: 1.25rem;
          font-weight: 500;
          margin-right: 0.25rem;
        }
      }

      &.for-logo {
        img {
          max-height: 1.5rem;
        }
      }

      &.for-location {
        position: relative;
        color: #90959f;
        font-size: .9375rem;
        padding-left: 1.5rem;
        height: 1.25rem;

        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          margin-top: -.625rem;
          width: 1.25rem;
          height: 1.25rem;
          background: url('/itarp-shared-assets-addon/images/cards/location_pin.svg') center center / contain no-repeat;
        }
      }
    }
  }
}

.card__dropdown {
  &__menu {
    box-shadow: 0 1px 3px rgba(#bcbec4, 0.5);
    border-radius: 0.25rem;
    padding: 0.5rem 0;
  }

  &__list {
    list-style-type: none;
    margin: 0;
    padding: 0;

    &__link {
      display: block;
      font-size: .875rem;
      padding: .5rem 1rem;
      cursor: pointer;
      outline: 0;
      text-decoration: none;
    }
  }
}