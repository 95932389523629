// Navbar
//---------------------------------------------

.navbar {
  background-color: $navbar-background;

  &__links {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    position: relative;

    &__link {
      font-size: $navbar-links-link-font-size;
      font-weight: $navbar-links-link-font-weight;
      color: $navbar-links-link-color;
      cursor: pointer;
      outline: 0;
    }

    &__item + &__item {
      margin-left: $navbar-links-item-distance;
    }
  }
}