// Component Wrapper
//---------------------------------------------

.component-wrapper {
  // min-height: $base-unit-height;
  height: 100%;
  // overflow: auto;
  position: relative;

  &.is-limited {
    overflow: hidden;
    // margin-bottom: $base-unit-height;

    // .readmore {
    //   position: absolute;
    //   bottom: -$base-unit-height;
    //   right: 0;
    //   left: 0;
    //   z-index: 1;
    //   height: $base-unit-height;
    //   display: flex;
    //   align-items: center;
    //   justify-content: flex-end;
    // }
  }

  &.is-scrollable {
    overflow-y: scroll;
  }

  &.has-padding {
    padding: 1rem 1rem;
  }

  &.has-ds-padding {
    padding: 1rem 2rem;
  }

  &.has-y-padding {
    padding: 1rem 0;
  }

  &.no-caret {
    .ember-power-select-status-icon {
      display: none;
    }
  }
}

// Component Align
//---------------------------------------------

.component-align--v-center {
  display: flex;
  align-items: center;
}

.component-align--v-bottom {
  display: flex;
  align-items: end;
}

.component-align--h-center {
  display: flex;
  justify-content: center;
}

.component-align--h-right {
  display: flex;
  justify-content: flex-end;
}

.component-align--checkbox {
  display: flex;
  align-items: center;
  padding-top: $component-align-checkbox-top;
}

.component-split {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
