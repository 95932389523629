.author {
  font-size: 70%;
}

#posts {
  width: 300px;
  float: left;
}

#bin {
  width: 200px;
  float: left;
  margin-left: 30px;
}

.title {
  font-size: 125%;
}

.author {
  font-size: 70%;
}

.rated-cards {
  float: left;
  margin: 15px;
}

.all-cards {
  clear: both;
}

.object-bin-title {
  font-size: 140%;
  font-weight: bold;
}

#bin .object-bin-title {
  text-align: center;
}

#bin .draggable-object-bin {
  border-width: 1px;
  border-style: solid;
  border-color: #b8c0d6;
  padding: 15px;
}

.draggable-object {
  padding-bottom: 2px;
}

.dropTarget {
  width: 100%;

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  li {
    word-break: break-all;
  }
}

.dropTarget.accepts-drag {
  background-color: rgba(#4BA4DA, .1);
}

// .dragObject {
//   position: relative;
//   background-color: #f1f3f5;
//   border: 1px solid #d1dbe3;
//   padding: .5625rem 1rem .5625rem 2.75rem;
//   border-radius: .25rem;
//   font-size: 1.125rem;
//   font-weight: 600;
//   line-height: 2;

//   &:before {
//     content: '';
//     position: absolute;
//     height: 1.75rem;
//     width: .5rem;
//     left: 1.25rem;
//     top: 50%;
//     margin: -.875rem 0 0 0;
//     background: url('images/icon_handler.svg') center center / contain no-repeat;
//   }

//   & + .dragObject {
//     margin-top: 1rem;
//   }
// }

.sortObject {
  border: 1px solid #4BA4DA;
  position: relative;

  img {
    display: block;
    max-width: 100%;
    // width: 100%;
  }
}
.u-pullLeft {
  float: left;
}
.u-marginLeft {
  margin-left: 10%;
}
.u-fullBlock {
  display: inline-block;
  width: 100%;
}
.u-halfBlock {
  display: inline-block;
  width: 50%;
}

.dragHandle {
  // display: inline-block;
  // position: absolute;
  // top: 1em;
  // right: 1em;
  // width: 1.5em;
  // height: 1.5em;
  // background-color: #fff;
  // border: 1px solid #333;
  cursor: move;
}

.sortObject {
  position: relative;
  padding: .5rem .5rem .5rem 1.75rem;
 
  &:before {
    content: '';
    position: absolute;
    width: 1.25rem;
    left: 0;
    top: 0;
    bottom: 0;
    background: #4BA4DA url('/itarp-shared-assets-addon/images/icon_handler_active.svg') .3125rem center / .5rem auto no-repeat;
  }
}

.dragComponent-controls {
  position: absolute;
  right: 1rem;
  top: -1rem;
  z-index: 11;
  display: none;
}

.dragControls-list {
  @extend .link-group;
  list-style-type: none;
  margin: 0;
  padding: 0;

  > * {
    border: 2px solid #4BA4DA;
  }  

  > * + * {
    border-left-width: 0;
  }
}

.designer-split {
  position: absolute;
  z-index: 10;
  display: none;

  &.on-right {
    top: 50%;
    right: 0;
    margin: -.875rem -.875rem 0 0;
  }

  &.on-bottom {
    top: 100%;
    left: 50%;
    margin: -.875rem 0 0 -.875rem;
  }

  &__inner {
    position: relative;
  }

  &__button {
    display: block;
    width: 1.75rem;
    height: 1.75rem;
    border: 2px solid #4BA4DA;
    border-radius: .25rem;
    padding: 0;

    &::before {
      position: absolute;
      width: 5.5rem;
      background-color: #BCBEC4;
      color: #FFF;
      font-size: .75rem;
      font-weight: 600;
      padding: .25rem .25rem; 
      border-radius: .25rem;
      display: none;
    }

    &.designer-split--column {
      background: #FFF url('/itarp-shared-assets-addon/images/icon_split.svg') center center / 1.25rem 1.25rem no-repeat;

      &::before {
        content: 'Add Column';
        left: -5.75rem;
        top: .125rem;
      }

      &.is-editing {
        background: #4BA4DA url('/itarp-shared-assets-addon/images/icon_split_active.svg') center center / 1.25rem 1.25rem no-repeat;
      }
    }

    &.designer-split--row {
      background: #FFF url('/itarp-shared-assets-addon/images/icon_split.svg') center center / 1.25rem 1.25rem no-repeat;
      transform: rotate(90deg);

      &::before {
        content: 'Add Row';
        transform: rotate(-90deg);
        left: -2rem;
        top: 3.875rem;
      }
    }

    &:focus,
    &:hover {
      &.designer-split--column {
        background: #4BA4DA url('/itarp-shared-assets-addon/images/icon_split_active.svg') center center / 1.25rem 1.25rem no-repeat;
      }
    
      &.designer-split--row {
        background: #4BA4DA url('/itarp-shared-assets-addon/images/icon_split_active.svg') center center / 1.25rem 1.25rem no-repeat;
      }

      &::before {
        animation: fadeIn .25s;
        display: block;
      }
    }
  } 
  
  &__menu {
    position: absolute;
    right: 0;
    top: 100%;
    margin: .125rem 0 0;
    padding: .5rem .5rem;
    width: 5rem;
    background-color: #FFF;
    box-shadow: 0 1px 3px rgba(#000, .15);
  }
}

.designer-droparea {
  .row {
    &:hover,
    &:focus {
      .designer-split {
        animation: fadeIn .25s;
        display: block;
      }

      .dragComponent-controls {
        animation: fadeIn .25s;
        display: block;
      }
    }

    .designer-split {
      &.designer-split--edit {
        display: block;
        animation: none;
      }
    }
  }
}

@-webkit-keyframes fadeIn {
  from { opacity: 0; }
    to { opacity: 1; }
}
@keyframes fadeIn {
  from { opacity: 0; }
    to { opacity: 1; }
}