// Dropdown
//---------------------------------------------

.dropdown {
  &__menu {
    min-width: $dropdown-menu-min-width;
    padding: $dropdown-menu-padding;
    box-shadow: $dropdown-menu-box-shadow;
    border-radius: $dropdown-menu-border-radius;
    margin: $dropdown-menu-margin;

    &__title {
      padding: $dropdown-menu-title-padding;
      font-size: $dropdown-menu-title-font-size;
      font-weight: $dropdown-menu-title-font-weight;
    }

    &__link {
      display: block;
      color: $dropdown-menu-link-color;
      padding: $dropdown-menu-link-padding;
      font-size: $dropdown-menu-link-font-size;
      font-weight: $dropdown-menu-link-font-weight;
      white-space: nowrap;
      cursor: pointer;
      outline: 0;
      text-decoration: none;
      font-family: 'proxima-nova', sans-serif !important;

      &:hover {
        background-color: $dropdown-menu-link-background-hover;
        color: $dropdown-menu-link-color-hover;
      }

      &:focus,
      &:active,
      &.active {
        background-color: $dropdown-menu-link-background-active;
        color: $dropdown-menu-link-color-active;
      }

      &.is-dark {
        color: $dropdown-menu-link-tabel-color;
        font-size: $dropdown-menu-link-tabel-font-size;
        font-family: 'proxima-nova', sans-serif !important;

        &:hover {
          color: $dropdown-menu-link-tabel-color;
        }

        &:focus,
        &:active,
        &.active {
          color: $dropdown-menu-link-color-active;
        }
      }

      &.disabled {
        cursor: default;
        user-select: none;
        color: $dropdown-menu-link-color-disabled;

        &:hover {
          color: $dropdown-menu-link-color-disabled;
          background-color: $dropdown-menu-link-background-disabled;
        }
      }
    }

    &.is-form {
      padding: $dropdown-menu-form-padding;
    }
  }

  &__menu__arrow {
    margin-top: $dropdown-menu-arrow-margin-top;

    &::before {
      content: '';
      position: absolute;
      top: $dropdown-menu-arrow-top;
      border-left: $dropdown-menu-arrow-border;
      border-right: $dropdown-menu-arrow-border;
      border-bottom: $dropdown-menu-arrow-border-bottom;
      width: $dropdown-menu-arrow-width;
    }

    &.dropdown__menu__arrow--center {
      &::before {
        left: 50%;
        margin-left: $dropdown-menu-arrow-margin-left;
      }
    }
  }
}

// Status Group
//---------------------------------------------

.status-group {
  display: inline-flex;
  border: $status-group-border;
  border-radius: $status-group-border-radius;
  background-color: $status-group-background;

  &__label {
    position: relative;
    font-size: $status-group-label-font-size !important;
    font-weight: $status-group-label-font-weight !important;
    padding: $status-group-label-padding;
    font-family: 'proxima-nova', sans-serif !important;
    background: url('/itarp-shared-assets-addon/images/buttons/status.svg')
      0.5rem center no-repeat;
    white-space: nowrap;

    span {
      $mark-colors: 'active'$card-block-job-status-mark-color-active,
      'draft'$card-block-job-status-mark-color-draft,
      'in-progress'$card-block-job-status-mark-color-in-progress,
      'closed'$card-block-job-status-mark-color-closed;

      @each $status,
      $color in $mark-colors {
        &.is-#{$status} {
          color: $color;
        }
      }
    }
  }

  &__trigger {
    display: inline-block;
    border-left: $status-group-border;
    height: 100%;
    width: $status-group-trigger-width;
    background: url('/itarp-shared-assets-addon/images/arrow_down_blue.svg')
      center center no-repeat;
  }
}
