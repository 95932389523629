// Sidebar
//---------------------------------------------

.dashboard-sidebar {
  position: fixed;
  left: 0;
  bottom: 0;
  top: 3.5rem;
  width: 13.75rem;
  z-index: 95;
  background-color: #2b3e5f;
  transition: .5s;

  &__content {
    padding: 2.5rem 0 1.5rem;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
  }

  &__collapse {
    position: absolute;
    top: .75rem;
    right: 1.25rem;
    width: 1rem;
    height: 1rem;
    background: url('/itarp-shared-assets-addon/images/sidebar/collapse.svg') center center no-repeat;
  }

  &__links {
    position: relative;
    list-style-type: none;
    padding: 0;
    margin: 0;

    &__link {
      position: relative;
      display: block;
      padding: .875rem 1rem .875rem 3.5rem;
      cursor: pointer;
      text-decoration: none;
      outline: 0;
      transform: perspective(1px) translateZ(0);
      -webkit-transition-duration: 0.5s;
      transition-duration: 0.5s;
      -webkit-transition-property: background-color;
      transition-property: background-color;
      font-family: 'proxima-nova', sans-serif !important;

      &__icon {
        position: absolute;
        width: 1.5rem;
        height: 1.5rem;
        left: 1rem;
        top: 50%;
        margin-top: -.75rem;

        $sidebar-icons: 'career''svg',
        'categories''svg',
        'clients''svg',
        'cms''svg',
        'configuration''svg',
        'dashboard''svg',
        'integrations''svg',
        'interview''svg',
        'metrics''svg',
        'opportunities''svg',
        'organization''svg',
        'permission''png',
        'settings''svg',
        'talent''svg',
        'talentclouds''svg',
        'templates''svg',
        'workflows''svg',
        'home''svg',
        'custom-fields''svg',
        'profile' 'svg';

        @each $icon,
        $type in $sidebar-icons {
          &.for-#{$icon} {
            background: url('/itarp-shared-assets-addon/images/sidebar/#{$icon}.#{$type}') center center no-repeat;
          }
        }
      }

      &__title {
        font-size: .875rem;
        font-weight: 600;
        line-height: 1.375;
        text-decoration: none;
        text-transform: uppercase;
        letter-spacing: -.1px;
        color: #f7f7f7;
        // white-space: nowrap;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        width: 9.5rem;
        user-select: none;
        font-family: 'proxima-nova', sans-serif !important;
      }

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 0;
        background-color: #4BA4DA;
        transition: width .25s ease-in-out;
      }

      &:hover,
      &:focus {
        background-color: #213457;
      }
    }

    &__item {
      position: relative;
      transform: perspective(1px) translateZ(0);
      -webkit-transition-duration: 0.5s;
      transition-duration: 0.5s;
      -webkit-transition-property: background-color;
      transition-property: background-color;

      &.is-active {

        &,
        &:hover,
        &:focus {
          background-color: #213457;

          &::before,
          .dashboard-sidebar__links__link::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            background-color: #4BA4DA;
            width: .3125rem;
          }
        }
      }

      &.has-sublinks {
        .dashboard-sidebar__links__link {
          padding: .875rem 1rem .25rem 3.5rem;

          &__icon {
            margin-top: -.5rem;
          }
        }

        &:hover,
        &:focus {
          background-color: #213457;
        }
      }
    }

    &__tooltip {
      font-size: .75rem;
      font-weight: 400;
      background-color: #2b3e5f;
      padding: .375rem .75rem;
      z-index: 91;

      &[x-placement^="right"] {
        .ember-tooltip-arrow {
          display: none;
        }
      }
    }

    &__popover {
      background-color: #2b3e5f;
      padding: .375rem .75rem;
      z-index: 91;
      border: 1px solid #2b3e5f;
      border-radius: .25rem;
      width: 10rem;
      margin: 0 0 0 .5rem !important;

      &[x-placement^="right"] {
        .ember-popover-arrow {
          display: none;
        }
      }

      &__link {
        font-size: .8125rem;
        font-weight: 600;
        text-transform: uppercase;
        text-decoration: none;
        color: #f7f7f7;
        font-family: 'proxima-nova', sans-serif !important;
      }

      &__sublinks {
        list-style-type: none;
        padding: .5rem 0 0 0;
        margin: .25rem 0 0 0;
        border-top: 1px solid rgba(#f7f7f7, .5);

        .dashboard-sidebar__sublinks__link {
          padding: .125rem 0;
          font-size: .8125rem;
          font-family: 'proxima-nova', sans-serif !important;
        }
      }
    }
  }

  &__sublinks {
    position: relative;
    list-style-type: none;
    padding: 0 0 .875rem 2.5rem;
    margin: 0;

    &__link {
      display: block;
      position: relative;
      padding: 0.125rem 1rem;
      font-size: 0.875rem;
      font-weight: 400;
      text-decoration: none;
      color: #FFFFFF;
      cursor: pointer;
      outline: 0;
      white-space: nowrap;
      font-family: 'proxima-nova', sans-serif !important;

      &:focus,
      &:hover {
        color: #4ba4da !important;
      } 

    &.is-active {
        color: #4ba4da !important;
      }
    }
  }

  &.is-collapsed {
    width: 3.5rem;

    .dashboard-sidebar__collapse {
      transform: rotate(180deg);
    }

    .dashboard-sidebar__links__item {
      &.has-sublinks {
        .dashboard-sidebar__links__link {
          padding: .875rem 1rem .875rem 3.5rem;

          &__icon {
            margin-top: -.75rem;
          }
        }
      }
    }
  }

  .dashboard-sidebar__content::-webkit-scrollbar {
    display: none;
  }

  .dashboard-sidebar__content {
    -ms-overflow-style: none;
  }
}
