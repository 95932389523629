// Colors
//---------------------------------------------

// Text Colors

$text-color-dark: #4a5162;
$text-color-light: #f7f7f7;
$text-color-placeholders: #a1a5ad;

// Button and Link Colors

$blue-button-primary: #4ba4da;
$blue-button-secondary: #86c2e6;
$blue-links-primary: #61a7db;
$blue-links-secondary: #4586bc;
$green-primary: #78c493;
$green-secondary: #50ae81;
$grey-primary: #bcbec4;
$white: #ffffff;

// Elements Colors

$body-background: #f9f9f9;
$navbar-background: #222e43;
$sidenav-background: #2b3e5f;
$border-color: #d1dbe3;
$table-background: #f1f3f5;

// Misc Colors

$icon-color-dark: #90959f;
$icon-color-light: #ebebed;
$badges-color: #70a0c7;
$tooltips-color: #38648f;

// Warnings and Error Color

$accent-color-1: #88437d;
$accent-color-2: #a73e7a;
$accent-color-3: #ce4b58;
$accent-color-4: #de5e6a;
$accent-color-5: #e3795b;

// Review Colors

$review-color-0-20: #de5e6a;
$review-color-21-40: #d67d86;
$review-color-41-60: #f8c573;
$review-color-61-80: #78c493;
$review-color-81-100: #3ba773;

// Mixins
//---------------------------------------------

// Buttons

$button-color-default: $white;

$button-social-icon-border-default: $border-color;
$button-social-icon-color-default: $text-color-dark;
$button-social-icon-width: 2rem;
$button-social-icon-height: 2rem;
$button-social-icon-margin: 0 1rem 0 0;

// Form Buttons

$form-button-font-weight: 400;
$form-button-form-size: 1rem;
$form-button-margin: 0 0.5rem 0 0;
$form-button-width: 1.25rem;
$form-button-height: 1.25rem;
$form-button-background: $white;
$form-button-border-color: $border-color;
$form-button-border-color-active: $blue-button-primary;
$form-button-box-shadow-active: 0 0 0 2px rgba($blue-button-secondary, 0.5);

$form-button-font-weight-strong: 600;

$form-button-border-width-checkbox: 1px;
$form-button-border-radius-checkbox: 0.25rem;
$form-button-background-size-checkbox: 0.625rem 0.5rem;

$form-button-border-width-radio: 2px;
$form-button-border-radius-radio: 50%;

// Button Square

$btn-square-border-default: $border-color;

// Card

$card-border-radius: 0.25rem;
$card-box-shadow: 0 1px 3px rgba($grey-primary, 0.5);

// Link Group

$link-group-link-width: 2.5rem;
$link-group-link-height: 2.25rem;
$link-group-link-border: 1px solid $border-color;
$link-group-link-background: $white;
$link-group-link-border-radius: 0.25rem;

$link-group-link-icon-height: 1.25rem;
$link-group-link-icon-width: 1.25rem;
$link-group-link-icon-margin: -0.625rem 0 0 -0.625rem;

$link-group-link-border-color-active: $blue-button-primary;
$link-group-link-background-active: $blue-button-primary;

$link-group-link-width: 2.25rem;
$link-group-link-height: 2rem;
$link-group-link-icon-height: 1.125rem;
$link-group-link-label-padding-large: 0 0.5rem 0 2rem;

$link-group-link-label-font-size: 0.875rem;
$link-group-link-label-color: $text-color-dark;
$link-group-link-label-padding: 0 0.5rem 0 2rem;
$link-group-link-label-color-active: $white;

$link-group-link-icon-width: 1.125rem;
$link-group-link-icon-margin: -0.5625rem 0 0 -0.5625rem;
$link-group-link-label-icon-left: 0.375rem;

$link-group-link-width-large: 2.5rem;
$link-group-link-height-large: 2.25rem;
$link-group-link-icon-height-large: 1.25rem;
$link-group-link-label-padding-large: 0 0.75rem 0 2.25rem;

$link-group-link-icon-width-large: 1.25rem;
$link-group-link-icon-margin-large: -0.625rem 0 0 -0.625rem;
$link-group-link-label-icon-left-large: 0.5rem;

$link-group-link-width-small: 2rem;
$link-group-link-height-small: 1.75rem;

$link-group-link-icon-height-small: 1.125rem;
$link-group-link-icon-width-small: 1.125rem;
$link-group-link-icon-margin-small: -0.5625rem 0 0 -0.5625rem;
$link-group-link-label-padding-small: 0 0.5rem 0 0.25rem;
$link-group-link-label-icon-left-small: 0.375rem;

// Match Score

$match-score-dot-size: .75rem;
$match-score-dot-background: $border-color;
$match-score-dot-background-filled: $blue-button-primary;
$match-score-dot-distance: 0.125rem;

// Bullet List

$bullet-list-font-size: 1rem;
$bullet-list-item-padding: 0 0 0 1.5rem;
$bullet-list-item-distance: 0.5rem;
$bullet-list-item-bullet-top: -0.5rem;
$bullet-list-item-bullet-left: 0.125rem;
$bullet-list-item-bullet-font-size: 2rem;
$bullet-list-item-bullet-color: $blue-button-primary;

// Tags

$tags-title-font-size: 1.125rem;
$tags-title-font-weight: 600;
$tags-title-margin: 0 0 0.5rem;
$tags-title-capitals-font-size: 1rem;
$tags-title-color-light: $grey-primary;

$tags-list-item-font-size: 1rem;
$tags-list-item-border: 1px solid $border-color;
$tags-list-item-border-radius: 0.25rem;
$tags-list-item-padding: 0.5rem 0.75rem;
$tags-list-item-background: #f5f5f5;
$tags-list-item-margin: 0.25rem 0.5rem 0.25rem 0;
$tags-list-line-height: 1.125;
$tags-list-item-elements-distance: 0.25rem;
$tags-list-item-duration-title-font-weight: 600;

$tags-title-font-size-large: 1.25rem;
$tags-title-margin-large: 0 0 0.75rem;
$tags-list-item-font-size-large: 1.125rem;
$tags-list-item-padding-large: 0.75rem 1rem;
$tags-list-item-margin-large: 0.375rem 0.75rem 0.375rem 0;

$tags-list-add-link-font-size: 0.875rem;
$tags-list-add-link-font-weight: 600;
$tags-list-add-link-padding: 0.5625rem 1.25rem 0.5625rem 3rem;
$tags-list-add-link-border: 1px solid $border-color;
$tags-list-add-link-border-radius: 0.25rem;
$tags-list-add-link-background: $white;
$tags-list-add-link-color: $text-color-dark;
$tags-list-add-link-box-shadow: 0 1px 15px rgba(#000000, 0.04);
$tags-list-add-link-icon-left: 0.5rem;
$tags-list-add-link-icon-margin-top: -0.75rem;
$tags-list-add-link-icon-width: 2rem;
$tags-list-add-link-icon-height: 1.5rem;

$tags-list-add-link-background-active: $blue-button-primary;
$tags-list-add-link-color-active: $white;
$tags-list-add-link-border-color-active: $blue-button-primary;

$tags-list-more-font-size: 0.9375rem;
$tags-list-more-font-weight: 600;

$tags-list-item-color-primary: $white;
$tags-list-item-background-primary: $blue-button-primary;
$tags-list-item-border-color-primary: $blue-button-primary;

$tags-list-item-background-outline-secondary: $white;
$tags-list-item-border-color-outline-secondary: $green-secondary;

$tags-list-item-checked-padding-right: 1.75rem;
$tags-list-item-checked-icon-position-right: 0.5rem;
$tags-list-item-checked-icon-margin-top: -0.5rem;
$tags-list-item-checked-icon-width: 1rem;
$tags-list-item-checked-icon-height: 1rem;

$tags-list-item-remove-width: 0.625rem;
$tags-list-item-remove-height: 0.625rem;

$tag-params-primary-color: #4586bc;
$tag-params-secondary-color: #3c956a;
$tag-params-info-color: #88437d;
$tag-params-warning-color: #e3795b;

// Workflow Line

$workflow-line-background: $border-color;
$workflow-line-thinkness: 0.125rem;
$workflow-line-margin: -0.0625rem;

// Plugins
//---------------------------------------------

// Ember Power Select

$ember-power-select-padding: 1rem 2rem 1rem 0.625rem;
$ember-power-select-border: 1px solid $border-color;
$ember-power-select-border-radius: 0.25rem;
$ember-power-select-min-height: 3.5rem;
$ember-power-select-line-height: 1.375;
$ember-power-select-font-size: 1rem;
$ember-power-select-placeholder-color: $text-color-placeholders;

$ember-power-multiselect-padding: 0.25rem 2rem 0.25rem 0.875rem;

$ember-power-select-icon-right: 1rem;
$ember-power-select-icon-border-width: 6px 6px 0 6px;
$ember-power-select-icon-border-color: #7c8189 transparent transparent transparent;

$ember-power-select-options-max-height: 12.5rem;

$ember-power-select-option-font-size: 1rem;
$ember-power-select-option-cell-padding: 0.625rem 1.125rem;
$ember-power-select-option-cell-background: $table-background;
$ember-power-select-option-cell-color: $text-color-dark;
$ember-power-select-option-cell-background-selected: $blue-button-primary;
$ember-power-select-option-cell-color-selected: $white;

$ember-power-multiselect-option-font-size: 1rem;
$ember-power-multiselect-option-line-height: 1.5;
$ember-power-multiselect-option-border: 1px solid $border-color;
$ember-power-multiselect-option-border-radius: 0.25rem;
$ember-power-multiselect-option-background: #f5f5f5;
$ember-power-multiselect-option-padding: 0.375rem 1.75rem 0.375rem 0.75rem;
$ember-power-multiselect-option-margin: 0.25rem 0.5rem 0.25rem 0;

$ember-power-multiselect-option-remove-width: 0.625rem;
$ember-power-multiselect-option-remove-height: 0.625rem;
$ember-power-multiselect-option-remove-right: 0.5rem;
$ember-power-multiselect-option-remove-margin: -0.3125rem 0 0;

$ember-power-multiselect-input-font-size: 1rem;
$ember-power-multiselect-input-line-height: 1.5;
$ember-power-multiselect-input-padding: 0.6875rem 0;
$ember-power-multiselect-input-text-indent: 0.25rem;

// Ember Phone Input

$ember-phone-input-flag-container-background: $white;

$ember-phone-input-selected-flag-padding: 0 1rem 0 1rem;
$ember-phone-input-selected-flag-width: 9rem;
$ember-phone-input-selected-flag-border: 1px solid $border-color;
$ember-phone-input-selected-flag-border-radius: 0.25rem 0 0 0.25rem;

$ember-phone-input-country-list-index: 20;

$ember-phone-input-country-padding: 0.875rem 1.125rem;
$ember-phone-input-country-font-size: 1.0675rem;
$ember-phone-input-country-line-height: 1.25;
$ember-phone-input-country-highlight-background: $table-background;
$ember-phone-input-country-active-color: $white;
$ember-phone-input-country-active-background: $blue-button-primary;
$ember-phone-input-country-active-dial-code-color: $icon-color-light;

$ember-phone-input-input-padding-right: 1.125rem;
$ember-phone-input-input-padding-left: 10.125rem;
$ember-phone-input-input-margin-left: 0;

$ember-phone-input-selected-flag-background: $white;

$ember-phone-input-selected-flag-dial-code-margin-left: 1rem;

$ember-phone-input-arrow-right: 1rem;
$ember-phone-input-arrow-margin-left: 6px;
$ember-phone-input-arrow-width: 0;
$ember-phone-input-arrow-height: 0;
$ember-phone-input-arrow-border-left: 6px solid transparent;
$ember-phone-input-arrow-border-right: 6px solid transparent;
$ember-phone-input-arrow-border-top: 6px solid #7c8189;

$ember-phone-input-arrow-up-border-bottom: 6px solid #7c8189;

// Ember CLI TinyMCE

$ember-tinymce-border-radius: 0.25rem;
$ember-tinymce-border: 1px solid $border-color;
$ember-tinymce-background: $text-color-light;

$ember-tinymce-button-width: 2.5rem;
$ember-tinymce-button-height: 2.5rem;

$ember-tinymce-icon-width: 1rem;
$ember-tinymce-icon-height: 1rem;

$ember-tinymce-custom-button-background: $white;
$ember-tinymce-custom-button-width: 2.25rem !important;
$ember-tinymce-custom-button-height: 2rem !important;
$ember-tinymce-custom-button-border: 1px solid $border-color;

$ember-tinymce-custom-button-background-active: $blue-button-primary;
$ember-tinymce-custom-button-border-color-active: $blue-button-primary;

// Ember Power Datepicker

$ember-power-datepicker-width: 24rem;
$ember-power-datepicker-border: 1px solid $border-color;
$ember-power-datepicker-border: 1px solid $border-color;
$ember-power-datepicker-background: $body-background;
$ember-power-datepicker-padding: 1.5rem 1.5rem;
$ember-power-datepicker-border-radius-above: 0.25rem 0.25rem 0 0;
$ember-power-datepicker-border-radius-below: 0 0 0.25rem 0.25rem;
$ember-power-datepicker-box-shadow: 0 1px 3px rgba($grey-primary, 0.15);

$datepicker-group-field-icon-padding-left: 2.75rem;
$datepicker-group-field-icon-left: 1.5rem;
$datepicker-group-field-icon-width: 1.25rem;
$datepicker-group-field-icon-height: 1.25rem;
$datepicker-group-field-icon-margin: -.625rem;

$ember-power-calendar-nav-height: 3rem;
$ember-power-calendar-nav-padding: 0 6rem 0.75rem 0;

$ember-power-calendar-nav-title-font-size: 1rem;
$ember-power-calendar-nav-title-font-weight: 600;

$ember-power-calendar-nav-control-width: 2.25rem;
$ember-power-calendar-nav-control-height: 2.25rem;
$ember-power-calendar-nav-control-border: 1px solid #e8e9ec;
$ember-power-calendar-nav-control-border-active: $blue-button-primary;
$ember-power-calendar-nav-control-border-radius: 0.25rem;
$ember-power-calendar-nav-control-box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.05);
$ember-power-calendar-nav-control-background: $white;
$ember-power-calendar-nav-control-background-active: $blue-button-primary;
$ember-power-calendar-nav-control-position-right-prev: 3.25rem;

$ember-power-calendar-weekdays-background: $icon-color-light;
$ember-power-calendar-weekdays-height: 3rem;
$ember-power-calendar-weekdays-border: 1px solid #e8e9ec;

$ember-power-calendar-weekday-font-size: 0.875rem;
$ember-power-calendar-weekday-font-weight: 700;
$ember-power-calendar-weekday-color: $icon-color-dark;

$ember-power-calendar-day-grid-border: 1px solid $border-color;
$ember-power-calendar-day-grid-background: $white;

$ember-power-calendar-week-height: 3rem;

$ember-power-calendar-day-font-size: 1rem;
$ember-power-calendar-day-font-weight: 600;
$ember-power-calendar-day-max-width: percentage(math.div(1, 7));
$ember-power-calendar-day-background-hover: $table-background;
$ember-power-calendar-day-color-active: $white;
$ember-power-calendar-day-background-active: $blue-button-primary;

// Ember Modal Dialog

$ember-modal-dialog-overlay-index: 1040;
$ember-modal-dialog-overlay-background: rgba(#213456, 0.8);

$ember-modal-dialog-border-radius: 0;
$ember-modal-dialog-background: transparent;

// Ember Slider

$ember-slider-path-color: $border-color;
$ember-slider-filler-color: $blue-links-primary;

$ember-slider-handle-line-height: 1.5rem;
$ember-slider-handle-height: 1.5rem;
$ember-slider-handle-width: 1.5rem;
$ember-slider-handle-border-radius: 1.5rem;
$ember-slider-handle-translate: -0.625rem;
$ember-slider-handle-cover-width: 1.25rem;
$ember-slider-handle-cover-height: 1.25rem;
$ember-slider-handle-cover-border-radius: 1.25rem;

// Layout
//---------------------------------------------

$body-color: $text-color-dark;

// Alerts
//---------------------------------------------

// Form Alert

$form-alert-background: $white;
$form-alert-border: 1px solid $accent-color-5;
$form-alert-border-radius: 0.25rem;
$form-alert-padding: 0 0 0 5rem;

$form-alert-icon-width: 5rem;
$form-alert-icon-background: $accent-color-5;
$form-alert-icon-border-radius: 0.25rem 0 0 0.25rem;

$form-alert-content-padding: 1.25rem;
$form-alert-content-font-size: 1.0625rem;
$form-alert-content-color: $accent-color-5;

$form-alert-content-ul-margin: 1rem 0 0;
$form-alert-content-ul-padding: 0 0 0 1.25rem;

// Alert Dialog

$alert-dialog-top: 9rem;
$alert-dialog-margin-left: -12.5rem;
$alert-dialog-width: 25rem;
$alert-dialog-border-radius: .25rem;
$alert-dialog-border: 1px solid $border-color;
$alert-dialog-background: $white;
$alert-dialog-box-shadow: 0 1px 6px rgba(#000000, .08);

$alert-dialog-inner-padding: 2rem 3rem 2rem 1.25rem;

$alert-dialog-heading-font-size: 1.15rem;
$alert-dialog-heading-font-weight: 600;
$alert-dialog-heading-border: 1px solid $border-color;

$alert-dialog-text-font-size: 1.0625rem;
$alert-dialog-text-font-weight: 500;

$alert-dialog-close-right: 1.25rem;
$alert-dialog-close-margin-top: -.4375rem;
$alert-dialog-close-width: .875rem;
$alert-dialog-close-height: .875rem;

$alert-dialog-lg-width: 35rem;
$alert-dialog-lg-inner-padding: 2rem 3rem 2rem 1.5rem;
$alert-dialog-lg-margin-left: -17.5rem;
$alert-dialog-lg-text-font-size: 1.125rem;

// Accordion
//---------------------------------------------

$accordion-icon-width: 1.375rem;
$accordion-icon-height: 1.375rem;
$accordion-icon-margin: -0.6875rem;

$accordion-header-margin: 0 0 1rem;

$accordion-header-title-font-size: 1.125rem;
$accordion-header-title-font-weight: 600;
$accordion-header-title-padding: 0 0 0 2rem;

$accordion-header-controls-distance: 1rem;

$accordion-list-color: $text-color-dark;

$accordion-list-item-border: 1px solid $border-color;

$accordion-list-item-header-padding: 1rem 0;
$accordion-list-item-header-min-height: 4.25rem;

$accordion-list-item-title-font-size: 1.0625rem;
$accordion-list-item-title-font-weight: 400;
$accordion-list-item-title-padding: 0 1.125rem;
$accordion-list-item-title-caret-border-width: 6px 6px 0 6px;
$accordion-list-item-title-caret-border-color: #7c8189 transparent transparent transparent;

$accordion-list-item-body-padding: 0.5rem 1.125rem 1rem;

$accordion-form-margin: 2rem 0 0;

// Collapse effect

$collapse-transition: height 0.35s ease;

// Buttons
//---------------------------------------------

// Button

// $btn-font-family: $font-family-primary;
$btn-font-size: 1.0625rem;
$btn-font-weight: 600;
$btn-background-color: $grey-primary;
$btn-border-radius: 0.25rem;
$btn-border: 1px solid $grey-primary;
$btn-padding: .75rem 1.25rem;
$btn-color: $white;
$btn-line-height: 1.325;
$btn-disabled-opacity: 0.7;

// Shapes

$btn-rounded-border-radius: 1.65rem;

// Sizes

$btn-xl-padding: 1.5rem 3rem;
$btn-xl-font-size: 1.5rem;
$btn-xl-line-height: calc(28 / 24);

$btn-lg-padding: 1.25rem 2.5rem;
$btn-lg-font-size: 1.25rem;
$btn-lg-line-height: calc(24 / 18);

$btn-md-padding: 1.125rem 2rem;
$btn-md-font-size: 1.125rem;
$btn-md-line-height: 1.25;

$btn-sm-padding: 0.6875rem 1.5rem;
$btn-sm-font-size: 1rem;
$btn-sm-line-height: 1.25;

$btn-xs-padding: 0.5rem 1.25rem;
$btn-xs-font-size: 0.875rem;
$btn-xs-line-height: 1.25;

// Types

$button-primary-background: $blue-button-primary;
$button-primary-color: $white;

$button-secondary-background: $green-secondary;
$button-secondary-color: $white;

$button-info-background: $accent-color-1;
$button-info-color: $white;

$button-light-background: $table-background;
$button-light-color: #222e43;

$button-dark-background: #044e89;
$button-dark-color: $white;

$button-default-background: $white;
$button-default-color: $text-color-dark;

$button-facebook-background: #1778f2;
$button-facebook-color: $white;

$button-alert-background: $accent-color-4;
$button-alert-color: $white;

// Social

$button-linkedin-background: #0077b7;
$button-linkedin-color: $white;
$button-likedin-border: #0077b7;
$button-linkedin-icon: 'linkedin.svg';

$button-google-background: #fdfdfd;
$button-google-color: $text-color-dark;
$button-google-border: $border-color;
$button-google-icon: 'google.svg';

$button-email-background: $table-background;
$button-email-color: $text-color-dark;
$button-email-border: $border-color;
$button-email-icon: 'email.svg';

// Button Add

$btn-add-font-size: 0.9375rem;
$btn-add-font-weight: 600;
$btn-add-color: rgba($text-color-dark, 0.7);
$btn-add-background: $white;
$btn-add-border: 2px dashed #c1c9d0;
$btn-add-border-radius: 0.5rem;
$btn-add-padding: 1.25rem 1.25rem;
$btn-add-border-color: #c1c9d0;

$btn-add-plus-margin: 0 0.5rem 0 0;

$btn-add-border-color-active: $blue-button-primary;
$btn-add-color-active: $text-color-dark;

$btn-add-font-size-sm: 0.8725rem;
$btn-add-font-weight-sm: 400;
$btn-add-border-sm: 1px dashed #c1c9d0;
$btn-add-padding-sm: 1.1875rem 1.5rem;

$btn-add-icon-padding-left: 1.5rem;
$btn-add-icon-width: 1.25rem;
$btn-add-icon-height: 1.25rem;
$btn-add-icon-margin-top: -0.625rem;

// Button Inline

$button-inline-border: 1px solid $border-color;
$button-inline-border-radius: 0.25rem;
$button-inline-background: $white;
$button-inline-height: 2rem;
$button-inline-min-width: 2.25rem;

$button-inline-label-font-size: 0.875rem;
$button-inline-label-font-weight: 600;
$button-inline-label-line-height: 1;
$button-inline-label-margin-left: 2.125rem;
$button-inline-label-padding: 0.25rem 0.75rem;
$button-inline-label-color: $blue-button-primary;

$button-inline-icon-wrapper-width: 2.25rem;
$button-inline-icon-wrapper-border-radius: 0.25rem 0 0 0.25rem;

$button-inline-icon-left: 0.5rem;
$button-inline-icon-height: 1.125rem;
$button-inline-icon-width: 1.125rem;
$button-inline-icon-margin: -0.5625rem 0 0 0;

$button-inline-background-active: $blue-button-primary;
$button-inline-background-active: $blue-button-primary;
$button-inline-label-color-active: $white;

// Button Icon

$btn-icon-add-icon-width: 1.25rem;
$btn-icon-add-icon-height: 1.25rem;
$btn-icon-add-icon-margin: 0 0.25rem 0 0;

$btn-icon-remove-icon-width: 1.25rem;
$btn-icon-remove-icon-height: 1.25rem;
$btn-icon-remove-icon-margin: 0 0.25rem 0 0;

$btn-icon-publish-icon-width: 1.25rem;
$btn-icon-publish-icon-height: 1.25rem;
$btn-icon-publish-icon-margin: 0 0.5rem 0 0;

$btn-icon-user-action-icon-width: 1.375rem;
$btn-icon-user-action-icon-height: 1.375rem;
$btn-icon-user-action-icon-margin: -0.6875rem 0 0 0;
$btn-icon-user-action-icon-left: 1rem;
$btn-icon-user-action-padding-left: 2.75rem;

$btn-icon-arrow-width: 0.5rem;
$btn-icon-arrow-height: 0.75rem;
$btn-icon-arrow-margin: 0.5rem;

// Button Dropdown

$btn-dropdown-padding: 0.375rem 2.75rem 0.375rem 1rem;
$btn-dropdown-font-size: 1.0625rem;
$btn-dropdown-font-weight: 600;
$btn-dropdown-border: 1px solid $border-color;
$btn-dropdown-border-radius: 0.25rem;
$btn-dropdown-line-height: 2.025;
$btn-dropdown-color: $text-color-dark;

$btn-dropdown-caret-right: 1rem;
$btn-dropdown-caret-margin-top: -0.25rem;
$btn-dropdown-caret-width: 0.75rem;
$btn-dropdown-caret-height: 0.5rem;

// Button Question

$btn-questions-distance: 0.375rem 0.75rem 0.375rem 0;

$btn-question-font-size: 1rem;
$btn-question-font-weight: 600;
$btn-question-line-height: 1.625;
$btn-question-color: $text-color-dark;
$btn-question-background: $white;
$btn-question-border: 1px solid $border-color;
$btn-question-border-radius: 0.25rem;
$btn-question-padding-top: 1rem;
$btn-question-padding-bottom: 1rem;
$btn-question-padding-right: 1.5rem;

$btn-question-icon-left: 1.5rem;

$btn-question-add-icon-width: 0.875rem;
$btn-question-add-icon-height: 0.875rem;
$btn-question-add-icon-margin-left: 0.5rem;

// Button Square

$btn-square-border: 1px solid $grey-primary;
$btn-square-button-radius: 0.25rem;
$btn-square-background: $grey-primary;
$btn-square-width: 3rem;
$btn-square-height: 3rem;

$btn-square-icon-size: 1.75rem;

// Button Square Sizes

$btn-square-xl-size: 4.875rem;
$btn-square-lg-size: 4.25rem;
$btn-square-md-size: 3.5rem;
$btn-square-sm-size: 2.5rem;
$btn-square-xs-size: 2.25rem;

$btn-square-xl-icon-size: 2.75rem;
$btn-square-lg-icon-size: 2.5rem;
$btn-square-md-icon-size: 2rem;
$btn-square-sm-icon-size: 1.5rem;
$btn-square-xs-icon-size: 1.25rem;

// Button Square Variants

$btn-square-light-color: $table-background;
$btn-square-dark-color: #243e63;
$btn-square-default-color: $white;

// Button Outline

$btn-outline-color: $grey-primary;
$btn-outline-padding: 0.875rem 1.4375rem;
$btn-outline-border: 2px solid $grey-primary;

$btn-outline-banner-color: $white;
$btn-outline-banner-border: 2px solid rgba(#fdfdfd, 0.5);

$btn-outline-banner-background-active: rgba(#000000, 0.15);
$btn-outline-banner-color-active: rgba(#fdfdfd, 0.65);

$btn-outline-banner-arrow-width: 1.5rem;
$btn-outline-banner-arrow-height: 0.875rem;
$btn-outline-banner-arrow-margin-left: 0.75rem;
$btn-outline-banner-arrow-border-left: 1px solid $white;

// Button Outline Sizes

$btn-outline-xl-padding: 1.5rem 3rem;
$btn-outline-xl-font-size: 1.5rem;
$btn-outline-xl-line-height: calc(28 / 24);

$btn-outline-lg-padding: 1.25rem 2.5rem;
$btn-outline-lg-font-size: 1.25rem;
$btn-outline-lg-line-height: calc(24 / 18);

$btn-outline-md-padding: 1.125rem 2rem;
$btn-outline-md-font-size: 1.125rem;
$btn-outline-md-line-height: 1.225;

$btn-outline-sm-padding: 0.6875rem 1.5rem;
$btn-outline-sm-font-size: 1rem;
$btn-outline-sm-line-height: 1.25;

$btn-outline-xs-padding: 0.25rem 1.125rem;
$btn-outline-xs-font-size: 1rem;
$btn-outline-xs-line-height: 1.5;

// Button Search
$button-search-min-height: 3.5rem;
$button-search-icon-width: 2.125rem;
$button-search-icon-height: 2.125rem;
$button-search-icon-margin: -1.0625rem 0 0 -1.0625rem;

// Cards
//---------------------------------------------

$card-wrapper-padding: 1rem 1rem;

// Card

$card-background: $white;
$card-border: 1px solid $border-color;

$card-header-padding: 1.25rem 1.25rem 0.5rem;
$card-header-title-font-size: 1.25rem;
$card-header-title-icon-padding: 0 0 0 2rem;
$card-header-title-icon-width: 1.5rem;
$card-header-title-icon-height: 1.5rem;
$card-header-title-icon-background: $blue-button-primary;
$card-header-title-font-size-sm: 1.125rem;
$card-header-controls-padding: 1rem 1.25rem 1rem;

$card-body-padding: 0.5rem 1.25rem 1.25rem;
$card-body-headerless-padding: 1.25rem 1.25rem;

$card-table-padding: 1rem 0 1rem;

$card-footer-padding: 1.25rem 1.25rem;
$card-footer-border: 1px solid $border-color;

$card-footer-control-link-width: 1.125rem;
$card-footer-control-link-height: 1.125rem;
$card-footer-control-link-distance: 0.25rem;

$card-header-title-icon-padding-large: 0 0 0 2.5rem;
$card-header-title-icon-width-large: 2rem;
$card-header-title-icon-height-large: 2rem;

$card-schedule-padding: 1.5rem 1.25rem 1.5rem;
$card-schedule-calendar-width: 20.875rem;
$card-schedule-content-padding-left: 2rem;
$card-schedule-list-margin: 1rem 0 0;
$card-schedule-list-distance: 1rem;
$card-schedule-list-font-size: 0.9375rem;
$card-schedule-list-title-color: $blue-button-primary;
$card-schedule-list-text-font-weight: 600;
$card-schedule-list-text-divider-margin: 0 0.375rem 0 0.125rem;

// Card Types

$card-light-border: 1px solid $border-color;
$card-light-background: $table-background;

$card-note-border: 5px solid #c2def4;
$card-note-border-radius: .5rem;

// Card Talentcloud

$card-talencloud-header-padding: 1.25rem 1.5rem 0.5rem;
$card-talencloud-header-title-font-size: 1.375rem;
$card-talencloud-header-title-font-weight: 600;
$card-talencloud-header-title-letter-spacing: -0.2px;
$card-talencloud-header-title-color: $blue-button-primary;
$card-talencloud-header-title-color-active: darken($blue-button-primary, 20%);

$card-talencloud-body-padding: 0.5rem 1.5rem 0.5rem;
$card-talencloud-body-title-font-size: 1.0625rem;

$card-talencloud-footer-padding: 0.5rem 1.5rem 1.5rem;

// Cards
//---------------------------------------------

$card-wrapper-padding: 1rem 1rem;

// Card

$card-background: $white;

$card-header-padding: 1.25rem 1.25rem 0.5rem;
$card-header-title-font-size: 1.25rem;
$card-header-title-icon-padding: 0 0 0 2rem;
$card-header-title-icon-width: 1.5rem;
$card-header-title-icon-height: 1.5rem;
$card-header-title-icon-background: $blue-button-primary;

$card-body-padding: 0.5rem 1.25rem 1.25rem;
$card-body-headerless-padding: 1.25rem 1.25rem;

$card-table-padding: 1rem 0 1rem;

$card-footer-padding: 1.25rem 1.25rem;
$card-footer-border: 1px solid $border-color;

$card-footer-control-link-width: 1.125rem;
$card-footer-control-link-height: 1.125rem;
$card-footer-control-link-distance: 0.25rem;

$card-header-title-icon-padding-large: 0 0 0 2.5rem;
$card-header-title-icon-width-large: 2rem;
$card-header-title-icon-height-large: 2rem;

// Card Types

$card-light-border: 1px solid $border-color;
$card-light-background: $table-background;

$card-note-border: 5px solid #c2def4;
$card-note-border-radius: 0.5rem;

// Card Talentcloud

$card-talencloud-header-padding: 1.25rem 1.5rem 0.5rem;
$card-talencloud-header-title-font-size: 1.375rem;
$card-talencloud-header-title-font-weight: 600;
$card-talencloud-header-title-letter-spacing: -0.2px;
$card-talencloud-header-title-color: $blue-button-primary;
$card-talencloud-header-title-color-active: darken($blue-button-primary, 10%);

$card-talencloud-body-padding: 0.5rem 1.5rem 0.5rem;
$card-talencloud-body-title-font-size: 1.0625rem;

$card-talencloud-footer-padding: 0.5rem 1.5rem 1.5rem;

// Card Promo

$card-promo-border-radius: 0.5rem;
$card-promo-padding: 4rem 4.5rem;

$card-promo-body-max-width: 30rem;

$card-promo-title-font-size: 1.75rem;
$card-promo-title-margin: 0 0 0.625rem;

$card-promo-text-font-size: 1.25rem;
$card-promo-text-margin: 0 0 1.25rem;

// Card Dashboard

$card-dash-background: $white;

$card-dash-header-padding: 1.875rem 1.5rem 0.375rem;
$card-dash-header-line-height: 0.375rem;
$card-dash-header-line-background: $blue-button-primary;
$card-dash-header-line-border-radius: 0.25rem 0.25rem 0 0;
$card-dash-header-title-font-size: 1.25rem;
$card-dash-header-title-font-weight: 600;

$card-dash-body-padding: 0.375rem 1.5rem 0.375rem;
$card-dash-body-text-font-size: 1.0625rem;

$card-dash-footer-padding: 0.75rem 1.5rem 1.5rem;
$card-dash-footer-link-font-size: 1.125rem;
$card-dash-footer-link-font-weight: 600;
$card-dash-footer-link-padding-right: 0.875rem;
$card-dash-footer-link-icon-width: 0.5rem;
$card-dash-footer-link-icon-height: 0.75rem;
$card-dash-footer-link-icon-top: 0.3125rem;

// Card Info

$card-info-padding: 1.5rem 1.5rem;
$card-info-border: 1px solid $border-color;
$card-info-border-radius: 0.375rem;

$card-info-header-margin: 0 0 1rem;
$card-info-header-title-font-size: 1.5rem;
$card-info-header-title-font-weight: 600;

$card-info-row-padding: 0.5rem 0;

// Card Tenant Placeholder

$card-tenant-placeholder-wrapper-padding: 1.75rem 0 0;

$card-tenant-placeholder-min-height: 15.5rem;
$card-tenant-placeholder-border: 8px solid $table-background;
$card-tenant-placeholder-border-radius: 0.375rem 0.375rem;
$card-tenant-placeholder-background: $white;
$card-tenant-placeholder-padding: 1.25rem 1.25rem;

$card-tenant-placeholder-body-distance: 1rem;

$card-tenant-placeholder-logo-width: 2.5rem;
$card-tenant-placeholder-logo-height: 2.75rem;

$card-tenant-placeholder-line-height: 1rem;
$card-tenant-placeholder-line-max-width: 25rem;
$card-tenant-placeholder-line-background: $table-background;
$card-tenant-placeholder-line-border-radius: 0.625rem;

$card-tenant-placeholder-button-height: 3.5rem;
$card-tenant-placeholder-button-max-width: 16.25rem;
$card-tenant-placeholder-button-border-radius: 0.25rem;

// Card Options

$card-option-padding: 3rem 2rem 2rem;
$card-option-background: $white;
$card-option-min-height: 20rem;
$card-option-border: 1px solid $border-color;
$card-option-border-radius: 0.5rem;

$card-option-header-icon-height: 4rem;
$card-option-header-icon-margin: 0 0 1.5rem;

$card-option-header-title-font-size: 1.5rem;
$card-option-header-title-font-weight: 700;
$card-option-header-title-color: $text-color-dark;

$card-option-body-padding: 2rem 0 0;
$card-option-stats-item-font-size: 1.125rem;
$card-option-stats-item-color: #f50505;

$card-option-socials-link-width: 3.5rem;
$card-option-socials-link-height: 3.5rem;

// Card Blocks

// Profile
$card-block-profile-padding: 0 0 0 5.75rem;
$card-block-profile-min-height: 4.75rem;
$card-block-profile-min-width: 18.75rem;

$card-block-profile-avatar-width: 4.75rem;
$card-block-profile-avatar-height: 4.75rem;
$card-block-profile-avatar-background: $icon-color-dark;
$card-block-profile-avatar-font-size: 1.5rem;
$card-block-profile-avatar-font-weight: 600;
$card-block-profile-avatar-color: $white;

$card-block-profile-name-font-size: 1.125rem;
$card-block-profile-name-font-weight: 600;
$card-block-profile-name-color: $blue-button-primary;
$card-block-profile-name-color-active: darken($blue-button-primary, 10%);
$card-block-profile-name-date-padding-right: 5rem;
$card-block-profile-name-date-font-size: .625rem;
$card-block-profile-name-date-font-weight: 600;
$card-block-profile-name-date-color: $icon-color-dark;

$card-block-profile-title-font-size: .875rem;
$card-block-profile-title-font-weight: 600;
$card-block-profile-title-margin: .125rem 0 0;
$card-block-profile-title-duration-color: $icon-color-dark;

$card-block-profile-location-font-size: .875rem;
$card-block-profile-location-margin: .25rem 0 0;
$card-block-profile-location-padding: 0 0 0 1rem;
$card-block-profile-location-color: $icon-color-dark;
$card-block-profile-location-pin-width: .75rem;
$card-block-profile-location-pin-height: 1.125rem;
$card-block-profile-location-pin-position-top: -.125rem;

$card-block-profile-source-font-size: 1rem;
$card-block-profile-source-margin: .25rem 0 0;
$card-block-profile-source-color: $icon-color-dark;

$card-block-profile-project-font-size: .875rem;
$card-block-profile-project-font-weight: 600;
$card-block-profile-project-padding-left: 1.375rem;
$card-block-profile-project-icon-width: 1rem;
$card-block-profile-project-icon-height: 1rem;
$card-block-profile-project-icon-margin: -.5rem;

$card-block-profile-message-font-size: .875rem;
$card-block-profile-message-color: $icon-color-dark;
$card-block-profile-message-padding: 0 2rem 0 0;
$card-block-profile-message-count-width: 1.25rem;
$card-block-profile-message-count-height: 1.25rem;
$card-block-profile-message-count-border-radius: .625rem;
$card-block-profile-message-count-font-size: .75rem;
$card-block-profile-message-count-font-weight: 600;
$card-block-profile-message-count-color: $white;
$card-block-profile-message-count-background: $blue-button-primary;

$card-block-profile-badges-margin: .25rem 0 0;
$card-block-profile-badges-item-distance: .375rem;
$card-block-profile-badges-tooltip-color: $text-color-dark !important;
$card-block-profile-badges-tooltip-border: 1px solid $border-color !important;
$card-block-profile-badges-tooltip-background: $white !important;
$card-block-profile-badges-tooltip-border-color: $border-color !important;
$card-block-profile-badges-tooltip-font-size: .875rem;
$card-block-profile-badges-tooltip-font-weight: 600;
$card-block-profile-badges-tooltip-text-color-primary: $blue-button-primary;

$card-block-profile-selectable-padding: 0 0 0 8rem;
$card-block-profile-selectable-min-width: 21rem;
$card-block-profile-selectable-avatar-position-left: 2.25rem;
$card-block-profile-selectable-checkbox-position-top: 0;
$card-block-profile-selectable-private-circle-left: 1.875rem;
$card-block-profile-selectable-more-left: 2.25rem;

$card-block-profile-private-circle-top: -.375rem;
$card-block-profile-private-circle-left: -.375rem;
$card-block-profile-private-circle-width: 5.5rem;
$card-block-profile-private-circle-width: 5.5rem;
$card-block-profile-private-circle-border: 3px solid $blue-button-primary;
$card-block-profile-name-private-padding: 0 0 0 1.75rem;
$card-block-profile-name-private-color: $text-color-dark;
$card-block-profile-name-private-icon-height: 1.5rem;
$card-block-profile-name-private-icon-width: 1.5rem;

$card-block-profile-more-width: 4.75rem;
$card-block-profile-more-top: 5.25rem;
$card-block-profile-more-min-height: 6.75rem;
$card-block-profile-more-link-font-size: .75rem;
$card-block-profile-more-link-font-weight: 600 !important;
$card-block-profile-more-link-color: $blue-button-primary;
$card-block-profile-more-link-background: #f4f6f7;
$card-block-profile-more-link-border: 1px solid $blue-button-primary;
$card-block-profile-more-link-border-radius: .25rem;
$card-block-profile-more-link-padding: .25rem 1.5rem .125rem .5rem;
$card-block-profile-more-link-icon-right: .5rem;
$card-block-profile-more-link-icon-margin: -.3125rem;
$card-block-profile-more-link-icon-width: .625rem;
$card-block-profile-more-link-icon-height: .625rem;
$card-block-profile-more-link-color-active: $white;
$card-block-profile-more-link-background-active: $blue-button-primary;

$card-block-profile-vertical-padding: 5.25rem 0 0;
$card-block-profile-vertical-control-width: .25rem;
$card-block-profile-vertical-control-height: 1rem;
$card-block-profile-vertical-avatar-margin-left: -2.375rem;
$card-block-profile-vertical-match-score-size: .625rem;
$card-block-profile-vertical-match-score-margin: 0 0 .25rem;
$card-block-profile-vertical-name-font-size: 1.125rem;
$card-block-profile-vertical-title-font-size: .875rem;
$card-block-profile-vertical-location-margin: .125rem 0 0;
$card-block-profile-vertical-badges-margin: .5rem 0 0;

$card-block-profile-lg-padding: .625rem 0 0 10.75rem;
$card-block-profile-lg-min-height: 9.25rem;
$card-block-profile-avatar-lg-width: 9.25rem;
$card-block-profile-avatar-lg-height: 9.25rem;
$card-block-profile-avatar-lg-font-size: 1.5rem;
$card-block-profile-name-lg-font-size: 1.375rem;
$card-block-profile-name-lg-font-weight: 700;
$card-block-profile-title-lg-font-size: 1.0625rem;
$card-block-profile-location-lg-font-size: .9375rem;

$card-block-profile-sm-padding: 0 0 0 3.75rem;
$card-block-profile-sm-min-height: 3rem;
$card-block-profile-avatar-sm-width: 3rem;
$card-block-profile-avatar-sm-height: 3rem;
$card-block-profile-avatar-sm-font-size: 1.25rem;
$card-block-profile-name-sm-font-size: 1rem;
$card-block-profile-name-sm-font-weight: 600;
$card-block-profile-title-sm-font-size: .875rem;
$card-block-profile-location-sm-font-size: .875rem;
$card-block-profile-badges-sm-margin: .5rem 0 0;

$card-profile-light-color: $white;

// Rates
$card-block-rates-list-item-font-size: 1rem;
$card-block-rates-list-item-font-weight: 600;
$card-block-rates-list-item-distance: 0.125rem;
$card-block-rates-unit-font-weight: 400;

// Info
$card-block-info-item-font-size: 1rem;
$card-block-info-item-label-font-size: 1rem;
$card-block-info-item-label-color: $icon-color-dark;
$card-block-info-availability-padding: 0 0 0 1.5rem;
$card-block-info-tracking-padding-ontrack: 0 0 0 1.5rem;
$card-block-info-tracking-padding-overdue: 0 0 0 0.75rem;
$card-block-info-bolded-font-weight: 600;
$card-block-info-item-distance: 0.25rem;

$card-block-info-lg-padding: 0.625rem 0 0;
$card-block-info-item-lg-font-size: 1.25rem;
$card-block-info-item-label-lg-font-size: 1rem;
$card-block-info-bolded-lg-font-weight: 700;

$card-block-info-item-sm-font-size: 0.875rem;
$card-block-info-item-label-sm-font-size: 0.875rem;

$card-block-info-light-color: $white;

//Work
$card-block-work-hours-font-size: 1rem;
$card-block-work-hours-font-weight: 600;
$card-block-work-hours-margin: 0 0 0.25rem;
$card-block-work-hours-unit-font-weight: 400;
$card-block-work-availability-font-size: .9375rem;
$card-block-work-availability-color: $icon-color-dark;
$card-block-work-availability-padding: 0 0 0 1.375rem;

// Paragraph
$card-block-paragraph-font-size: 1rem;
$card-block-paragraph-sm-font-size: 0.875rem;

// Heading
$card-block-heading-font-size: 1.25rem;
$card-block-heading-font-weight: 600;
$card-block-heading-color-primary: $blue-button-primary;

// Text
$card-block-text-font-size: 1rem;
$card-block-text-element-margin: 0 0 1rem;

$card-block-text-header-font-size: 1.125rem;
$card-block-text-header-font-weight: 600;
$card-block-text-header-margin: 0 0 0.5rem !important;

$card-block-text-list-ordered-padding: 0 0 0 1rem;
$card-block-text-list-spaced-distance: 0.75rem;

$card-block-text-light-color: $white;

//Stages
$card-block-stages-item-font-size: 0.5rem;
$card-block-stages-item-color: $white;
$card-block-stages-item-width: 1.75rem;
$card-block-stages-item-height: 1.375rem;
$card-block-stages-item-padding: 0 0.25rem;
$card-block-stages-item-distance: -0.125rem;

// Tracking
$card-block-tracking-date-font-size: 1rem;
$card-block-tracking-date-margin: 0 0 0.125rem;
$card-block-tracking-status-font-size: .9375rem;
$card-block-tracking-status-color: $icon-color-dark;
$card-block-tracking-status-padding-ontrack: 0 0 0 1.5rem;
$card-block-tracking-status-padding-overdue: 0 0 0 0.75rem;

// Job Role
$card-block-job-role-min-width: 15rem;
$card-block-job-role-title-font-size: 1rem;
$card-block-job-role-title-font-weight: 600;
$card-block-job-role-title-color: $blue-button-primary;
$card-block-job-role-title-color-active: darken($blue-button-primary, 10%);
$card-block-job-role-owner-font-size: 1rem;
$card-block-job-role-owner-margin: 0.125rem 0 0;
$card-block-job-role-selectable-padding: 0 0 0 2.25rem;
$card-block-job-role-title-sm-font-size: 0.9375rem;

// Controls
$card-block-controls-item-distance: 0.5rem;
$card-block-controls-select-min-width: 15rem;

// Video
$card-block-video-padding: 56.25%;

// Link
$card-block-links-link-font-size: 1.0625rem;
$card-block-links-link-font-weight: 600;
$card-block-links-link-color: $blue-button-primary;
$card-block-links-link-color-active: darken($blue-button-primary, 10%);
$card-block-links-item-distance: 0.25rem;

// Credentials
$card-block-credentials-title-font-size: 1.25rem;
$card-block-credentials-title-font-weight: 600;
$card-block-credentials-title-margin: 0 0 .5rem;
$card-block-credentials-title-icon-padding: 0 0 0 1.875rem;
$card-block-credentials-title-icon-width: 1.325rem;
$card-block-credentials-title-icon-height: 1.25rem;
$card-block-credentials-distance: 1.25rem;

$card-block-credentials-title-font-size-inline: 1.125rem;
$card-block-credentials-distance-inline: 0.5rem;

$card-block-credential-title-font-size: 1.125rem;
$card-block-credential-title-font-weight: 600;
$card-block-credential-title-margin: 0 0 0.25rem;
$card-block-credential-info-margin: 0 0 0.75rem;
$card-block-credential-info-item-font-size: 1rem;
$card-block-credential-info-item-distance: 0.25rem;
$card-block-credential-info-separation-margin-pipe: 0 0.25rem 0 0.125rem;
$card-block-credential-info-separation-margin-comma: 0 0.25rem 0 -0.1875rem;
$card-block-credential-info-separation-margin-hyphen: 0 0.25rem 0 0.125rem;
$card-block-credential-text-font-size: 1.0625rem;

$card-block-credential-title-margin-inline: 0 0.25rem 0 0;
$card-block-credential-info-item-distance-inline: 0.25rem;
$card-block-credential-info-item-color-inline: $icon-color-dark;
$card-block-credential-elements-separator-margin-inline: 0 0.1875rem 0 -0.1875rem;

// Preferences
$card-block-preferences-title-font-size: 1.0625rem;
$card-block-preferences-title-font-weight: 600;
$card-block-preferences-title-margin: 0 0 0.25rem;
$card-block-preferences-list-font-size: 1rem;
$card-block-preferences-list-distance: 0.25rem;
$card-block-preferences-list-item-pin-padding: 0 0 0 1.25rem;
$card-block-preferences-list-item-pin-width: 0.875rem;
$card-block-preferences-list-item-pin-height: 1.25rem;
$card-block-preferences-list-item-check-padding: 0 0 0 1.5rem;
$card-block-preferences-list-item-check-width: 1rem;
$card-block-preferences-list-item-check-height: 1rem;
$card-block-preferences-list-bolded-font-weight: 600;

// Tags
$card-blog-tags-title-icon-padding: 0 0 0 2rem;
$card-blog-tags-title-icon-width: 1.5rem;
$card-blog-tags-title-icon-height: 1.5rem;

// Scorecard
$card-block-scorecard-padding: 2rem 2rem;

$card-block-scorecard-circle-width: 10.75rem;
$card-block-scorecard-circle-height: 10.75rem;
$card-block-scorecard-circle-icon-width: 2.75rem;
$card-block-scorecard-circle-icon-height: 2.75rem;
$card-block-scorecard-circle-icon-margin: 0 0 0.5rem;
$card-block-scorecard-circle-label-font-size: 2.25rem;
$card-block-scorecard-circle-label-font-weight: 600;
$card-block-scorecard-circle-label-color: rgba($white, 0.8);

$card-block-scorecard-info-padding: 1rem 0 0 2.5rem;
$card-block-scorecard-title-font-size: 1.375rem;
$card-block-scorecard-text-font-size: 1.0625rem;
$card-block-scorecard-text-font-weight: 600;
$card-block-scorecard-text-margin: 0 0 1rem;
$card-block-scorecard-list-item-distance: 0.5rem;
$card-block-scorecard-list-poster-width: 3.5rem;
$card-block-scorecard-list-poster-height: 3.5rem;
$card-block-scorecard-list-poster-font-size: 1.25rem;
$card-block-scorecard-list-poster-font-weight: 600;
$card-block-scorecard-list-poster-color: $white;

// Job Header
$card-block-job-header-title-font-size: 1.5rem;
$card-block-job-header-title-font-weight: 600;
$card-block-job-header-title-margin: 0 0 0.25rem;
$card-block-job-header-title-color: $blue-button-primary;
$card-block-job-header-title-color-active: darken($blue-button-primary, 10%);

$card-block-job-header-poster-font-size: 1.0625rem;
$card-block-job-header-poster-margin: 0 0 0.375rem;

$card-block-job-header-info-font-size: 1rem;
$card-block-job-header-info-distance: 1rem;

$card-block-job-header-requirements-margin: 0.75rem 0 0;
$card-block-job-header-requirements-font-size: 1rem;
$card-block-job-header-requirements-margin-top: 0.25rem;

$card-block-job-header-bookmark-padding-right: 5.25rem;
$card-block-job-header-bookmark-right: 1rem;
$card-block-job-header-bookmark-width: 1.25rem;
$card-block-job-header-bookmark-height: 1.75rem;

$job-info-icon-location-padding: 0.125rem 0 0.125rem 1.25rem;
$job-info-icon-location-width: 0.875rem;
$job-info-icon-location-height: 1.125rem;

$job-info-icon-contract-padding: 0.125rem 0 0.125rem 1.375rem;
$job-info-icon-contract-width: 1rem;
$job-info-icon-contract-height: 1rem;

$job-info-icon-salary-padding: 0.125rem 0 0.125rem 1.375rem;
$job-info-icon-salary-width: 1rem;
$job-info-icon-salary-height: 1rem;

$job-info-icon-positions-padding: 0 0 0 1.5rem;
$job-info-icon-positions-width: 1.125rem;
$job-info-icon-positions-height: 1rem;

$job-info-icon-remote-padding: 0 0 0 1.375rem;
$job-info-icon-remote-width: 1rem;
$job-info-icon-remote-height: 0.75rem;

$card-block-job-header-padding-splited: 24rem;
$card-block-job-header-min-height-splited: 9rem;
$card-block-job-header-info-border: 1px solid $border-color;
$card-block-job-header-info-width-splited: 22.5rem;
$card-block-job-header-info-padding-splited: 0 0 0 1rem;
$card-block-job-header-info-distance-splited: 0.5rem;

// Job Status
$card-block-job-status-font-size: 1.25rem;
$card-block-job-status-font-weight: 600;
$card-block-job-status-item-distance: 0.75rem;
$card-block-job-status-mark-padding: 0 0 0 1.5rem;
$card-block-job-status-mark-left: 0.25rem;
$card-block-job-status-mark-width: 1rem;
$card-block-job-status-mark-height: 1rem;
$card-block-job-status-mark-margin: -0.5rem 0 0;

$card-block-job-status-mark-color-active: $blue-button-primary;
$card-block-job-status-mark-color-draft: #deb250;
$card-block-job-status-mark-color-in-progress: #78c493;
$card-block-job-status-mark-color-closed: #de5e6a;

// Job Owner
$card-block-job-owner-padding: 0 0 0 5.25rem;
$card-block-job-owner-min-height: 4.5rem;

$card-block-job-owner-logo-width: 4.5rem;
$card-block-job-owner-logo-height: 4.5rem;

$card-block-job-owner-text-font-size: 1.0625rem;
$card-block-job-owner-title-font-weight: 600;

// Workflow Heading
$card-block-workflow-header-title-font-size: 1.0625rem;
$card-block-workflow-header-title-font-weight: 600;
$card-block-workflow-header-title-margin: 0 0 0.25rem;
$card-block-workflow-header-text-font-size: 1rem;
$card-block-workflow-header-text-color: $icon-color-dark;

// User Name
$card-block-user-name-min-width: 15rem;
$card-block-user-name-title-font-size: 1.125rem;
$card-block-user-name-title-font-weight: 600;
$card-block-user-name-title-color: $blue-button-primary;
$card-block-user-name-title-color-active: darken($blue-button-primary, 10%);
$card-block-user-name-selectable-padding: 0 0 0 2.25rem;

// Data
$card-block-data-title-font-size: 1.25rem;
$card-block-data-title-font-weight: 600;
$card-block-data-title-margin: 0 0 1rem;

$card-block-data-list-font-size: 1.0625rem;
$card-block-data-list-line-height: 1.25;
$card-block-data-list-title-font-weight: 600;
$card-block-data-list-title-margin: 0 0 0.25rem;

$card-block-data-list-numbered-padding: 1.25rem;

$card-block-data-list-distance: 0.75rem;

// Licence Plan
$card-block-licence-plan-title-font-size: 1.325rem;
$card-block-licence-plan-title-font-weight: 400;
$card-block-licence-plan-title-font-weight-span: 600;
$card-block-licence-plan-title-margin: 0 0 0.5rem;
$card-block-licence-plan-list-font-size: 1.0625rem;
$card-block-licence-plan-list-line-height: 1.375;

// Tenant
$card-block-tenant-min-height: 3rem;
$card-block-tenant-padding: 0 0 0 4rem;

$card-block-tenant-initials-width: 3rem;
$card-block-tenant-initials-height: 3rem;
$card-block-tenant-initials-background: $white;
$card-block-tenant-initials-border: 2px solid $grey-primary;
$card-block-tenant-initials-border-radius: 0.375rem;
$card-block-tenant-initials-font-size: 1.125rem;
$card-block-tenant-initials-font-weight: 600;
$card-block-tenant-initials-color: #707070;

$card-block-tenant-title-font-size: 1.375rem;
$card-block-tenant-title-font-weight: 600;
$card-block-tenant-title-margin: 0;

$card-block-tenant-id-font-size: 0.875rem;
$card-block-tenant-id-border-radius: 0.375rem;
$card-block-tenant-id-background: $table-background;
$card-block-tenant-id-padding: 0.1875rem 0.375rem;

// Presentation
$card-block-presentation-list-item-distance: 2rem;
$card-block-presentation-list-item-font-size: 1rem;
$card-block-presentation-list-item-font-weight: 600;
$card-block-presentation-list-item-color: $blue-button-primary;

$card-block-presentation-rating-item-width: 1.25rem;
$card-block-presentation-rating-item-height: 1.25rem;

$card-block-presentation-sublist-link-width: 1.625rem;
$card-block-presentation-sublist-link-height: 1.625rem;

$card-block-presentation-sublist-item-distance: 0.25rem;

// Chat
$card-block-chat-margin: 0 0 1rem;

$card-block-chat-post-padding: 1rem 12.5rem 1rem 5rem;
$card-block-chat-post-border-radius: .25rem;
$card-block-chat-post-background-color: $table-background;
$card-block-chat-post-min-height: 6rem;

$card-block-chat-post-distance: 1rem;

$card-block-chat-post-avatar-top: 1rem;
$card-block-chat-post-avatar-left: 1rem;
$card-block-chat-post-avatar-width: 3rem;
$card-block-chat-post-avatar-height: 3rem;

$card-block-chat-post-title-font-size: 1.0625rem;
$card-block-chat-post-title-margin: 0 0 .25rem;

$card-block-chat-post-date-top: 1rem;
$card-block-chat-post-date-right: 1rem;
$card-block-chat-post-date-font-size: .875rem;

$card-block-chat-post-controls-top: 2.5rem;
$card-block-chat-post-controls-right: 1rem;

// Select
$card-block-select-label-padding-right: 1rem;
$card-block-select-label-caret-width: .75rem;
$card-block-select-label-caret-height: .5rem;
$card-block-select-label-caret-margin-top: -.25rem;

// Card Roster

$card-roster-border: 1px solid $border-color;
$card-roster-padding: 1rem 1.25rem;
$card-roster-distance: 1.5rem;
$card-roster-second-element-width: 25rem;

// Dropdowns
//---------------------------------------------

$dropdown-menu-min-width: 12.5rem;
$dropdown-menu-padding: 0.5rem 0;
$dropdown-menu-box-shadow: 0 1px 3px rgba($grey-primary, 0.5);
$dropdown-menu-border-radius: 0.25rem;
$dropdown-menu-margin: 0.25rem 0 0;

$dropdown-menu-title-padding: 0.5rem 1.5rem 0.5rem 1rem;
$dropdown-menu-title-font-size: 1rem;
$dropdown-menu-title-font-weight: 600;

$dropdown-menu-link-color: $blue-button-primary;
$dropdown-menu-link-padding: 0.5rem 1.5rem 0.5rem 1rem;
$dropdown-menu-link-font-size: 0.9375rem;
$dropdown-menu-link-font-weight: 600;
$dropdown-menu-link-background-hover: $table-background;
$dropdown-menu-link-color-hover: $blue-button-primary;
$dropdown-menu-link-background-active: $blue-button-primary;
$dropdown-menu-link-color-active: $white;
$dropdown-menu-link-color-disabled: $text-color-placeholders;
$dropdown-menu-link-background-disabled: $white;

$dropdown-menu-form-padding: 1.5rem 1.5rem;

$dropdown-menu-link-tabel-color: $text-color-dark;
$dropdown-menu-link-tabel-font-size: 0.875rem;

$dropdown-menu-arrow-margin-top: 0.5rem;
$dropdown-menu-arrow-top: -0.5rem;
$dropdown-menu-arrow-border: 8px solid transparent;
$dropdown-menu-arrow-border: 8px solid transparent;
$dropdown-menu-arrow-border-bottom: 8px solid $white;
$dropdown-menu-arrow-width: 0.0625rem;
$dropdown-menu-arrow-margin-left: -0.5rem;

// Status Group

$status-group-border: 1px solid $border-color;
$status-group-border-radius: 0.25rem;
$status-group-background: $white;

$status-group-label-font-size: 1.0625rem;
$status-group-label-font-weight: 600;
$status-group-label-padding: .75rem 1.5rem .75rem 2.5rem;
$status-group-label-text-color: $green-secondary;

$status-group-trigger-width: 2.5rem;

// Filters
//---------------------------------------------

// Filter Collapse

$filter-collapse-padding: 1rem 1rem 2rem 0;
$filter-collapse-top: 2rem;
$filter-collapse-left: 2rem;

$filter-collapse-link-font-size: 1.125rem;
$filter-collapse-link-font-weight: 600;
$filter-collapse-link-border: 2px solid $border-color;
$filter-collapse-link-border-radius: 0.25rem;
$filter-collapse-link-padding: 0.75rem 4rem 0.75rem 2.875rem;
$filter-collapse-link-color: $text-color-dark;
$filter-collapse-link-width: 15rem;
$filter-collapse-link-height: 3.5rem;
$filter-collapse-link-background: $white;

$filter-collapse-link-before-left: 1rem;
$filter-collapse-link-before-margin: -0.625rem;
$filter-collapse-link-before-width: 1.375rem;
$filter-collapse-link-before-height: 1.25rem;

$filter-collapse-link-after-right: -0.125rem;
$filter-collapse-link-after-top: -0.125rem;
$filter-collapse-link-after-bottom: -0.125rem;
$filter-collapse-link-after-width: 3rem;
$filter-collapse-link-after-background: $green-secondary;
$filter-collapse-link-after-border-radius: 0 0.25rem 0.25rem 0;

// Filter Category

$filter-category-padding: 0 1rem;
$filter-category-border: 1px solid $border-color;
$filter-category-margin-top: 1.5rem;

$filter-category-header-padding: 1.5rem 2rem 1.5rem 0;
$filter-category-header-title-font-size: 1.125rem;
$filter-category-header-title-font-weight: 700;

// Filter Group

$filter-group-border: 1px solid $border-color;

$filter-group-header-padding: 1.125rem 2rem .875rem 0;

$filter-group-header-arrow-width: 1rem;
$filter-group-header-arrow-height: 1rem;
$filter-group-header-arrow-right: 0;
$filter-group-header-margin: -0.5rem;

$filter-group-title-font-size: .9375rem;
$filter-group-title-font-weight: 600;

$filter-group-body-padding: 0 0 1.25rem;

// Filter Block

$filter-block-border: 3px solid $border-color;

$filter-block-header-padding: 1.25rem 1.5rem;
$filter-block-header-title-font-size: 1.0625rem;
$filter-block-header-title-font-weight: 600;
$filter-block-header-title-color: $text-color-dark;
$filter-block-header-link-padding-left: 1.25rem;
$filter-block-header-link-caret-width: 0.75rem;
$filter-block-header-link-caret-height: 0.5rem;
$filter-block-header-link-caret-margin-top: -0.25rem;

$filter-block-locked-header-title-padding-right: 1.5rem;
$filter-block-locked-header-title-icon-width: 1.25rem;
$filter-block-locked-header-title-icon-height: 1.5rem;
$filter-block-locked-header-title-icon-margin-top: -0.75rem;

$filter-block-body-padding: 0 0.5rem 1rem;
$filter-block-body-min-height: 10.875rem;

$filter-block-locked-background: rgba($white, 0.6);
$filter-block-locked-inner-padding: 1rem 1.5rem 2rem;

$filter-block-locked-box-padding: 1rem 1rem;
$filter-block-locked-box-border: 2px solid $border-color;
$filter-block-locked-box-border-radius: 0.375rem;
$filter-block-locked-box-background: $body-background;
$filter-block-locked-box-shadow: 0 3px 6px rgba(#000000, 0.16);

$filter-block-locked-title-font-size: 1.0625rem;
$filter-block-locked-title-font-weight: 600;
$filter-block-locked-title-color: $green-secondary;
$filter-block-locked-title-margin: 0 0 0.25rem;
$filter-block-locked-title-padding-left: 1.75rem;
$filter-block-locked-title-icon-margin-top: -0.6875rem;
$filter-block-locked-title-icon-width: 1.375rem;
$filter-block-locked-title-icon-height: 1.375rem;

$filter-block-locked-text-font-size: 1.0625rem;

$filter-block-locked-link-font-weight: 600;

// Collapse

$filters-collapse-height: 2rem;
$filters-collapse-width: 6rem;

$filters-collapse-icon-background: $blue-links-secondary;
$filters-collapse-link-background: $green-primary;

// Forms
//---------------------------------------------

// Fieldset

$fieldset-label-margin-required: -0.25rem;

$fieldset-lable-font-weight-optional: 400;
$fieldset-label-margin-optional: 0.25rem;

$fieldset-has-tip-label-padding: 0 2.75rem 0 0;

$fieldset-has-recommendation-label-padding: 0 11.25rem 0 0;
$fieldset-has-tip-and-recommendation-label-padding: 0 14.25rem 0 0 !important;
$fieldset-has-tip-and-recommendation-right: 3rem !important;

$fieldset-note-font-size: .9375rem;
$fieldset-note-color: $text-color-placeholders;
$fieldset-note-margin: 0.5rem 0 0;

$fieldset-suggestions-margin: 0.75rem 0 0;
$fieldset-suggestions-title-font-size: 0.9375rem;
$fieldset-suggestions-title-font-weight: 600;
$fieldset-suggestions-title-color: $blue-button-primary;
$fieldset-suggestions-title-margin: 0 0 0.125rem;

$fieldset-suggestions-list-item-margin: 0.125rem 0.25rem 0.125rem 0;
$fieldset-suggestions-list-link-font-size: 0.8125rem;
$fieldset-suggestions-list-link-padding: 0.125rem 0.25rem 0.125rem 1.125rem;
$fieldset-suggestions-list-link-icon-left: 0.25rem;
$fieldset-suggestions-list-link-icon-width: 0.625rem;
$fieldset-suggestions-list-link-icon-height: 0.625rem;
$fieldset-suggestions-list-link-icon-margin: -0.3125rem 0 0;
$fieldset-suggestions-list-link-color-added: $white;
$fieldset-suggestions-list-link-background-added: $blue-button-primary;
$fieldset-suggestions-list-link-border-radius: 0.25rem;

$fieldset-suggestions-list-link-padding-boxed: 0.375rem 0.75rem 0.375rem 1.5rem;
$fieldset-suggestions-list-link-border-boxed: 1px solid $blue-button-primary;
$fieldset-suggestions-list-link-border-radius-boxed: 0.25rem;
$fieldset-suggestions-list-link-background-boxed: $white;
$fieldset-suggestions-list-item-margin-boxed: 0.1875rem 0.375rem 0.1875rem 0;
$fieldset-suggestions-list-link-icon-left-boxed: 0.5rem;

$fieldset-suggestions-secondary-color: $green-secondary;

$fieldset-subgroup-margin: 1rem 0 0;
$fieldset-subgroup-padding: 0 0 0 1.5rem;
$fieldset-subgroup-caret-top: 0.25rem;
$fieldset-subgroup-caret-width: 0.75rem;
$fieldset-subgroup-caret-height: 0.5rem;
$fieldset-subgroup-title-font-size: 1rem;
$fieldset-subgroup-title-font-weight: 400;
$fieldset-subgroup-header-margin: 0 0 1rem;

// Label

$fieldset-label-font-size: 1rem;
$fieldset-lable-font-weight: 600;
$fieldset-label-margin: 0.375rem;

// Tip

$form-tip-font-size: 0.875rem;
$form-tip-font-weight: 700;
$form-tip-color: $text-color-light;
$form-tip-background: $green-primary;
$form-tip-padding: 0.1875rem 0.375rem;
$form-tip-border-radius: 0.1875rem;

$form-tip-tooltip-color: $text-color-dark;
$form-tip-tooltip-border: 1px solid $border-color;
$form-tip-tooltip-max-width: 20rem;
$form-tip-tooltip-border-color: $border-color;

// Recommendations

$form-recommendations-font-size: 0.75rem;
$form-recommendations-font-weight: 600;
$form-recommendations-padding: 0 0 0 1.25rem;
$form-recommendations-line-height: 1.5;
$form-recommendations-top: 0.25rem;

$form-recommendations-tooltip-width: 1rem;
$form-recommendations-tooltip-height: 1rem;
$form-recommendations-tooltip-margin-right: 0.25rem;

// Input Group

$input-group-label-margin-required: 0.125rem;

$input-group-lable-font-weight-optional: 400;
$input-group-label-margin-optional: 0.25rem;

$input-group-add-on-font-size: 1.0625rem;
$input-grou-add-on-background: $white;
$input-grou-add-on-border: 1px solid $border-color;
$input-grou-add-on-padding: 0.5rem 1.25rem;
$input-group-add-on-border-radius-first: 0.25rem 0 0 0.25rem !important;
$input-group-add-on-border-radius-last: 0 0.25rem 0.25rem 0 !important;

$input-group-range-divider-font-size: 1.0625rem;
$input-group-range-divider-font-weight: 600;
$input-group-range-divider-padding: 0.5rem 0.75rem;
$input-group-range-distance: 1rem;

$input-group-from-padding-left: 3.125rem;
$input-group-from-font-size: 1.0625rem;
$input-group-from-font-weight: 600;

// Input

$input-group-field-font-size: 1rem;
$input-group-field-line-height: 1.375;
$input-group-field-padding: 1rem 1.125rem;
$input-group-field-border: 1px solid $border-color;
$input-group-field-border-radius: 0.25rem;
$input-group-field-focus-border: $blue-button-primary;
$input-group-field-focus-box-shadow: 0 0 0 2px rgba($blue-button-secondary, 0.5);
$input-group-field-background-color: $white;

$input-group-field-padding-xl: 1.5rem 1.25rem;
$input-group-field-line-height-xl: 1.5;
$input-group-field-font-size-xl: 1.25rem;

$input-group-field-padding-lg: 1.25rem 1.5rem;
$input-group-field-line-height-lg: 1.375;
$input-group-field-font-size-lg: 1rem;

$input-group-field-padding-md: 1.125rem 1.5rem;
$input-group-field-line-height-md: 1.375;
$input-group-field-font-size-md: 1rem;

$input-group-field-padding-sm: 0.75rem 1rem;
$input-group-field-line-height-sm: 1.125;
$input-group-field-font-size-sm: 1rem;

$input-group-field-padding-xs: 0.5rem 0.75rem;
$input-group-field-line-height-xs: 1.125;
$input-group-field-font-size-xs: 1rem;

// Checkbbox

$checkbox-group-label-font-size: 1rem;
$checkbox-group-label-font-weight: 400;

$checkbox-group-field-width: 1.25rem;
$checkbox-group-field-height: 1.25rem;
$checkbox-group-field-border: 1px solid $border-color;
$checkbox-group-field-border-radius: 0.25rem;
$checkbox-group-field-background: $white;
$checkbox-group-field-margin: 0 0.5rem 0 0;
$checkbox-group-field-border-checked: 1px solid $blue-button-primary;
$checkbox-group-field-box-shadow-checked: 0 0 0 2px rgba($blue-button-secondary, 0.5);
$checkbox-group-field-background-size: 0.625rem 0.5rem;

// Radio

$radio-group-label-font-size: 1.0625rem;
$radio-group-label-font-weight: 400;

$radio-group-field-width: 1.25rem;
$radio-group-field-height: 1.25rem;
$radio-group-field-border: 2px solid $border-color;
$radio-group-field-border-radius: 50%;
$radio-group-field-background: $white;
$radio-group-field-margin: 0 0.5rem 0 0;
$radio-group-field-left-checked: 0.25rem;
$radio-group-field-border-checked: 2px solid $blue-button-primary;
$radio-group-field-margin-checked: -0.375rem 0 0;
$radio-group-field-width-checked: 0.75rem;
$radio-group-field-height-checked: 0.75rem;
$radio-group-field-background-checked: $blue-button-primary;

// Select

$select-group-label-margin: 0.5rem;
$select-group-label-font-size: 1.0625rem;
$select-group-lable-font-weight: 600;

$margin-group-label-margin-required: 0.125rem;

$multiselect-collaborators-initials-font-weight: 600;
$multiselect-collaborators-initials-color: $white;
$multiselect-collaborators-initials-background: $blue-button-primary;

$multiselect-collaborators-selected-padding-left: 2.125rem !important;
$multiselect-collaborators-selected-initials-left: 0.5rem;
$multiselect-collaborators-selected-initials-margin-top: -0.625rem;
$multiselect-collaborators-selected-initials-font-size: 0.5rem;
$multiselect-collaborators-selected-initials-width: 1.25rem;
$multiselect-collaborators-selected-initials-height: 1.25rem;

$multiselect-collaborators-option-padding-left: 4.5rem !important;
$multiselect-collaborators-option-line-height: 1.25;
$multiselect-collaborators-option-name-font-weight: 600;
$multiselect-collaborators-option-initials-font-size: 1.25rem;
$multiselect-collaborators-option-initials-width: 2.5rem;
$multiselect-collaborators-option-initials-height: 2.5rem;
$multiselect-collaborators-option-initials-left: 1.125rem;
$multiselect-collaborators-option-initials-margin-top: -1.25rem;

$multiselect-collaborators-option-initials-selected-color: $blue-button-primary;
$multiselect-collaborators-option-initials-selected-background: $white;

$select-group-field-padding-md: 1.125rem 2rem 1.125rem 0.75rem;
$select-group-field-font-size-md: 1rem;

$select-group-field-padding-sm: 1rem 2rem 1rem 0.625rem;
$select-group-field-font-size-sm: 1rem;

$select-group-field-padding-xs: 0.375rem 2rem 0.375rem 0.625rem;

$select-default-font-size: 1rem;

$select-min-width-xs: 7.2rem;

$input-default-font-size: 1rem;

// Datepicker

$datepicker-group-label-min-height: 1.25rem;

$datepicker-group-distance: 2rem;

$datepicker-group-field-background-position: right 1rem center;
$datepicker-group-field-border-color: $border-color;
$datepicker-group-field-padding-right: 2rem;
$datepicker-group-field-border-radius-open-below: 0.25rem 0.25rem 0.25rem 0;
$datepicker-group-field-border-radius-open-above: 0 0.25rem 0.25rem 0.25rem;

$datepicker-group-select-border-radius-first: 0.25rem 0 0 0.25rem;
$datepicker-group-select-border-radius-last: 0 0.25rem 0.25rem 0;
$datepicker-group-select-item-width: 0 0 6.75rem;

$datepicker-group-checkbox-margin: 1rem 0 0;

// Form Group

$form-group-border-radius-first: 0.25rem 0 0 0.25rem;
$form-group-border-radius-last: 0 0.25rem 0.25rem 0;
$form-group-border-color-first: $border-color;
$form-group-border-color-last: $border-color;
$form-group-select-trigger-min-width: 17.5rem;

$form-group-date-select-width: 0 0 6.75rem;

$form-group-dismissable-field-padding-right: 2.5rem;
$form-group-dismissable-button-position-right: 1rem;
$form-group-dismissable-button-margin: -0.375rem 0 0;
$form-group-dismissable-button-width: 0.75rem;
$form-group-dismissable-button-height: 0.75rem;
$form-group-dismissable-button-opacity: 0.75;
$form-group-dismissable-button-opacity-active: 1;

$form-group-separated-distance: 1rem;
$form-group-separated-select-min-width: 15rem;

// Form Add

$form-add-elements-distance: 1rem;

// File Group

$file-group-uploaded-list-item-border: 1px solid $border-color;
$file-group-uploaded-list-item-font-size: 1rem;
$file-group-uploaded-list-item-font-weight: 600;

$file-group-dropzone-border: 2px dashed $blue-button-primary;
$file-group-dropzone-border-radius: 0.5rem;
$file-group-dropzone-background: $white;
$file-group-dropzone-min-height: 8rem;
$file-group-dropzone-padding: 1.5rem 1.5rem;

$file-group-file-margin: 0 0 0.75rem;
$file-group-file-empty-width: 2.25rem;
$file-group-file-empty-height: 1.5rem;
$file-group-file-title-font-size: 1.0675rem;
$file-group-file-title-font-weight: 600;
$file-group-file-title-line-height: 1.411764706;
$file-group-file-title-padding: 0 1.75rem 0 0;
$file-group-file-title-icon-margin: -0.5rem 0 0;
$file-group-file-title-icon-width: 1rem;
$file-group-file-title-icon-height: 1rem;

$file-group-file-avatar-empty-width: 6.375rem;
$file-group-file-avatar-empty-height: 6.375rem;

$file-group-text-font-size: 1.0625rem;
$file-group-text-color: $text-color-placeholders;

$file-group-link-label-font-weight: 400;
$file-group-link-label-color: $blue-button-primary;
$file-group-link-label-color-active: darken($blue-button-primary, 20%);
$file-group-link-label-margin: 0 0.25rem;

$file-group-uploading-label-font-size: 1.125rem;
$file-group-uploading-label-font-weight: 600;
$file-group-uploading-label-margin: 0 0 1rem;
$file-group-uploading-label-color: $text-color-placeholders;

$file-group-dropzone-for-avatar-min-height: 11.625rem;

// Form Tabs

$form-tabs-list-margin: 0 0 -2px;
$form-tabs-list-item-border: 2px solid $border-color;
$form-tabs-list-item-background: $table-background;
$form-tabs-list-item-padding: 1rem 3rem;
$form-tabs-list-item-font-size: 1.125rem;
$form-tabs-list-item-font-weight: 600;
$form-tabs-list-item-line-height: 1.15;
$form-tabs-list-item-color: $blue-button-primary;
$form-tabs-list-item-color-active: $text-color-dark;
$form-tabs-list-item-border-between: 1px solid $border-color;
$form-tabs-list-add-padding: 0.75rem 1.5rem;
$form-tabs-list-add-font-size: 1.5rem;
$form-tabs-list-add-color: $text-color-dark;

$form-tabs-panel-border: 2px solid $border-color;
$form-tabs-panel-border-radius: 0 0.25rem 0.25rem 0.25rem;
$form-tabs-panel-padding: 1rem 0.5rem;
$form-tabs-panel-background: #f9f9f9;
$form-tabs-panel-min-height: 5rem;

// Form Fieldset

$form-fieldset-panel-border: 2px solid $border-color;
$form-fieldset-panel-border-radius: 0.25rem;
$form-fieldset-panel-padding: 1rem 0.5rem;
$form-fieldset-panel-background: #f9f9f9;

// Country Select

$country-select-flag-width: 1.5rem;
$country-select-flag-margin: 0.5rem;

// Modal Group

$modal-group-background: $white;
$modal-group-border: 1px solid $border-color;
$modal-group-border-radius: 0.25rem;
$modal-group-padding: 1rem 1rem;
$modal-group-box-shadow: 0 1px 3px rgba($grey-primary, 0.15);

$modal-group-panel-controls-min-width: 10rem;
$modal-group-panel-controls-distance: 1rem;

// Switch

$form-switch-width: 3.5rem;
$form-switch-height: 2rem;

$form-switch-slider-background: $border-color;
$form-switch-slider-border-radius: 1rem;
$form-switch-slider-transition: 0.4s;
$form-switch-toggle-height: 1.5rem;
$form-switch-toggle-width: 1.5rem;
$form-switch-toggle-left: 0.25rem;
$form-switch-toggle-bottom: 0.25rem;
$form-switch-toggle-background: $white;
$form-switch-toggle-background-size: 1rem auto;
$form-switch-toggle-transition: left 0.2s ease;
$form-switch-toggle-box-shadow: 0px 3px 6px #00000029;
$form-switch-slider-background-active: $green-primary;
$form-switch-toggle-translate: 1.75rem;

$form-switch-group-label-font-size: 1rem;
$form-switch-group-label-margin: 0 0 0 1rem;

$form-switch-group-opposite-label-margin: 0 1rem 0 0;

$form-switch-group-as-row-padding: 0.75rem 0;
$form-switch-group-as-row-border: 1px solid $border-color;
$form-switch-group-as-row-label-margin: 0 1rem 0 0;

$form-switch-width-sm: 2.5rem;
$form-switch-height-sm: 1.5rem;
$form-switch-slider-border-radius-sm: .75rem;
$form-switch-toggle-height-sm: 1.125rem;
$form-switch-toggle-width-sm: 1.125rem;
$form-switch-toggle-left-sm: .1875rem;
$form-switch-toggle-translate-sm: 1.1875rem;

// Slider

$slider-group-elements-distance: 0.5rem;
$slider-group-input-width: 5rem;

// Form Item

$form-item-list-font-size: 1.0675rem;
$form-item-list-title-font-weight: 600;
$form-item-list-title-inline-margin-left: 0.5rem;

// Colorpicker

$colorpicker-elements-border-radius: 0.25rem;
$colorpicker-elements-focus-box-shadow: 0 0 0 2px rgba($blue-button-secondary, 0.5);
$colorpicker-elements-focus-border-color: $blue-button-primary;

$colorpicker-field-width: 3.625rem;
$colorpicker-field-height: 3.5rem;
$colorpicker-field-background: $white;
$colorpicker-field-color: $green-secondary;
$colorpicker-field-border-width: 1px;
$colorpicker-field-border-color: $border-color;
$colorpicker-field-padding: 0.25rem 0.375rem;
$colorpicker-field-border-radius: 0.25rem 0 0 0.25rem;

// Form Controls

$form-controls-button-min-width: 7.5rem;
$form-controls-distance: 1rem;

// Errors

$form-element-error-border: 1px solid $accent-color-4  !important;
$form-element-error-box-shadow: 0 0 0 2px rgba($accent-color-4, 0.25) !important;

// Form Collaborators

$form-collaborators-list-item-width: 3.5rem;
$form-collaborators-list-item-height: 3.5rem;

$form-collaborators-new-background: #b2b6c1;
$form-collaborators-new-background-size: 1.5rem 1.5rem;

$form-collaborators-user-font-size: 1.5rem;
$form-collaborators-user-font-weight: 600;
$form-collaborators-user-color: $white;
$form-collaborators-user-background-even: $green-primary;
$form-collaborators-user-background-odd: $blue-button-primary;
$form-collaborators-user-remove-width: 1.125rem;
$form-collaborators-user-remove-height: 1.125rem;
$form-collaborators-user-remove-margin-top: -0.625rem;
$form-collaborators-user-remove-right: 0.75rem;

$form-collaborators-users-background: #b2b6c1;
$form-collaborators-users-background-size: 1rem auto;
$form-collaborators-users-background-position: 0.875rem center;
$form-collaborators-users-count-right: 0.75rem;
$form-collaborators-users-count-font-size: 1.125rem;
$form-collaborators-users-count-font-weight: 600;
$form-collaborators-users-count-color: $white  !important;
$form-collaborators-users-menu-width: 15rem;
$form-collaborators-users-menu-item-padding: 0.75rem 2.125rem 0.75rem 1rem;
$form-collaborators-users-menu-item-font-size: 1rem;
$form-collaborators-users-menu-item-font-weight: 600;

$form-collaborators-list-distance: 0.5rem;

// Filters

$filter-inline-max-width: 75rem;
$filter-inline-box-shadow: 0 1px 6px rgba(#000000, .08);
$filter-inline-button-min-width: 10rem;

$filter-options-distance: .75rem;
$filter-options-select-min-width: 15rem;
$filter-options-dropdown-min-width: 12.5rem;

// Form Links

$form-links-label-margin: 0;
$form-links-label-font-size: 1.0625rem;
$form-links-label-font-weight: 600;
$form-links-label-padding: 0 5rem 0 0;
$form-links-label-min-height: 2.25rem;

$form-link-items-distance: 1rem;

// Placeholder

$placeholder-color: $text-color-placeholders;

// Lists
//---------------------------------------------

// List checkbox Option

$list-checkbox-options-item-padding: 0.625rem 5rem 0.375rem 0;
$list-checkbox-options-controls-top: 0.25rem;

// List Sortable

$list-sortable-border: 1px solid $border-color;
$list-sortable-border-radius: 0.25rem;
$list-sortable-margin: 0 0 1rem;

$list-sortable-item-inner-padding: 0.75rem 1.25rem 0.75rem 1.75rem;
$list-sortable-item-inner-background: $white;

$list-sortable-item-title-font-size: 1rem;
$list-sortable-item-title-font-weight: 400;
$list-sortable-item-title-margin: 0 1.5rem 0 0;

$list-sortable-item-handler-left: 0.75rem;
$list-sortable-item-handler-top: 50%;
$list-sortable-item-handler-height: 1.5rem;
$list-sortable-item-handler-width: 0.3125rem;
$list-sortable-item-handler-margin: -0.75rem 0 0;

$list-sortable-item-border-radius-first: 0.25rem 0.25rem 0 0;
$list-sortable-item-border-radius-last: 0 0 0.25rem 0.25rem;

$list-sortable-form-padding: 0 1.25rem 1.25rem 1.75rem;
$list-sortable-form-background: $white;
$list-sortable-form-controls-margin: 1rem 0 0;
$list-sortable-form-controls-min-width: 6.25rem;
$list-sortable-form-controls-distance: 1rem;

// Collaborators

$collaborators-item-element-width: 2.375rem;
$collaborators-item-element-height: 2.375rem;
$collaborators-item-element-border: 1px solid $white;

$collaborators-item-trigger-background: $grey-primary;
$collaborators-item-trigger-color: $white;
$collaborators-item-trigger-font-weight: 600;
$collaborators-item-trigger-font-size: 0.875rem;
$collaborators-item-other-font-size: 0.75rem;
$collaborators-item-other-left: 40%;
$collaborators-item-other-padding: 0.625rem 0 0 1.3125rem;
$collaborators-item-new-background: #a1a5ad;
$collaborators-item-new-left: 60%;
$collaborators-item-distance: -0.375rem;

// Page Steps

$page-steps-item-padding: 0 1rem;
$page-steps-item-border-right: 1px solid $border-color;

$page-steps-link-font-size: 1.125rem;
$page-steps-link-font-weight: 600;
$page-steps-link-padding: 0.75rem 1rem;
$page-steps-link-color: $text-color-dark;
$page-steps-link-border-radius: 0.25rem;
$page-steps-link-min-width: 25rem;
$page-steps-link-line-height: 1.25;

$page-steps-link-background-active: #eef1f4;

$page-steps-link-number-margin: 0.5rem;

// Page Sidenav

$page-sidenav-item-distance: 1rem;

$page-sidenav-link-font-size: 1rem;
$page-sidenav-link-font-weight: 600;
$page-sidenav-link-color: $text-color-dark;
$page-sidenav-link-padding: .5rem 2rem .5rem 4rem;
$page-sidenav-link-height: 2.5rem;

$page-sidenav-link-icon-left: 2rem;
$page-sidenav-link-icon-width: 1.5rem;
$page-sidenav-link-icon-height: 1.5rem;
$page-sidenav-link-icon-margin-top: -.75rem;
$page-sidenav-link-icon-background: $blue-button-primary;

$page-sidenav-link-background-active: $table-background;
$page-sidenav-link-icon-background-active: $green-primary;

// List Assets

$list-assets-title-font-size: 1.25rem;
$list-assets-title-font-weight: 600;
$list-assets-title-margin: 0 0 0.75rem;

$list-assets-item-padding: 0.75rem 0;
$list-assets-item-border: 1px solid $border-color;
$list-assets-item-title-padding: 0 1rem 0 0;
$list-assets-item-title-font-size: 1.0625rem;
$list-assets-item-title-font-weight: 600;

// Board Postings

$board-posting-border: 1px solid $border-color;

$board-posting-item-padding: 1.5rem 1.5rem;
$board-posting-item-distance: 1.5rem;
$board-posting-item-background: $white;

$board-posting-item-logo-width: 2.5rem;
$board-posting-item-logo-height: 2.5rem;

$board-posting-item-title-width: 12.5rem;
$board-posting-item-title-h4-font-size: 1.125rem;
$board-posting-item-title-h4-margin-bottom: 0;
$board-posting-item-title-link-font-size: .875rem;

$board-posting-item-date-width: 15rem;

$board-posting-item-status-width: 8.75rem;
$board-posting-item-status-font-size: 1.0625rem;
$board-posting-item-status-font-weight: 600;
$board-posting-item-status-padding: 0 0 0 1.5rem;
$board-posting-item-status-dot-width: 1.125rem;
$board-posting-item-status-dot-height: 1.125rem;
$board-posting-item-status-dot-margin-top: -.5625rem;
$board-posting-item-status-color-draft: $text-color-placeholders;
$board-posting-item-status-color-published: $green-primary;

$borad-note-border: 1px solid $border-color;
$borad-note-border-radius: .25rem;
$borad-note-padding: 1.5rem 1.5rem;
$borad-note-background: $table-background;
$borad-note-text-font-size: 1.125rem;

// List Jobs

$list-jobs-wrapper-padding: 1rem 0 0;
$list-jobs-wrapper-title-font-size: 1.25rem;
$list-jobs-wrapper-title-font-weight: 600;
$list-jobs-wrapper-title-padding: 1rem 2rem;

$list-jobs-item-padding: .5rem 0 .5rem 1rem;

$list-jobs-item-border: 1px solid $border-color;
$list-jobs-item-background-active: $white;
$list-jobs-item-border-active: 2px solid $blue-button-primary;
$list-jobs-item-bar-width-active: .5rem;
$list-jobs-item-bar-background-active: $blue-button-primary;

// List Account

$list-account-item-title-font-size: 1.25rem;
$list-account-item-title-font-weight: 600;
$list-account-item-title-padding: 0 0 0 2rem;
$list-account-item-title-icon-width: 1.5rem;
$list-account-item-icon-height: 1.5rem;
$list-account-item-icon-background: $blue-button-primary;

$list-account-item-status-margin: 0 1rem;
$list-account-item-status-width: 1rem;
$list-account-item-status-height: 1rem;

$list-account-item-distance: 2rem;

// Modals
//---------------------------------------------

// Modal

$modal-border-radius: 0.25rem 0.25rem;
$modal-background: $white;

// Modal Content

$modal-content-header-border-radius: 0.25rem 0.25rem 0 0;
$modal-content-header-padding: 1.25rem 3.5rem 1.25rem 2rem;
$modal-content-header-border: 1px solid $border-color;

$modal-content-header-title-font-size: 1.25rem;

$modal-content-header-title-sucecss-padding-right: 1.625rem;
$modal-content-header-title-sucecss-icon-width: 1.375rem;
$modal-content-header-title-sucecss-icon-height: 1.375rem;

$modal-content-header-padding-lg: 1.75rem 3.5rem 1.25rem 2rem;
$modal-content-header-title-font-size-lg: 1.5rem;

$modal-content-header-close-position-top: 50%;
$modal-content-header-close-position-right: 1.5rem;
$modal-content-header-close-margin: -0.625rem 0 0;
$modal-content-header-close-width: 1.25rem;
$modal-content-header-close-height: 1.25rem;

$modal-content-body-padding: 1.25rem 2rem;

$modal-content-footer-border: 1px solid $border-color;
$modal-content-footer-padding: 1.5rem 2rem;
$modal-content-footer-button-min-width: 7.5rem;

// Modal Sizes

$modal-width-sm: 30rem;
$modal-width-md: 45rem;
$modal-width-lg: 64rem;
$modal-width-xl: 75rem;

// Modal Layout

$modal-wrapper-padding: 1rem 1rem !important;

// Modal Recommendations

$modal-recommendations-body-height: 33.75rem;

$modal-recommendations-nav-width: 13rem;

$modal-recommendations-nav-item-font-size: 1rem;
$modal-recommendations-nav-item-font-weight: 400;
$modal-recommendations-nav-item-line-height: 1.25;
$modal-recommendations-nav-item-border: 1px solid $border-color;
$modal-recommendations-nav-item-padding: 0.75rem 1.75rem 0.75rem 1rem;

$modal-recommendations-nav-item-arrow-right: 1rem;
$modal-recommendations-nav-item-arrow-width: 0.625rem;
$modal-recommendations-nav-item-arrow-height: 1rem;
$modal-recommendations-nav-item-arrow-margin: -0.5rem;

$modal-recommendations-nav-item-background-hover: #f5f5f5;

$modal-recommendations-nav-item-color-active: $white;
$modal-recommendations-nav-item-background-active: $blue-button-primary;
$modal-recommendations-nav-item-border-color-active: $blue-button-primary;

$modal-recommendations-panel-padding: 3.25rem 0 0;
$modal-recommendations-panel-search-height: 3.25rem;
$modal-recommendations-panel-search-border: 1px solid $border-color;
$modal-recommendations-panel-search-font-size: 1.0625rem;
$modal-recommendations-panel-search-input-padding: 0.875rem 1.5rem 0.875rem 3.5rem;
$modal-recommendations-panel-search-input-background: #f5f5f5;
$modal-recommendations-panel-search-input-position: 1.25rem 0.875rem;

$modal-recommendations-panel-options-padding: 1rem 0 2rem;

$recommenddation-row-padding: 0.25rem 1.5rem;

$recommenddation-row-margin: 0 0 1rem;
$recommenddation-row-padding-depth-zero: 0 0 0 1.5rem;
$recommenddation-row-gap: 1rem;
$recommenddation-row-padding-depth-one: 0 0 0 1.5rem;

// Navbar
//---------------------------------------------

$navbar-background: $navbar-background;

$navbar-links-link-font-size: .9375rem;
$navbar-links-link-font-weight: 600;
$navbar-links-link-color: $text-color-light;

$navbar-links-item-distance: 2rem;

// Offscreen
//---------------------------------------------

$offscreen-background: $white;
$offscreen-box-shadow: 0 3px 6px rgba(#000000, 0.16);
$offscreen-width: 30.25rem;

$offscreen-header-padding: 3.75rem 2rem 1rem;
$offscreen-header-title-font-size: 1.25rem;
$offscreen-header-title-font-weight: 600;
$offscreen-header-actions-title-font-weight: 400;

$offscreen-header-icon-width: 6.25rem;
$offscreen-header-icon-height: 5rem;

$offscreen-header-close-position-top: 1.5rem;
$offscreen-header-close-position-right: 1.5rem;
$offscreen-header-close-width: 1.25rem;
$offscreen-header-close-height: 1.25rem;

$offscreen-header-inline-padding: 1.25rem 2rem;

$offscreen-header-border: 1px solid $border-color;

$offscreen-body-padding: 0 1rem 2rem;

$offscreen-body-signature-padding: 3rem 0 3rem;
$offscreen-body-signature-text-font-size: 1.25rem;
$offscreen-body-signature-text-font-weight: 600;
$offscreen-body-signature-text-color: $icon-color-dark;
$offscreen-body-signature-text-padding: 0 1rem;
$offscreen-body-signature-text-background: $white;
$offscreen-body-signature-line-left: 1rem;
$offscreen-body-signature-line-right: 1rem;
$offscreen-body-signature-line-height: 0.0625rem;
$offscreen-body-signature-line-background: $icon-color-dark;

// Size

$offscreen-width-lg: 45rem;
$offscreen-width-md: 37.5rem;
$offscreen-width-sm: 22.5rem;

$offscreen-header-small-padding-top: 3.75rem;
$offscreen-header-small-min-height: 3.75rem;
$offscreen-header-small-title-font-size: 1.125rem;
$offscreen-header-small-close-position-top: 1.25rem;

// Note

$offscreen-note-padding: 1rem;
$offscreen-note-border: 5px solid #c2def4;
$offscreen-note-border-radius: 0.5rem;

// In Body

$offscreen-inbody-top: 10rem;
$offscreen-inbody-height: calc(100vh - 10rem);

$offscreen-inbody-content-padding-top: 4rem;

$offscreen-inbody-header-padding: 1.25rem 3.25rem 1.25rem 2rem;
$offscreen-inbody-header-background: #8a90a3;

$offscreen-inbody-header-title-color: $white;

$offscreen-inbody-header-icon-padding: 1.25rem 3.25rem 1.25rem 5.375rem;
$offscreen-inbody-header-icon-left: 2rem;
$offscreen-inbody-header-icon-width: 2.625rem;
$offscreen-inbody-header-icon-height: 1.875rem;
$offscreen-inbody-header-icon-margin-top: -0.9375rem;
$offscreen-inbody-header-icon-border: 1px solid $white;

// Profile

$offscreen-profile-padding: 2rem 1rem 2rem;

$offscreen-profile-header-padding-bottom: 1rem;
$offscreen-profile-image-width: 6rem;
$offscreen-profile-image-height: 6rem;
$offscreen-profile-image-margin-left: 0.75rem;
$offscreen-profile-image-background: $icon-color-dark;
$offscreen-profile-image-font-size: 1.5rem;
$offscreen-profile-image-font-weight: 600;
$offscreen-profile-image-color: $white;

$offscreen-profile-name-font-size: 1.5rem;
$offscreen-profile-name-font-weight: 600;

$offscreen-profile-actions-font-size: 0.9375rem;
$offscreen-profile-actions-font-weight: 600;

// Tabs

$offscreen-tabs-margin: 1rem -1rem 0;

// Preview

$offscreen-preview-top: 3.5rem;
$offscreen-preview-background: $body-background;
$offscreen-preview-width: calc(100vw - 3.5rem);
$offscreen-preview-position-collapsed: calc(-100vw + 3.5rem);

$offscreen-preview-header-background: $white;
$offscreen-preview-header-border: 1px solid $border-color;
$offscreen-preview-header-min-height: 6.5rem;
$offscreen-preview-header-padding-left: 5rem;
$offscreen-preview-header-close-width: 5rem;
$offscreen-preview-header-close-background: $blue-button-primary;
$offscreen-preview-header-close-padding: 1rem;
$offscreen-preview-header-close-font-size: 0.9375rem;
$offscreen-preview-header-close-color: #c2def4;
$offscreen-preview-header-close-icon-width: 1.875rem;
$offscreen-preview-header-close-icon-height: 1.875rem;
$offscreen-preview-header-close-icon-margin: 0 0 0.5rem;
$offscreen-preview-header-content-padding: 1rem 2rem 1rem 1rem;
$offscreen-preview-header-title-font-size: 1.375rem;
$offscreen-preview-header-title-font-weight: 600;
$offscreen-preview-header-info-margin: 0.25rem 0 0;
$offscreen-preview-header-info-item-distance: 1.5rem;
$offscreen-preview-header-info-item-font-size: 1rem;
$offscreen-preview-header-info-item-span-color: $text-color-placeholders;
$offscreen-preview-header-info-item-span-margin-right: 0.25rem;

$offscreen-preview-body-nav-padding: 1.5rem 2rem 0;
$offscreen-preview-body-nav-border: 1px solid $border-color;

// Pagination
//---------------------------------------------

$pagination-elements-distance: 1rem;

$pagination-list-border: 1px solid $border-color;
$pagination-list-border-radius: 0.25rem;

$pagination-list-link-font-size: 1rem;
$pagination-list-link-font-weight: 600;
$pagination-list-link-color: #b2b6c1;
$pagination-list-link-background: $white;
$pagination-list-link-height: 2.5rem;
$pagination-list-link-min-width: 2.75rem;
$pagination-list-link-padding: 0.25rem 0.25rem;

$pagination-list-link-color-active: $white;
$pagination-list-link-background-active: $blue-button-primary;

$pagination-list-link-font-size-sm: 1rem;
$pagination-list-link-height-sm: 2.5rem;
$pagination-list-link-min-width-sm: 2.5rem;

// Panels
//---------------------------------------------

// Panel

$panel-border-radius: .375rem .375rem;
$panel-background: #f9f9f9;

$panel-header-padding: 1.25rem 2.5rem;
$panel-header-border-radius: .375rem .375rem 0 0;
$panel-header-note-font-size: 1.25rem;

$panel-header-background-dark: #8a90a3;
$panel-header-background-primary: $blue-button-primary;
$panel-header-background-secondary: $green-primary;
$panel-header-variant-text-color: $white;

$panel-body-border-radius: 0 0 .375rem .375rem;
$panel-body-form-padding: 1rem 1rem;

// Panel Auth

$panel-auth-margin: 3rem 0 0;
$panel-auth-border-radius: 0.375rem 0.375rem;
$panel-auth-background: #f9f9f9;

$panel-auth-header-border-radius: 0.375rem 0.375rem 0 0;
$panel-auth-header-background: $green-secondary;
$panel-auth-header-padding: 1.125rem 2rem;
$panel-auth-header-title-color: $white;
$panel-auth-header-subscript-color: #e2e4e6;
$panel-auth-header-subscript-font-size: 1.25rem;

// Template Preview

$template-preview-border: 1px solid $border-color;
$template-preview-border-radius: 0.25rem;

$template-preview-header-background: $text-color-light;
$template-preview-header-padding: 1.25rem 1.25rem;

$template-preview-body-background: $white;
$template-preview-preview-body-height: 25rem;
$template-preview-body-border-radius: 0 0 0.25rem 0.25rem;

$template-preview-info-item-font-size: 1rem;
$template-preview-info-item-font-weight: 600;
$template-preview-info-item-distance: 0.25rem;

$template-preview-content-padding: 1.75rem 1.25rem;

$template-preview-content-text-font-size: 1rem;
$template-preview-content-list-padding: 1.25rem;
$template-preview-content-text-element-margin: 0 0 1rem;

// Widgets

$panel-widgets-background: #68ba92;
$panel-widgets-border-radius: 0.25rem;
$panel-widgets-box-shadow: 0 1px 3px rgba($grey-primary, 0.5);

$panel-widgets-list-wrapper-width: 99rem;
$panel-widgets-list-item-width: 9rem;
$panel-widgets-list-item-padding: 1.5rem 0.75rem;
$panel-widgets-list-item-icon-height: 2.5rem;
$panel-widgets-list-item-icon-margin: 0.75rem;
$panel-widgets-list-item-title-font-size: 0.75rem;
$panel-widgets-list-item-title-font-weight: 600;
$panel-widgets-list-item-title-color: #f9f9f9;

// Roster

$roster-header-margin-bottom: 2rem;
$roster-header-elements-distance: 0.5rem;

$roster-header-title-font-size: 1.25rem;
$roster-header-title-font-weight: 600;

$roster-header-text-font-size: 1.125rem;
$roster-header-text-label-font-weight: 600;

$roster-header-info-font-size: 0.9375rem;
$roster-header-info-distance: 0.25rem;

// Roster Group

$roster-group-border: 1px solid $border-color;
$roster-group-border-radius: 0.25rem;
$roster-group-box-shadow: 0 1px 6px rgba(#000000, 0.08);

$roster-group-header-background: $table-background;
$roster-group-header-border-radius: 0.25rem 0.25rem 0 0;
$roster-group-header-padding: 1rem 1.25rem;
$roster-group-header-title-font-size: 1.25rem;
$roster-group-header-title-font-weight: 600;
$roster-group-header-controls-margin: 0 0 0 1.5rem;
$roster-group-header-controls-distance: 0.5rem;

$roster-group-body-background: $white;
$roster-group-body-header-padding: 1rem 2.75rem 1rem 1.25rem;
$roster-group-body-header-font-size: 1rem;
$roster-group-body-header-font-weight: 600;
$roster-group-body-header-color: $text-color-dark;
$roster-group-body-header-background-active: #fafafb;

$roster-group-body-header-caret-right: 1.5rem;
$roster-group-body-header-caret-width: 0.75rem;
$roster-group-body-header-caret-height: 0.5rem;
$roster-group-body-header-caret-margin-top: -0.375rem;

$roster-group-body-content-border-radius: 0 0 0.25rem 0.25rem;
$roster-group-body-content-pagination-padding: 1.5rem 1.25rem;

// Progress
//---------------------------------------------

$progress-width: 15rem;
$progress-height: 0.75rem;
$progress-background: $border-color;
$progress-border-radius: 0.375rem;

$progress-bar-border-radius: 0.375rem;
$progress-bar-background: $blue-button-primary;

// Form Progress

$form-progress-height: 0.75rem;
$form-progress-background: #e6e6e6;

$form-progress-bar-border-radius: 0 0.375rem 0.375rem 0;

// Sidebar
//---------------------------------------------

$sidebar-background: $sidenav-background;

$sidebar-links-link-padding: 0.5rem 1rem;
$sidebar-links-link-font-size: 1rem;
$sidebar-links-link-font-weight: 600;
$sidebar-links-link-color: $text-color-light;

$sidebar-sublinks-padding: 0 0 0 2rem;
$sidebar-sublinks-link-padding: 0.25rem 1rem;
$sidebar-sublinks-link-font-size: 0.875rem;
$sidebar-sublinks-link-font-weight: 600;
$sidebar-links-sublink-color: $text-color-light;

// Panel Sidebar

$panel-sidebar-background: $table-background;
$panel-sidebar-padding: 2rem 0;
$panel-sidebar-links-padding: .5rem 1rem;
$panel-sidebar-links-font-size: 1rem;
$panel-sidebar-links-font-weight: 700;
$panel-sidebar-link-icon-height: 1.625rem;
$panel-sidebar-link-icon-width: 1.625rem;
$panel-sidebar-link-color: $text-color-dark;
$panel-sidebar-links-link-padding: .2rem .5rem;

// Tables
//---------------------------------------------

$table-wrapper-box-shadow: 0 1px 6px rgba(#000000, 0.08);
$table-wrapper-border-radius: 0.5rem;
$table-wrapper-background: $white;
$table-wrapper-border: 1px solid $border-color;
$table-wrapper-scrollbar-width: 0.375rem;
$table-wrapper-scrollbar-background: $table-background;
$table-wrapper-scrollbar-thumb-background: #a7afb9;
$table-wrapper-scrollbar-thumb-background-hover: #8a95a3;

$table-min-width: 64rem;

$table-head-cell-background: #8a95a3;
$table-head-cell-border-raious-first: 0.5rem 0 0 0;
$table-head-cell-border-raious-last: 0 0.5rem 0 0;
$table-head-cell-padding: 1rem 1rem;
$table-head-title-color: $white;
$table-head-title-font-size: .875rem;
$table-head-title-font-weight: 600;
$table-head-title-padding-filter: 0 1rem 0 0;
$table-head-title-caret-width: 0.75rem;
$table-head-title-caret-height: 0.5rem;
$table-head-title-caret-margin: -0.25rem 0 0;

$table-body-cell-border: 1px solid $border-color;
$table-body-cell-padding: 0.5rem 0;
$table-body-cell-background: $white;
$table-body-cell-background-active: $table-background;
$table-body-cell-border-radius-first: 0 0 0 0.5rem;
$table-body-cell-border-radius-last: 0 0 0.5rem 0;

$table-body-cell-wider-padding: 0;

$table-body-cell-padding-left-shifted: 2.25rem;

$table-body-cell-pdf-height: 40rem;

$table-row-menu-box-shadow: 0 3px 6px rgba(#000000, 0.05);
$table-row-menu-width: 20rem;
$table-row-menu-top: -0.75rem;
$table-row-menu-right: 1rem;
$table-row-menu-margin-left: -10rem;

$table-body-filters-cell-padding: .75rem 1rem .75rem;
$table-body-filters-cell-border: 1px solid $border-color;

$table-row-filters-distance: 0.75rem;
$table-row-filters-dropdown-height: 2.75rem;

// Table Reviews

$table-review-head-cell-font-size: 1.125rem;
$table-review-head-cell-font-weight: 600;
$table-review-head-cell-color: $icon-color-dark;
$table-review-head-cell-padding: 1rem 1.25rem;

$table-review-header-cell-font-size: 1.25rem;
$table-review-header-cell-font-weight: 600;
$table-review-header-cell-background: $table-background;
$table-review-header-cell-padding: 1.25rem 1.25rem;
$table-review-header-cell-color: $sidenav-background;

$table-review-body-row-border: 1px solid $border-color;

$table-review-body-cell-font-size: 1.0625rem;
$table-review-body-cell-padding: 1rem 1.25rem;

$table-review-body-cell-arrow-width: 0.5rem;
$table-review-body-cell-arrow-height: 0.75rem;
$table-review-body-cell-arrow-margin: 0 0.125rem;

$table-review-list-min-height: 2.5rem;
$table-review-list-item-height: 1.875rem;
$table-review-list-item-width: 1.875rem;
$table-review-list-item-distance: 0.25rem;

$table-review-comments-trigger-width: 2rem;
$table-review-comments-trigger-height: 1.75rem;
$table-review-comments-menu-background: $white;
$table-review-comments-menu-box-shadow: 0 3px 6px rgba(#000000, 0.08);
$table-review-comments-menu-border: 1px solid $border-color;
$table-review-comments-menu-border-radius: 0.25rem;
$table-review-comments-menu-padding: 1rem 0;
$table-review-comments-menu-margin: 0.5rem 0 0;
$table-review-comments-menu-max-width: 30rem;
$table-review-comments-menu-max-height: 25rem;
$table-review-comments-menu-posters-padding: 0 1.5rem;

$table-review-percentage-height: 1.25rem;
$table-review-percentage-border-radius: 0.5rem;
$table-review-percentage-padding: 0 0 0 5rem;
$table-review-percentage-bar-background: $green-primary;

$table-review-posters-border: 1px solid $border-color;
$table-review-posters-border-radius: 0.5rem;
$table-review-posters-padding: 1.25rem 2.5rem;
$table-review-posters-list-item-padding: 1rem 0 1rem 3.75rem;
$table-review-posters-list-item-min-height: 4.75rem;
$table-review-posters-list-avatar-top: 1rem;
$table-review-posters-list-avatar-weight: 2.75rem;
$table-review-posters-list-avatar-height: 2.75rem;
$table-review-posters-list-avatar-font-size: 1.125rem;
$table-review-posters-list-avatar-font-weight: 600;
$table-review-posters-list-avatar-color: $white;
$table-review-posters-list-name-font-size: 1.0625rem;
$table-review-posters-list-name-font-weight: 600;
$table-review-posters-list-name-color: $blue-button-primary;
$table-review-posters-list-name-color-active: darken($blue-button-primary, 10%);
$table-review-posters-list-text-font-size: 1rem;
$table-review-posters-list-text-margin: 0.125rem 0 0;

$review-color-0-20: #de5e6a;
$review-color-21-40: #d67d86;
$review-color-41-60: #f8c573;
$review-color-61-80: #78c493;
$review-color-81-100: #3ba773;

// Table Modal Review

$table-modal-review-head-font-size: 1.125rem;
$table-modal-review-head-font-weight: 600;
$table-modal-review-head-padding: 1rem 1rem;
$table-modal-review-head-color: #7c8189;
$table-modal-review-head-font-background: $table-background;

$table-modal-review-body-cell-font-size: 1.0625rem;
$table-modal-review-body-cell-border: 1px solid $border-color;
$table-modal-review-body-cell-padding: 1rem 1rem;

$table-modal-review-body-header-font-size: 1.5rem !important;
$table-modal-review-body-header-font-weight: 600;
$table-modal-review-body-header-color: $blue-button-primary;
$table-modal-review-body-header-padding: 2rem 1rem 1rem !important;

$table-modal-review-body-cell-input-width: 15rem;
$table-modal-review-body-cell-comment-width: 8.75rem;
$table-modal-review-body-cell-rating-width: 12.5rem;
$table-modal-review-body-parent-cell-background: $table-background;
$table-modal-review-body-parent-cell-font-size: 1.125rem;
$table-modal-review-body-parent-cell-font-weight: 600;
$table-modal-review-body-parent-cell-padding-right: 2.5rem;

$table-modal-review-body-child-cell-padding: 2rem;

$table-modal-review-comments-trigger-width: 2rem;
$table-modal-review-comments-trigger-height: 1.75rem;

// Table Card

$table-card-head-cell-font-size: 1rem;
$table-card-head-cell-font-weight: 400;
$table-card-head-cell-padding: 1rem 1rem 0.5rem;

$table-card-body-cell-padding: 1rem 1rem;
$table-card-body-cell-padding-extrems: 1rem;
$table-card-body-cell-limited-width: 16.25rem;

$table-card-cell-border: 1px solid $border-color;

$table-card-head-cell-font-size-sm: 0.8125rem;
$table-card-body-cell-actions-width-sm: 15rem;
$table-card-body-cell-actions-distance-sm: 0.5rem;
$table-card-body-cell-controls-width-sm: 4rem;

$table-card-head-cell-font-weight-light: 600;
$table-card-head-cell-background-light: $table-background;

// Table Light

$table-light-head-cell-font-size: .875rem;
$table-light-head-cell-fonr-weight: 600;
$table-light-head-cell-color: #707070;
$table-light-head-cell-background: $table-background;
$table-light-head-cell-padding: 1rem 1rem 1rem;
$table-light-head-cell-border-radius-first: 0.5rem 0 0 0;
$table-light-head-cell-border-radius-last: 0 0.5rem 0 0;
$table-light-head-cell-selectable-padding-left: 3.25rem;

$table-light-body-cell-border: 1px solid $border-color;
$table-light-body-cell-padding: 0.5rem 0;
$table-light-body-cell-background: $white;
$table-light-body-cell-background-active: $table-background;
$table-light-body-cell-border-radius-first: 0 0 0 0.5rem;
$table-light-body-cell-border-radius-last: 0 0 0.5rem 0;

// Table Status

$table-light-status-header-padding: 1.25rem 0.5rem 1.25rem 1.5rem;
$table-light-status-header-status-width: 6.25rem;
$table-light-status-font-size: 1rem;
$table-light-status-font-weight: 600;
$table-light-status-padding: 0 0 0 1.5rem;
$table-light-status-dot-width: 1.125rem;
$table-light-status-dot-height: 1.125rem;
$table-light-status-dot-margin-top: -0.5625rem;
$table-light-status-color-inactive: $text-color-placeholders;
$table-light-status-color-draft: $text-color-placeholders;
$table-light-status-color-rejected: $text-color-placeholders;
$table-light-status-color-active: $green-primary;
$table-light-status-color-deleted: $text-color-dark;
$table-light-status-color-pending_delete: $text-color-dark;

// Table Selectable

$table-selectable-controls-height: 3.75rem;
$table-selectable-controls-top: 2.75rem;

$table-selectable-controls-select-padding: 0.75rem 1rem .5rem;
$table-selectable-controls-select-width: 10rem;

$table-selectable-controls-actions-distance: 0.75rem;

// Table Empty

$table-empty-padding: 6rem 2rem;

$table-empty-icon-margin: 0 0 2rem;
$table-empty-icon-users-margin-left: 0.5rem;
$table-empty-icon-users-width: 6.375rem;
$table-empty-icon-users-height: 6.375rem;
$table-empty-icon-groups-width: 17rem;
$table-empty-icon-groups-height: 11.375rem;

$table-empty-title-font-size: 1.375rem;
$table-empty-title-font-weight: 600;
$table-empty-title-margin: 0 0 1rem;

$table-empty-text-margin: 0 0 2rem;

$table-empty-padding-narrow: 3rem 2rem;

$table-empty-dark-background: $table-background;
$table-empty-dark-border-radius: 0.375rem;

// Plans

$table-plans-cell-width: 25%;
$table-plans-cell-border: 1px solid $border-color;

$table-plans-head-cell-content-padding: 1.5rem 1.5rem;

$table-plans-head-cell-title-font-size: 2rem;
$table-plans-head-cell-title-font-weight: 200;
$table-plans-head-cell-title-margin: 0 0 1rem;
$table-plans-head-cell-title-span-font-weight: 600;

$table-plans-head-cell-text-font-size: 1.25rem;
$table-plans-head-cell-text-font-weight: 400;
$table-plans-head-cell-text-margin: 0 0 2rem;

$table-plans-head-cell-button-min-width: 13.75rem;

$table-plans-body-head-cell-padding: 2rem 1.5rem 0.5rem;
$table-plans-body-head-title-font-size: 1.625rem;
$table-plans-body-head-title-font-weight: 600;

$table-plans-body-cell-padding: 1rem 1.5rem;
$table-plans-body-cell-font-size: 1.125rem;
$table-plans-body-cell-padding-last: 1rem 1.5rem 1.5rem;

// Table Templates

$table-templates-head-cell-sentby-width: 13.75rem;
$table-templates-head-cell-status-width: 30rem;
$table-templates-head-cell-to-width: 30rem;

$table-templates-body-cell-border: 1px solid $border-color;
$table-templates-body-cell-padding: 1.5rem 1.5rem;

$template-group-title-font-size: 1.375rem;
$template-group-title-font-weight: 600;
$template-group-title-color: $blue-button-primary;
$template-group-title-margin: 0 0 1rem;

$template-group-title-span-font-size: 1rem;
$template-group-title-span-font-weight: 400;
$template-group-title-span-color: $text-color-dark;
$template-group-title-span-margin: .25rem 0 0;

$template-group-item-background: #f7f8f9;
$template-group-item-border: 1px solid $border-color;
$template-group-item-border-radius: 0.25rem;
$template-group-item-margin: 0 0 0.75rem;

$template-group-item-header-padding: 1.25rem 0.5rem 1.25rem 1.5rem;
$template-group-item-header-element-padding: 0 1rem;
$template-group-item-header-info-padding-left: 2rem !important;
$template-group-item-header-sentby-font-size: 1.0625rem;
$template-group-item-header-sentby-width: 13.75rem;
$template-group-item-header-status-width: 18.5rem;
$template-group-item-header-controls-width: 9.5rem;
$template-group-item-header-to-width: 19rem;
$template-group-item-header-caret-left: 0.5rem;
$template-group-item-header-caret-width: 0.75rem;
$template-group-item-header-caret-height: 0.5rem;
$template-group-item-header-caret-margin-top: -0.25rem;

$template-group-item-header-info-padding: 2rem;

$template-group-item-body-padding: 1.5rem;

$template-group-item-title-font-size: 1.125rem;
$template-group-item-title-font-weight: 600;
$template-group-item-title-margin: 0 0 0.25rem;
$template-group-item-title-color: $text-color-dark;

$template-group-item-text-font-size: 0.9375rem;
$template-group-item-text-color: #7c8189;

$template-group-item-status-font-size: 1rem;
$template-group-item-status-font-weight: 600;
$template-group-item-status-padding: 0 0 0 1.5rem;
$template-group-item-status-dot-width: 1.125rem;
$template-group-item-status-dot-height: 1.125rem;
$template-group-item-status-dot-margin-top: -0.5625rem;
$template-group-item-status-color-inactive: $grey-primary;
$template-group-item-status-color-draft: $review-color-41-60;
$template-group-item-status-color-rejected: $review-color-0-20;
$template-group-item-status-color-active: $table-light-status-color-active;
$template-group-item-status-color-deleted: $text-color-dark;

$template-group-item-info-item-font-size: 1rem;
$template-group-item-info-item-font-weight: 600;
$template-group-item-info-item-distance: 0.25rem;

$template-group-item-content-border-radius: 0.25rem;
$template-group-item-content-background: $white;
$template-group-item-content-padding: 1.25rem;
$template-group-item-content-max-height: 25rem;
$template-group-item-content-margin: 1.5rem 0 0;

$template-group-item-content-text-font-size: 1rem;
$template-group-item-content-list-padding: 1.25rem;
$template-group-item-content-text-element-margin: 0 0 1rem;

$template-group-workflows-title-font-size: 1.125rem;
$template-group-workflows-title-font-weight: 600;
$template-group-workflows-title-margin: 1.5rem 0 0.5rem;

$template-group-workflows-list-border: 1px solid $border-color;
$template-group-workflows-list-border-radius: 0.25rem;
$template-group-workflows-list-background: $white;

$template-group-workflows-list-item-padding: .75rem 1rem;

// Tabs
//---------------------------------------------

$tab-list-border: 1px solid $border-color;

$tab-link-font-size: 1rem;
$tab-link-font-weight: 600;
$tab-link-color: $text-color-dark;
$tab-link-color-active: $blue-button-primary;
$tab-link-padding: 0.75rem 0;

$tab-link-mark-height: 0.375rem;
$tab-link-mark-border-radius: 0.25rem;
$tab-link-mark-background: $text-color-dark;

$tab-list-add-padding: 0.5rem;
$tab-list-add-font-size: 1.75rem;
$tab-list-add-color: $blue-button-primary;

$tab-link-distance: 2.25rem;

$tab-link-font-size-sm: .9375rem;

$tab-panel-padding: 1rem 0;

$tab-list-inside-padding: 0 2rem;
$tab-panel-inside-padding: 1rem 2rem;

// Card tabs

$card-tabs-list-link-font-size: 1.25rem;
$card-tabs-list-link-font-weight: 600;
$card-tabs-list-link-color: $text-color-dark;
$card-tabs-list-link-padding: 1.75rem 1.75rem;
$card-tabs-list-link-color-hover: $blue-button-primary;

$card-tabs-list-link-border-radius-first: 0.25rem 0.25rem 0 0;
$card-tabs-list-link-border-radius-last: 0 0 0.25rem 0.25rem;

$card-tabs-list-link-active-bar-width: 0.5rem;
$card-tabs-list-link-active-bar-background: $blue-button-primary;

$card-tabs-list-link-active-bar-border-radius-first: 0.25rem 0 0 0;
$card-tabs-list-link-active-bar-border-radius-last: 0 0 0 0.25rem;

$card-tabs-item-border: 1px solid $border-color;

// Sidetabs

$sidetabs-list-width: 13.75rem;

$sidetabs-list-link-font-size: 1rem;
$sidetabs-list-link-font-weight: 600;
$sidetabs-list-link-padding: 0.75rem 2rem;
$sidetabs-list-link-background-active: $blue-button-primary;
$sidetabs-list-link-color-active: $white;

$sidetabs-list-panel-padding: 0 2rem;
$sidetabs-list-panel-min-height: 15rem;

// Tags
//---------------------------------------------

$nav-source-height: 3.75rem;
$nav-source-border-radius: 1.875rem;
$nav-source-background: $table-background;
$nav-source-padding: 1rem 5rem 1rem 3rem;

$nav-source-title-font-size: 1.25rem;
$nav-source-title-font-weight: 600;

$nav-source-icon-right: 1.5rem;
$nav-source-icon-width: 1.25rem;
$nav-source-icon-height: 1.25rem;
$nav-source-icon-margin-top: -0.625rem;
$nav-source-icon-background: $blue-button-primary;
$nav-source-icon-left: 0.3125rem;

// Typography
//---------------------------------------------

// Headings

$headings-font-weight: 600;

$headings-h1-font-size: 2rem;

$headings-h2-font-size: 1.75rem;

$headings-h3-font-size: 1.5rem;
$headings-h3-line-height: 1.2;

$headings-h4-font-size: 1.375rem;

$headings-h5-font-size: 1.25rem;

// Link

$link-color: $blue-button-primary;
$link-color-active: darken($blue-button-primary, 10%);

$link-add-font-weight: 600;
$link-add-padding: 0 0 0 1.25rem !important;
$link-add-margin: 0 0.25rem 0 0;
$link-add-icon-weight: 0.875rem;
$link-add-icon-height: 0.875rem;
$link-add-icon-margin-top: -0.4375rem;

$link-caret-font-weight: 600;
$link-caret-padding: 0 1.25rem 0 0;
$link-caret-icon-margin-top: -0.25rem;
$link-caret-icon-width: 0.75rem;
$link-caret-icon-height: 0.5rem;

$link-preview-font-size: 1.0625rem;
$link-preview-font-weight: 600;
$link-preview-padding: 0 0 0 2rem;
$link-preview-color: $text-color-dark;
$link-preview-icon-width: 1.5rem;
$link-preview-icon-height: 1rem;
$link-preview-icon-margin-top: -0.5rem;

$link-settings-weight: 1.325rem;
$link-settings-height: 1.325rem;

// Decorators

$text-highlighted-primary-color: $blue-button-primary;
$text-highlighted-secondary-color: $green-primary;
$text-faded-color: $icon-color-dark;

$text-align-verticaly-element-margin: 0 0.25rem;

$text-flex-span-margin: 0 0.75rem;

$text-with-icon-padding-left: 1.375rem;
$text-with-icon-width: 1rem;
$text-with-icon-height: 1rem;
$text-with-icon-margin-top: -0.5rem;

$text-underline-padding-bottom: 0.5rem;
$text-underline-border: 3px solid $border-color;

// Text with icon link

$text-icon-link-margin: 1.5rem;
$text-icon-link-width: 1.25rem;
$text-icon-link-height: 1.25rem;

// Text Icons

$text-icon-padding-left: 2rem;
$text-icon-top: 0.125rem;
$text-icon-width: 1.5rem;
$text-icon-height: 1.5rem;

// Text with controls

$text-controls-margin: 1rem;

// Divider
//---------------------------------------------

$divider-border: 2px solid $border-color;
$divider-margin: 1rem 0;

//---------------------------------------------
// PAGES
//---------------------------------------------

// Opportunity
//---------------------------------------------

// Opportunity Overview

$opportunity-overview-background: $table-background;
$opportunity-overview-padding: 1rem 0;
$opportunity-item-padding: .25rem 1.5rem .5rem;
$opportunity-item-max-width: 15rem;
$opportunity-item-min-width: 10rem;
$opportunity-item-number-color: $blue-button-primary;
$opportunity-item-number-font-size: 2.25rem;
$opportunity-item-number-font-weight: 600;
$opportunity-item-text-font-size: 1rem;
$opportunity-item-text-font-weight: 600;
$opportunity-item-text-color: $icon-color-dark;
$opportunity-divider-color: #d3dbe2;
$opportunity-divider-height: 90px;
$opportunity-divider-width: 1px;
$opportunity-divider-margin: 1rem;
$opportunity-border-color: #d3dbe2;
$opportunity-border-radius: 10px;

// Opportunity Onboarding process

$opportunity-onboarding-process-background-color: $table-background;
$opportunity-onboarding-process-padding: 0 .5rem;
$opportunity-onboarding-process-item-padding: 1.5rem 1rem;
$opportunity-onboarding-process-item-number-color: $blue-button-primary;
$opportunity-onboarding-process-item-number-font-size: 1.25rem;
$opportunity-onboarding-process-item-number-font-weight: 600;
$opportunity-onboarding-process-item-text-font-size: 1.125rem;
$opportunity-onboarding-process-item-text-padding: 0 1rem 0 0.15rem;
$opportunity-onboarding-process-item-icon-arrow-width: 0.75rem;
$opportunity-onboarding-process-item-icon-arrow-height: 1.75rem;
$opportunity-onboarding-process-item-icon-arrow-margin: -0.875rem 0 0;
$opportunity-onboarding-process-border-color: #d3dbe2;
$opportunity-onboarding-process: 1px;

// Opportunity Candidate Status

$opportunity-candidate-status-background-color: $table-background;
$opportunity-candidate-status-padding: 0 1rem 0 1rem;
$opportunity-candidate-status-item-padding: 2.125rem 2rem;
$opportunity-candidate-status-item-number-color: $blue-button-primary;
$opportunity-candidate-status-item-number-font-size: 1.25rem;
$opportunity-candidate-status-item-number-font-weight: 600;
$opportunity-candidate-status-item-text-font-size: 1.125rem;
$opportunity-candidate-status-item-line-height: 1.25;
$opportunity-candidate-status-item-text-padding: 0 1rem 0 0.15rem;
$opportunity-candidate-status-item-icon-arrow-width: 0.81rem;
$opportunity-candidate-status-item-icon-arrow-height: 1.875rem;
$opportunity-candidate-status-item-icon-arrow-margin: -0.75rem 0 0;
$opportunity-candidate-status-border-color: #d3dbe2;
$opportunity-candidate-status: 1px;

// Job
//---------------------------------------------

$job-header-padding: 1rem 1rem;
$job-header-border: 1px solid $border-color;
$job-header-controls-padding: 0 0 0 1rem;
$job-header-controls-width: 0 0 29.25rem;
$job-header-background-light: $white;
$job-header-breadcrumb-padding: 1rem 1rem 0;
$job-header-breadcrumb-link-font-size: 1.125rem;
$job-header-breadcrumb-link-font-weight: 600;
$job-header-breadcrumb-link-color: $icon-color-dark;
$job-header-breadcrumb-link-padding: 0 0 0 1rem;
$job-header-breadcrumb-link-color-active: $blue-button-primary;
$job-header-breadcrumb-link-arrow-width: 0.625rem;
$job-header-breadcrumb-link-arrow-height: 1rem;
$job-header-breadcrumb-link-arrow-margin-top: -0.5rem;

$job-nav-padding: 0 1rem;
$job-nav-background: $white;
$job-nav-box-shadow: 0 3px 5px rgba(#000000, 0.08);

$job-body-padding: 2rem .5rem 2rem;
$job-body-right-width: 0 0 28.25rem;

// Job Applied Note

$job-applied-note-font-size: 1.125rem;
$job-applied-note-font-weight: 600;
$job-applied-note-padding: 1rem 2.5rem;
$job-applied-note-border-radius: 0.25rem;
$job-applied-note-background: $table-background;
$job-applied-note-border: 1px solid $border-color;
$job-applied-note-line-height: 1.225;

$job-applied-note-icon-left: 1.25rem;
$job-applied-note-icon-width: 1rem;
$job-applied-note-icon-height: 1rem;
$job-applied-note-icon-margin: -0.5rem;

// Jobs

$jobs-banner-height: 30rem;
$jobs-banner-padding: 0 1rem;
$jobs-banner-color: $white;
$jobs-banner-inner-padding: 2rem 0;
$jobs-banner-title-font-size: 3rem;
$jobs-banner-title-font-weight: 600;
$jobs-banner-text-font-size: 1.25rem;

$jobs-list-padding: 0 1rem;
$jobs-list-inner-padding: 1rem 0 2rem;

// Profile
//---------------------------------------------

$profile-header-background: #213558;
$profile-header-padding: 2rem 0 0;
$profile-header-parent-column-padding: 0 1rem 1rem;
$profile-header-parent-column-border: 1px solid #4a5162;

$profile-nav-background: $white;
$profile-nav-padding: 1rem 1rem 0;
$profile-nav-box-shadow: 0 3px 5px rgba(#000000, .08);

$profile-body-padding: 2rem 1rem 2rem;

// Workflows
//---------------------------------------------

// Workflow Content

$workflow-content-info-width: 37.5rem;

// Workflow Stages

$workflow-stages-border: 1px solid $border-color;

$workflow-stages-wrapper-border: 4px solid #cfe6f7;
$workflow-stages-wrapper-border-radius: 0.5rem;
$workflow-stages-wrapper-padding: 3rem 2rem 2rem;
$workflow-stages-wrapper-title-font-size: 1.25rem;
$workflow-stages-wrapper-title-font-weight: 600;
$workflow-stages-wrapper-title-color: $blue-button-primary;
$workflow-stages-wrapper-title-background: #f8f8f8;
$workflow-stages-wrapper-title-padding: 0 0.25rem;
$workflow-stages-wrapper-title-top: -1rem;
$workflow-stages-wrapper-title-left: 2rem;

$workflow-stages-wrapper-button-margin: 1rem;

$workflow-stages-wrapper-distance: 3rem;

$workflow-stages-wrapper-line-top: -3.25rem;
$workflow-stages-wrapper-line-height: 3rem;
$workflow-stages-wrapper-line-width: 0.25rem;
$workflow-stages-wrapper-line-background: #cfe6f7;
$workflow-stages-wrapper-line-margin-left: -0.125rem;
$workflow-stages-wrapper-item-distance: 1rem !important;

$workflow-stages-list-item-background: $table-background;
$workflow-stages-list-item-border: 2px solid $blue-button-primary;
$workflow-stages-list-item-border-radius: 0.375rem;
$workflow-stages-list-item-distance: 2rem;
$workflow-stages-list-item-padding: 1.25rem 1.75rem;
$workflow-stages-list-item-box-shadow: 0 3px 7px rgba(#000000, 0.08);

$workflow-stages-list-item-handler-width: 0.625rem;
$workflow-stages-list-item-handler-height: 2rem;
$workflow-stages-list-item-handler-left: 0.5rem;
$workflow-stages-list-item-handler-margin: -1rem;

$workflow-stages-list-item-header-padding: 0 14rem 0 4.375rem;
$workflow-stages-list-item-header-min-height: 4.375rem;
$workflow-stages-list-item-header-padding-no-icon: 0 14rem 0 0.25rem;

$workflow-stages-list-item-icon-height: 3.375rem;
$workflow-stages-list-item-icon-width: 3.375rem;
$workflow-stages-list-item-icon-top: 0.5rem;

$workflow-stages-list-item-title-font-size: 1.125rem;
$workflow-stages-list-item-title-font-weight: 600;
$workflow-stages-list-item-title-margin: 0 0 0.125rem;

$workflow-stages-list-item-type-font-size: 1rem;
$workflow-stages-list-item-type-color: $icon-color-dark;

$workflow-stages-list-item-link-font-size: 0.9375rem;
$workflow-stages-list-item-link-color: $text-color-dark;
$workflow-stages-list-item-link-padding: 0 0 0 1.25rem;
$workflow-stages-list-item-link-margin: 0.125rem 0 0;
$workflow-stages-list-item-link-line-height: 1.6rem;
$workflow-stages-list-item-link-icon-width: 0.75rem;
$workflow-stages-list-item-link-icon-height: 1.5rem;

$workflow-stages-list-item-button-font-size: 0.9375rem;
$workflow-stages-list-item-button-color: $text-color-dark;
$workflow-stages-list-item-button-padding: 0 0 0.5rem 1.75rem;
$workflow-stages-list-item-button-margin: 0.125rem 0 0;
$workflow-stages-list-item-button-line-height: 1.6rem;
$workflow-stages-list-item-button-icon-width: 1.5rem;
$workflow-stages-list-item-button-icon-height: 1.5rem;

$workflow-stages-list-item-body-padding: 0 0 0 4.375rem;

$workflow-stages-actions-margin: 1.25rem 0 1.25rem;
$workflow-stages-actions-border: 1px solid $border-color;
$workflow-stages-actions-border-radius: 0.375rem;
$workflow-stages-actions-item-background: $text-color-light;
$workflow-stages-actions-item-font-size: 0.9375rem;
$workflow-stages-actions-item-padding: 1rem 5.5rem 1rem 3.625rem;
$workflow-stages-actions-item-min-height: 3.125rem;
$workflow-stages-actions-item-control-right: 1rem;
$workflow-stages-actions-item-control-top: 0.6875rem;

$workflow-stages-actions-item-icon-top: 0.4375rem;
$workflow-stages-actions-item-icon-left: 1rem;
$workflow-stages-actions-item-icon-width: 2.25rem;
$workflow-stages-actions-item-icon-height: 2.25rem;

$workflow-stages-controls-padding: 1rem 0;

$workflow-stages-list-form-background: $table-background;
$workflow-stages-list-form-border: 2px solid $border-color;
$workflow-stages-list-form-border-radius: 0.375rem;

$workflow-stages-list-form-title-font-size: 1.125rem;
$workflow-stages-list-form-title-font-weight: 600;

$workflow-stages-list-form-header-padding: 1.25rem 2rem;
$workflow-stages-list-form-header-border: 1px solid $border-color;

$workflow-stages-list-form-body-padding: 1rem 1rem;

$workflow-stages-list-form-footer-padding: 0 2rem 2rem;
$workflow-stages-list-form-footer-button-min-width: 6.25rem;
$workflow-stages-list-form-footer-button-distance: 0.75rem;

// Workflow Action Group

$worlflow-actions-group-title-font-size: 1.125rem;
$worlflow-actions-group-title-fonr-weight: 600;
$worlflow-actions-group-title-color: $blue-button-primary;
$worlflow-actions-group-title-margin: 0 0 0.5rem;

$worlflow-actions-group-list-item-border: 1px solid $border-color;
$worlflow-actions-group-list-item-border-radius: 0.25rem;
$worlflow-actions-group-list-item-padding: 0.875rem 1rem 0.875rem 4.5rem;
$worlflow-actions-group-list-item-margin: 0.5rem;
$worlflow-actions-group-list-item-font-size: 1rem;

$worlflow-actions-group-list-item-icon-left: -1px;
$worlflow-actions-group-list-item-icon-top: -1px;
$worlflow-actions-group-list-item-icon-bottom: -1px;
$worlflow-actions-group-list-item-icon-border-radius: 0.25rem 0 0 0.25rem;
$worlflow-actions-group-list-item-icon-width: 3.5rem;

$worlflow-actions-group-list-color-workflow: $blue-button-primary;
$worlflow-actions-group-list-color-communication: #a0a8b7;
$worlflow-actions-group-list-color-task: #54a5b4;
$worlflow-actions-group-list-color-interview: $green-primary;
$worlflow-actions-group-list-color-assessments: #77c4b2;
$worlflow-actions-group-list-color-questionarie: #aac478;
$worlflow-actions-group-list-color-candidate: #925c8b;
$worlflow-actions-group-list-color-owner: #e37983;
$worlflow-actions-group-list-color-other: #e68870;

$worlflow-actions-group-item-distance: 0.5rem;

//Error Pages
$error-container-height: 100vh;
$error-container-width: 100vw;
$error-image-width: 9rem;
$error-image-height: 10rem;
$error-message-font-size: 1.625rem;
$error-message-font-weight: 400;
$error-assistance-text-color: $blue-button-primary;
$error-assistance-text-font-size: 1.25rem;
$error-assistance-icon-margin-right: 0.4rem;
$error-btn-margin-left: 1rem;
$error-assistance-icon-width: 0.625rem;
$error-assistance-icon-height: 1rem;
$error-assistance-icon-position-left: -8%;
$error-assistance-icon-position-top: 15%;
// Workflow Chart

$workflow-chart-element-padding: 0 1rem;

$workflow-chart-parallel-padding: 1.5rem 0;
$workflow-chart-parallel-line-height: 0.125rem;
$workflow-chart-parallel-line-background: $border-color;
$workflow-chart-parallel-element-line-top: -1.5rem;
$workflow-chart-parallel-element-line-margin: -0.0625rem;
$workflow-chart-parallel-element-line-width: 0.125rem;
$workflow-chart-parallel-element-line-height: 1.5rem;

$workflow-chart-connection-padding: 1rem 0;
$workflow-chart-connection-link-padding: 0 0 0 1.75rem;
$workflow-chart-connection-link-height: 1.375rem;
$workflow-chart-connection-link-font-size: 0.875rem;
$workflow-chart-connection-link-color: $text-color-dark;
$workflow-chart-connection-link-icon-width: 1.375rem;
$workflow-chart-connection-link-icon-height: 1.375rem;
$workflow-chart-connection-link-margin-left: -0.6875rem;
$workflow-chart-connection-line-margin: -0.0625rem;
$workflow-chart-connection-line-height: 1.0625rem;
$workflow-chart-connection-line-width: 0.125rem;
$workflow-chart-connection-line-background: $border-color;

$workflow-chart-placeholder-height: 1.5rem;
$workflow-chart-placeholder-icon-height: 1.5rem;
$workflow-chart-placeholder-icon-width: 1.5rem;
$workflow-chart-placeholder-icon-border: 2px dashed $border-color;
$workflow-chart-placeholder-icon-margin: -0.75rem;

$workflow-chart-trigger-height: 2.5rem;
$workflow-chart-trigger-border-radius: 1.25rem;
$workflow-chart-trigger-background: #213558;
$workflow-chart-trigger-padding: 0.5rem 2.5rem 0.5rem 3.25rem;
$workflow-chart-trigger-title-font-size: 1rem;
$workflow-chart-trigger-title-font-weight: 400;
$workflow-chart-trigger-title-color: $white;
$workflow-chart-trigger-icon-left: 2.5rem;
$workflow-chart-trigger-icon-margin: -0.6875rem;
$workflow-chart-trigger-icon-width: 0.875rem;
$workflow-chart-trigger-icon-height: 1.375rem;

$workflow-chart-action-width: 20rem;
$workflow-chart-action-min-height: 8.25rem;
$workflow-chart-action-border-radius: 0.25rem;
$workflow-chart-action-border-width: 1px;
$workflow-chart-action-background: #f9f9f9;
$workflow-chart-action-header-border-radius: 0.25rem 0.25rem 0 0;
$workflow-chart-action-header-padding: 0.625rem 3rem 0.625rem 3.25rem;
$workflow-chart-action-header-title-font-size: 0.9375rem;
$workflow-chart-action-header-title-font-weight: 600;
$workflow-chart-action-header-edit-right: 0.75rem;
$workflow-chart-action-header-edit-width: 1.25rem;
$workflow-chart-action-header-edit-height: 1.25rem;
$workflow-chart-action-header-edit-margin: -0.625rem;
$workflow-chart-action-header-icon-width: 2.5rem;
$workflow-chart-action-header-icon-border-radius: 0.25rem 0 0 0;
$workflow-chart-action-body-padding: 1rem 1.25rem;
$workflow-chart-action-body-font-size: 0.9375rem;
$workflow-chart-action-body-line-height: 1.325;

$workflow-chart-action-placeholder-border-color: $blue-button-primary;
$workflow-chart-action-placeholder-box-shadow: 0 0 0 2px rgba($blue-button-secondary, 0.5);
$workflow-chart-action-placeholder-font-size: 1rem;
$workflow-chart-action-placeholder-color: $blue-button-primary;

$workflow-chart-condition-width: 44rem;
$workflow-chart-condition-header-border-radius: 0.25rem;
$workflow-chart-condition-header-background: $blue-button-primary;
$workflow-chart-condition-header-width: 12.5rem;
$workflow-chart-condition-header-padding: 1.25rem 1.25rem;
$workflow-chart-condition-header-title-font-size: 1rem;
$workflow-chart-condition-header-title-font-weight: 400;
$workflow-chart-condition-header-title-color: $white;
$workflow-chart-condition-header-margin: 0 0 1.5rem;
$workflow-chart-condition-branches-width: 44rem;
$workflow-chart-condition-branches-padding: 1.5rem 0 0;
$workflow-chart-condition-branches-item-width: 22rem;
$workflow-chart-condition-branches-item-border-radius: 0.25rem;
$workflow-chart-condition-branches-item-color: $white;
$workflow-chart-condition-branches-item-padding: 0.5rem 1.5rem 0.5rem 1.5rem;
$workflow-chart-condition-branches-item-font-size: 1rem;
$workflow-chart-condition-branches-item-background-positive: $green-secondary;
$workflow-chart-condition-branches-item-background-negative: $accent-color-4;
$workflow-chart-condition-branches-line-top: 0;
$workflow-chart-condition-branches-line-left: 3rem;
$workflow-chart-condition-branches-line-width: 22rem;
$workflow-chart-condition-branches-line-height: 0.125rem;
$workflow-chart-condition-branches-line-background: $border-color;
$workflow-chart-condition-line-top: -1.4375rem;
$workflow-chart-condition-line-bottom: -1.4375rem;
$workflow-chart-condition-line-margin: -0.0625rem;
$workflow-chart-condition-line-width: 0.125rem;
$workflow-chart-condition-line-height: 1.5rem;
$workflow-chart-condition-line-backgound: $border-color;

// Trigger Group

$trigger-group-title-font-size: 1.25rem;
$trigger-group-title-font-weight: 600;
$trigger-group-title-margin: 0 0 0.5rem;
$trigger-group-title-padding: 0 0 0 2rem;

$trigger-group-list-padding: 0 0 0 2rem;
$trigger-group-list-distance: 0.375rem;
$trigger-group-list-link-font-size: 1.125rem;
$trigger-group-list-link-color: $text-color-dark;
$trigger-group-list-link-padding: 0 0 0 1.75rem;
$trigger-group-list-link-icon-width: 1.375rem;
$trigger-group-list-link-icon-height: 1.375rem;

$trigger-group-icon-width: 1.625rem;
$trigger-group-icon-height: 1.5rem;

// Process Stages

$process-stages-item-background: $white;
$process-stages-item-border: 1px solid $border-color;
$process-stages-item-border-radius: 0.75rem;
$process-stages-item-box-shadow: 0 3px 6px rgba(#000000, 0.16);
$process-stages-item-width: 8.125rem;
$process-stages-item-height: 8rem;
$process-stages-item-margin: 0.5rem 0.5rem;
$process-stages-item-padding: 1rem 1rem;

$process-stages-item-icon-height: 3.75rem;
$process-stages-item-icon-margin: 0 0 0.75rem;

$process-stages-item-title-font-size: 0.875rem;
$process-stages-item-title-font-weight: 600;
$process-stages-item-title-color-static: $text-color-placeholders;
