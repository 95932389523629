// List checkbox Options
//---------------------------------------------

.list-checkbox-options {
  list-style-type: none;
  margin: 0;
  padding: 0;

  &__item {
    position: relative;
    display: flex;
    align-items: center;
    padding: $list-checkbox-options-item-padding;
  }

  &__controls {
    @include link-group;

    position: absolute;
    right: 0;
    top: $list-checkbox-options-controls-top;
  }
}

// List Sortable
//---------------------------------------------

.list-sortable {
  border: $list-sortable-border;
  border-radius: $list-sortable-border-radius;
  margin: $list-sortable-margin;

  &__item {
    cursor: move;

    &__inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      padding: $list-sortable-item-inner-padding;
      background-color: $list-sortable-item-inner-background;

      &::before {
        content: '';
        position: absolute;
        left: $list-sortable-item-handler-left;
        top: $list-sortable-item-handler-top;
        height: $list-sortable-item-handler-height;
        width: $list-sortable-item-handler-width;
        margin: $list-sortable-item-handler-margin;
        background: url('/itarp-shared-assets-addon/images/icon_handler_small.svg') center center / contain no-repeat;
      }
    }

    &__title {
      font-size: $list-sortable-item-title-font-size;
      font-weight: $list-sortable-item-title-font-weight;
      margin: $list-sortable-item-title-margin;
    }

    &__controls {
      @include link-group;
    }

    &:first-child {

      &,
      & .list-sortable__item__inner {
        border-radius: $list-sortable-item-border-radius-first;
      }
    }

    &:last-child {

      &,
      & .list-sortable__item__inner {
        border-radius: $list-sortable-item-border-radius-last;
      }
    }

    &.is-dragging-object {
      border: $list-sortable-border;
      border-radius: $list-sortable-border-radius;

      .list-sortable__item__controls {
        visibility: hidden;
      }
    }

    &.for-adding {
      cursor: auto;
    }
  }

  &__form {
    padding: $list-sortable-form-padding;
    background-color: $list-sortable-form-background;

    &__input {
      padding: 0 !important;
    }

    &__controls {
      display: flex;
      align-items: center;
      margin: $list-sortable-form-controls-margin;

      .app-btn {
        min-width: $list-sortable-form-controls-min-width;
      }

      .app-btn+.app-btn {
        margin-left: $list-sortable-form-controls-distance;
      }
    }
  }

  &__collapse {
    &__header {
      cursor: move;

      button {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        padding: .75rem 1.75rem;
        background-color: $list-sortable-item-inner-background;
        font-weight: 600;

        &::before {
          content: '';
          position: absolute;
          left: $list-sortable-item-handler-left;
          top: $list-sortable-item-handler-top;
          height: $list-sortable-item-handler-height;
          width: $list-sortable-item-handler-width;
          margin: $list-sortable-item-handler-margin;
          background: url('/itarp-shared-assets-addon/images/icon_handler_small.svg') center center / contain no-repeat;
        }

        &::after {
          content: '';
          position: absolute;
          right: 1rem;
          top: 50%;
          margin-top: -.375rem;
          width: .75rem;
          height: .75rem;
          background: url('/itarp-shared-assets-addon/images/arrow_down_dark.svg') center center / contain no-repeat;
        }

        &[aria-expanded="true"] {
          &::after {
            transform: rotate(270deg);
          }
        }
      }
    }
    
    &__panel {
      .list-sortable {
        border-radius: 0;
        border: none;
        margin-bottom: 0;
      }

      .list-sortable__item {
        border-top: $list-sortable-border;
      }

      .list-sortable__item__inner {
        padding-left: 2.75rem;

        &::before {
          left: 1.75rem;
        }
      }

      .list-sortable__item__title {
        font-weight: 400 !important;
      }
    }
  }

  &__item+&__item {
    border-top: $list-sortable-border;
  }
}

// Collaborators
//---------------------------------------------

.collaborators {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: inline-flex;
  position: relative;

  &__item {
    position: relative;

    &__trigger,
    &__other,
    &__new {
      display: flex;
      width: $collaborators-item-element-width;
      height: $collaborators-item-element-height;
      border: $collaborators-item-element-border;
      border-radius: 50%;
      user-select: none;
    }

    &__trigger,
    &__new {
      outline: 0;
      cursor: pointer;
    }

    &__trigger,
    &__other {

      &,
      &:hover {
        background-color: $collaborators-item-trigger-background;
        color: $collaborators-item-trigger-color;
        font-weight: $collaborators-item-trigger-font-weight;
      }
    }

    &__trigger {
      align-items: center;
      justify-content: center;
      font-size: $collaborators-item-trigger-font-size;
    }

    &__other {
      background-image: url('/itarp-shared-assets-addon/images/buttons/person.svg');
      background-position: $collaborators-item-other-left center;
      background-repeat: no-repeat;
      padding: $collaborators-item-other-padding;
      font-size: $collaborators-item-other-font-size;
    }

    &__new {
      background: $collaborators-item-new-background url('/itarp-shared-assets-addon/images/buttons/person_new.svg') $collaborators-item-new-left center no-repeat;
    }
  }

  &__item+&__item {
    margin-right: $collaborators-item-distance;
  }

  @for $i from 1 to 5 {
    &__item:nth-child(#{$i}) {
      z-index: $i + 1;
      order: 5 - $i;
    }
  }
}

// Page Steps
//---------------------------------------------

.page-steps {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: inline-flex;
  counter-reset: page-steps;

  &__item {
    padding: $page-steps-item-padding;
    border-right: $page-steps-item-border-right;
    counter-increment: page-steps;

    .page-steps__link {
      &::before {
        content: counter(page-steps, decimal-leading-zero) '.';
        display: inline-block;
        margin-right: $page-steps-link-number-margin;
      }
    }
  }

  &__link {
    display: block;
    outline: 0;
    user-select: none;
    font-size: $page-steps-link-font-size !important;
    font-weight: $page-steps-link-font-weight !important;
    text-transform: uppercase;
    text-decoration: none;
    padding: $page-steps-link-padding;
    color: $page-steps-link-color;
    border-radius: $page-steps-link-border-radius;
    min-width: $page-steps-link-min-width;
    line-height: $page-steps-link-line-height;
    font-family: 'proxima-nova', sans-serif !important;

    &:hover,
    &:focus {
      color: $page-steps-link-color;
      background-color: $page-steps-link-background-active;
    }

    &.active,
    &.is-active {
      background-color: $page-steps-link-background-active;
    }
  }
}

// Page Sidenav
//---------------------------------------------

.page-sidenav {
  list-style-type: none;
  margin: 0;
  padding: 0;

  &__link {
    position: relative;
    display: flex;
    align-items: center;
    font-size: $page-sidenav-link-font-size !important;
    font-weight: $page-sidenav-link-font-weight !important;
    color: $page-sidenav-link-color;
    padding: $page-sidenav-link-padding;
    height: $page-sidenav-link-height;
    outline: 0;
    user-select: none;
    cursor: pointer;
    text-decoration: none;
    font-family: 'proxima-nova', sans-serif !important;

    &:hover,
    &:focus {
      color: $page-sidenav-link-color;
    }

    &::before {
      content: '';
      display: inline-block;
      position: absolute;
      left: $page-sidenav-link-icon-left;
      top: 50%;
      margin-top: $page-sidenav-link-icon-margin-top;
      width: $page-sidenav-link-icon-width;
      height: $page-sidenav-link-icon-height;
      border-radius: 50%;
      background-color: $page-sidenav-link-icon-background;
    }

    $sidenav-links: 'basics''expertise', 'arrangement''work-arrangement',
      'application''application', 'scorecard''scorecard', 'guide''guide',
      'workflow''workflow', 'preview''preview';

    @each $link,
    $icon in $sidenav-links {
      &.for-#{$link} {
        &::before {
          background-image: url('/itarp-shared-assets-addon/images/sidebar/page-sidebar/#{$icon}.svg');
          background-position: center center;
          background-repeat: no-repeat;
        }
      }
    }

    &.active,
    &.is-active {
      background-color: $page-sidenav-link-background-active;

      &::before {
        background-color: $page-sidenav-link-icon-background-active;
      }
    }
  }

  &__item+&__item {
    margin-top: $page-sidenav-item-distance;
  }
}

// List Assets
//---------------------------------------------

.list-assets {
  &__title {
    font-size: $list-assets-title-font-size;
    font-weight: $list-assets-title-font-weight;
    margin: $list-assets-title-margin;
  }

  &__list {
    list-style-type: none;
    margin: 0;
    padding: 0;

    &__item {
      display: flex;
      align-items: center;
      padding: $list-assets-item-padding;
      border-bottom: $list-assets-item-border;

      &__title {
        flex: 1;
        padding: $list-assets-item-title-padding;

        &,
        & a {
          font-size: $list-assets-item-title-font-size;
          font-weight: $list-assets-item-title-font-weight;
        }
      }

      &__controls {
        @include link-group('large');
      }
    }
  }
}

// Board Posting
//---------------------------------------------

.board-postings {
  border: $board-posting-border;

  &__item {
    display: flex;
    align-items: center;
    padding: $board-posting-item-padding;
    background: $board-posting-item-background;

    >*+* {
      margin-left: $board-posting-item-distance;
    }

    &__logo {
      width: $board-posting-item-logo-width;
      height: $board-posting-item-logo-height;

      $postings-list: 'glassdoor',
      'indeed',
      'linkedin',
      'monster',
      'neuvoo',
      'nexxt',
      'upwork',
      'ziprecruiter',
      'dribbble';

      @each $item in $postings-list {
        &.for-#{$item} {
          background: url('/itarp-shared-assets-addon/images/postings/#{$item}.png') center center / contain no-repeat;
        }
      }
    }

    &__title {
      width: $board-posting-item-title-width;

      h4 {
        font-size: $board-posting-item-title-h4-font-size;
        margin-bottom: $board-posting-item-title-h4-margin-bottom;
      }

      a {
        font-size: $board-posting-item-title-link-font-size;
      }
    }

    &__status {
      width: $board-posting-item-status-width;

      &__mark {
        position: relative;
        font-size: $board-posting-item-status-font-size;
        font-weight: $board-posting-item-status-font-weight;
        padding: $board-posting-item-status-padding;

        &::before {
          content: '';
          position: absolute;
          display: inline-block;
          left: 0;
          top: 50%;
          width: $board-posting-item-status-dot-width;
          height: $board-posting-item-status-dot-height;
          margin-top: $board-posting-item-status-dot-margin-top;
          border-radius: 50%;
        }

        $item-statuses: 'not-posted'$board-posting-item-status-color-draft,
          'posted'$board-posting-item-status-color-published;

        @each $status,
        $color in $item-statuses {
          &.is-#{$status} {
            color: $color;

            &::before {
              background-color: $color;
            }
          }
        }
      }
    }

    &__date {
      width: $board-posting-item-date-width;
    }

    &__switch {
      display: flex;
      justify-content: flex-end;
      flex: 1;
    }
  }

  &__item+&__item {
    border-top: $board-posting-border;
  }
}

.board-note {
  border: $borad-note-border;
  border-radius: $borad-note-border-radius;
  padding: $borad-note-padding;
  background: $borad-note-background;

  &__text {
    font-size: $borad-note-text-font-size;
    margin-bottom: 0;
  }
}

// List Jobs
//---------------------------------------------

.list-jobs__wrapper {
  padding: $list-jobs-wrapper-padding;

  &__title {
    font-size: $list-jobs-wrapper-title-font-size;
    font-weight: $list-jobs-wrapper-title-font-weight;
    padding: $list-jobs-wrapper-title-padding;
    margin: 0;
  }
}

.list-jobs {
  margin: 0;
  padding: 0;
  list-style-type: none;

  &__item {
    position: relative;
    padding: $list-jobs-item-padding;
    border-top: $list-jobs-item-border;

    &:last-child {
      border-bottom: $list-jobs-item-border;
    }

    &.is-active {
      background-color: $list-jobs-item-background-active;
      border-top: $list-jobs-item-border-active;
      border-bottom: $list-jobs-item-border-active;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: $list-jobs-item-bar-width-active;
        background-color: $list-jobs-item-bar-background-active;
      }
    }
  }
}

// List Account
//---------------------------------------------

.list-account {
  margin: 0;
  padding: 0;
  list-style-type: none;

  &__item {
    display: flex;
    align-items: center;

    &__title {
      flex: 1;
      position: relative;
      font-size: $list-account-item-title-font-size;
      font-weight: $list-account-item-title-font-weight;
      padding: $list-account-item-title-padding;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        width: $list-account-item-title-icon-width;
        height: $list-account-item-icon-height;
      }

      $item-icons: 'work' 'work',
        'expertise' 'expertise',
        'basics' 'info';

      @each $name,
      $icon in $item-icons {
        &.for-#{$name} {
          &::before {
            background-image: url('/itarp-business-application/assets/images/cards/icon_#{$icon}.svg');
            background-repeat: no-repeat;
            background-position: center center;
            border-radius: 50%;
            background-color: $list-account-item-icon-background;
          }
        }
      }
    }

    &__status {
      position: relative;
      margin: $list-account-item-status-margin;
      width: $list-account-item-status-width;
      height: $list-account-item-status-height;

      $status-icons: 'filled' 'yes',
      'missing' 'no';

      @each $status,
      $icon in $status-icons {
        &.is-#{$status} {
          background: url('/itarp-business-application/assets/images/check_#{$icon}.png') center center no-repeat;
        }
      }
    }
  }

  &__item+&__item {
    margin-top: $list-account-item-distance;
  }

  @media only screen and (max-width: 768px) {
    &__item {
      &__title {
        font-size: 1.125rem;
      }
    }
  }

  &.is-bordered {
    .list-account__item {
      border-top: 1px solid $border-color;
      padding: 1rem 0;

      &:last-child {
        border-bottom: 1px solid $border-color;
      }
    }

    .list-account__item+.list-account__item {
      margin-top: 0;
    }
  }
}
