// Pagination
//---------------------------------------------

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;

  > * {
    margin-left: $pagination-elements-distance;
  }

  &-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: inline-flex;
    border: $pagination-list-border;
    border-radius: $pagination-list-border-radius;

    &__link {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: $pagination-list-link-font-size;
      font-weight: $pagination-list-link-font-weight;
      color: $pagination-list-link-color;
      height: $pagination-list-link-height;
      min-width: $pagination-list-link-min-width;
      padding: $pagination-list-link-padding;
      background-color: $pagination-list-link-background;
      text-decoration: none;
    }

    &__item {
      &.is-active {
        .pagination-list__link {
          color: $pagination-list-link-color-active;
          background-color: $pagination-list-link-background-active;
        }  
      }

      &.is-next,
      &.is-prev {
        .pagination-list__link {
          background-position: center center;
          background-repeat: no-repeat;
        }
      }

      &.is-next {
        .pagination-list__link {
          background-image: url('/itarp-shared-assets-addon/images/arrow_next.svg');
        }
      }

      &.is-prev {
        .pagination-list__link {
          background-image: url('/itarp-shared-assets-addon/images/arrow_prev.svg');
        }
      }
    }

    &__item + &__item {
      border-left: $pagination-list-border; 
    }
  }
}

// Pagination Sizes
//---------------------------------------------

.pagination-list {
  &.pagination-list--sm {
    .pagination-list__link {
      font-size: $pagination-list-link-font-size-sm;
      height: $pagination-list-link-height-sm;
      min-width: $pagination-list-link-min-width-sm;
    }
  }
}
