// Modals
//---------------------------------------------

// Ember Modal Overrides
//---------------------------------------------

.ember-modal-overlay {
  z-index: 1050;

  &.translucent {
    background-color: rgba(0, 0, 0, .8);
  }
}  

.ember-modal-dialog { 
  padding: 0 !important;
}

// Modal Clasic
//---------------------------------------------

.modal-clasic {
  // Ember Modal Overrides

  .ember-modal-overlay {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  } 

  .ember-modal-dialog {
    display: flex;
    align-items: center;
    position: relative;
    top: auto !important;
    left: auto !important;
    transform: none !important;
    width: 100%;
    max-width: 36.625rem;
    min-height: calc(100% - 4rem);
    margin: 2rem auto;
    padding: 0;
    z-index: 1051;
    background-color: transparent; 
    box-shadow: none;
  }

  // Content

  &__content {
    width: 100%;
    background-color: #FFF;
    border-radius: .25rem;
    min-height: 30rem;
  }

  &__header {
    border-radius: .25rem .25rem 0 0;
    position: relative;
    padding: 1.25rem 3rem 1.25rem 1.5rem;
    border-bottom: 1px solid #D1DBE3;

    &__title {
      font-size: 1.375rem;
      font-weight: 600;
      letter-spacing: -.2px;
      margin: 0;
    }

    &__close {
      position: absolute;
      right: 1.5rem;
      top: 50%;
      margin: -.625rem 0 0;
      display: inline-block;
      width: 1.25rem;
      height: 1.25rem;
      outline: 0;
      border: none;
      background: transparent url('/itarp-shared-assets-addon/images/btn_cancel_dark.svg') center center / contain no-repeat;
      cursor: pointer;
    }
  }

  &__body {
    height: 100%;
    overflow-y: auto;
    border-radius: 0 0 .25rem .25rem;
    padding: 1.25rem 1.25rem;
  }
}  

// Modal Popup
//---------------------------------------------

.modal-popup {
  // Ember Modal Overrides

  .ember-modal-overlay {
    width: 40rem;
    height: calc(100vh - 10.5rem);
    overflow-x: hidden;
    overflow-y: auto;
    top: 10.5rem;
    bottom: 0;
    left: 0;
    right: auto;
  } 

  .ember-modal-dialog {
    top: 10.5rem !important;
    left: 0 !important;
    bottom: 0;
    transform: none !important;
    width: 40rem;
    border-radius: 0;
    height: calc(100vh - 10.5rem);
    box-shadow: 4px 0 2px -2px rgba(0, 0, 0, .15);
  } 
  
  &--right {
    .ember-modal-overlay {
      right: 0 !important;
      left: auto !important;
    }  

    .ember-modal-dialog {
      position: fixed;
      left: auto !important;
      right: 0 !important;
      box-shadow: -4px 0 2px -2px rgba(0, 0, 0, .15);
      height: calc(100vh - 10.5rem);
    }
  }

  // Content

  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 10.5rem);
  }

  &__header {
    position: relative;
    padding: 1.25rem 3rem 1.25rem 1.5rem;
    color: #FFF;
    background-color: #8A90A3;

    &__title {
      font-size: 1.5rem;
      font-weight: 600;
      letter-spacing: -.2px;
      margin: 0;
    }

    &__close {
      position: absolute;
      right: 1.5rem;
      top: 50%;
      margin: -.625rem 0 0;
      display: inline-block;
      width: 1.25rem;
      height: 1.25rem;
      outline: 0;
      border: none;
      background: transparent url('/itarp-shared-assets-addon/images/btn_cancel.svg') center center / contain no-repeat;
      cursor: pointer;
    }
  }

  &__body {
    padding: 1.25rem 1.5rem 2rem;
    flex: 1;
    background-color: #fff;
  }

  &.modal-popup--sm {
    .ember-modal-overlay,
    .ember-modal-dialog {
      width: 30rem;
    }  
  }
}
