// Toolbar
//---------------------------------------------

.toolbar {
  position: fixed;
  bottom: 0;
  top: 10.5rem;
  z-index: 50;
  background-color: #FFF;
  box-shadow: 0px 3px 15px #00000040;

  &.toolbar--right {
    right: 0;
  }

  &.toolbar--left {
    left: 0;
  }
}

// Toolbar for Triggers

.toolbar--triggers {
  width: 17.5rem;
  background-color: rgba(#EBEBED, .15);

  .toolbar__content {
    padding: 1.5rem 1rem;
    overflow-y: auto;
    height: calc(100vh - 10.5rem);
  }
}

.trigger-group {
  padding: .75rem 0;
  
  &__title {
    margin: 0 0 .5rem;
  }

  &__list {
    list-style-type: none;
    margin: 0;
    padding: 0 0 0 1rem;

    &__link {
      display: block;
      position: relative;
      padding: 0 0 0 1.5rem;
      opacity: .75;
      cursor: pointer;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        margin: -.5rem 0 0;
        width: 1rem;
        height: 1rem;
        background: url('/itarp-shared-assets-addon/images/icon_add_circle_outline.svg') center center / contain no-repeat;
      }

      &:hover,
      &:focus {
        opacity: 1;
      }
    }

    &__item + &__item {
      margin-top: .25rem;
    }  
  }
}

// Toolbar for Actions

.toolbar--actions {
  width: 32.5rem;

  &__inner {
    position: relative;
    height: 100%;
  }

  &__close {
    position: absolute;
    right: 1.5rem;
    top: 2rem;
    margin: -.625rem 0 0;
    display: inline-block;
    width: 1.25rem;
    height: 1.25rem;
    outline: 0;
    border: none;
    background: transparent url('/itarp-shared-assets-addon/images/btn_cancel.svg') center center / contain no-repeat;
    cursor: pointer;
  }

  &__header {
    padding: 1.25rem 1.5rem 1.25rem 1.5rem;
    background-color: #8A90A3;
    color: #FFF;

    &__title {
      margin: 0;
    }
  }

  &__body {
    padding: 1rem 1.5rem 2rem;
    overflow-y: auto;
    height: calc(100vh - 13.125rem);
  }
}

.action-group {
  padding: .75rem 0;

  &__title {
    color: #4BA4DA;
    margin: .5rem 0;
  }

  &__item {
    position: relative;
    padding: 0 2.5rem 0 0;
    font-size: 1rem;
    padding: .75rem 1.5rem .75rem 5rem;
    display: block;
    position: relative;
    cursor: pointer;
    border-radius: .25rem;
    border: 2px solid #C7DDF2;

    &::before {
      content: '';
      position: absolute;
      left: -2px;
      top: -2px;
      bottom: -2px;
      width: 3.5rem;
      border-radius: .25rem 0 0 .25rem;
    }

    $action-items: 'workflow' #4BA4DA,
                   'internal-communication' #A0A8B7,
                   'external-communication' #A0A8B7,
                   'task' #54A5B4,
                   'interview' #78C493,
                   'assessment' #78C493,
                   'candidate' #925C8B,
                   'owner' #E37983,
                   'other-actions' #E68870;

    @each $action, $color in $action-items {
      &.for-#{$action} {
        &::before {
          background-color: #{$color};
        }
      }
    }               
  }

  &__item + &__item {
    margin-top: .5rem;
  }
}

.action-form {
  &__controlls {
    display: flex;
    align-items: center;

    .app-btn {
      min-width: 10rem;
    }

    .app-btn + .app-btn {
      margin-left: 1rem;
    }
  }
}
