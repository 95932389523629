// Components
//---------------------------------------------

.library-components {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -.5rem;

  &__item {
    flex: 0 0 percentage(math.div(1, 3));
    padding: .5rem .5rem;

    &:nth-child(-n+3) {
      padding-top: 0;
    }
  }
}

.library-component {
  position: relative;
  background-color: #f1f3f5;
  border: 1px solid #d1dbe3;
  padding: 6rem 1rem .5rem;
  border-radius: .25rem;
  font-size: .9375rem;
  font-weight: 600;
  text-align: center;
  height: 9rem;
  cursor: move;
  transform: perspective(1px) translateZ(0);
  -webkit-transition-duration: 0.5s;
          transition-duration: 0.5s;
  -webkit-transition-property: color, background-color;
          transition-property: color, background-color;

  &::before {
    content: '';
    position: absolute;
    height: .5rem;
    width: 1.75rem;
    left: 50%;
    top: 1rem;
    margin: 0 0 0 -.875rem;
    background: url('/itarp-shared-assets-addon/images/icon_handler_h.svg') center center / contain no-repeat;
  }

  &::after {
    content: '';
    position: absolute;
    top: 2rem;
    left: 50%;
    margin: 0 0 0 -3rem;
    width: 6rem;
    height: 3.5rem;
  }

  $component-icons: 'Heading' 'heading',
                    'Image' 'image',
                    'Text' 'text',
                    'Input' 'input',
                    'Checkbox' 'checkbox',
                    'TextArea' 'textarea',
                    'Select' 'select',
                    'PhoneNumber' 'phone',
                    'NavItem' 'link',
                    'FieldGroup' 'form-group',
                    'Tabs' 'tabs',
                    'RadioButton' 'radio',
                    'Links' 'link',
                    'TabsComponent' 'tabs',
                    'Divider' 'divider',
                    'Date' 'date',
                    'Upload' 'upload',
                    'LocationInput' 'location';

  @each $name, $icon in $component-icons {
    &.library-component#{$name} {
      &::after {
        background: url('/itarp-shared-assets-addon/images/components/#{$icon}.svg') center center no-repeat;
      }
    }
  } 
  
  &:hover,
  &:focus {
    background-color: #4BA4DA;
    color: #FFF;

    &::before {
      background: url('/itarp-shared-assets-addon/images/icon_handler_h_active.svg') center center / contain no-repeat;
    }

    @each $name, $icon in $component-icons {
      &.library-component#{$name} {
        &::after {
          background: url('/itarp-shared-assets-addon/images/components/#{$icon}_active.svg') center center no-repeat;
        }
      }
    } 
  }
}
