// Filter Collapse
//---------------------------------------------

// .filter-collapse {
//   // position: absolute;
//   // top: $filter-collapse-top;
//   // left: $filter-collapse-left;

//   &__link {
//     position: relative;
//     display: inline-flex;
//     align-items: center;
//     font-size: $filter-collapse-link-font-size;
//     font-weight: $filter-collapse-link-font-weight;
//     border: $filter-collapse-link-border;
//     border-radius: $filter-collapse-link-border-radius;
//     padding: $filter-collapse-link-padding;
//     color: $filter-collapse-link-color;
//     width: $filter-collapse-link-width;
//     height: $filter-collapse-link-height;
//     background-color: $filter-collapse-link-background;
//     outline: 0;
//     cursor: pointer;
//     user-select: none;

//     &::before {
//       content: '';
//       position: absolute;
//       left: $filter-collapse-link-before-left;
//       top: 50%;
//       margin-top: $filter-collapse-link-before-margin;
//       width: $filter-collapse-link-before-width;
//       height: $filter-collapse-link-before-height;
//       background: url('/itarp-shared-assets-addon/images/buttons/filters.svg') center center no-repeat;
//     }

//     &::after {
//       content: '';
//       position: absolute;
//       right: $filter-collapse-link-after-right;
//       top: $filter-collapse-link-after-top;
//       bottom: $filter-collapse-link-after-bottom;
//       width: $filter-collapse-link-after-width;
//       background: $filter-collapse-link-after-background url('/itarp-shared-assets-addon/images/buttons/ic_arrow_left_white.svg') center center no-repeat;
//       border-radius: $filter-collapse-link-after-border-radius;
//     }

//     &.is-collapsed {
//       &::after {
//         background: $filter-collapse-link-after-background url('/itarp-shared-assets-addon/images/buttons/ic_arrow_right_white.svg') center center no-repeat;
//       }
//     }
//   }
// }

// Filter Sidebar
//---------------------------------------------

.filter-sidebar {
  height: 100vh;
  overflow-y: auto;
}

// Filter Category
//---------------------------------------------

.filter-category {
  padding: $filter-category-padding;

  &__header {
    position: relative;
    padding: $filter-category-header-padding;

    &__title {
      font-size: $filter-category-header-title-font-size;
      font-weight: $filter-category-header-title-font-weight;
      text-transform: uppercase;
      margin: 0;

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        margin-top: $filter-block-header-link-caret-margin-top;
        width: $filter-block-header-link-caret-width;
        height: $filter-block-header-link-caret-height;
        background: url('/itarp-shared-assets-addon/images/arrow_down_dark.svg') center center / contain no-repeat;
      }
    }
  }
}

// Filter Group
//---------------------------------------------

.filter-group {
  border-top: $filter-group-border;

  &__header {
    position: relative;
    padding: $filter-group-header-padding;
    cursor: pointer;

    &::after {
      content: '';
      position: absolute;
      width: $filter-group-header-arrow-width;
      height: $filter-group-header-arrow-height;
      top: 50%;
      right: $filter-group-header-arrow-right;
      margin-top: $filter-group-header-margin;
      background: url('/itarp-shared-assets-addon/images/arrow_down_blue.svg') center center no-repeat;
    }
  }

  &__title {
    margin: 0;
    font-size: $filter-group-title-font-size;
    font-weight: $filter-group-title-font-weight;
    text-transform: uppercase;
  }

  &__body {
    padding: $filter-group-body-padding;
  }

  &.is-collapsed {
    .filter-group__header {
      &::after {
        transform: rotate(-90deg);
      }
    }

    .filter-group__body {
      display: none;
    }
  }
}

// Filter Block
//---------------------------------------------

.filter-blocks {
  overflow: hidden;
  overflow-y: auto;
  height: 100%;
  padding-top: 2rem;

  .filter-block + .filter-block {
    border-top: $filter-block-border;
  }

  &::-webkit-scrollbar {
    width: $table-wrapper-scrollbar-width;
  }

  &::-webkit-scrollbar-track {
    background: $table-wrapper-scrollbar-background;
  }

  &::-webkit-scrollbar-thumb {
    background: $table-wrapper-scrollbar-thumb-background;

    &:hover {
      background: $table-wrapper-scrollbar-thumb-background-hover;
    }
  }
}

.filter-block {
  overflow: hidden;

  &__header {
    padding: $filter-block-header-padding;

    &__title,
    &__link {
      position: relative;
      font-size: $filter-block-header-title-font-size;
      font-weight: $filter-block-header-title-font-weight;
      text-transform: uppercase;
      color: $filter-block-header-title-color;
      margin: 0;

      &:hover,
      &:focus {
        color: $filter-block-header-title-color;
      }
    }

    &__link {
      text-transform: uppercase;
      cursor: pointer;
      outline: 0;
      user-select: none;
      padding-right: $filter-block-header-link-padding-left;

      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 50%;
      }

      &::before {
        left: 0;
      }

      &::after {
        right: 0;
        margin-top: $filter-block-header-link-caret-margin-top;
        width: $filter-block-header-link-caret-width;
        height: $filter-block-header-link-caret-height;
        background: url('/itarp-shared-assets-addon/images/arrow_down_dark.svg') center center / contain no-repeat;
        transform: rotate(180deg);
      }

      &.is-collapsed {
        &::after {
          transform: rotate(0);
        }
      }
    }
  }

  &__body {
    position: relative;
    padding: $filter-block-body-padding;
    min-height: $filter-block-body-min-height;
  }

  &__locked {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-color: $filter-block-locked-background;

    &__inner {
      display: flex;
      align-items: flex-end;
      height: 100%;
      width: 100%;
      padding: $filter-block-locked-inner-padding;
    }

    &__box {
      padding: $filter-block-locked-box-padding;
      border: $filter-block-locked-box-border;
      border-radius: $filter-block-locked-box-border-radius;
      background-color: $filter-block-locked-box-background;
      box-shadow: $filter-block-locked-box-shadow;
    }

    &__title {
      position: relative;
      font-size: $filter-block-locked-title-font-size;
      font-weight: $filter-block-locked-title-font-weight;
      color: $filter-block-locked-title-color;
      margin: $filter-block-locked-title-margin;
      padding-left: $filter-block-locked-title-padding-left;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: $filter-block-locked-title-icon-margin-top;
        width: $filter-block-locked-title-icon-width;
        height: $filter-block-locked-title-icon-height;
        background: url('/itarp-shared-assets-addon/images/filters/unlock.svg') center center no-repeat;
      }
    }

    &__text {
      font-size: $filter-block-locked-text-font-size;
    }

    &__link {
      font-weight: $filter-block-locked-link-font-weight;
    }
  }

  &.is-locked {
    .filter-block__header__title {
      padding-right: $filter-block-locked-header-title-padding-right;

      &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        width: $filter-block-locked-header-title-icon-width;
        height: $filter-block-locked-header-title-icon-height;
        margin-top: $filter-block-locked-header-title-icon-margin-top;
        background: url('/itarp-shared-assets-addon/images/filters/locked.svg') center center no-repeat;
      }
    }
  }

  $filter-types: 'certification' 'certification' 1.625rem 1.5rem,
                 'clearance' 'clearance' 1.625rem 1.875rem,
                 'company' 'company' 1.5rem 1.625rem,
                 'compensation' 'compensation' 1.25rem 1.375rem,
                 'diversity' 'diversity' 1.625rem 1.25rem,
                 'education' 'education' 1.625rem 1.25rem,
                 'experience' 'experience' 1.375rem 1.25rem,
                 'healthcare' 'healthcare' 1.5rem 1.5rem,
                 'languages' 'languages' 1.625rem 1.5rem,
                 'quick-search' 'quick_search' 1.5rem 1.5rem,
                 'sales' 'sales' 1.375rem 1.375rem,
                 'scholar' 'scholar' 1.5rem 1.5rem,
                 'tech' 'tech' 1.625rem 1.25rem,
                 'tracking' 'tracking' 1.625rem 1.625rem,
                 'work-arrangement' 'work_arrangement' 1.5rem 1.5rem,
                 'work-authorization' 'work_authorization' 1.5rem 2.25rem;

  @each $type, $icon, $width, $height in $filter-types {
    $margin-top: math.div($height, 2);

    &.for-#{$type} {
      .filter-block__header__link {
        padding-left: calc(#{$width} + .75rem);

        &::before {
          width: #{$width};
          height: #{$height};
          margin-top: -$margin-top;
          background: url('/itarp-shared-assets-addon/images/filters/#{$icon}.svg') center center no-repeat;
        }
      }
    }
  }
}

// Filter Collapse
//---------------------------------------------

.filters-collapse {
  position: absolute;
  top: 0;
  right: 0;
  display: inline-flex;
  height: $filters-collapse-height;
  width: $filters-collapse-width;
  z-index: 11;

  &__icon,
  &__link {
    display: inline-block;
    width: 50%;
  }

  &__icon {
    background: $filters-collapse-icon-background url('/itarp-shared-assets-addon/images/filters/filters.svg') center center / auto 1rem no-repeat;
  }

  &__link {
    background: $filters-collapse-link-background url('/itarp-shared-assets-addon/images/filters/arrow.svg') center center / auto 1rem no-repeat;
  }
}

.is-collapsed {
  .filters-collapse {
    right: -$filters-collapse-width;

    &__link {
      transform: rotate(180deg);
    }
  }
}
