// Workflow Content
//---------------------------------------------

.workflow-content {
  display: flex;
  margin: 0 -1rem;
  padding-top: 2rem;

  &__stages {
    flex: 1;
  }

  &__info {
    flex: 0 0 $workflow-content-info-width;
  }

  @media only screen and (max-width: 1400px) {
    .workflow-content__info {
      flex: 0 0 25rem;
    }
  }  
}

// Workflow Stages
//---------------------------------------------

.workflow-stages {
  // border-bottom: $workflow-stages-border;

  &__wrapper {
    scroll-margin: 5.5rem 0 0;
    position: relative;
    border: $workflow-stages-wrapper-border;
    border-radius: $workflow-stages-wrapper-border-radius;
    padding: $workflow-stages-wrapper-padding;

    &__title {
      position: absolute;
      top: $workflow-stages-wrapper-title-top;
      left: $workflow-stages-wrapper-title-left;
      font-size: $workflow-stages-wrapper-title-font-size;
      font-weight: $workflow-stages-wrapper-title-font-weight;
      color: $workflow-stages-wrapper-title-color;
      background-color: $workflow-stages-wrapper-title-background;
      padding: $workflow-stages-wrapper-title-padding;
      font-family: 'proxima-nova', sans-serif !important;
    }

    // .btn-add {
    //   margin-top: $workflow-stages-wrapper-button-margin;
    // }

    .workflow-stages__list__item + .workflow-stages__list__item,
    .workflow-stages__list__item + .workflow-stages__list__form,
    .workflow-stages__list__item + .btn-add,
    .workflow-stages__list__form + .btn-add {
      margin-top: $workflow-stages-wrapper-item-distance;
    }
  }

  &__wrapper + &__wrapper {
    margin-top: $workflow-stages-wrapper-distance;

    &::before {
      content: '';
      position: absolute;
      left: 50%;
      top: $workflow-stages-wrapper-line-top;
      height: $workflow-stages-wrapper-line-height;
      width: $workflow-stages-wrapper-line-width;
      background-color: $workflow-stages-wrapper-line-background;
      margin-left: $workflow-stages-wrapper-line-margin-left;
    }
  }

  &__list {
    &__item {
      position: relative;
      background-color: $workflow-stages-list-item-background;
      border: $workflow-stages-list-item-border;
      border-radius: $workflow-stages-list-item-border-radius;
      padding: $workflow-stages-list-item-padding;
      box-shadow: $workflow-stages-list-item-box-shadow;

      &__header {
        position: relative;
        padding: $workflow-stages-list-item-header-padding;
        min-height: $workflow-stages-list-item-header-min-height;

        &.no-icon {
          padding: $workflow-stages-list-item-header-padding-no-icon;
        }
      }

      &__icon {
        position: absolute;
        left: 0;
        top: $workflow-stages-list-item-icon-top;
        height: $workflow-stages-list-item-icon-height;
        width: $workflow-stages-list-item-icon-width;

        $stage-icons: 'applied', 'screening', 'interview', 'shortlisted',
          'offer', 'onboarding', 'hired', 'completed';

        @each $stage in $stage-icons {
          &.for-#{$stage} {
            background: url('/itarp-shared-assets-addon/images/workflows/stage_#{$stage}.svg')
              center
              center
              no-repeat;
          }
        }
      }

      &__title {
        font-size: $workflow-stages-list-item-title-font-size;
        font-weight: $workflow-stages-list-item-title-font-weight;
        margin: $workflow-stages-list-item-title-margin;
        font-family: 'proxima-nova', sans-serif !important;
      }

      &__type {
        font-size: $workflow-stages-list-item-type-font-size;
        color: $workflow-stages-list-item-type-color;
        font-family: 'proxima-nova', sans-serif !important;
      }

      &__link {
        position: relative;
        display: inline-block;
        font-size: $workflow-stages-list-item-link-font-size;
        color: $workflow-stages-list-item-link-color;
        padding: $workflow-stages-list-item-link-padding;
        margin: $workflow-stages-list-item-link-margin;
        line-height: $workflow-stages-list-item-link-line-height;
        font-family: 'proxima-nova', sans-serif !important;

        &::before {
          content: '';
          position: absolute;
          left: 0;
          width: $workflow-stages-list-item-link-icon-width;
          height: $workflow-stages-list-item-link-icon-height;
          background: url('/itarp-shared-assets-addon/images/workflows/actions.svg')
            center center no-repeat;
        }

        &:hover,
        &:focus {
          color: inherit;
        }
      }

      &__button {
        position: relative;
        display: inline-block;
        font-size: $workflow-stages-list-item-button-font-size;
        color: $workflow-stages-list-item-button-color;
        padding: $workflow-stages-list-item-button-padding;
        margin: $workflow-stages-list-item-button-margin;
        line-height: $workflow-stages-list-item-button-line-height;
        font-family: 'proxima-nova', sans-serif !important;

        &::before {
          content: '';
          position: absolute;
          left: 0;
          width: $workflow-stages-list-item-button-icon-width;
          height: $workflow-stages-list-item-button-icon-height;
          background: url('/itarp-shared-assets-addon/images/workflows/buttons.svg')
            center center no-repeat;
        }

        &:hover,
        &:focus {
          color: inherit;
        }
      }

      &__controls {
        @include link-group('large');
        position: absolute;
        top: 0;
        right: 0;
      }

      &::before {
        content: '';
        position: absolute;
        width: $workflow-stages-list-item-handler-width;
        height: $workflow-stages-list-item-handler-height;
        top: 50%;
        left: $workflow-stages-list-item-handler-left;
        margin-top: $workflow-stages-list-item-handler-margin;
        background: url('/itarp-shared-assets-addon/images/icon_handler_light.svg')
          center center no-repeat;
      }

      &__body {
        // padding: $workflow-stages-list-item-body-padding;
        overflow: hidden;

        &.is-collapsed {
          transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
          max-height: 0;
        }

        &.is-expanded {
          transition: max-height 1s ease-in-out;
          max-height: 100rem;
        }
      }
    }

    &__form {
      background-color: $workflow-stages-list-form-background;
      border: $workflow-stages-list-form-border;
      border-radius: $workflow-stages-list-form-border-radius;

      &__title {
        font-size: $workflow-stages-list-form-title-font-size;
        font-weight: $workflow-stages-list-form-title-font-weight;
        margin: 0;
        font-family: 'proxima-nova', sans-serif !important;
      }

      &__header {
        padding: $workflow-stages-list-form-header-padding;
        border-bottom: $workflow-stages-list-form-header-border;
      }

      &__body {
        padding: $workflow-stages-list-form-body-padding;
      }

      &__footer {
        padding: $workflow-stages-list-form-footer-padding;

        .app-btn {
          min-width: $workflow-stages-list-form-footer-button-min-width;
        }

        .app-btn + .app-btn {
          margin-left: $workflow-stages-list-form-footer-button-distance;
        }
      }
    }

    &__item + &__item {
      margin-top: $workflow-stages-list-item-distance;
    }
  }

  &__actions {
    margin: $workflow-stages-actions-margin;
    padding: 0;
    list-style-type: none;
    border: $workflow-stages-actions-border;
    border-radius: $workflow-stages-actions-border-radius;

    &__item {
      position: relative;
      font-size: $workflow-stages-actions-item-font-size;
      background-color: $workflow-stages-actions-item-background;
      padding: $workflow-stages-actions-item-padding;
      min-height: $workflow-stages-actions-item-min-height;

      &::before {
        content: '';
        position: absolute;
        top: $workflow-stages-actions-item-icon-top;
        left: $workflow-stages-actions-item-icon-left;
        width: $workflow-stages-actions-item-icon-width;
        height: $workflow-stages-actions-item-icon-height;
      }

      $actions: 'add-tag' 'add_tag', 'advance' 'advance', 'arrow_down' 'arrow_down',
        'send_assessment' 'assessment', 'chat' 'chat', 'comment' 'comment', 'condition' 'condition',
        'decline' 'decline', 'send_email' 'email', 'enter' 'enter', 'request_interview_feedback' 'feedback',
        'attach_scorecard' 'guideline', 'attach_interview_guide' 'interview_scorecard',
        'send_notification' 'notification', 'notify' 'notify',
        'send_questionnaire' 'questionarie', 'reject' 'reject',
        'remove-owner' 'remove_owner', 'remove-tag' 'remove_tag', 'shortlist_for_interview' 'shortlisted',
        'source' 'source', 'send_message' 'send_message', 'talentcloud' 'talentcloud', 'schedule_interview' 'schedule',
        'send_text_message' 'text_message', 'trigger' 'trigger', 'update' 'update',
        'update-owner' 'update_owner', 'video' 'video';

      @each $type, $icon in $actions {
        &.action--#{$type} {
          &::before {
            background: url('/itarp-shared-assets-addon/images/workflows/actions/#{$icon}.svg')
              center
              center
              no-repeat;
          }
        }
      }

      &__controls {
        @include link-group('small');
        position: absolute;
        right: $workflow-stages-actions-item-control-right;
        top: $workflow-stages-actions-item-control-top;
      }

      &:first-child {
        border-top-left-radius: $workflow-stages-actions-border-radius;
        border-top-right-radius: $workflow-stages-actions-border-radius;
      }

      &:last-child {
        border-bottom-left-radius: $workflow-stages-actions-border-radius;
        border-bottom-right-radius: $workflow-stages-actions-border-radius;
      }
    }

    > li + li {
      border-top: $workflow-stages-actions-border;
    }
  }

  &__buttons {
    display: flex;
    flex-wrap: wrap;
    margin-top: 0.25rem;

    .app-btn {
      margin: 0.25rem 0.5rem 0.25rem 0
    }
  }

  &__controls {
    padding: $workflow-stages-controls-padding;
  }
}

// Workflow Actions Group
//---------------------------------------------

.workflow-actions-group {
  &__title {
    font-size: $worlflow-actions-group-title-font-size;
    font-weight: $worlflow-actions-group-title-fonr-weight;
    // color: $worlflow-actions-group-title-color;
    margin: $worlflow-actions-group-title-margin;
    font-family: 'proxima-nova', sans-serif !important;
  }

  .actions-tip {
    top: 1rem;
    right: 1rem;
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style-type: none;

    &__item {
      position: relative;
      border: $worlflow-actions-group-list-item-border;
      border-radius: $worlflow-actions-group-list-item-border-radius;
      font-size: $worlflow-actions-group-list-item-font-size;
      padding: $worlflow-actions-group-list-item-padding;
      margin-bottom: $worlflow-actions-group-list-item-margin;
      cursor: pointer;

      &.is-selected {
        background-image: url('/itarp-shared-assets-addon/images/check_yes.png');
        background-position: center right 1rem;
        background-repeat: no-repeat;
      }

      &.is-included {
        background-image: url('/itarp-shared-assets-addon/images/check_yes.png');
        background-position: center right 1rem;
        background-repeat: no-repeat;
      }

      &.with-toggle {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: .75rem;
        padding-bottom: .75rem;
      }

      &::before {
        content: '';
        position: absolute;
        left: $worlflow-actions-group-list-item-icon-left;
        top: $worlflow-actions-group-list-item-icon-top;
        bottom: $worlflow-actions-group-list-item-icon-bottom;
        border-radius: $worlflow-actions-group-list-item-icon-border-radius;
        width: $worlflow-actions-group-list-item-icon-width;
      }

      $actions: 'add-tag' 'add_tag', 'advance' 'advance', 'arrow_down' 'arrow_down',
        'send_assessment' 'assessment', 'chat' 'chat', 'comment' 'comment', 'condition' 'condition',
        'decline' 'decline', 'send_email' 'email', 'enter' 'enter', 'request_interview_feedback' 'feedback',
        'attach_scorecard' 'guideline', 'attach_interview_guide' 'interview_scorecard',
        'send_notification' 'notification', 'notify' 'notify',
        'send_questionnaire' 'questionarie', 'reject' 'reject',
        'remove-owner' 'remove_owner', 'remove-tag' 'remove_tag', 'shortlist_for_interview' 'shortlisted',
        'source' 'source', 'send_message' 'send_message', 'talentcloud' 'talentcloud', 'schedule_interview' 'task',
        'send_text_message' 'text_message', 'trigger' 'trigger', 'update' 'update',
        'update-owner' 'update_owner', 'video' 'video', 'ui-actions' 'ui_actions';

      @each $type, $icon in $actions {
        &.action--#{$type} {
          &::before {
            background-image: url('/itarp-shared-assets-addon/images/workflows/actions/#{$icon}_light.svg');
            background-position: center center;
            background-repeat: no-repeat;
          }
        }
      }
    }

    $list-types: 'workflow' $worlflow-actions-group-list-color-workflow,
      'communication' $worlflow-actions-group-list-color-communication,
      'task' $worlflow-actions-group-list-color-task,
      'interview' $worlflow-actions-group-list-color-interview,
      'assessment' $worlflow-actions-group-list-color-assessments,
      'questionarie' $worlflow-actions-group-list-color-questionarie,
      'candidate' $worlflow-actions-group-list-color-candidate,
      'owner' $worlflow-actions-group-list-color-owner,
      'other' $worlflow-actions-group-list-color-other;

    @each $type, $color in $list-types {
      &.for-#{$type} {
        .workflow-actions-group__list__item {
          &::before {
            background-color: $color;
          }
        }
      }
    }

    > li + li {
      margin-top: $worlflow-actions-group-item-distance;
    }
  }
}

// Workflow Condition
//---------------------------------------------

.workflow-condition-add {
  &__title {
    text-transform: uppercase;
  }

  &__list {
    @include link-group('large');
  }
}

// Workflow Chart
//---------------------------------------------

.workflow-chart {
  display: inline-flex;
  flex-direction: column;

  &__parallel {
    position: relative;
    display: flex;
    // padding: $workflow-chart-parallel-padding;

    &::before {
      content: '';
      position: absolute;
      height: $workflow-chart-parallel-line-height;
      background: $workflow-chart-parallel-line-background;
      left: 11rem;
      right: 11rem;
    }

    &::before {
      top: 0;
    }

    &.is-merging {
      &::after {
        bottom: 0;
      }

      &::after {
        content: '';
        position: absolute;
        height: $workflow-chart-parallel-line-height;
        background: $workflow-chart-parallel-line-background;
        left: 11rem;
        right: 11rem;
      }
    }
  }

  &__block {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__action {
      position: relative;
      padding: 1.5rem 0;

      &::before {
        @include workflow-line('vertical');
        top: 0;
        height: 1.5rem;
      }

      &::after {
        @include workflow-line('vertical');
        bottom: 0;
        height: 1.5rem;
      }
    }

    &__trigger {
      position: relative;
      padding: 0 0 1.5rem;

      &::after {
        @include workflow-line('vertical');
        bottom: 0;
        height: 1.5rem;
      }
    }

    &__condition {
      position: relative;
      padding: 1.5rem 0 0;

      &::before {
        @include workflow-line('vertical');
        top: 0;
        height: 1.5rem;
      }
    }
  }

  &__start {
    .workflow-chart__element {
      padding-top: 0 !important;
    }
  }

  &__element {
    position: relative;
    display: inline-flex;
    flex-direction: column;
    padding: $workflow-chart-element-padding;
  }

  &__connection {
    position: relative;
    padding: $workflow-chart-connection-padding;

    &__link {
      display: inline-block;
      position: relative;
      left: 50%;
      margin-left: $workflow-chart-connection-link-margin-left;
      height: $workflow-chart-connection-link-height;
      line-height: $workflow-chart-connection-link-height;
      padding: $workflow-chart-connection-link-padding;
      font-size: $workflow-chart-connection-link-font-size;
      color: $workflow-chart-connection-link-color;
      text-decoration: none;
      user-select: none;
      cursor: pointer;
      z-index: 1;

      &:hover,
      &:focus {
        color: $workflow-chart-connection-link-color;
      }

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: $workflow-chart-connection-link-icon-width;
        height: $workflow-chart-connection-link-icon-height;
        background: url('/itarp-shared-assets-addon/images/buttons/add_circle.svg')
          center center / cover no-repeat;
      }
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 50%;
      margin-left: $workflow-chart-connection-line-margin;
      height: $workflow-chart-connection-line-height;
      width: $workflow-chart-connection-line-width;
      background-color: $workflow-chart-connection-line-background;
    }

    &::before {
      top: 0;
    }

    &::after {
      bottom: 0;
    }
  }

  &__placeholder {
    position: relative;
    height: $workflow-chart-placeholder-height;

    &::after {
      content: '';
      position: absolute;
      left: 50%;
      top: 0;
      height: $workflow-chart-placeholder-icon-height;
      width: $workflow-chart-placeholder-icon-width;
      border: $workflow-chart-placeholder-icon-border;
      margin-left: $workflow-chart-placeholder-icon-margin;
    }
  }

  &__trigger {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    height: $workflow-chart-trigger-height;
    border-radius: $workflow-chart-trigger-border-radius;
    background-color: $workflow-chart-trigger-background;
    padding: $workflow-chart-trigger-padding;

    &__title {
      margin: 0;
      font-size: $workflow-chart-trigger-title-font-size;
      font-weight: $workflow-chart-trigger-title-font-weight;
      color: $workflow-chart-trigger-title-color;
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: $workflow-chart-trigger-icon-left;
      margin: $workflow-chart-trigger-icon-margin;
      width: $workflow-chart-trigger-icon-width;
      height: $workflow-chart-trigger-icon-height;
      background: url('/itarp-shared-assets-addon/images/workflow_trigger.svg')
        center center no-repeat;
    }
  }

  &__action {
    position: relative;
    display: inline-flex;
    flex-direction: column;
    width: $workflow-chart-action-width;
    min-height: $workflow-chart-action-min-height;
    border-radius: $workflow-chart-action-border-radius;
    border-width: $workflow-chart-action-border-width;
    border-style: solid;
    background-color: $workflow-chart-action-background;

    &__header {
      position: relative;
      border-radius: $workflow-chart-action-header-border-radius;
      padding: $workflow-chart-action-header-padding;

      &__title {
        margin: 0;
        font-size: $workflow-chart-action-header-title-font-size;
        font-weight: $workflow-chart-action-header-title-font-weight;
      }

      &__edit {
        position: absolute;
        top: 50%;
        right: $workflow-chart-action-header-edit-right;
        width: $workflow-chart-action-header-edit-width;
        height: $workflow-chart-action-header-edit-height;
        margin-top: $workflow-chart-action-header-edit-margin;
        background: url('/itarp-shared-assets-addon/images/icon_edit.svg')
          center center no-repeat;
      }

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: $workflow-chart-action-header-icon-width;
        border-radius: $workflow-chart-action-header-icon-border-radius;
      }
    }

    &__body {
      padding: $workflow-chart-action-body-padding;
      font-size: $workflow-chart-action-body-font-size;
      line-height: $workflow-chart-action-body-line-height;
      flex: 1;
    }

    $action-group: 'workflow' $worlflow-actions-group-list-color-workflow,
      'communication' $worlflow-actions-group-list-color-communication,
      'task' $worlflow-actions-group-list-color-task,
      'interview' $worlflow-actions-group-list-color-interview,
      'assessment' $worlflow-actions-group-list-color-assessments,
      'questionarie' $worlflow-actions-group-list-color-questionarie,
      'candidate' $worlflow-actions-group-list-color-candidate,
      'owner' $worlflow-actions-group-list-color-owner,
      'other' $worlflow-actions-group-list-color-other;

    @each $type, $color in $action-group {
      &.for-#{$type} {
        border-color: $color;

        .workflow-chart__action__header {
          background-color: rgba($color, 0.15);

          &::before {
            background-color: $color;
          }
        }
      }
    }

    $actions-types: 'add-tag' 'add_tag' 60%, 'advance' 'advance' 60%,  'arrow_down' 'arrow_down' 60%,
      'send_assessment' 'assessment' 60%, 'chat' 'chat' 60%, 'comment' 'comment' 50%,
      'condition' 'condition' 60%, 'decline' 'decline' 60%, 'send_email' 'email' 60%, 'enter' 'enter' 60%,
      'request_interview_feedback' 'feedback' 55%, 'attach_scorecard' 'guideline' 50%,
      'attach_interview_guide' 'interview_scorecard' 60%,
      'send_notification' 'notification' 60%, 'notify' 'notify' 70%,
      'send_questionnaire' 'questionarie' 75%, 'reject' 'reject' 50%,
      'remove-owner' 'remove_owner' 75%, 'remove-tag' 'remove_tag' 60%, 'shortlist_for_interview' 'shortlisted' 60%,
      'source' 'source' 60%, 'send_message' 'send_message' 60%, 'talentcloud' 'talentcloud' 60%, 'schedule_interview' 'task' 60%,
      'send_text_message' 'text_message' 50%, 'trigger' 'trigger' 60%,
      'update' 'update' 50%, 'update-owner' 'update_owner' 60%,
      'video' 'video' 60%;

    @each $type, $icon, $size in $actions-types {
      &.action--#{$type} {
        .workflow-chart__action__header {
          &::before {
            background-image: url('/itarp-shared-assets-addon/images/workflows/actions/#{$icon}_light.svg');
            background-position: center center;
            background-repeat: no-repeat;
            background-size: #{$size};
          }
        }
      }
    }

    &.as-placeholder {
      border-color: $workflow-chart-action-placeholder-border-color;
      box-shadow: $workflow-chart-action-placeholder-box-shadow;

      .workflow-chart__action__body {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: $workflow-chart-action-placeholder-font-size;
        color: $workflow-chart-action-placeholder-color;

        .workflow-chart__action__remove {
          position: absolute;
          width: 1.5rem;
          height: 1.5rem;
          top: 0.5rem;
          right: 0.5rem;
          border-radius: 50%;
          z-index: 2;
          background: #fff
            url('/itarp-shared-assets-addon/images/icon_remove_circle.svg')
            center center / 110% no-repeat;
        }
      }
    }
  }

  &__condition {
    position: relative;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    min-width: $workflow-chart-condition-width;

    &__header {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      position: relative;
      border-radius: $workflow-chart-condition-header-border-radius;
      background-color: $workflow-chart-condition-header-background;
      width: $workflow-chart-condition-header-width;
      padding: $workflow-chart-condition-header-padding;
      margin: $workflow-chart-condition-header-margin;
      user-select: none;

      &__title {
        font-size: $workflow-chart-condition-header-title-font-size;
        font-weight: $workflow-chart-condition-header-title-font-weight;
        color: $workflow-chart-condition-header-title-color;
        margin: 0;
        text-align: center;
      }

      &::after {
        content: '';
        position: absolute;
        bottom: $workflow-chart-condition-line-bottom;
        left: 50%;
        margin: $workflow-chart-condition-line-margin;
        width: $workflow-chart-condition-line-width;
        height: $workflow-chart-condition-line-height;
        background-color: $workflow-chart-condition-line-backgound;
      }
    }

    &__branches {
      position: relative;
      display: inline-flex;
      min-width: $workflow-chart-condition-branches-width;
      // padding: $workflow-chart-condition-branches-padding;

      &__item {
        display: flex;
        flex-direction: column;
        position: relative;
        min-width: $workflow-chart-condition-branches-item-width;

        &__connection {
          position: relative;
          height: 1.5rem;

          &::before {
            content: '';
            @include workflow-line('vertical');
            top: 0;
            bottom: 0;
          }
        }

        &__label {
          display: flex;
          justify-content: center;

          span {
            border-radius: $workflow-chart-condition-branches-item-border-radius;
            font-size: $workflow-chart-condition-branches-item-font-size;
            color: $workflow-chart-condition-branches-item-color;
            padding: $workflow-chart-condition-branches-item-padding;
          }

          &.is-positive {
            span {
              background-color: $workflow-chart-condition-branches-item-background-positive;
            }
          }

          &.is-negative {
            span {
              background-color: $workflow-chart-condition-branches-item-background-negative;
            }
          }
        }

        &::before {
          @include workflow-line('horizontal');
          left: 0;
          right: 0;
        }

        &:first-child {
          &::before {
            left: 50%;
          }
        }

        &:last-child {
          &::before {
            right: 50%;
          }
        }
      }
    }
  }

  &__page {
    position: relative;
    display: inline-flex;
    flex-direction: column;
    width: $workflow-chart-action-width;
    min-height: $workflow-chart-action-min-height;
    border-radius: $workflow-chart-action-border-radius;
    border-width: 2px;
    border-style: solid;
    border-color: $blue-button-primary;
    background-color: $workflow-chart-action-background;

    &__body {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      padding: $workflow-chart-action-body-padding;
      font-size: 1.125rem;
      flex: 1;
    }
  }
}

// Workflow UI Actions Group
//---------------------------------------------

.workflow-uiactions-group {
  &__title {
    font-size: $worlflow-actions-group-title-font-size;
    font-weight: $worlflow-actions-group-title-fonr-weight;
    margin: $worlflow-actions-group-title-margin;
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style-type: none;

    &__item {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }          

    > li + li {
      margin-top: .75rem;
    }
  }
}

// Chart

.chart-zoom {
  position: absolute;
  left: 2rem;
  top: 2rem;
  z-index: 5;

  &__controls {
    border: 1px solid $border-color;

    &__item {
      position: relative;
      display: block;
      width: 2rem;
      height: 2rem;
      cursor: pointer;

      &::after {
        position: absolute;
        width: 1rem;
        height: 1rem;
        left: 0.5rem;
        top: 0.5rem;
        color: $text-color-dark;
        line-height: 1rem;
        font-size: 1.5rem;
        font-weight: 600;
        text-align: center;
      }

      &.zoom-in {
        &::after {
          content: '+';
        }
      }

      &.zoom-out {
        &::after {
          content: '-';
        }
      }

      &:hover,
      &:focus {
        background-color: $table-background;
      }
    }

    &__item + &__item {
      border-top: 1px solid $border-color;
    }
  }

  &__value {
    display: flex;
    justify-content: center;
    padding: 0.5rem 0;
    font-size: 0.875rem;
  }
}

// Chart

.chart-designer {
  // height: 100vh;
  width: 100%;
  position: relative;
  overflow: auto;

  &__inner {
    display: block;
    height: 100%;
    position: relative;
    cursor: grab;
  }

  &__canvas {
    display: table;
    position: relative;
    width: 100%;
  }
}

.chart-container {
  display: table-cell;
  text-align: center;
  padding: 2rem 2rem;
}

.chart {
  display: inline-flex;
  flex-direction: column;
  flex-shrink: 0;
  text-align: left;

  &-block {
    display: inline-flex;
    flex-direction: column;
    align-items: center;

    &__children {
      position: relative;
      display: flex;

      &::before {
        @include workflow-line('horizontal');
        left: 11rem;
        right: 11rem;
      }

      > .chart-block__element {
        &::before {
          @include workflow-line('vertical');
          top: 0;
          height: 1.5rem;
        }
      }
    }

    &__element {
      position: relative;

      &.chart-block--trigger {
        padding: 0 1rem;
      }

      &.chart-block--action {
        padding: 1.5rem 1rem;
      }

      &.chart-block--page {
        padding: 1.5rem 1rem;

        &::after {
          @include workflow-line('vertical');
          height: 1.5rem;
          bottom: 0;
        }

        &.is-last {
          &::after {
            display: none;
          }
        }
      }
    }

    &__connection {
      height: 4.5rem;
      position: relative;

      &::before {
        @include workflow-line('vertical');
        top: 0;
        bottom: 0;
      }
    }

    &__dropzone {
      position: relative;
      height: $workflow-chart-placeholder-height;

      &::after {
        content: '';
        position: absolute;
        left: 50%;
        top: 0;
        height: $workflow-chart-placeholder-icon-height;
        width: $workflow-chart-placeholder-icon-width;
        border: $workflow-chart-placeholder-icon-border;
        margin-left: $workflow-chart-placeholder-icon-margin;
      }
    }
  }

  &-add,
  &-remove {
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    top: 1.5rem;
    left: 50%;
    margin-left: -0.75rem;
    border-radius: 50%;
    z-index: 2;
  }

  &-add {
    background: #fff
      url('/itarp-shared-assets-addon/images/buttons/add_circle.svg') center
      center / 110% no-repeat;
  }

  &-remove {
    background: #fff
      url('/itarp-shared-assets-addon/images/icon_remove_circle.svg') center
      center / 110% no-repeat;
  }
}

// Trigger Group
//---------------------------------------------

.trigger-group {
  &__title {
    position: relative;
    font-size: $trigger-group-title-font-size;
    font-weight: $trigger-group-title-font-weight;
    margin: $trigger-group-title-margin;
    padding: $trigger-group-title-padding;
  }

  &__list {
    list-style-type: none;
    margin: 0;
    padding: $trigger-group-list-padding;

    &__link {
      display: inline-block;
      position: relative;
      font-size: $trigger-group-list-link-font-size;
      color: $trigger-group-list-link-color;
      outline: 0;
      cursor: pointer;
      text-decoration: none;
      padding: $trigger-group-list-link-padding;

      &:hover,
      &:focus {
        color: $trigger-group-list-link-color;
      }

      &::before {
        content: '';
        position: absolute;
        left: 0;
        width: $trigger-group-list-link-icon-width;
        height: $trigger-group-list-link-icon-height;
        background: url('/itarp-shared-assets-addon/images/workflows/triggers/trigger_icon_add.svg')
          center center no-repeat;
      }

      &.is-added {
        &::before {
          background: url('/itarp-shared-assets-addon/images/workflows/triggers/trigger_icon_added.svg')
            center center no-repeat;
        }
      }
    }

    &__item + &__item {
      margin-top: $trigger-group-list-distance;
    }
  }

  $trigger-groups: 'candidate' 'trigger_group_candidate',
    'job' 'trigger_group_job', 'talentcloud' 'trigger_group_talentcloud';

  @each $group, $icon in $trigger-groups {
    &.for-#{$group} {
      .trigger-group__title {
        &::before {
          content: '';
          position: absolute;
          left: 0;
          width: $trigger-group-icon-width;
          height: $trigger-group-icon-height;
          background: url('/itarp-shared-assets-addon/images/workflows/triggers/#{$icon}.svg')
            center
            center
            no-repeat;
        }
      }
    }
  }
}

// Process Stages
//---------------------------------------------

.process-stages {
  display: flex;
  margin: 1rem 0 0;

  &__item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
    user-select: none;
    background: $process-stages-item-background;
    border: $process-stages-item-border;
    border-radius: $process-stages-item-border-radius;
    box-shadow: $process-stages-item-box-shadow;
    width: $process-stages-item-width;
    height: $process-stages-item-height;
    margin: $process-stages-item-margin;
    padding: $process-stages-item-padding;
    transform: perspective(1px) translateZ(0);
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
    -webkit-transition-property: color, background-color;
    transition-property: color, background-color;
    cursor: pointer;

    &__icon {
      width: 100%;
      height: $process-stages-item-icon-height;
      margin: $process-stages-item-icon-margin;

      $stage-icons: 'invited' 'Invited', 'applied' 'Applied', 'created' "Created", 'reviewed' "Reviewed", 'sourced' 'Sourced', 'screening' 'Screening', 'interview' 'Interview', 'offer' 'Offer', 'onboarding' 'Onboarding',
        'hired' 'Hired', 'assessment' 'Assessment', 'shortlisted' 'Shortlisted', 'job_complete' 'JobCompleted', 'declined' 'Declined', 'custom' 'Custom', 'on_hold' 'OnHold', 'pending' 'Pending', 'open' 'Open', 
        'closed' 'Closed', 'inactive' 'Inactive';

      @each $name, $icon in $stage-icons {
        &.for-#{$name} {
          background: url('/itarp-shared-assets-addon/images/workflows/stages/#{$icon}.svg')
            center
            center
            no-repeat;
        }
      }
    }

    &__title {
      font-size: $process-stages-item-title-font-size;
      font-weight: $process-stages-item-title-font-weight;
      text-align: center;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &.is-static {
      .process-stages__item__title {
        color: $process-stages-item-title-color-static;
      }
    }

    &.inactive {
      pointer-events: none !important;
    }

    &:hover, &:focus{
      background: #4ba4da;
      border-color: #4ba4da;

      .process-stages__item__title {
        color: #fff;
      }

      .process-stages__item__icon {
        $stage-icons: 'invited' 'Invited', 'applied' 'Applied', 'created' "Created", 'reviewed' "Reviewed", 'sourced' 'Sourced', 'screening' 'Screening', 'interview' 'Interview', 'offer' 'Offer', 'onboarding' 'Onboarding',
          'hired' 'Hired', 'assessment' 'Assessment', 'shortlisted' 'Shortlisted', 'job_complete' 'JobCompleted', 'declined' 'Declined', 'custom' 'Custom', 'on_hold' 'OnHold', 'closed' 'Closed', 'pending' 'Pending', 
          'open' 'Open', 'inactive' 'Inactive';
  
        @each $name, $icon in $stage-icons {
          &.for-#{$name} {
            background: url('/itarp-shared-assets-addon/images/workflows/stages/#{$icon}_light.svg')
              center
              center
              no-repeat;
          }
        }
      }
    }
  }

  &__sortable {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0 -0.5rem;
  }
}

// Stages List
//---------------------------------------------

.list-stages {
  &__item {
    display: flex;
    align-items: center;
    position: relative;
    background-color: #fff;
    border: 1px solid #d1dbe3;
    border-radius: 0.25rem;
    padding: 1rem 1rem 1rem 2.5rem;
    min-height: 4.5rem;

    &::before {
      content: '';
      position: absolute;
      left: 1rem;
      height: 1.5rem;
      width: 0.5rem;
      top: 50%;
      margin-top: -0.75rem;
      background: url('/itarp-shared-assets-addon/images/icon_handler.svg')
        center center / contain no-repeat;
    }

    &__title {
      font-size: 1.125rem;
      font-weight: 600;
      flex: 1;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 1rem;
    }

    &__form {
      display: flex;
      align-items: center;
      width: 100%;

      .input-group,
      .select-group {
        flex: 1;
        padding-bottom: 0;
      }

      .app-btn {
        min-width: 5.625rem;
      }

      > * + * {
        margin-left: 0.5rem;
      }
    }

    &.is-static {
      .list-stages__item__title {
        color: $process-stages-item-title-color-static;
      }
    }

    &.inactive {
      pointer-events: none !important;
      &::before {
        display: none;
      }
    }

    &__controls {
      @include link-group;
    }

    &__icon {
      display: inline-block;
      margin-right: 1rem;
      width: 2rem;
      height: 1.5rem;

      $stage-icons: 'invited' 'Invited', 'applied' 'Applied', 'created' 'Created', 'reviewed' 'Reviewed', 'sourced' 'Sourced', 'screening' 'Screening', 'interview' 'Interview', 'offer' 'Offer', 'onboarding' 'Onboarding',
        'hired' 'Hired', 'assessment' 'Assessment', 'shortlisted' 'Shortlisted', 'job_complete' 'JobCompleted', 'declined' 'Declined', 'custom' 'Custom', 'on_hold' 'OnHold', 'closed' 'Closed', 'pending' 'Pending', 
        'open' 'Open', 'inactive' 'Inactive';
      
      @each $name, $icon in $stage-icons {
        &.for-#{$name} {
          background: url('/itarp-shared-assets-addon/images/workflows/stages/#{$icon}.svg')
            center
            center /
            contain
            no-repeat;
        }
      }
    }
  }

  &__item + &__item {
    margin-top: 0.5rem;
  }
}
