// Job
//---------------------------------------------

.job {
  &__header {
    padding: $job-header-padding;
    border-bottom: $job-header-border;

    &__inner {
      display: flex;
    }

    &__content {
      flex: 1;
    }

    &__controls {
      flex: $job-header-controls-width;
      padding: $job-header-controls-padding;
      border-left: $job-header-border; 
    }

    &__breadcrumb {
      padding: $job-header-breadcrumb-padding;

      &__link {
        position: relative;
        font-size: $job-header-breadcrumb-link-font-size;
        font-weight: $job-header-breadcrumb-link-font-weight;
        color: $job-header-breadcrumb-link-color;
        padding: $job-header-breadcrumb-link-padding;
        outline: 0;
        cursor: pointer;
        text-decoration: none;

        &:focus,
        &:hover {
          color: $job-header-breadcrumb-link-color-active;
        }

        &::before {
          content: '';
          position: absolute;
          width: $job-header-breadcrumb-link-arrow-width;
          height: $job-header-breadcrumb-link-arrow-height;
          left: 0;
          top: 50%;
          margin-top: $job-header-breadcrumb-link-arrow-margin-top;
          background: url('/itarp-business-application/assets/images/arrow_prev.svg') center center no-repeat;
        }
      }
    }

    &--light {
      background-color: $job-header-background-light;
    }
  }

  &__nav {
    position: relative;
    padding: $job-nav-padding;
    background-color: $job-nav-background;
    box-shadow: $job-nav-box-shadow;
  }

  &__body {
    display: flex;
    padding: $job-body-padding;

    &__description {
      flex: 1;
    }

    &__info {
      flex: $job-body-right-width;
    }
  }

  @media only screen and (max-width: 768px) {
    &__header {
      width: 100%;
      padding: .5rem .5rem;

      &__inner {
        flex-wrap: wrap;
      }

      &__controls {
        flex: 0 0 100%;
        padding: 0;
        border-left: none;

      }  

      &__breadcrumb {
        &__link {
          font-size: 1rem;
        }
      }  
    }   
    
    &__nav {
      padding: 0 .5rem;
    }

    &__body {
      flex-direction: column;
      padding: .5rem .5rem;
  
      &__description {
        flex: 1;
      }
  
      &__info {
        flex: 0 0 100%;
      }
    }
  } 
}

// Job Applied Note
//---------------------------------------------

.job-applied-note {
  display: block;
  position: relative;
  font-size: $job-applied-note-font-size;
  font-weight: $job-applied-note-font-weight;
  padding: $job-applied-note-padding;
  border-radius: $job-applied-note-border-radius;
  border: $job-applied-note-border;
  background: $job-applied-note-background;
  line-height: $job-applied-note-line-height;
  text-align: center;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: $job-applied-note-icon-left;
    width: $job-applied-note-icon-width;
    height: $job-applied-note-icon-height;
    margin-top: $job-applied-note-icon-margin;
    background: url('/itarp-business-application/assets/images/check_yes.png') center center no-repeat;
  }
}

// Jobs
//---------------------------------------------

.jobs {
  &__banner {
    height: $jobs-banner-height;
    padding: $jobs-banner-padding;
    color: $jobs-banner-color;
    background: url('/itarp-business-application/assets/images/pages/jobs_bg.png') center center / cover scroll no-repeat;

    > *,
    &__inner {
      height: 100%;
    }

    &__inner {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: $jobs-banner-inner-padding; 
    }

    &__title {
      margin: 0;
      font-size: $jobs-banner-title-font-size;
      font-weight: $jobs-banner-title-font-weight;
      letter-spacing: -.2px;
    }

    &__text {
      font-size: $jobs-banner-text-font-size;
    }
  }

  &__list {
    padding: $jobs-list-padding;

    &__inner {
      padding: $jobs-list-inner-padding;
    }
  }
}
