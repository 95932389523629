// Headings
//---------------------------------------------

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 1rem;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.75rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.25rem;
}

h5 {
  font-size: 1rem;
}

h6 {
  font-size: .875rem;
}

// Paragraph
//---------------------------------------------

p {
  margin: 0 0 1rem;
  font-weight: 400;
}

// List
//---------------------------------------------

ul, 
ol {
  li {
    font-weight: 400;
  }  
}

// Text Sizes
//---------------------------------------------

@for $i from 1 to 100 {
  .ts--#{$i} {
    font-size: math.div($i, 16) * 1rem !important;
  }
}
