// Content
//---------------------------------------------

.designer__content {
  position: relative;
  padding: 6.5rem 0 0 0;
  height: calc(100vh - 3.5rem);
  background-color: #f9f9f9;
  transition: transform 400ms ease-in-out 100ms, opacity 300ms ease-in-out 200ms;

  &.is-collapsed {
    height: 100%;
    opacity: 0;
    overflow: hidden;
    transform: translateX($designer-content-offset);
  }

  &.is-expanded {
    opacity: 1;
    transform: none;
  }

  &.has-header {
    padding: 7rem 0 0 0;
  }

  &__flex {
    display: flex;
    flex-direction: column;
    height: 100%;

    >div[class^="designer__content"] {
      width: 100%;
    }

    .designer__content__body {
      flex: 1;
    }
  }

  &__header {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 9;
    height: 6.5rem;
    border-bottom: 1px solid #d1dbe3;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 1.5rem 1.5rem;

    &__content {
      &.title-limited {
        min-width: 0;
        flex: 1;
      }
    }

    &__title {
      margin: 0;
      font-size: 1.375rem !important;
      font-weight: 600 !important;
      font-family: 'proxima-nova', sans-serif !important;
      width: 100%;
      // white-space: nowrap;
      // overflow: hidden;
      // text-overflow: ellipsis;
      padding-right: 1.5rem;

      &__breadcrumb {
        position: relative;
        display: inline-block;
        padding-left: 1.5rem;
        margin-left: 0.5rem;

        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: 50%;
          margin: -0.5rem 0 0 0;
          display: inline-block;
          width: 0.625rem;
          height: 1rem;
          background: url("/itarp-shared-assets-addon/images/arrow_right_dark.svg") center center / contain no-repeat;
        }
      }

      >a {
        text-decoration: none;
        color: #4a5162;
        cursor: pointer;
      }

      &__return {
        display: inline-block;
        cursor: pointer;
        outline: 0;
        width: 2rem;
        height: 2rem;
        margin-right: 1rem;
        background: url('/itarp-shared-assets-addon/images/back_icon.svg') center center no-repeat;
      }

      &.has-origin {
        &,
        & a {
          color: #4a5162;
          text-decoration: none;
        }

        span {
          display: inline-flex;
          align-items: center;
          font-weight: 400;
          margin-right: -0.25rem;

          &::after {
            content: '';
            display: inline-block;
            width: .625rem;
            height: 1rem;
            margin: 0.5rem;
            background: url('/itarp-shared-assets-addon/images/arrow_next.svg') center center no-repeat;
          }
        }
      }

      &.with-return {
        display: inline-flex;
        align-items: center;
        text-decoration: none;
        color: #fff;
      }

      &.is-limited {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &__link {
      text-decoration: none;
      position: relative;
      padding-left: 1rem;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -.375rem;
        width: .5rem;
        height: .75rem;
        background: url('/itarp-shared-assets-addon/images/arrow_left_blue.svg') center center no-repeat;
      }
    }

    &__info {
      list-style-type: none;
      padding: 0;
      margin: .375rem 0 0;
      display: inline-flex;

      &__item {
        font-size: 1.125rem;
        font-family: 'proxima-nova', sans-serif !important;

        span {
          display: inline-block;
          color: #a1a5ad;
          margin-right: .25rem;
        }
      }

      >li+li {
        margin-left: 1.5rem;
      }
    }

    &__controls {
      display: flex;
      align-items: center;

      .app-btn {
        min-width: 7.5rem;
      }

      >*+* {
        margin-left: .75rem;
      }
    }

    &__return {
      position: absolute;
      padding: 0 !important;
      left: 0;
      top: 50%;
      margin-top: -.5rem;
      width: .75rem;
      height: 1rem;
      outline: 0;
      cursor: pointer;
      z-index: 1;
      background: url('/itarp-shared-assets-addon/images/arrow_prev.svg') center center no-repeat;
    }

    &__nav {
      width: 100%;
      height: 100%;
      display: flex;

      &__source {
        display: flex;
        align-items: center;
        width: 24rem;
        padding: 1rem 2rem 1rem 0;
      }

      &__content {
        flex: 1;
        display: flex;
        align-items: flex-end;
        padding-left: 1.5rem;
      }
    }

    &__tooltip {
      background-color: #2b3e5f;
      border-color: #2b3e5f;
      color: #fff;
      font-weight: 600;
      margin-left: 1rem;

      &.ember-popover[x-placement^="bottom"] .ember-popover-arrow {
        border-bottom-color: #2b3e5f;
        display: none;
      }
    }

    &.as-popup {
      background-color: #8a90a3;
      color: #ffffff;
    }

    &.with-return {
      .designer__content__header__content {
        padding-left: 1rem;
        position: relative;
      }

      .designer__content__header__title {
        position: relative;
        padding-left: 1rem;
        margin-left: -1rem;
      }
    }

    &.has-nav {
      padding: 0 2rem;
    }
  }

  &__steps {
    padding: 1.5rem .5rem;
    background-color: #fff;
    border-bottom: 1px solid #d1dbe3;
  }

  &__nav {
    border-bottom: 1px solid #d1dbe3;

    &__inner {
      padding: 1rem 1.5rem 0;

      &.is-split {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  &__stats {
    padding: .5rem .5rem;
  }

  &__block {
    padding: 0 1rem;
  }

  &__filters {
    padding: 1rem 1rem;
    border-bottom: 1px solid #d1dbe3;
  }

  &__body {
    position: relative;
    padding: 2rem 1.5rem;
    min-height: 100%;

    &.has-toolbar {
      padding: 0 0 0 32.75rem;
    }

    &.has-sidebar {
      display: flex;
      height: auto;
      min-height: calc(100vh - 17.625rem);
      padding: 0;

      .designer__content__body__inner {
        flex: 1;
      }
    }

    &.has-filters {
      overflow-x: hidden;
      transition: padding .35s ease;
      padding: 0;

      &.filters-expanded {
        padding-left: 26rem;
      }

      &.filters-collapsed {
        padding-left: 0;
      }
    }

    &.no-sp {
      padding: 1rem 0 2rem;
    }

    &.for-tasks {
      padding: 0 .5rem 2rem;
    }

    &.no-p {
      padding: 0;
      width: 100%;
    }

    &.for-components {
      padding: 1rem .5rem 2rem;
      height: auto;
    }

    &.is-bordered {
      border-top: 1px solid #d1dbe3;

      .designer__body__listing {
        padding-top: 0;
      }
    }

    &.job-browse {
      @media only screen and (max-width: 1800px) {
        .job {
          &__header {
            &__controls {
              flex: 0 0 20rem;
            }
          }
        }
      } 
      
      @media only screen and (max-width: 1680px) {
        .job {
          &__header {
            &__controls {
              flex: 0 0 15rem;
            }
          }

          &__body {
            flex-wrap: wrap;

            &__description {
              flex: 0 0 100%;
            }

            &__info {
              flex: 0 0 100%;
            }
          }
        }
      } 

      @media only screen and (max-width: 1400px) {
        .job {
          &__header {
            &__inner {
              flex-wrap: wrap;
            }  

            &__controls {
              border-left: none;
              flex: 0 0 100%;
              padding: 0;
            }
          }
        }  
      }  
    }

    &__sidebar {
      padding: 1.5rem 0rem;
      width: 17.5rem;
      border-right: 1px solid #d1dbe3;
    }

    &__collapse {
      position: relative;
      display: flex;

      .designer__content__body__inner {
        flex: 1;
        overflow: auto;
      }

      &.is-bordered {
        border-top: 1px solid #d1dbe3;
      }
    }

    &__inner {
      position: relative;
      padding: 1rem 1rem 2rem 1rem;

      &.is-limited {
        max-width: 67rem;
        border-right: 1px solid #d1dbe3;
      }

      &.is-form-wrapper {
        padding-top: 0;
        padding-left: 0;
        padding-right: 0;
      }
    }

    &__list {
      display: flex;

      .list-jobs__wrapper {
        width: 31.25rem;
        overscroll-behavior-y: contain;
      }

      .list-jobs__preview {
        flex: 1;
        border-left: 1px solid #d1dbe3;

        &.is-fixed {
          position: fixed;
          top: 3.5rem;
          bottom: 0;
          right: 0;
          z-index: 91;
          background-color: #f8f8f8;
          width: calc(100% - 34.75rem);
          border-left: 1px solid #d1dbe3;
          height: calc(100vh - 3.5rem);
          overflow: auto;
          overscroll-behavior-y: contain;
        }

        &.is-collapsed {
          width: calc(100% - 45rem);
        }
      }
    }

    .designer__body__listing {
      padding: 1rem .5rem;
    }

    .designer__body__filters {
      position: absolute;
      left: 0;
      bottom: 0;
      top: 0;
      flex-shrink: 0;
      width: 26rem;
      background-color: #fff;
      border-right: 1px solid #d1dbe3;
      // padding-top: 2.5rem;
      // overflow: hidden;
      
      &.is-collapsed {
        transition: left .35s ease;
        left: -26rem !important;
      }

      &.is-fixed {
        position: fixed;
        top: 3.5rem;
        bottom: 0;
        left: 13.75rem;
        z-index: 2;
        overflow: auto;
      }

      &.is-fixed-collapsed {
        left: 3.5rem;
      }

      &.is-left {
        left: 0;
      }
    }
  }

  &__controls {
    display: flex;
    align-items: center;
    padding: 1rem 0 0;

    .app-btn {
      min-width: 10rem;
    }

    .app-btn+.app-btn {
      margin-left: 1rem;
    }
  }

  & > div[id] {
    height: calc(100vh - 3.5rem);

    .itarp-tenant-configuration-frontend-style-wrap {
      height: 100%;
    }
  } 
}

// Toolbar

.designer__toolbar {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 32.75rem;
  padding: 0;
  border-right: 1px solid #d1dbe3;
  background-color: #fff;
  height: 100%;
}

.designer-toolbar {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  &__controls {
    display: flex;
    align-items: center;

    .app-btn {
      flex: 1;
    }

    .app-btn+.app-btn {
      margin-left: 1rem;
    }
  }

  &__group {
    padding: 2rem 1.5rem 1.5rem;
    border-bottom: 2px solid #d1dbe3;

    &__title {
      color: #4ba4da;
      text-transform: uppercase;
      letter-spacing: -.2px;
      margin-bottom: 1.5rem;
    }

    &:last-child {
      border-bottom: none;
      padding-bottom: 4rem;
    }
  }

  &__form {
    padding: 2rem 1.5rem 1.5rem;
    border-bottom: 2px solid #d1dbe3;

    &__title {
      font-weight: 600;
      letter-spacing: -.2px;
      margin-bottom: 1.5rem;
    }

    &:last-child {
      border-bottom: none;
      padding-bottom: 4rem;
    }
  }

  &__header {
    padding: 1.25rem 1.5rem;
    color: #FFF;
    background-color: #8A90A3;

    &__link {
      position: relative;
      display: inline-block;
      padding: 0 0 0 2.25rem;
      font-size: 1.5rem;
      font-weight: 600;
      cursor: pointer;
      outline: 0;
      color: #FFFFFF;
      user-select: none;

      &:hover,
      &:focus {
        color: #FFFFFF;
      }

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        margin: -.9375rem 0 0;
        width: 1.875rem;
        height: 1.875rem;
        background: url('/itarp-shared-assets-addon/images/back_icon.svg') center center / contain no-repeat;
      }
    }
  }
}

// Editor 

.designer__editor {
  position: relative;
  height: 100%;
  overflow-y: auto;

  &__inner {
    height: 100%;
    width: 100%;
    min-width: 47.5rem;
    overflow-x: auto;
    padding: 0 0 2rem;
  }
}

// Droparea

.designer-droparea {
  padding: 2rem 1.5rem;

  .dropTarget {
    min-height: 4rem;
    border: 1px solid #4ba4da;
  }

  .row {
    min-height: 4rem;

    div[class^='col--'] {

      .dropTarget,
      .sortObject {
        height: 100%;
      }
    }
  }
}

// New Row

.designer-new-row {
  display: block;
  width: 100%;
  background-color: transparent;
  border: 1px dashed #4ba4da;
  padding: 1.5rem 2rem;

  &__label {
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;
    color: #4ba4da;
    display: inline-block;
    position: relative;
    padding: 0 0 0 1.5rem;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      margin: -.625rem 0 0;
      width: 1.25rem;
      height: 1.25rem;
      background: url('/itarp-shared-assets-addon/images/icon_add_circle.svg') center center no-repeat;
    }
  }

  &:hover,
  &:focus {
    background-color: rgba(#4BA4DA, .1);
  }
}

// Multistep Form
//---------------------------------------------

.multistep-form {
  position: relative;
  padding: 3rem 1rem;

  &__progress {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }

  & div[class^="AddTaxonomyScorecard_padd-wrapper"] {
    padding: 1rem 1rem 2rem !important;
    margin-bottom: 0 !important;
    // border-bottom: 2px solid #d1dbe3 !important;

    & > div[class^="AddTaxonomyScorecard_main-heading"] {
      &:first-child {
        font-size: 1.375rem !important;
        font-weight: 600 !important;
        line-height: 1.2 !important;
        font-family: 'proxima-nova', sans-serif !important;
      }
    }

    & > div[class^="AddTaxonomyScorecard_main-heading-content"] {
      font-size: 1rem;
      margin-top: 1rem !important;
      line-height: 1.325 !important;
      font-family: 'proxima-nova', sans-serif !important;
    }

    & > div[class^="AddTaxonomyScorecard_sub-heading"] {
      margin-top: 2rem !important;
      font-size: 1.125rem !important;
      font-weight: 600 !important;
      font-family: 'proxima-nova', sans-serif !important;
      margin-bottom: .5rem !important;

      & > .col-6 {
        width: 50% !important;

        &:first-child {
          font-size: 1.125rem !important;
          font-weight: 600 !important;
          font-family: 'proxima-nova', sans-serif !important;

          & > div[class^="AddTaxonomyScorecard_sub-heading-content"] {
            font-size: 1rem !important;
            font-weight: 400 !important;
            margin: 0 0 .25rem !important;
            font-family: 'proxima-nova', sans-serif !important;
          }
        }
      }

      & select {
        font-size: 1rem !important;
        font-weight: 400 !important;
        border-radius: .25rem !important;
        font-family: 'proxima-nova', sans-serif !important;
        color: #4a5162;
        padding: .9375rem 1rem;
        width: 100% !important;

        &:focus {
          border-color: #4ba4da !important;
          border-width: 2px !important;
          padding: .875rem .9375rem !important;
          box-shadow: none !important;
        }
      }
    }

    & div[class^="AddTaxonomyScorecard_scorecards"] {
      margin: 0 !important;

      & > div[class^="AddTaxonomyScorecard_selected-scorecard_"] {
        align-items: center;
        border-bottom: 1px solid #d1dbe3;

        & div[class^="PreviewTaxonomyScorecard_scorecard-name"],
        & div[class^="AddTaxonomyScorecard_scorecard-name"],
        & div[class^="PreviewScorecard_scorecard-name"] {
          font-size: 1rem !important;
          font-weight: 600 !important;
          margin: 0 !important;
          padding: 1rem 0;
          font-family: 'proxima-nova', sans-serif !important;
          
        }
      }

      & > hr {
        display: none !important;
      }
    }  

    & button[class^="AddTaxonomyScorecard_btn-primary"] {
      font-size: 1.0625rem !important;
      padding: .75rem 1.25rem !important;
      font-weight: 600 !important;
      font-family: 'proxima-nova', sans-serif !important;
      width: auto !important;
    }

    div[class^="CreateScorecard_padd-wrapper"] {
      padding-left: 0 !important;
      padding-right: 0 !important;
    } 

    div[class^="CreateScorecard_listing-score-grid"] {
      grid-template-columns: 1fr 240px;

      > div[class^="CreateScorecard_right-score-section"] {
        div[class^="CreateScorecard_scorecard-action-btns"] {
          div[class^="CreateScorecard_btn-wrapper"] {
            grid-template-columns: 100% !important;
            grid-template-rows: 1fr 1fr !important;
            height: 108px !important;
          }
        }
      }  
    }

    & > hr {
      display: none !important;
    }
  }
}

.d-none {
  display: none;
}
